import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Navarbar({ site_id, design_id }) {
  let navigate = useNavigate();
  return (
    <div className=" bg-white shadow-lg py-6 relative no-print ">
      <h2 className=" f-f-sm text-black  text-xl sm:text-2xl text-start md:text-center pl-3 md:pl-0 ">
        Basis of Design Document
      </h2>
      {/* <Link to={`/basis/design/${site_id}`} > */}
      <button
        onClick={() => navigate(-1)}
        className=" absolute right-[10px] top-[8px] sm:top-[2px] mt-3  border border-primary w-[110px] h-[40px] sm:w-[186px] text-center sm:h-[48px] rounded-[8px] f-f-r text-[13px] sm:text-lg text-primary  "
      >
        Close Preview
      </button>
      {/* </Link> */}
    </div>
  );
}
