import React, { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

import { Link } from "react-router-dom";

import Sidebardata from "../home/Sideebar";
import Header from "../home/header";
import Hero from "./ViewContactHero";
// import Tableproject from "./Tableproject";
// import UniversalDataTable from "./UniversalDataTable";
import Config from "../../utils/Config";

import { useNavigate, useParams } from "react-router-dom";

import swal from "sweetalert";

import { useSelector } from "react-redux";

export default function ViewContact() {
  const { contact_id } = useParams();
  const [contactSites, setContactSites] = React.useState([]);
  const [sites, setSites] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [lastPage, setLastPage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);

  const [contact, setContact] = React.useState(null);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [label, setLabel] = React.useState("");
  const [type, setType] = React.useState("");

  const [editContactModel, setEditContactModel] = React.useState(false);
  const [addSitesContactModel, setAddSitesContactModel] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const [contactLoading, setContactLoading] = React.useState(false);
  const [siteLoading, setSiteLoading] = React.useState(false);
  const [addSiteLoading, setAddSiteLoading] = React.useState(false);

  const cancelButtonRef = React.useRef(null);
  const navigate = useNavigate();

  const user = useSelector((state) => state.user.profile);

  const increasePage = () => {
    if (currentPage >= lastPage) {
      setPage(lastPage);
    } else {
      setPage(currentPage + 1);
    }
  };

  React.useEffect(() => {
    loadContact();
    loadContactSites();
  }, [user, page]);

  const loadContactSites = () => {
    setSiteLoading(true);
    fetch(`${Config.API.URL}/sites/contact/${contact_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        setSiteLoading(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          localStorage.removeItem("user");
          navigate("/");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setSiteLoading(false);
        // console.log('res', res);
        if (res.status) {
          setContactSites(res.data.data);
          //setContact(res.data);
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setSiteLoading(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  const loadContact = () => {
    setContactLoading(true);
    fetch(`${Config.API.URL}/contacts/${contact_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        setContactLoading(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          localStorage.removeItem("user");
          navigate("/");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setContactLoading(false);
        if (res.status) {
          setContact(res.data);
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setContactLoading(false);

        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  const _onEditContact = () => {
    // setContact(contact);

    setEditContactModel(true);

    setFirstName(contact.first_name ? contact.first_name : "");
    setLastName(contact.last_name ? contact.last_name : "");
    setEmail(contact.email ? contact.email : "");
    setPhoneNumber(contact.phone_number ? contact.phone_number : "");
    setCompany(contact.company ? contact.company : "");
    setTitle(contact.title ? contact.title : "");
    setLabel(contact.label ? contact.label : "");
    setType(contact.type ? contact.type : "");
  };
  const submitContact = () => {
    if (firstName.length && lastName.length && email.length) {
      setLoading(true);
      const payload = {
        title: title,
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phoneNumber,
        label: label,
        company: company,
        type: type,
      };

      fetch(`${Config.API.URL}/contacts/${contact?.id}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          setLoading(false);

          // if 401 error (unauthenticated user, then log user out)
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");
            localStorage.removeItem("user");
            navigate("/");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          setLoading(false);

          // if request is succesful, alert user that project has been cloned
          if (res.status) {
            setEditContactModel(false);
            setContact(res.data);
          } else {
            swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          setLoading(false);

          console.log("Error: ", error);

          swal(
            "Oh no!",
            "An error occured!!! while connecting to server. Please try again",
            "error"
          );
        });
    } else {
      swal("Oh no!", "All fields with asterisk(*) are required", "error");
    }
  };

  const initAddingSitesContact = () => {
    setAddSitesContactModel(true);
    setAddSiteLoading(true);

    fetch(`${Config.API.URL}/sites`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          localStorage.removeItem("user");
          navigate("/");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setAddSiteLoading(false);
        // console.log('res:', res);
        if (res.status) {
          setSites(res.data.data);
          setLastPage(res.data.last_page);
          setCurrentPage(res.data.current_page);
          setTotal(res.data.total);
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setAddSiteLoading(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  const _onChangeSite = (e) => {
    const data = [...sites];
    if (data[e.target.id].checked === undefined) {
      data[e.target.id].checked = false;
    }

    data[e.target.id].checked = !data[e.target.id].checked;
    setSites(data);
  };

  const addSitesContact = async () => {
    setLoading(true);
    for (let i = 0; i < sites.length; i++) {
      const site = sites[i];
      if (site.checked) {
        const site_contacts = [parseInt(contact_id)];
        for (let i = 0; i < site?.contacts.length; i++) {
          const contact = site?.contacts[i];
          site_contacts.push(contact.id);
        }

        // console.log('site:', site);
        // console.log(i, 'site_contacts:', site_contacts);

        //const contact_sites = [];
        //const payload = { site_contacts: [contact_id] };

        fetch(`${Config.API.URL}/sites/${site.id}`, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({ site_contacts }),
        })
          .then((res) => {
            setLoading(false);
            // setLoadingEditSiteInformation(false);
            // if 401 error (unauthenticated user, then log user out)
            if (res.status === 401) {
              console.log("401 Error: Unauthenticated");

              localStorage.removeItem("user");
              navigate("/");
            }
            return res;
          })
          .then((res) => res.json())
          .then(async (res) => {
            setLoading(false);
            // setLoadingEditSiteInformation(false);
            // console.log("res:", res);

            // if request is succesful, alert user that project has been cloned
            if (res.status) {
              const data = res.data;

              setAddSitesContactModel(false);
              setContactSites([data, ...contactSites]);
            } else {
              swal("Oh no!", res.message, "error");
            }
          })
          .catch((error) => {
            setLoading(false);
            // setLoadingEditSiteInformation(false);
            console.log("Error: ", error);

            swal(
              "Oh no!",
              "An error occured!!! while connecting to server. Please try again",
              "error"
            );
          });
      }
    }

    // console.log('contact_sites:', contact_sites);
  };

  const removeSiteContact = async (site) => {
    // console.log('site?.contacts', site?.contacts);

    const site_contacts = [];
    for (let i = 0; i < site?.contacts.length; i++) {
      const contact_item = site?.contacts[i];
      if (contact.id !== contact_item.id) {
        site_contacts.push(contact_item.id);
      }
    }

    // console.log('contact.id:', contact.id);
    // console.log('site_contacts:', site_contacts);

    swal({
      title: `Are you sure?`,
      text: `You want to remove "${contact?.first_name} ${contact?.last_name}" from "${site.site_name}" contact list? because once deleted, you will not be able to recover this action!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(`${Config.API.URL}/sites/${site.id}`, {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({ site_contacts }),
        })
          .then((res) => {
            // if 401 error (unauthenticated user, then log user out)
            if (res.status === 401) {
              console.log("401 Error: Unauthenticated");
              localStorage.removeItem("user");
              navigate("/");
            }
            return res;
          })
          .then((res) => res.json())
          .then(async (res) => {
            // console.log('remove-res:', res);
            // if request is succesful, alert user that project has been cloned
            if (res.status) {
              let index = contactSites.findIndex((x) => x.id === res?.data?.id);
              let data = [...contactSites];

              data.splice(index, 1);
              setContactSites(data);

              swal(
                `The contact "${contact?.first_name} ${contact?.last_name}" has been successfully removed from "${site.site_name}" contact list!`,
                { icon: "success" }
              );
            } else {
              swal("Oh no!", res.message, "error");
            }
          })
          .catch((error) => {
            console.log("Error: ", error);

            swal(
              "Oh no!",
              "An error occured!!! while connecting to server. Please try again",
              "error"
            );
          });
      }
    });
  };

  const _onDeleteContact = () => {
    swal({
      title: `Are you sure?`,
      text: `You want to delete "${contact.first_name}"? because once deleted, you will not be able to recover this contact!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(`${Config.API.URL}/contacts/${contact.id}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        })
          .then((res) => {
            // if 401 error (unauthenticated user, then log user out)
            if (res.status === 401) {
              console.log("401 Error: Unauthenticated");
              localStorage.removeItem("user");
              navigate("/");
            }
            return res;
          })
          .then((res) => res.json())
          .then(async (res) => {
            // if request is succesful, alert user that project has been cloned
            if (res.status) {
              navigate("/contacts");
              // let index = contacts.findIndex((x) => x.id === contact.id);
              // let data  = [ ...contacts ];

              // data.splice(index, 1);
              // setContacts(data);

              //swal( `The "${contact.first_name}" contact has been successfully deleted!`, { icon: "success" } );
            } else {
              swal("Oh no!", res.message, "error");
            }
          })
          .catch((error) => {
            console.log("Error: ", error);

            swal(
              "Oh no!",
              "An error occured!!! while connecting to server. Please try again",
              "error"
            );
          });
      }
    });
  };

  return (
    <>
      <div className="grid grid-cols-12  ">
        <div className=" lg:col-span-2">
          <Sidebardata />
        </div>
        <div className=" transition-none col-span-12 lg:col-span-12 mr-3  ">
          <div className=" w-[85%] sm:w-[95%] ml-auto ">
            <Header />
            <Hero contact_id={contact_id} />

            <div className=" mt-10 bg-white rounded-[8px] p-4">
              <div className="col-span-12 md:col-span-6 mt-5">
                <ul className=" w-full md:inline-flex">
                  <li>
                    <h3 className=" text-black text-2xl f-f-r ">
                      Contact Library
                    </h3>{" "}
                  </li>
                  <li className=" ml-2 mt-2">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.99988 16.5001C13.142 16.5001 16.4999 13.1422 16.4999 9.00006C16.4999 4.85793 13.142 1.50006 8.99988 1.50006C4.85774 1.50006 1.49988 4.85793 1.49988 9.00006C1.49988 13.1422 4.85774 16.5001 8.99988 16.5001Z"
                        stroke="#D0D1D2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9 6.00006V9.00006"
                        stroke="#D0D1D2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <circle cx="8.99988" cy="12" r="0.75" fill="#D0D1D2" />
                    </svg>
                  </li>
                </ul>
              </div>

              <div className="flex space-x-4 justify-center mb-10">
                <div className="bg-white overflow-hidden shadow rounded-lg border mx-4 box">
                  <div className="px-4 py-5 sm:px-6">
                    <div className="flex justify-between items-center">
                      <h3 className="text-xl leading-6 font-medium text-primary-dark">
                        Contact Details
                      </h3>
                      <div>
                        <button
                          onClick={_onDeleteContact}
                          className="text-base font-medium text-red-500 mr-2"
                        >
                          Delete
                        </button>
                        <button
                          onClick={_onEditContact}
                          className="text-base font-medium text-gray-500 ml-2"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                    <p className="mt-1 max-w-2xl text-base text-gray-500">
                      The contact information is provided below.
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">
                      <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-base font-medium text-gray-500">
                          Full name
                        </dt>
                        <dd className="mt-1 text-base text-end text-gray-900 sm:mt-0 sm:col-span-2">
                          {contactLoading
                            ? "Loading..."
                            : `${contact?.first_name} ${contact?.last_name}`}
                        </dd>
                      </div>
                      <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-base font-medium text-gray-500">
                          Email address:
                        </dt>
                        <dd className="mt-1 text-base text-end text-gray-900 sm:mt-0 sm:col-span-2">
                          {contactLoading ? "Loading..." : contact?.email}
                        </dd>
                      </div>
                      <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-base font-medium text-gray-500">
                          Phone number:
                        </dt>
                        <dd className="mt-1 text-base text-end text-gray-900 sm:mt-0 sm:col-span-2">
                          {contactLoading
                            ? "Loading..."
                            : contact?.phone_number}
                        </dd>
                      </div>
                      <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-base font-medium text-gray-500">
                          Contact Type:
                        </dt>
                        <dd className="mt-1 text-base text-end text-gray-900 sm:mt-0 sm:col-span-2">
                          {contactLoading ? "Loading..." : contact?.type}
                        </dd>
                      </div>
                      <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-base font-medium text-gray-500">
                          Company:
                        </dt>
                        <dd className="mt-1 text-base text-end text-gray-900 sm:mt-0 sm:col-span-2">
                          {contactLoading ? "Loading..." : contact?.company}
                        </dd>
                      </div>
                      <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-base font-medium text-gray-500">
                          Job Title:
                        </dt>
                        <dd className="mt-1 text-base text-end text-gray-900 sm:mt-0 sm:col-span-2">
                          {contactLoading ? "Loading..." : contact?.title}
                        </dd>
                      </div>
                      <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-base font-medium text-gray-500">
                          Department:
                        </dt>
                        <dd className="mt-1 text-base text-end text-gray-900 sm:mt-0 sm:col-span-2">
                          {contactLoading ? "Loading..." : contact?.label}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>

                <div className="bg-white overflow-hidden shadow rounded-lg border box">
                  <div className="px-4 py-5 sm:px-6">
                    <div className="flex justify-between items-center">
                      <h3 className="text-xl leading-6 font-medium text-primary-dark">
                        {" "}
                        Assigned Project Sites{" "}
                      </h3>
                      <button
                        onClick={initAddingSitesContact}
                        className="text-base font-medium text-gray-500 mx-4"
                      >
                        {" "}
                        Add
                      </button>
                    </div>
                    <p className="mt-1 max-w-2xl text-base text-gray-500">
                      You can find the project sites assigned to this contact
                      below.
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200 overflow-y-scroll h-[420px]">
                      {siteLoading ? (
                        <p style={{ textAlign: "center", marginTop: 5 }}>
                          Loading...
                        </p>
                      ) : (
                        contactSites.map((site, i) => (
                          <div
                            key={i}
                            className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                          >
                            <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                              <strong>{site?.project_name}</strong> <br />
                              {site?.site_name}
                            </dd>
                            <div
                              style={{
                                display: "flex",
                                flex: 0,
                                justifyContent: "end",
                              }}
                            >
                              <button
                                className="text-base font-medium text-red-500"
                                onClick={() => removeSiteContact(site)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))
                      )}
                      {/* <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                          RG 301 <br />
                          Wahl Ranch
                        </dd>
                        <button className="text-base font-medium text-gray-500">Remove</button>
                      </div>
                      <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                          RG 301 <br />
                          Lagrand Valley
                        </dd>
                        <button className="text-base font-medium text-gray-500">Remove</button>
                      </div>
                      <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                          RG 301 <br />
                          Wahl Ranch
                        </dd>
                        <button className="text-base font-medium text-gray-500"> Remove</button>
                      </div>
                      <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                          RG 301 <br />
                          Lagrand Valley
                        </dd>
                        <button className="text-base font-medium text-gray-500">Remove</button>
                      </div>
                      <div className="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dd className="mt-1 text-base text-gray-900 sm:mt-0 sm:col-span-2">
                          RG 301 <br />
                          Wahl Ranch
                        </dd>
                        <button className="text-base font-medium text-gray-500">Remove</button>
                      </div> */}
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Transition.Root show={editContactModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditContactModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Contact Information
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                First name{" "}
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => setFirstName(e.target.value)}
                                  value={firstName}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Last name{" "}
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  onChange={(e) => setLastName(e.target.value)}
                                  value={lastName}
                                  rows="4"
                                  className="pr-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                E-mail Address{" "}
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="email"
                                  onChange={(e) => setEmail(e.target.value)}
                                  value={email}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Phone Number
                              </label>
                              <div className="mt-0">
                                <PhoneInput
                                  value={phoneNumber}
                                  onChange={setPhoneNumber}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6"
                                  flags={flags}
                                  defaultCountry="US"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Contact Type
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) => setType(e.target.value)}
                                  value={type}
                                  className="cursor-pointer p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="Employee">Employee</option>
                                  <option value="Subcontractor">
                                    Subcontractor
                                  </option>
                                  <option value="Client">Client</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Company
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => setCompany(e.target.value)}
                                  value={company}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Title
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => setTitle(e.target.value)}
                                  value={title}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-base font-medium leading-6 text-gray-900">
                                Department/Scope
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => setLabel(e.target.value)}
                                  value={label}
                                  placeholder="e.g. Civil & Electrical"
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loading ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={submitContact}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-base font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditContactModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={addSitesContactModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setAddSitesContactModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        {/* <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Add Sites to Contact
                        </Dialog.Title> */}
                        <div className="mt-2" style={{ width: "100%" }}>
                          <h1
                            style={{
                              fontSize: "1.0rem",
                              textAlign: "center",
                              fontWeight: "bold",
                            }}
                          >
                            Select site(s) from the options below:
                          </h1>
                          <div className=" mt-8 ">
                            {/* started */}
                            <h4 className=" text-black-vlight text-tiny f-f-r mt-2 ">
                              {total} Results
                            </h4>
                            <div className="overflow-y-scroll h-[400px]">
                              {addSiteLoading ? (
                                <p
                                  style={{ textAlign: "center", marginTop: 5 }}
                                >
                                  Loading...
                                </p>
                              ) : (
                                <ul className=" mt-6">
                                  {sites.map((site, i) => (
                                    <li key={i}>
                                      <div className="flex items-center mb-4">
                                        <input
                                          type="checkbox"
                                          value=""
                                          id={i}
                                          onChange={_onChangeSite}
                                          checked={
                                            site.checked === undefined
                                              ? false
                                              : site.checked
                                          }
                                          className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                                        />
                                        <label
                                          htmlFor={i}
                                          className="ml-4 f-f-r text-lg text-black"
                                        >
                                          {site.site_name}
                                        </label>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              )}
                              {/* <div className="inline-flex w-full justify-center">
                                <button
                                  className=" bg-white text-base text-black border border-black  w-[127px] h-[40px] rounded-[20px] mr-1 captilize "
                                >
                                  Load More
                                </button>
                              </div> */}
                            </div>

                            {/* ended */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loading ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={addSitesContact}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-base font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setAddSitesContactModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
