import React, { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

import { Link } from "react-router-dom";

import Sidebardata from "../home/Sideebar";
import Header from "../home/header";
import Hero from "./Hero";
import Loading from "../../Website/Loading";
import UniversalDataTable from "./UniversalDataTable";
import Config from "../../utils/Config";

import { useNavigate } from "react-router-dom";

import swal from "sweetalert";

import { useSelector } from "react-redux";

//import LoadingOverlay from 'react-loading-overlay-ts';

export default function Index() {
  const [ contacts, setContacts ] = React.useState([]);
  const [ page, setPage ] = React.useState(1);
  const [ lastPage, setLastPage ] = React.useState(1);
  const [ currentPage, setCurrentPage ] = React.useState(1);

  const [ contact, setContact ] = React.useState(null);

  const [ firstName, setFirstName ] = React.useState("");
  const [ lastName, setLastName ] = React.useState("");
  const [ email, setEmail ] = React.useState("");
  const [ phoneNumber, setPhoneNumber ] = React.useState("");
  const [ company, setCompany ] = React.useState("");
  const [ title, setTitle ] = React.useState("");
  const [ label, setLabel ] = React.useState("");
  const [ type, setType ] = React.useState("");

  const [ editContactModel, setEditContactModel ] = React.useState(false);

  const [ loading, setLoading ] = React.useState(false);

  const [ contactLoading, setContactLoading ] = React.useState(false);
  const [ isAscending, setIsAscending ] = React.useState(true);

  const cancelButtonRef = React.useRef(null);
  const navigate        = useNavigate();

  const user            = useSelector((state) => state.user.profile);

  // const [isActive, setActive] = React.useState(true)
  // const handleButtonClick = React.useCallback(() => {
  //   setActive(value => !value);
  // }, [])

  React.useEffect(() => {
    loadContacts(isAscending ? "DESC" : "ASC");
  }, [user, page]);

  const loadContacts = (sort_direction) => {

    console.log('sort_direction', sort_direction);
    setContactLoading(true);
    fetch(`${Config.API.URL}/contacts?page=${page}&sort_by=first_name&sort_direction=${sort_direction}`, {
      method: "GET",
      headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
    }).then((res) => {
      setContactLoading(false);
      // if 401 error (unauthenticated user, then log user out)
      if (res.status === 401) {
        console.log("401 Error: Unauthenticated");

        localStorage.removeItem("user");
        navigate("/");
      }
      return res;
    }).then((res) => res.json()).then(async (res) => {
      setContactLoading(false);
      if (res.status) {
        setContacts(res.data.data);
        setLastPage(res.data.last_page);
        setCurrentPage(res.data.current_page);
      } else {
        swal("Oh no!", res.message, "error");
      }
    }).catch((error) => {
      setContactLoading(false);
      console.log("Error: ", error);

      swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
    });
  };

  const _onDeleteContact = (contact) => {
    swal({
      title: `Are you sure?`,
      text: `You want to delete "${contact.first_name}"? because once deleted, you will not be able to recover this contact!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(`${Config.API.URL}/contacts/${contact.id}`, {
          method: "DELETE",
          headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
        })
          .then((res) => {
            // if 401 error (unauthenticated user, then log user out)
            if (res.status === 401) {
              console.log("401 Error: Unauthenticated");
              localStorage.removeItem("user");
              navigate("/");
            }
            return res;
          })
          .then((res) => res.json())
          .then(async (res) => {
            // if request is succesful, alert user that project has been cloned
            if (res.status) {
              let index = contacts.findIndex((x) => x.id === contact.id);
              let data  = [ ...contacts ];

              data.splice(index, 1);
              setContacts(data);

              swal( `The "${contact.first_name}" contact has been successfully deleted!`, { icon: "success" } );
            } else {
              swal("Oh no!", res.message, "error");
            }
          })
          .catch((error) => {
            console.log("Error: ", error);

            swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
          });
      }
    });
  };
  const _onEditContact = (contact) => {
    setContact(contact);

    setEditContactModel(true);

    setFirstName(contact.first_name ? contact.first_name : "");
    setLastName(contact.last_name ? contact.last_name : "");
    setEmail(contact.email ? contact.email : "");
    setPhoneNumber(contact.phone_number ? contact.phone_number : "");
    setCompany(contact.company ? contact.company : "");
    setTitle(contact.title ? contact.title : "");
    setLabel(contact.label ? contact.label : "");
    setType(contact.type ? contact.type : "");
  };
  const submitContact = () => {
    if (firstName.length && lastName.length && email.length) {
      setLoading(true);
      const payload = {
        title: title,
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phoneNumber,
        label: label,
        company: company,
        type: type,
      };

      fetch(`${Config.API.URL}/contacts/${contact?.id}`, {
        method: "PATCH",
        headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          setLoading(false);

          // if 401 error (unauthenticated user, then log user out)
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");
            localStorage.removeItem("user");
            navigate("/");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          setLoading(false);

          // if request is succesful, alert user that project has been cloned
          if (res.status) {
            setEditContactModel(false);

            let index   = contacts.findIndex((x) => x.id === contact.id);
            let data    = [ ...contacts ];

            data[index] = res.data;

            setContacts(data);
          } else {
            swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          setLoading(false);

          console.log("Error: ", error);

          swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
        });
    } else {
      swal("Oh no!", "All fields with asterisk(*) are required", "error");
    }
  };

  const _onSearchContacts = (keywords) => {
    if (keywords.length >= 3) {
      fetch(`${Config.API.URL}/contacts/search?keyword=${keywords}&page=1&limit=10`, {
        method: "GET",
        headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
      }).then((res) => {
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
          localStorage.removeItem("user");
          navigate("/");
        }
        return res;
      }).then((res) => res.json()).then(async (res) => {
        if (res.status) {
          const result = res.data;
          result.data ? setContacts(result.data) : setContacts(result);
          setLastPage(result.last_page);
          setCurrentPage(result.current_page);
        } else {
          swal("Oh no!", res.message, "error");
        }
      }).catch((error) => {
        console.log("Error: ", error);

        swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error" );
      });
    } else {
      if (!keywords.length) {
        loadContacts(isAscending ? "DESC" : "ASC");
      }
    }
  };
  const _onSortedData = (isAsc) => {
    console.log('isAsc:', isAsc);
    loadContacts(isAsc ? "DESC" : "ASC");

    setIsAscending(isAsc);
  }
  return (
    <>
      {/* <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
        <LoadingOverlay
          active={isActive}
          spinner
          text='Loading your content...'
        >
          <div style={{ height: '100vh', position: 'relative' }}>
          </div>
        </LoadingOverlay>
      </div> */}
      <div className="grid grid-cols-12  ">
        <div className=" lg:col-span-2">
          <Sidebardata />
        </div>
        <div className=" transition-none col-span-12 lg:col-span-12 mr-3  ">
          <div className=" w-[85%] sm:w-[95%] ml-auto ">
            <Header />
            <Hero />
            { contactLoading ?
              <Loading />
            :
              <div className=" mt-10 bg-white rounded-[8px] p-4">
                <UniversalDataTable
                  table_label="Contact Library"
                  header_format={[ 
                    { column: "contact_name", label: "Contact Name" }, 
                    { column: "email", label: "E-mail" }, 
                    { column: "phone_number", label: "Phone Number" }, 
                    { column: "company", label: "Company" }, 
                    { column: "type", label: "Contact Type" }, 
                    { column: "label", label: "Department/Scope" }, 
                    { column: "last_modified_by", label: "Last modified by" }, 
                    { column: "title", label: "Job Title" }
                  ]}
                  data={contacts}
                  data_format={[
                    { column: "contact_name", keys: ["first_name", "last_name"] }, 
                    { column: "email", keys: ["email"] }, 
                    { column: "phone_number", keys: ["phone_number"] }, 
                    { column: "company", keys: ["company"] }, 
                    { column: "type", keys: ["type"] }, 
                    { column: "label", keys: ["label"] }, 
                    { column: "last_modified_by", keys: ["last_modified_by"] }, 
                    { column: "title", keys: ["title"], button: true }
                  ]}
                  columns_format={
                    {
                      contact_name: true,
                      email: true,
                      phone_number: true,
                      company: true,
                      last_modified_by: false,
                      title: true,
                      type: true,
                      label: true,
                    }
                  }
                  setPage={setPage}
                  lastPage={lastPage}
                  currentPage={currentPage}
                  onCreateItem={() => navigate("/contact/create") }
                  onViewItem={(contact) => navigate(`/contact/view/${contact.id}`) }
                  onDeleteItem={_onDeleteContact}
                  onEditItem={_onEditContact}
                  onSearchData={_onSearchContacts}
                  //onSortedData={_onSortedData}
                  //isAscending={isAscending} 
                  //setIsAscending={setIsAscending}
                />
              </div>
            }
          </div>
        </div>
      </div>

      <Transition.Root show={editContactModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditContactModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Contact Information
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                First name{" "}
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => setFirstName(e.target.value)}
                                  value={firstName}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Last name{" "}
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-2">
                                <input
                                  type="text"
                                  onChange={(e) => setLastName(e.target.value)}
                                  value={lastName}
                                  rows="4"
                                  className="pr-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                E-mail Address{" "}
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="email"
                                  onChange={(e) => setEmail(e.target.value)}
                                  value={email}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Phone Number
                              </label>
                              <div className="mt-0">
                                <PhoneInput
                                  value={phoneNumber}
                                  onChange={setPhoneNumber}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  flags={flags}
                                  defaultCountry="US"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Contact Type
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) => setType(e.target.value)}
                                  value={type}
                                  className="cursor-pointer p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="Employee">Employee</option>
                                  <option value="Subcontractor">
                                    Subcontractor
                                  </option>
                                  <option value="Client">Client</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Company
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => setCompany(e.target.value)}
                                  value={company}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Title
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => setTitle(e.target.value)}
                                  value={title}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Department/Scope
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => setLabel(e.target.value)}
                                  value={label}
                                  placeholder="e.g. Civil & Electrical"
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loading ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={submitContact}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditContactModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
