import React from 'react'
import Basicdesign from '../../assets/images/Basic-desigg.svg'
import Basicdesign2 from '../../assets/images/Basic-desigg2.svg'
import Scopework from '../../assets/images/scope-work.svg'
import Scopework2 from '../../assets/images/scope-work-2.svg'
import Bill from '../../assets/images/Bill.svg'
import Bill2 from '../../assets/images/Bill-2.svg'
import { Link } from 'react-router-dom'

export default function Actioncard() {

       return (
              <div className=' w-11/12 lg:w-full ml-auto mr-auto ' >
                     <h3 className=' text-primary text-2xl f-f-r mt-10 ' >
                     Deliverable Library
                     </h3>
                     {/*   Product Library grid started  */}
                     <div className="grid grid-cols-12 mt-5 gap-4  ">

                            {/* second grid started */}
                            <div className="   col-span-12 sm:col-span-6 xl:col-span-3">
                                   <Link to="/basicdesign" >
                                   <div className='  solar-crd  bg-white text-black  hover:bg-primary  shadow-lg p-[10px] rounded-[8px] ' >
                                          <h2 className='  text-xl f-f-sm text-center  ' >Basis of Design</h2>
                                   
                                          {/*  svg 1  */}
                             
                                          <img src={Basicdesign} className=' w-[65px] h-[65px] ml-auto mr-auto my-2 svg-1 '  />
                                          
                                          {/* svg 1 ended */}
                                          {/* svg 1 started */}
                                     
                                          <img src={Basicdesign2} className=' w-[65px] h-[65px] ml-auto mr-auto my-2 svg-2 '  />
                                          
                                          
                                          {/* svg 1 ended */}

                                   </div>
                                   </Link>
                            </div>
                            {/* Grid ended */}
                            {/* second grid started */}
                            <div className="   col-span-12 sm:col-span-6 xl:col-span-3">
                                   <div className='  solar-crd  bg-white text-black  hover:bg-primary  shadow-lg p-[10px] rounded-[8px] ' >
                                          <h2 className='  text-xl f-f-sm text-center  ' >Scope of Work</h2>
                      
                                          {/*  svg 1  */}
                                          

                                         {/*  svg 1  */}
                             
                                         <img src={Scopework} className=' w-[65px] h-[65px] ml-auto mr-auto my-2 svg-1 '  />
                                          
                                          {/* svg 1 ended */}
                                          {/* svg 1 started */}
                                     
                                          <img src={Scopework2} className=' w-[65px] h-[65px] ml-auto mr-auto my-2 svg-2 '  />
                                          
                                          
                                          {/* svg 1 ended */}

                                   </div>

                            </div>
                            {/* Grid ended */}

                            {/* second grid started */}
                            <div className="   col-span-12 sm:col-span-6 xl:col-span-3">
                                   <div className='  solar-crd  bg-white text-black  hover:bg-primary  shadow-lg p-[10px] rounded-[8px] ' >
                                          <h2 className='  text-xl f-f-sm text-center  ' >Bill of Materials</h2>
                                          {/*  svg 1  */}

                                          <img src={Bill} className=' w-[65px] h-[65px] ml-auto mr-auto my-2 svg-1 '  />
                                          {/* svg 1 ended */}
                                          {/* svg 1 started */}
                                          <img src={Bill2} className=' w-[65px] h-[65px] ml-auto mr-auto my-2 svg-2 '  />
                                          

                                          {/* svg 1 ended */}

                                   </div>

                            </div>
                            {/* Grid ended */}
                       

                   



                     </div>
              
                    
              </div>
       )
}
