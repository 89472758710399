import React from 'react'
import Navarbar from './Navarbar'
import Hero from './Hero';

import './index.css'

import { useParams } from 'react-router-dom';

export default function Index() {
  const { site_id, design_id } = useParams();
  return (
    <div>
      <Navarbar site_id={site_id} design_id={design_id} />
      <Hero site_id={site_id} design_id={design_id} />
    </div>
  )
}
