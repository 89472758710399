import React, { Fragment, useRef, useState, useEffect } from "react";

import { Dialog, Transition } from "@headlessui/react";
// import profileimg from "../../assets/images/profile.svg";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

import Loading from "../../Website/Loading";

import Config from "../../utils/Config";


import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const navigate = useNavigate();
  const [profile, setProfile] = React.useState(null);
  const [editProfileInformationModel, setEditProfileInformationModel] = useState(false);
  
  const [ loading, setLoading ] = React.useState(false);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");

  const user = useSelector((state) => state.user.profile);
  const cancelButtonRef = useRef(null);

  React.useEffect(() => {
    setLoading(true);
    fetch(`${Config.API.URL}/profiles/${user.id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        setLoading(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
          localStorage.removeItem("user");
          navigate("/");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoading(false);
        //console.log("res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          const data = res.data;
          setProfile(data);

          setFirstName(data.first_name ? data.first_name : "");
          setLastName(data.last_name ? data.last_name : "");
          setCompany(data.company ? data.company : "");
          setCountry(data.country ? data.country : "");
          setPhone(data.phone ? data.phone : "");
          setEmail(data.email ? data.email : "");
        } else {
          alert(res.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error: ", error);
      });
  }, [user, navigate]);

  const editProfileInformation = () => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      country: country,
      company: company,
    };

    fetch(`${Config.API.URL}/profiles/${profile.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
          localStorage.removeItem("user");
          navigate("/");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        console.log("res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setEditProfileInformationModel(false);
          setProfile(res.data);
        } else {
          alert(res.message);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };
  if (!profile) {
    return <Loading />;
  }
  return (
    <div className="bg-white rounded-[8px] p-4  mt-4 shadow-lg ">
      <div className=" text-center ">
        <button className=" relative mt-4 ml-auto mr-auto  ">
          <img
            src={`https://ui-avatars.com/api/?name=${profile?.first_name}+${profile?.last_name}&background=random&rounded=true`}
            alt=""
            className=" h-[92px] w-[92px] relative "
          />

          {/* <svg className='absolute  right-0 top-[-7px] ' width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14.5" cy="14.5" r="14.5" fill="white" />
            <circle cx="14.5" cy="14.5" r="14" stroke="#A3A3A3" stroke-opacity="0.25" />
            <path d="M8.375 20.625H9.6L17.1469 13.0781L15.9219 11.8531L8.375 19.4V20.625ZM20.8875 11.8094L17.1687 8.13437L18.3937 6.90938C18.7292 6.57396 19.1413 6.40625 19.6301 6.40625C20.119 6.40625 20.5308 6.57396 20.8656 6.90938L22.0906 8.13437C22.426 8.46979 22.601 8.87462 22.6156 9.34887C22.6302 9.82312 22.4698 10.2277 22.1344 10.5625L20.8875 11.8094ZM19.6187 13.1L10.3437 22.375H6.625V18.6562L15.9 9.38125L19.6187 13.1ZM16.5344 12.4656L15.9219 11.8531L17.1469 13.0781L16.5344 12.4656Z" fill="black" />
          </svg> */}
        </button>
        <h2 className=" f-f-sm text-2xl text-black my-1  ">{`${profile?.first_name} ${profile?.last_name}`}</h2>
        <h2 className=" f-f-r text-lg text-black-vlight my-1  ">
          {user?.email}
        </h2>
      </div>
      {/* grid started */}
      <div className="  sm:px-8 ">
        <div className="grid grid-cols-12 mt-5 gap-4  ">
          <div className=" col-span-3  ">
            <h2 className=" f-f-r text-xl sm:text-2xl text-black-vlight   ">
              {" "}
              Name{" "}
            </h2>
          </div>
          <div className=" col-span-9 ">
            <ul className=" inline-flex  w-full ">
              <li className=" w-[100%] ">
                <h2 className=" f-f-r text-xl sm:text-2xl text-black   ">
                  {" "}
                  {`${profile?.first_name} ${profile?.last_name}`}{" "}
                </h2>
              </li>
              <li onClick={() => setEditProfileInformationModel(true)}>
                <h2 className=" f-f-sm text-2xl text-primary cursor-pointer   ">
                  {" "}
                  Edit{" "}
                </h2>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="profilebrdr my-3 "></div>
      {/* Grid ended */}
      {/* grid started */}
      <div className="  sm:px-8 ">
        <div className="grid grid-cols-12 mt-5 gap-4  ">
          <div className=" col-span-3  ">
            <h2 className=" f-f-r text-xl sm:text-2xl text-black-vlight   ">
              {" "}
              Company{" "}
            </h2>
          </div>
          <div className=" col-span-9 ">
            <ul className=" inline-flex  w-full ">
              <li className=" w-[100%] ">
                <h2 className=" f-f-r text-xl sm:text-2xl text-black   ">
                  {" "}
                  {profile?.company}{" "}
                </h2>
              </li>
              {/* <li>
                                <h2 className=' f-f-sm text-2xl text-primary cursor-pointer   ' > Edit </h2>
                            </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="profilebrdr my-3 "></div>
      {/* Grid ended */}
      {/* grid started */}

      <div className="  sm:px-8 ">
        <div className="grid grid-cols-12 mt-5 gap-4  ">
          <div className=" col-span-3  ">
            <h2 className=" f-f-r text-xl sm:text-2xl text-black-vlight   ">
              {" "}
              Country{" "}
            </h2>
          </div>
          <div className=" col-span-9 ">
            <ul className=" inline-flex  w-full ">
              <li className=" w-[100%] ">
                <h2 className=" f-f-r text-xl sm:text-2xl text-black   ">
                  {profile?.country}{" "}
                </h2>
              </li>
              {/* <li>
                                <h2 className=' f-f-sm text-2xl text-primary cursor-pointer   ' > Edit </h2>
                            </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="profilebrdr my-3 "></div>
      {/* Grid ended */}
      {/* grid started */}

      <div className="  sm:px-8 ">
        <div className="grid grid-cols-12 mt-5 gap-4  ">
          <div className=" col-span-3  ">
            <h2 className=" f-f-r text-xl sm:text-2xl text-black-vlight   ">
              {" "}
              Phone Number{" "}
            </h2>
          </div>
          <div className=" col-span-9 ">
            <ul className=" inline-flex  w-full ">
              <li className=" w-[100%] ">
                <h2 className=" f-f-r text-xl sm:text-2xl text-black   ">
                  {" "}
                  {profile?.phone}{" "}
                </h2>
              </li>
              {/* <li>
                                <h2 className=' f-f-sm text-2xl text-primary cursor-pointer   ' > Edit </h2>
                            </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="profilebrdr my-3 "></div>
      {/* Grid ended */}
      {/* grid started */}
      <div className="  sm:px-8 ">
        <div className="grid grid-cols-12 mt-5 gap-4  ">
          <div className=" col-span-3  ">
            <h2 className=" f-f-r text-xl sm:text-2xl text-black-vlight   ">
              {" "}
              Email{" "}
            </h2>
          </div>
          <div className=" col-span-9 ">
            <ul className=" inline-flex  w-full ">
              <li className=" w-[100%] ">
                <h2 className=" f-f-r text-xl sm:text-2xl text-black   ">
                  {" "}
                  {profile?.email}{" "}
                </h2>
              </li>
              <li>
                {/* <h2 className=' f-f-sm text-2xl text-primary cursor-pointer   ' > Edit </h2> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="profilebrdr my-3 "></div>
      {/* Grid ended */}
      <Transition.Root show={editProfileInformationModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditProfileInformationModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Profile Information
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <form>
                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  first Name
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      setFirstName(e.target.value)
                                    }
                                    value={firstName}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Last Name
                                </label>
                                <div className="mt-2">
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      setLastName(e.target.value)
                                    }
                                    value={lastName}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                                {/* <div className="mt-0">
                                <input type="text" onChange={e => setProjectSummary(e.target.value)} value={projectSummary} className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                              </div> */}
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Company
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={(e) => setCompany(e.target.value)}
                                    value={company}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Country
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={(e) => setCountry(e.target.value)}
                                    value={country}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Phone Number
                                </label>
                                <div className="mt-0">
                                  {/*                                   <input
                                    type="tel"
                                    onChange={(e) =>
                                      setPhone(e.target.value)
                                    }
                                    value={phone}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  /> */}
                                  <PhoneInput
                                    value={phone}
                                    onChange={setPhone}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    flags={flags}
                                    defaultCountry="US"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  E-mail Address
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                      onClick={editProfileInformation}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditProfileInformationModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
