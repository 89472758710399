import React from "react";
import Dilogbox from "./Dilogbox";
import UtilityDilogbox from "./UtilityDilogbox";
import Config from "../../utils/Config";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import swal from "sweetalert";

export default function Hero() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.profile);
  // const { id } = useParams();

  const [projectName, setProjectName] = React.useState("");
  const [projectSummary, setProjectSummary] = React.useState("");
  const [projectContacts, setProjectContacts] = React.useState([]);
  const [projectType, setProjectType] = React.useState("");
  const [utilityName, setUtilityName] = React.useState("");
  const [utilityId, setUtilityId] = React.useState(0);
  const [customerType, setCustomerType] = React.useState("Utility");
  const [projectBudget, setProjectBudget] = React.useState(0);
  const [contactValue, setContactValue] = React.useState(0);
  const [contractDate, setContractDate] = React.useState("2024-01-01");
  const [projectNotes, setProjectNotes] = React.useState(
    "Add additional project notes here"
  );

  const [loading, setLoading] = React.useState(false);

  const [projectServices, setProjectServices] = React.useState([
    { title: "Foundation Design", key: "foundational_design", checked: true },
    {
      title: "Structural Calculations",
      key: "structural_calculations",
      checked: true,
    },
    { title: "Structural Drawings", key: "structural_drawings", checked: true },
    {
      title: "Equipment Elevations",
      key: "equipment_elevations",
      checked: true,
    },
    { title: "AC/DC Single Line Diagrams", key: "single_line", checked: true },
    { title: "Site Layout", key: "layout", checked: true },
    {
      title: "3D SketchUp Rendering Installation Training",
      key: "sketch_up",
      checked: false,
    },
    { title: "Installation Training", key: "install_training", checked: true },
    {
      title: "Installation Management",
      key: "install_management",
      checked: true,
    },
    {
      title: "Permit Submission (Turnkey only)",
      key: "permit_submission",
      checked: true,
    },
    { title: "Commissioning", key: "commissioning", checked: true },
  ]);
  
  const [ projectUtilities, setProjectUtilities ] = React.useState([]);

  // const [ utilities, setUtilities ] = React.useState([]);
  // const [ utilitiesPage, setUtilitiesPage ] = React.useState(1);
  // const [ utilitiesLastPage, setUtilitiesLastPage ] = React.useState(1);
  // const [ utilitiesCurrentPage, setUtilitiesCurrentPage ] = React.useState(1);

  // const [ utilitiesLoading, setUtilitiesLoading ] = React.useState(false);

  // React.useEffect(() => {
  //   //loadUtilities();
  // }, [user]);

  // const loadUtilities = () => {
  //   setUtilitiesLoading(true);
  //   fetch(`${Config.API.URL}/utilities?sort_direction=ASC`, { // page=${page}&sort_by=first_name&
  //     method: "GET",
  //     headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
  //   }).then((res) => {
  //     //setContactLoading(false);
  //     // if 401 error (unauthenticated user, then log user out)
  //     if (res.status === 401) {
  //       console.log("401 Error: Unauthenticated");

  //       localStorage.removeItem("user");
  //       navigate("/");
  //     }
  //     return res;
  //   }).then((res) => res.json()).then(async (res) => {
  //     console.log('res', res);
  //     setUtilitiesLoading(false);
  //     if (res.status) {
  //       setUtilities(res.data.data);
  //       setUtilitiesLastPage(res.data.last_page);
  //       setUtilitiesCurrentPage(res.data.current_page);
  //     } else {
  //       swal("Oh no!", res.message, "error");
  //     }
  //   }).catch((error) => {
  //     setUtilitiesLoading(false);
  //     console.log("Error: ", error);

  //     swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
  //   });
  // };

  // const _onChangeProjectServices = (e) => {
  //     const data = [ ...projectServices ];

  //     data[e.target.id].checked  = !data[e.target.id].checked;

  //     setProjectServices(data);
  // }
  const submitProject = () => {
    if (projectContacts.length && projectName.length && projectSummary.length) {
      setLoading(true);
      const project_services = [];
      for (let i = 0; i < projectServices.length; i++) {
        const serv = projectServices[i];
        const item = {};

        item[serv.key] = serv.checked;

        project_services.push(item);
      }

      const project_contacts = [];
      for (let i = 0; i < projectContacts.length; i++) {
        const contact = projectContacts[i];
        project_contacts.push(contact.id);
      }
      const payload = {
        project_name: projectName.trim(),
        project_summary: projectSummary.trim(),
        project_contacts: project_contacts,
        project_type: projectType,
        //utility_name: utilityName,
        utility_id: utilityId,
        contract_gfc_date: "2024-01-01",
        customer_type: customerType,
        project_budget: projectBudget,
        contract_value: contactValue,
        contract_date: contractDate,
        project_notes: projectNotes,
        project_services: project_services,
        contract_number: "",
        pm_number: "",
        po_number: "",
        status: "Design Stage",
        others: [],
      };

      //console.log('payload:', payload);
      //return false;

      fetch(`${Config.API.URL}/projects`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          // if 401 error (unauthenticated user, then log user out)
          setLoading(false);
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");

            localStorage.removeItem("user");
            navigate("/");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          setLoading(false);
          // if request is succesful, alert user that project has been cloned
          if (res.status) {
            navigate("/dashboard");
          } else {
            swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error: ", error);

          swal(
            "Oh no!",
            "An error occured!!! while connecting to server. Please try again",
            "error"
          );
        });
    } else {
      swal("Oh no!", "All fields with asterisk(*) are required", "error");
    }
  };
  const _onReceiveContact = (contacts) => {
    setProjectContacts(contacts);
    // console.log('contacts:', contacts);
  };

  const _onReceiveUtility = (utilities) => {
    setProjectUtilities(utilities);
    setUtilityId(utilities?.length ? utilities[0]?.id : 0);
    // console.log('utilities:', utilities);
  };
  return (
    <>
      <div className="bg-white rounded-[8px] p-6  mt-[90px] lg:mt-3 ">
        <h2 className=" f-f-sm text-3xl text-black text-center ">
          Create New Project{" "}
        </h2>
        <div className="2xl:px-20 ">
          <div className="grid grid-cols-12 mt-10   ">
            {/* .....................  Electric input started ..............*/}

            {/* input section started */}
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Project Name <span className="text-primary">*</span>
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input
                onChange={(e) => setProjectName(e.target.value)}
                value={projectName}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>
            {/* input section ended */}
            {/* input section started */}
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Project Summary <span className="text-primary">*</span>
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <textarea
                onChange={(e) => setProjectSummary(e.target.value)}
                value={projectSummary}
                className=" w-full  border border-grey bg-white-dark rounded-[4px] p-4 outline-none  "
                id="w3review"
                name="w3review"
                rows="4"
                cols="50"
              ></textarea>
            </div>
            {/* input section ended */}
            {/* input section started */}
            {/* <div className=" col-span-12 lg:col-span-5 mt-5 ">
                            <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                                Average Demand (kW)
                            </h2>
                        </div>
                        <div className=" col-span-12 lg:col-span-7 mt-5 ">
                            <input onChange={e => setAverageDemand(e.target.value)} value={averageDemand} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
                        </div> */}
            {/* input section ended */}
            {/* input section started */}
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Project Contacts <span className="text-primary">*</span>
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              {projectContacts.length ? (
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    width: "68%",
                  }}
                >
                  {projectContacts.map((contact, i) => (
                    <span key={i}>{contact.first_name}, </span>
                  ))}
                </div>
              ) : null}
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  width: "30%",
                }}
              >
                <Dilogbox onReceiveContact={_onReceiveContact} />
              </div>
            </div>
            {/* input section ended */}

            {/* input section started */}
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Project Type
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <select
                onChange={(e) => setProjectType(e.target.value)}
                value={projectType}
                className="border border-grey bg-white-dark rounded-[4px] px-4 outline-none w-full h-[56px] "
              >
                <option value=""></option>
                <option value="Turnkey">Turnkey</option>
                <option value="Equipment">Equipment</option>
                <option value="Equipment + Consulting">
                  Equipment + Consulting
                </option>
              </select>
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Utility Name
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              {projectUtilities.length ? (
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    width: "68%",
                  }}
                >
                  {projectUtilities.map((utility, i) => (
                    <span key={i}>{`${utility.utility_full_name} (${utility.utility_name})`} </span>
                  ))}
                </div>
              ) : null}
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  width: "30%",
                }}
              >
                <UtilityDilogbox onReceiveUtility={_onReceiveUtility} />
              </div>
            </div>
            {/* <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <select
                onChange={(e) => setUtilityName(e.target.value)}
                value={utilityName}
                className="border border-grey bg-white-dark rounded-[4px] px-4 outline-none w-full h-[56px] "
              >
                <option value=""></option>
                <option value="PG&E">PG&E</option>
                <option value="SCE">SCE</option>
                <option value="Liberty">Liberty</option>
                <option value="SDG&E">SDG&E</option>
                <option value="Pacificorp">Pacificorp</option>
                <option value="Puget Sound Energy">Puget Sound Energy</option>
                <option value="Holston Electric Cooperative">
                  Holston Electric Cooperative
                </option>
                <option value="ATCO">ATCO</option>
              </select>
            </div> */}
            {/* input section ended */}
            {/* input section started */}
            {/* <div className=" col-span-12 lg:col-span-5 mt-5 ">
                            <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                                Type of Customer
                            </h2>
                        </div>
                        <div className=" col-span-12 lg:col-span-7 mt-5 ">
                            <select onChange={e => setCustomerType(e.target.value)} value={customerType} className='border border-grey bg-white-dark rounded-[4px] px-4 outline-none w-full h-[56px] ' >
                                <option value=""></option>
                                <option value="Utility">Utility</option>
                                <option value="Commercial & Industrial">Commercial & Industrial</option>
                                <option value="Residential">Residential</option>
                            </select>
                        </div> */}
            {/* input section ended */}
            {/* input section started */}
            {/* <div className=" col-span-12 lg:col-span-5 mt-5 ">
                            <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                                Project Budget (in USD)
                            </h2>
                        </div>
                        <div className=" col-span-12 lg:col-span-7 mt-5 ">
                            <form>
                                <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 12H2C2 13.08 3.37 14 5 14C6.63 14 8 13.08 8 12C8 10.9 6.96 10.5 4.76 9.97C2.64 9.44 0 8.78 0 6C0 4.21 1.47 2.69 3.5 2.18V0H6.5V2.18C8.53 2.69 10 4.21 10 6H8C8 4.92 6.63 4 5 4C3.37 4 2 4.92 2 6C2 7.1 3.04 7.5 5.24 8.03C7.36 8.56 10 9.22 10 12C10 13.79 8.53 15.31 6.5 15.82V18H3.5V15.82C1.47 15.31 0 13.79 0 12Z" fill="black" />
                                        </svg>
                                    </div>
                                    <input onChange={e => setProjectBudget(e.target.value)} value={projectBudget} type='number' className=' w-full z-10  h-[56px] border border-grey bg-white-dark rounded-[4px] pl-12 outline-none  ' required />
                                </div>
                            </form>
                        </div> */}
            {/* input section ended */}
            {/* input section started */}
            {/* <div className=" col-span-12 lg:col-span-5 mt-5 ">
                            <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                                Contact Value (in USD)
                            </h2>
                        </div>
                        <div className=" col-span-12 lg:col-span-7 mt-5 ">
                            <form>
                                <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 12H2C2 13.08 3.37 14 5 14C6.63 14 8 13.08 8 12C8 10.9 6.96 10.5 4.76 9.97C2.64 9.44 0 8.78 0 6C0 4.21 1.47 2.69 3.5 2.18V0H6.5V2.18C8.53 2.69 10 4.21 10 6H8C8 4.92 6.63 4 5 4C3.37 4 2 4.92 2 6C2 7.1 3.04 7.5 5.24 8.03C7.36 8.56 10 9.22 10 12C10 13.79 8.53 15.31 6.5 15.82V18H3.5V15.82C1.47 15.31 0 13.79 0 12Z" fill="black" />
                                        </svg>
                                    </div>
                                    <input onChange={e => setContactValue(e.target.value)} value={contactValue} type='number' className=' w-full z-0 h-[56px] border border-grey bg-white-dark rounded-[4px] pl-12 outline-none  ' required />
                                </div>
                            </form>
                        </div> */}
            {/* input section ended */}

            {/* input section started */}
            {/* <div className=" col-span-12 lg:col-span-5 mt-5 ">
                            <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                                Target Delivery Date
                            </h2>
                        </div>
                        <div className=" col-span-12 lg:col-span-7 mt-5 ">
                            <form action="/action_page.php">
                                <input onChange={e => setContractDate(e.target.value)} value={contractDate} type="date" id="birthday" name="birthday" className=" w-full z-0 relative h-[56px] border border-grey bg-white-dark rounded-[4px] pl-[47px] pr-4 outline-none" placeholder="Select date" />
                            </form>
                        </div> */}

            {/* input section ended */}

            {/* input section started */}
            {/* <div className=" col-span-12 lg:col-span-5 mt-5 ">
                            <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                                Project Notes
                            </h2>
                        </div>
                        <div className=" col-span-12 lg:col-span-7 mt-5 ">
                            <textarea onChange={e => setProjectNotes(e.target.value)} value={projectNotes} className=' w-full  border border-grey bg-white-dark rounded-[4px] p-4 outline-none  ' id="w3review" name="w3review" rows="4" cols="50"></textarea>
                        </div> */}
            {/* input section ended */}

            {/* input section started */}
            {/* <div className=" col-span-12 lg:col-span-5 mt-5 ">
                            <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                                Project Services
                            </h2>
                        </div>
                        <div className=" col-span-12 lg:col-span-7 mt-5 ">
                            <ul>
                                { projectServices.map((service, i) => (
                                    <li key={i}>
                                        <div className="flex items-center mb-4">
                                            <input id={i} value={service.key} onChange={_onChangeProjectServices} type="checkbox" checked={service.checked} className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px]" />
                                            <label htmlFor={i} className="ml-4 f-f-r text-2xl text-black">{ service.title }</label>
                                        </div>
                                    </li>
                                )) }
                            </ul>
                        </div> */}
            {/* input section ended */}
          </div>
        </div>

        {/* button started */}
        <div className=" mt-10 text-end  ">
          <ul className=" inline-flex  ">
            <li>
              {loading ? (
                <button className=" bg-gray text-lg f-f-r text-gray  w-[127px] h-[48px] rounded-[8px] captilize ">
                  Loading...
                </button>
              ) : (
                <button
                  onClick={submitProject}
                  className=" bg-primary text-lg f-f-r text-white  w-[127px] h-[48px] rounded-[8px] captilize "
                >
                  Done
                </button>
              )}
            </li>
            <li className=" ml-6 ">
              <button
                onClick={() => navigate(-1)}
                className=" border border-primary text-lg f-f-r text-primary  w-[127px] h-[48px] rounded-[8px] captilize "
              >
                Back
              </button>
            </li>
          </ul>
        </div>
        {/* button ended */}
      </div>
    </>
  );
}
