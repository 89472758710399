import React from 'react'

import { Link, useParams, useNavigate } from 'react-router-dom';
import Config from '../../../utils/Config';
import { useSelector, useDispatch } from 'react-redux';

import MoniteringTable from './MoniteringTable';

import { setMonitoringOverview } from "../../../redux/slices/basisOfDesignSlice";

import swal from 'sweetalert';

const staticComponents = [
  {
    component: "Stellar Edge",
    unit_qty: 2,
    power_consumption: "5",
  },
  {
    component: "SEL-751 Protection Relay",
    unit_qty: 1,
    power_consumption: "25",
  },
  {
    component: "PCC Meter / Acuvim IIR",
    unit_qty: 1,
    power_consumption: "5",
  },
  {
    component: "Schneider Contactor Latch Block",
    unit_qty: 1,
    power_consumption: "30",
  },
  {
    component: "Combustible Gas Sensor",
    unit_qty: 1,
    power_consumption: "20.4",
  },
  {
    component: "Managed industrial switch, LTE M2M Router",
    unit_qty: 1,
    power_consumption: "3.84",
  },
  {
    component: "Siemens 200A 120/240V Breaker, Shunt Trip",
    unit_qty: 1,
    power_consumption: "5(1 Second)",
  },
];

export default function Index() {
  const user     = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [ isMoniteringTableEditable, setIsMoniteringTableEditable ] = React.useState(false);

  const { monitoring_overview } = useSelector((state) => state.basisOfDesign);
  const { site_id, design_id } = useParams();

  const _onSaveMonitoringOverview = (components) => {
    const payload = { design_id, components: components };
    // console.log("payload:", payload);

    fetch(`${Config.API.URL}/design-basis/monitoring-overview`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` },
      body: JSON.stringify(payload),
    }).then((res) => {
      // if 401 error (unauthenticated user, then log user out)
      if ((res.status) === 401) {
        console.log("401 Error: Unauthenticated");

        localStorage.removeItem('user');
        navigate('/');
      }
      return res;
    }).then((res) => res.json()).then( async (res) => {
      // console.log('res:', res);

      // if request is succesful, alert user that project has been cloned
      if (res.status) {
        dispatch(setMonitoringOverview(res.data));
      } else {
        swal("Oh no!", res.message, "error");
      }
    }).catch((error) => {
      console.log('Error: ', error);
      
      swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
    });
  }

  const nextPage = () => {
    if (isMoniteringTableEditable) {
      swal({
        title: "Editable mode detected?",
        text: "When go to the next page, unsave changes will be lost. do you still want go to next page?",
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willProcced) => {
        if (willProcced) {
          if (!monitoring_overview.length) {
            _onSaveMonitoringOverview(staticComponents);
          }
          navigate(`/basis/design/life/${site_id}/${design_id}`);
        } 
      });
    } else {
      if (!monitoring_overview.length) {
        _onSaveMonitoringOverview(staticComponents);
      }
      navigate(`/basis/design/life/${site_id}/${design_id}`);
    }
  }

  return (
    <div className='' >
        <h2 className=' f-f-r text-lg text-black ' >PG&E Basis of Design</h2>
        <h4 className=' f-f-sm text-black text-2xl  sm:text-3xl ' >Monitoring Overview</h4>
        <h2 className=' f-f-m text-black text-lg mt-4 ' >Fill out the Monitoring Overview Table below</h2>

   {/*  table started */}

<MoniteringTable onEditMoniteringTable={setIsMoniteringTableEditable}  onSaveMonitoringOverview={_onSaveMonitoringOverview} />

<div className='  ' >

        

<div className=' border border-gray-300 mt-20  ' ></div>
<div className="grid grid-cols-12  gap-4  ">
<div className="   col-span-3">
    <Link to={`/basis/design/deviations/${site_id}/${design_id}`} >

<button className="  mt-8 relative border border-primary w-[110px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-primary  ">
       {/* inner ul started */}
        <ul className=' inline-flex text-center justify-center mt-1 mr-3 ' >
            <li>
                        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.25 7.5L11.75 13L17.25 18.5" stroke="#ED583B" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
            </li>
            <li className='ml-1' >
    Back
    </li>
        </ul>
        {/* inner ul ended */}
       </button>
       </Link>
</div>
<div className="   col-span-9">
<div className=' text-end ' >
<ul className=" inline-flex items-center  ">

<Link to={`/basis/design/life/${site_id}/${design_id}`}>
<li >
<button className=" mt-8  text-center  f-f-r text-lg text-black-darkest  ">
Skip</button>
  </li>
  </Link>
<li className=" ml-6 ">
<button onClick={nextPage} className=" mt-8 relative border bg-primary w-[127px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-white  ">Next</button></li>

</ul>
</div>
</div>
</div>

</div>
{/* adjuest in bottom ended  */}

    </div>
  )
}
