import React from "react";

export default function Generationtable({ site_discussions_general_equips }) {
  return (
    <div className="pr-10 pl-10">
      <div className="relative overflow-x-auto mt-8 ">
        <table className=" table-site w-full border border-slate-500 text-left  ">
          <caption className="border border-slate-600 px-3 py-2 f-f-m  text-lg text-center  bg-primary text-white ">
            Generation Equipment Summary 
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b  text-sm text-black-dark ">
              <th className="border border-slate-600 px-6 py-3">Component</th>
              <th className="border border-slate-600 px-6 py-3">Manufacturer / Model</th>
              <th className="border border-slate-600 px-6 py-3">Unit Rating</th>
              <th className="border border-slate-600 px-6 py-3">Units</th>
              <th className="border border-slate-600 px-6 py-3">Unit Qty</th>
            </tr>
          </thead>
          <tbody>
            {site_discussions_general_equips.map((equipment, i) => (
              <tr
                key={i}
                className="bg-white border-b  f-f-r  text-sm text-black-dark "
              >
                <td className="border border-slate-600 px-6 py-3">{equipment.component}</td>
                <td className="border border-slate-600 px-6 py-3">{equipment.manufacturer_model}</td>
                <td className="border border-slate-600 px-6 py-3">{equipment.unit_rating}</td>
                <td className="border border-slate-600 px-6 py-3">{equipment.units}</td>
                <td className="border border-slate-600 px-6 py-3">{equipment.unit_qty}</td>
              </tr>
            ))}
            {/* <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Inverter
                            </td>
                            <td className="px-6 py-3   ">
                            Schneider / XW Pro 6848 NA  
                            </td>
                            <td className="px-6 py-3   ">
                            6.8
                            </td>
                            <td className="px-6 py-3   ">
                            kVA
                            </td>
                            <td className="px-6 py-3   ">
                            4
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Charge Controller
                            </td>
                            <td className="px-6 py-3   ">
                            Schneider / MPPT 100
                            </td>
                            <td className="px-6 py-3   ">
                            600
                            </td>
                            <td className="px-6 py-3   ">
                            Vdc
                            </td>
                            <td className="px-6 py-3   ">
                            2
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Battery ESS 
                            </td>
                            <td className="px-6 py-3   ">
                            SimpliPhi AmpliPhi Battery Modules
                            </td>
                            <td className="px-6 py-3   ">
                            3.8
                            </td>
                            <td className="px-6 py-3   ">
                            kWh-dc
                            </td>
                            <td className="px-6 py-3   ">
                            16
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Monitoring & Controls
                            </td>
                            <td className="px-6 py-3   ">
                            New Sun Road / Communications
                            </td>
                            <td className="px-6 py-3   ">
                            N/A
                            </td>
                            <td className="px-6 py-3   ">
                            N/A
                            </td>
                            <td className="px-6 py-3   ">
                            2
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            HVAC
                            </td>
                            <td className="px-6 py-3   ">
                            Bard / W12AB Series
                            </td>
                            <td className="px-6 py-3   ">
                            1 
                            </td>
                            <td className="px-6 py-3   ">
                            Ton
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Fuel
                            </td>
                            <td className="px-6 py-3   ">
                            Propane Tank
                            </td>
                            <td className="px-6 py-3   ">
                            1000 
                            </td>
                            <td className="px-6 py-3   ">
                            gallons
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
