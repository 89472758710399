import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Dropwdownhome from './DropwdownBasic'


export default function Tableproject() {
   
  //const { site_id } = useParams();

    return (
        <div className=' mt-7 ' >
            <ul className=' inline-flex ' >
                <li>
                    <h3 className=' text-black text-2xl f-f-r ' >
                    All Projects (6)
                    </h3>
                </li>
                <li className=' ml-2 mt-1' >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clip-rule="evenodd" d="M8.99988 16.5001C13.142 16.5001 16.4999 13.1422 16.4999 9.00006C16.4999 4.85793 13.142 1.50006 8.99988 1.50006C4.85774 1.50006 1.49988 4.85793 1.49988 9.00006C1.49988 13.1422 4.85774 16.5001 8.99988 16.5001Z" stroke="#D0D1D2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9 6.00006V9.00006" stroke="#D0D1D2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <circle cx="8.99988" cy="12" r="0.75" fill="#D0D1D2" />
                    </svg>

                </li>
            </ul>
 
       



      {/* new div started */}
      <div className=' mt-8' >
                <div className="grid grid-cols-12 mt-5 gap-4  ">
                    <div className="   col-span-12 md:col-span-6">
                        <ul className=' w-full md:inline-flex' >
                            <li>
                                {/* search started */}

                                <form className="flex items-center">
                                    <label for="simple-search" className="sr-only">Search</label>
                                    <div className="relative w-full">
                                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <svg aria-hidden="true" className="w-5 h-5 text-gray-500 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                        </div>
                                        <input type="text" id="simple-search" className="bg-gray-50 border w-full outline-none border-gray-300 text-black-light text-lg rounded-[4px] block md:w-[231px] pl-10 p-2.5  " placeholder="Search By Anything" required />
                                    </div>

                                </form>
                                {/* search ended */}
                            </li>
                            <li className=' mt-3 md:mt-0 md:ml-3' >
                                {/* form started */}
                                <form action="/action_page.php">

                                    <select name="cars" id="cars" className="bg-gray-50 border outline-none relative border-gray-300 text-black-light text-lg rounded-[4px] block w-full  md:w-[152px]  p-2.5  ">
                                        <option value="volvo">Filters</option>
                                        <option value="saab">Saab</option>
                                        <option value="opel">Opel</option>
                                        <option value="audi">Audi</option>

                                    </select>

                                </form>
                            </li>
                        </ul>
                    </div>

                    <div className="   col-span-12 md:col-span-6">

                        <div className=' text-center md:text-end ' >



                            <ul className=' inline-flex ' >
                                {/* list started */}
                                <li>
                                    <button  className=' f-f-r text-lg text-black w-[169px] h-[44px] shadow-lg bg-white rounded-[4px]  ' >
                                        <ul className=' inline-flex ' >
                                            <li className=' mt-2 ' >
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M14.0002 2.66667H9.3335" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M6.66667 2.66667H2" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14 8H8" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M5.33333 8H2" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M13.9998 13.3333H10.6665" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M8 13.3333H2" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M9.3335 1.33333V4" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M5.3335 6.66667V9.33333" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10.6665 12V14.6667" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </li>
                                            <li className=' ml-3 mt-1 ' >
                                                Edit Columns
                                            </li>
                                        </ul>


                                    </button>
                                </li>
                            
                                {/* list ended */}
                            </ul>
                        </div>

                    </div>

                </div>





            </div>
            {/* new div ended */}
            {/* table started */}


            <div className="relative overflow-x-auto mt-8 ">
                <table className="w-full  text-left  ">
                    <thead className=" text-black  bg-grey-light shadow-lg  ">
                        <tr className='tabel-home f-f-r text-base text-black-dark ' >
                            <th className="px-6 py-3    ">
                            Project Name
                            </th>
                            <th className="px-6 py-3   ">
                            site Name
                            </th>
                            <th className="px-6 py-3   ">
                                Project Type
                            </th>
                            <th className="px-6 py-3   ">
                                Number of Basis Design
                            </th>
                            <th className="px-6 py-3   ">
                            Basis of Design Last Modified by
                            </th>
                            <th className="px-6 py-3   ">
                            Last Modified At
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Ohio Running Spring
                            </td>
                            <td className="px-6 py-3   ">
                            Maron Corgi Ranch
                            </td>
                            <td className="px-6 py-3   ">
                            Equipment Sale
                            </td>
                            <td className="px-6 py-3   ">
                                2
                            </td>
                            <td className="px-6 py-3   ">
                            Kevin Wagner
                            </td>
                            <td className="px-6 py-3  relative ">
                            01/22/15 17:15
                                {/* drop down started */}

                                <Dropwdownhome />
                                {/* drop down ended */}



                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Ohio Running Spring
                            </td>
                            <td className="px-6 py-3   ">
                            Whispering Pines
                            </td>
                            <td className="px-6 py-3   ">
                            Remote Grid
                            </td>
                            <td className="px-6 py-3   ">
                                4
                            </td>
                            <td className="px-6 py-3   ">
                            Dustin Wilson
                            </td>
                            <td className="px-6 py-3  relative ">
                                01/22/15 17:15
                                {/* drop down started */}

                                <Dropwdownhome />
                                {/* drop down ended */}
                            </td>
                        </tr>
                   
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Ohio Running Spring
                            </td>
                            <td className="px-6 py-3   ">
                            Whispering Pines
                            </td>
                            <td className="px-6 py-3   ">
                            Remote Grid
                            </td>
                            <td className="px-6 py-3   ">
                                4
                            </td>
                            <td className="px-6 py-3   ">
                            Dustin Wilson
                            </td>
                            <td className="px-6 py-3  relative ">
                                01/22/15 17:15
                                {/* drop down started */}

                                <Dropwdownhome />
                                {/* drop down ended */}
                            </td>
                        </tr>
                   
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Ohio Running Spring
                            </td>
                            <td className="px-6 py-3   ">
                            Whispering Pines
                            </td>
                            <td className="px-6 py-3   ">
                            Remote Grid
                            </td>
                            <td className="px-6 py-3   ">
                                4
                            </td>
                            <td className="px-6 py-3   ">
                            Dustin Wilson
                            </td>
                            <td className="px-6 py-3  relative ">
                                01/22/15 17:15
                                {/* drop down started */}

                                <Dropwdownhome />
                                {/* drop down ended */}
                            </td>
                        </tr>
                   
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Ohio Running Spring
                            </td>
                            <td className="px-6 py-3   ">
                            Whispering Pines
                            </td>
                            <td className="px-6 py-3   ">
                            Remote Grid
                            </td>
                            <td className="px-6 py-3   ">
                                4
                            </td>
                            <td className="px-6 py-3   ">
                            Dustin Wilson
                            </td>
                            <td className="px-6 py-3  relative ">
                                01/22/15 17:15
                                {/* drop down started */}

                                <Dropwdownhome />
                                {/* drop down ended */}
                            </td>
                        </tr>
                   
                    </tbody>
                </table>
            </div>
            <div className=' tabel-home py-5 px-3 w-full  mb-20 ' >
                <ul className=' inline-flex  w-full  justify-end ' >
                    <li>
                        <h2 className=' inline-flex f-f-m text-tiny  text-black-vdark ' >Rows per page: 10  <svg width="8" className=' mt-2 ml-2 ' height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.87636 5L0 0H7.75273L3.87636 5Z" fill="#808080" />
                        </svg>
                        </h2>
                    </li>
                    <li className=' ml-5 ' >
                        <h2 className=' inline-flex f-f-m text-tiny  text-black-vdark ' >1-7 of 276
                        </h2>
                    </li>
                    <li className=' ml-5 ' >
                        {/* inner icon list started */}
                        <ul className=' inline-flex ' >
                            <li>
                                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.62044 1.10636C6.22943 0.51287 5.2652 -0.426818 4.65621 0.216126L0.190308 4.51891C-0.0634362 4.7662 -0.0634362 5.21131 0.190308 5.4586L4.65621 9.81084C5.2652 10.4043 6.22943 9.46464 5.62044 8.87115L1.66203 5.01348L5.62044 1.10636Z" fill="#808080" />
                                </svg>

                            </li>
                            <li className=' ml-8 ' >
                                <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.65983 1.10636C0.0508423 0.51287 1.01507 -0.426818 1.62406 0.216126L6.08996 4.51891C6.34371 4.7662 6.34371 5.21131 6.08996 5.4586L1.62406 9.81084C1.01507 10.4043 0.0508423 9.46464 0.65983 8.87115L4.61825 5.01348L0.65983 1.10636Z" fill="#808080" />
                                </svg>

                            </li>
                        </ul>
                        {/* inner icon listended */}
                    </li>
                </ul>

            </div>
            {/* table ended */}


          

        </div>
    )
}
