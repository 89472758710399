import React from "react";
import { Menu } from "@headlessui/react";
import { Link } from "react-router-dom";

export default function MyDropdown({ columns, onEditColumns }) {
  return (
    <>
      <Menu>
        <Menu.Button className=" cursor-pointer">
          <button className=" f-f-r text-lg text-black w-[169px] h-[44px] shadow-lg bg-white rounded-[4px]  ">
            <ul className=" inline-flex ">
              <li className=" mt-2 ">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.0002 2.66667H9.3335"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.66667 2.66667H2"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 8H8"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.33333 8H2"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.9998 13.3333H10.6665"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 13.3333H2"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.3335 1.33333V4"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.3335 6.66667V9.33333"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.6665 12V14.6667"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </li>
              <li className=" ml-3 mt-1 ">Edit Columns</li>
            </ul>
          </button>
        </Menu.Button>
        <Menu.Items>
          <div
            id="dropdown"
            className="z-10 px-3 absolute w-[243px] bg-white divide-y divide-gray-100 rounded-lg shadow "
          >
            <ul
              className="py-2 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownDefaultButton"
            >
              <li className=" profilebrdr2">
                {/* inner ul started */}
                <ul
                  className=" inline-flex mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "contact_name",
                          value: !columns.contact_name,
                        })
                      }
                      checked={columns.contact_name}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Contact Name
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>
              <li className=" profilebrdr2 mt-1">
                <ul
                  className=" inline-flex cursor-pointer mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "email", value: !columns.email })
                      }
                      checked={columns.email}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r">Email</h2>
                  </li>
                </ul>
              </li>
              <li className=" profilebrdr2 mt-1">
                <ul
                  className=" inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "phone_number",
                          value: !columns.phone_number,
                        })
                      }
                      checked={columns.phone_number}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Phone Number
                    </h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1">
                <ul
                  className=" inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "company",
                          value: !columns.company,
                        })
                      }
                      checked={columns.company}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Company</h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1">
                <ul
                  className=" inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "last_modified_by",
                          value: !columns.last_modified_by,
                        })
                      }
                      checked={columns.last_modified_by}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Last modified by
                    </h2>
                  </li>
                </ul>
              </li>

              {/*               <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "title", value: !columns.title })
                      }
                      checked={columns.title}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Job Title</h2>
                  </li>
                </ul>
              </li> */}

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "type", value: !columns.type })
                      }
                      checked={columns.type}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Contact Type
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "label", value: !columns.label })
                      }
                      checked={columns.label}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Department/Scope
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>
            </ul>
          </div>
        </Menu.Items>
      </Menu>
    </>
  );
}
