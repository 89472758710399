import React, { Fragment, useRef, useState, useEffect } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import Config from "../../utils/Config";

import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import SiteMilestonesDialogBox from "./SiteMilestonesDialogBox";
import UniversalDataTable from "../Contacts/UniversalDataTable";

export default function ElectricloadCrd(props) {
  // const { site } = props;
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.profile);

  const [site, setSite] = useState({ ...props.site });
  const [editElectricLoadModel, setEditElectricLoadModel] = useState(false);
  const [editDirectoryLinksModel, setEditDirectoryLinksModel] = useState(false);
  const [addDirectoryLinksModel, setAddDirectoryLinksModel] = useState(false);
  const [editLogisticsModel, setEditLogisticsModel] = useState(false);
  const [
    editPowerDistributionSummaryModel,
    setEditPowerDistributionSummaryModel,
  ] = useState(false);

  const [averageDailyUsage, setAverageDailyUsage] = React.useState(
    site?.average_daily_usage
  );
  const [peakDemand, setPeakDemand] = React.useState(site?.peak_demand);
  const [averageDemand, setAverageDemand] = React.useState(
    site?.average_demand
  );
  const [apparentPowerContinuous, setApparentPowerContinuous] = React.useState(
    site?.apparent_power_continuous
  );
  const [realPowerContinuous, setRealPowerContinuous] = React.useState(
    site?.real_power_continuous
  );
  const [currentContinuous, setCurrentContinuous] = React.useState(
    site?.current_continuous
  );
  const [maxCurrentTransient, setMaxCurrentTransient] = React.useState(
    site?.max_current_transient
  );
  const [apparentPowerTransient, setApparentPowerTransient] = React.useState(
    site?.apparent_power_transient
  );

  const [maxTransientDuration, setMaxTransientDuration] = React.useState(
    site?.max_transient_duration
  );

  const [targetDeliveryDate, setTargetDeliveryDate] = React.useState("");
  const [equipment, setEquipment] = React.useState("");
  const [loadingMethod, setLoadingMethod] = React.useState("");
  const [shippingMethod, setShippingMethod] = React.useState("");
  const [unloadingMethod, setUnloadingMethod] = React.useState("");
  const [systemPlacement, setSystemPlacement] = React.useState("");
  const [preferredUnloadingMethod, setPreferredUnloadingMethod] =
    React.useState("");
  const [customerShippingMaxBudget, setCustomerShippingMaxBudget] =
    React.useState("");
  const [miscellaneousItemsBeingShipped, setMiscellaneousItemsBeingShipped] =
    React.useState("");

  const [site_directions, setSite_directions] = React.useState("");
  const [site_distance_miles, setSite_distance_miles] = React.useState("");
  const [site_distance_drive, setSite_distance_drive] = React.useState("");
  const [access_road_flags, setAccess_road_flags] = React.useState("");
  const [publicly_accessible, setPublicly_accessible] = React.useState("");
  const [locked_access_road, setLocked_access_road] = React.useState("");
  const [lock_access_code, setLock_access_code] = React.useState("");
  const [procurement_yard, setProcurement_yard] = React.useState("");

  const [PCCToTransformerType, setPCCToTransformerType] = React.useState("");
  const [PCCToTransformerInstall, setPCCToTransformerInstall] =
    React.useState("Existing");
  const [PCCToTransformerDistanceFeet, setPCCToTransformerDistanceFeet] =
    React.useState("");
  const [transformerToMeterType, setTransformerToMeterType] =
    React.useState("");
  const [transformerToMeterInstall, setTransformerToMeterInstall] =
    React.useState("Exist");
  const [transformerToMeterDistanceFeet, setTransformerToMeterDistanceFeet] =
    React.useState("");

  const [directory_of_links, setDirectory_of_links] = React.useState([]);
  const [additionalLinks, setAdditionalLinks] = React.useState([]);
  const [additionalLink, setAdditionalLink] = React.useState({
    name: "",
    value: "",
  });

  const [loadingEditElectricLoad, setLoadingEditElectricLoad] =
    React.useState(false);
  const [loadingEditLogistics, setLoadingEditLogistics] = React.useState(false);
  const [loadingEditDirectoryLinks, setLoadingEditDirectoryLinks] =
    React.useState(false);
  const [loadingAddDirectoryLink, setLoadingAddDirectoryLink] =
    React.useState(false);
  const [
    loadingEditPowerDistributionSummary,
    setLoadingEditPowerDistributionSummary,
  ] = React.useState(false);

  const [milestones, setMilestones] = useState([]);
  const [milestone, setMilestone] = useState(null);

  const [targetData, setTargetData] = useState("");
  const [dateAchieved, setDateAchieved] = useState("");
  const [milestoneName, setMilestoneName] = useState("");

  const [editMilestoneModel, setEditMilestoneModel] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const cancelButtonRef = useRef(null);

  useEffect(() => {
    setSite(props.site);

    setAverageDailyUsage(props.site?.average_daily_usage);
    setPeakDemand(props.site?.peak_demand);
    setAverageDemand(props.site?.average_demand);

    setApparentPowerContinuous(props.site?.apparent_power_continuous);
    setRealPowerContinuous(props.site?.real_power_continuous);
    setCurrentContinuous(props.site?.current_continuous);
    setMaxCurrentTransient(props.site?.max_current_transient);
    setApparentPowerTransient(props.site?.apparent_power_transient);
    setMaxTransientDuration(props.site?.max_transient_duration);

    // Temporary
    //setRealPowerContinuous(props.site?.average_demand);
    //setApparentPowerTransient(props.site?.peak_demand);
    //////////

    setTargetDeliveryDate(props.site?.target_delivery_date);
    setEquipment(props.site?.equipment);
    setLoadingMethod(props.site?.loading);
    setShippingMethod(props.site?.shipping);
    setUnloadingMethod(props.site?.unloading);
    setSystemPlacement(props.site?.system_placement);
    setPreferredUnloadingMethod(props.site?.preferred_unloading_method);
    setCustomerShippingMaxBudget(props.site?.customer_shipping_max_budget);
    setMiscellaneousItemsBeingShipped(
      props.site?.miscellaneous_items_being_shipped
    );

    setSite_directions(props.site?.site_directions);
    setSite_distance_miles(props.site?.site_distance_miles);
    setSite_distance_drive(props.site?.site_distance_drive);
    setAccess_road_flags(props.site?.access_road_flags);
    setPublicly_accessible(props.site?.publicly_accessible);
    setLocked_access_road(props.site?.locked_access_road);
    setLock_access_code(props.site?.lock_access_code);
    setProcurement_yard(props.site?.procurement_yard);

    setPCCToTransformerType(props.site?.pcc_to_transformer_type);
    setPCCToTransformerInstall(props.site?.pcc_to_transformer_install);
    setPCCToTransformerDistanceFeet(props.site?.pcc_to_transformer_distance);

    setTransformerToMeterType(props.site?.transformer_to_meter_type);
    setTransformerToMeterInstall(props.site?.transformer_to_meter_install);
    setTransformerToMeterDistanceFeet(
      props.site?.transformer_to_meter_distance
    );

    setAdditionalLinks(props.site?.additional_links);

    if (props.site?.milestones) {
      const milestones_items = [];
      for (let i = 0; i < props.site?.milestones.length; i++) {
        const item = { ...props.site?.milestones[i] };

        if (!item?.achieved_date) {
          item.achieved_date = "Not Added Yet";
        }

        milestones_items.push(item);
      }

      setMilestones(milestones_items);
    }

    // console.log("props.site:", props.site);
  }, [props.site]);

  useEffect(() => {
    const data = [];
    const links = props.site?.additional_links;

    for (let i = 0; i < links?.length; i++) {
      const link = links[i];
      const count = data?.length;

      if (count <= 0) {
        data.push([link]);
      } else {
        if (data[count - 1]?.length >= 0 && data[count - 1]?.length <= 9) {
          data[count - 1].push(link);
        } else {
          data.push([link]);
        }
      }

      if (i === links.length - 1) {
        // console.log('data:', data);
        setDirectory_of_links(data);
      }
    }
  }, [props.site?.additional_links]);

  useEffect(() => {
    if (props.siteDistance) {
      //console.log('props.siteDistance:', props.siteDistance);
      setSite_distance_miles(props.siteDistance?.distance?.text);
      setSite_distance_drive(props.siteDistance?.duration?.text);

      // setSite_distance_miles(props.siteDistance?.distance?.value / 1609);
      // setSite_distance_drive(props.siteDistance?.duration?.value / 3600);
    }
  }, [props.siteDistance]);

  /******************* Code to shorten URL Links *****************************/
  const [shortLinks, setShortLinks] = useState(false);

  const shortenLink = (url) => {
    return new Promise((resolve, reject) => {
      const options = {
        method: "POST",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
          Authorization: "sk_lA9XnijUhqlpMDoU",
        },
        body: JSON.stringify({
          domain: "go.boxpower.cloud",
          allowDuplicates: false,
          originalURL: url,
        }),
      };

      if (
        url.startsWith("https://go.boxpower.cloud") ||
        url.startsWith("https://example.com")
      ) {
        // The URL is already shortened, return it as is
        resolve(url);
      } else {
        fetch(
          "https://web-production-30c4.up.railway.app/https://api.short.io/links",
          options
        )
          .then((response) => response.json())
          .then((data) => {
            resolve(data.shortURL); // Assuming the API response contains the shortened URL in `shortURL`
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  };

  const updateLinksWithShortenedUrls = () => {
    setShortLinks(true);
    setLoadingEditDirectoryLinks(true);
    setLoadingAddDirectoryLink(true);
    // Map each link to a promise to shorten it
    const shorteningPromises = additionalLinks.map((link) => {
      return shortenLink(link.value)
        .then((shortenedUrl) => {
          return { ...link, value: shortenedUrl }; // Return a new link object with the shortened URL
        })
        .catch(() => link); // In case of error, keep the original link
    });

    // Wait for all promises to resolve
    Promise.all(shorteningPromises).then((updatedLinks) => {
      setAdditionalLinks(updatedLinks); // Update state with new links
      setLoadingEditDirectoryLinks(false);
      setLoadingAddDirectoryLink(false);
      if (editDirectoryLinksModel) {
        setEditDirectoryLinksModel(false);
      }
      if (addDirectoryLinksModel) {
        setAddDirectoryLinksModel(false);
      }
    });
  };

  const addDirectoryLinks = () => {
    const payload = {
      // ...site,
      // service_voltage: site?.service_voltage ? site?.service_voltage : "0",
      // preferred_unloading_method: site?.preferred_unloading_method
      // ? site?.preferred_unloading_method
      // : "Nill",
      additional_links: additionalLinks,
    };

    if (additionalLink.name.length && additionalLink.value.length) {
      payload.additional_links.push(additionalLink);
    }
    // console.log("payload:", payload);
    setAdditionalLinks(payload.additional_links);
    updateLinksWithShortenedUrls();

    /*     fetch(`${Config.API.URL}/sites/${site.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          //localStorage.removeItem('token');
          //localStorage.removeItem('user');
          //history.push('/login');
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        console.log("res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setAddDirectoryLinksModel(false);
          setSite(res.data);
          
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      }); */
  };

  const editDirectoryLinks = () => {
    //setLoadingEditDirectoryLinks(true);
    const payload = {
      // ...site,
      // service_voltage: site?.service_voltage ? site?.service_voltage : "0",
      // preferred_unloading_method: site?.preferred_unloading_method
      // ? site?.preferred_unloading_method
      // : "Nill",
      additional_links: additionalLinks,
    };

    // console.log("payload:", payload);

    fetch(`${Config.API.URL}/sites/${site.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setShortLinks(false);
        //setLoadingEditDirectoryLinks(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
          swal("You must be logged in to perform this action");

          //localStorage.removeItem('token');
          //localStorage.removeItem('user');
          //history.push('/login');
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        //setLoadingEditDirectoryLinks(false);
        // console.log("res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          //setEditDirectoryLinksModel(false);
          setSite(res.data);
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        //setLoadingEditDirectoryLinks(false);
        setShortLinks(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  useEffect(() => {
    if (additionalLinks?.length > 0 && shortLinks) {
      editDirectoryLinks();
    }
  }, [additionalLinks]);

  /****************************************************************************/

  const editPowerDistributionSummary = () => {
    setLoadingEditPowerDistributionSummary(true);
    const payload = {
      // ...site,
      // service_voltage: site?.service_voltage ? site?.service_voltage : "0",
      // additional_links: site?.additional_links ? site?.additional_links : [],
      // preferred_unloading_method: site?.preferred_unloading_method
      // ? site?.preferred_unloading_method
      // : "Nill",
      pcc_to_transformer_type: PCCToTransformerType,
      pcc_to_transformer_install: PCCToTransformerInstall,
      pcc_to_transformer_distance: PCCToTransformerDistanceFeet,
      transformer_to_meter_type: transformerToMeterType,
      transformer_to_meter_install: transformerToMeterInstall,
      transformer_to_meter_distance: transformerToMeterDistanceFeet,
    };

    fetch(`${Config.API.URL}/sites/${site.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingEditPowerDistributionSummary(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          //localStorage.removeItem('token');
          //localStorage.removeItem('user');
          //history.push('/login');
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditPowerDistributionSummary(false);
        //console.log("res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setEditPowerDistributionSummaryModel(false);

          setSite(res.data);
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditPowerDistributionSummary(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  const editLogistics = () => {
    setLoadingEditLogistics(true);
    const payload = {
      // ...site,
      //service_voltage: site?.service_voltage ? site?.service_voltage : "0",
      //additional_links: site?.additional_links ? site?.additional_links : [],
      //target_delivery_date: targetDeliveryDate,
      //equipment: equipment,
      //loading: loadingMethod,
      shipping: shippingMethod,
      unloading: unloadingMethod,
      system_placement: systemPlacement,
      //preferred_unloading_method: preferredUnloadingMethod,
      //customer_shipping_max_budget: customerShippingMaxBudget,
      //miscellaneous_items_being_shipped: miscellaneousItemsBeingShipped,

      site_directions: site_directions,
      site_distance_miles: site_distance_miles,
      site_distance_drive: site_distance_drive,
      access_road_flags: access_road_flags,
      publicly_accessible: publicly_accessible,
      locked_access_road: locked_access_road,
      lock_access_code: lock_access_code,
      procurement_yard: procurement_yard,
    };

    fetch(`${Config.API.URL}/sites/${site.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingEditLogistics(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          //localStorage.removeItem('token');
          //localStorage.removeItem('user');
          //history.push('/login');
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditLogistics(false);
        // console.log("res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setEditLogisticsModel(false);
          setSite(res.data);
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditLogistics(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  const editElectricLoad = () => {
    setLoadingEditElectricLoad(true);
    const payload = {
      // ...site,
      // service_voltage: site?.service_voltage ? site?.service_voltage : "0",
      // additional_links: site?.additional_links ? site?.additional_links : [],
      // preferred_unloading_method: site?.preferred_unloading_method
      // ? site?.preferred_unloading_method
      // : "Nill",
      average_daily_usage: averageDailyUsage,
      peak_demand: peakDemand,
      average_demand: averageDemand,

      apparent_power_continuous: apparentPowerContinuous,
      real_power_continuous: realPowerContinuous,
      current_continuous: currentContinuous,
      max_current_transient: maxCurrentTransient,
      apparent_power_transient: apparentPowerTransient,
      max_transient_duration: maxTransientDuration,
    };

    fetch(`${Config.API.URL}/sites/${site.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingEditElectricLoad(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          //localStorage.removeItem('token');
          //localStorage.removeItem('user');
          //history.push('/login');
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditElectricLoad(false);
        // console.log("res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setEditElectricLoadModel(false);
          setSite(res.data);
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditElectricLoad(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  const _onDeleteMilestone = (milestone) => {
    swal({
      title: `Are you sure?`,
      text: `You want to delete "${milestone.title}"? because once deleted, you will not be able to recover this milestone!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(`${Config.API.URL}/site-milestones`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify([{ id: milestone.id }]),
        })
          .then((res) => {
            // if 401 error (unauthenticated user, then log user out)

            console.log("res.ok", res.ok);
            console.log("res.status", res.status);
            if (res.status === 401) {
              console.log("401 Error: Unauthenticated");
              localStorage.removeItem("user");
              //navigate("/");
            }
            return res;
          })
          //.then((res) => res.json())
          .then(async (res) => {
            // if request is succesful, alert user that project has been cloned

            if (res.ok) {
              let index = milestones.findIndex((x) => x.id === milestone.id);
              let data = [...milestones];

              data.splice(index, 1);
              setMilestones(data);

              swal(
                `The "${milestone.title}" milestone has been successfully deleted!`,
                { icon: "success" }
              );
            } else {
              swal("Oh no!", res.message, "error");
            }
          })
          .catch((error) => {
            console.log("Error: ", error);

            swal(
              "Oh no!",
              "An error occured!!! while connecting to server. Please try again",
              "error"
            );
          });
      }
    });
  };
  const _onEditMilestone = (milestone) => {
    setMilestone(milestone);

    setEditMilestoneModel(true);

    setTargetData(milestone.target_date ? milestone.target_date : "");
    setDateAchieved(milestone.achieved_date ? milestone.achieved_date : "");
    setMilestoneName(milestone.title ? milestone.title : "");
  };
  const submitMilestone = () => {
    if (milestoneName.length && dateAchieved.length) {
      setLoading(true);
      const payload = {
        target_date: targetData,
        achieved_date: dateAchieved,
        title: milestoneName,
      };

      fetch(`${Config.API.URL}/site-milestones/${milestone?.id}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          setLoading(false);

          // if 401 error (unauthenticated user, then log user out)
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");
            localStorage.removeItem("user");
            navigate("/");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          setLoading(false);

          // if request is succesful, alert user that project has been cloned
          if (res.status) {
            setEditMilestoneModel(false);

            let index = milestones.findIndex((x) => x.id === milestone.id);
            let data = [...milestones];

            data[index] = res.data;

            setMilestones(data);
          } else {
            swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          setLoading(false);

          console.log("Error: ", error);

          swal(
            "Oh no!",
            "An error occured!!! while connecting to server. Please try again",
            "error"
          );
        });
    } else {
      swal("Oh no!", "All fields with asterisk(*) are required", "error");
    }
  };

  return (
    <>
      <div className=" w-11/12 lg:w-full ml-auto mr-auto ">
        {/* crd started */}
        <div className=" bg-white  shadow-lg p-[20px] rounded-[8px] mt-6  relative ">
          <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
            Electric Load
          </h2>
          {/* <div
            className="absolute top-3 left-3 h-[50px] col-span-12 sm:col-span-4 xl:col-span-3 bg-white rounded-[10px] shadow-lg flex items-center justify-between px-4 cursor-pointer"
            onClick={() =>
              window.open(
                "https://design.boxpower.cloud/auth/project/management/92/overview",
                "_blank",
                "noopener,noreferrer"
              )
            }
          >
            <button className="text-primary f-f-r text-lg">Load Profile</button>
          </div> */}
          {/* button started */}
          <button
            onClick={() => setEditElectricLoadModel(true)}
            className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
          >
            Edit
          </button>
          {/* buttons ended */}

          <h3 className="  f-f-r text-lg text-black-vlight mt-14  ">
            Average Daily Usage (kWh/day){" "}
            <span className=" float-right text-black ">
              {site?.average_daily_usage?.toFixed(2)}
            </span>{" "}
          </h3>
          <div className=" border border-white-vlight my-1 "></div>
          {/* start */}
          {/*           <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
            Peak Demand (kW)
            <span className=" float-right text-black ">
              {site?.peak_demand?.toFixed(2)}
            </span>{" "}
          </h3>
          <div className=" border border-white-vlight my-1 "></div>
          {/* start */}
          {/* 
          <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
            Average Demand (kW)
            <span className=" float-right text-black ">
              {site?.average_demand?.toFixed(2)}
            </span>{" "}
          </h3>
          <div className=" border border-white-vlight my-1 "></div> */}

          {/* start */}
          {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
            Max Continuous Load - Apparent Power (kVA)
            <span className=" float-right text-black ">
              {site?.apparent_power_continuous?.toFixed(2)}
            </span>{" "}
          </h3> 
          <div className=" border border-white-vlight my-1 "></div>*/}
          {/* start */}
          <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
            Max Continuous Load - Real Power (kW)
            <span className=" float-right text-black ">
              {site?.real_power_continuous?.toFixed(2)}
            </span>{" "}
          </h3>
          <div className=" border border-white-vlight my-1 "></div>
          {/* start */}
          {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
            Max Continuous Load - Current (Amps)
            <span className=" float-right text-black ">
              {site?.current_continuous?.toFixed(2)}
            </span>{" "}
          </h3> 
          <div className=" border border-white-vlight my-1 "></div>*/}
          {/* start */}
          {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
            Max Transient Current (A)
            <span className=" float-right text-black ">
              {site?.max_current_transient?.toFixed(2)}
            </span>{" "}
          </h3>
          <div className=" border border-white-vlight my-1 "></div> */}
          {/* start */}
          <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
            Max Transient Apparent Power (kVA)
            <span className=" float-right text-black ">
              {site?.apparent_power_transient?.toFixed(2)}
            </span>{" "}
          </h3>
          <div className=" border border-white-vlight my-1 "></div>
          {/* start */}
          {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
            Max Transient Duration (mSec)
            <span className=" float-right text-black ">
              {site?.max_transient_duration?.toFixed(2)}
            </span>{" "}
          </h3>
          <div className=" border border-white-vlight my-1 "></div> */}
        </div>
        {/* card ended */}

        {/* crd started */}
        <div className=" bg-white  shadow-lg p-[20px] rounded-[8px] mt-6  relative ">
          <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
            Site Milestones
          </h2>
          {/* button started */}
          <SiteMilestonesDialogBox
            site={site}
            onReceiveMilestone={(items) => {
              let item = { ...items[0] };
              item.achieved_date = "Not Added Yet";
              setMilestones([...milestones, item]);
            }}
          />
          {milestones.length ? (
            <UniversalDataTable
              table_label={null}
              header_format={[
                // { column: "contacts", label: "Project Contacts" },
                { column: "title", label: "Milestone Name" },
                { column: "target_date", label: "Target Date" },
                { column: "achieved_date", label: "Achieved Date" },
              ]}
              data={milestones}
              data_format={[
                // { column: "contacts", keys: ["contacts"] },
                { column: "title", keys: ["title"] },
                { column: "target_date", keys: ["target_date"] },
                {
                  column: "achieved_date",
                  keys: ["achieved_date"],
                  button: true,
                },
              ]}
              setPage={() => console.log("Hi")}
              lastPage={1}
              currentPage={1}
              onCreateItem={false}
              onViewItem={false}
              onDeleteItem={_onDeleteMilestone}
              onEditItem={_onEditMilestone}
              onSearchData={false}
            />
          ) : (
            <p style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>
              No milestones have been added to this site yet
            </p>
          )}
          {/* <button
            //onClick={() => setEditElectricLoadModel(true)}
            className="absolute top-3 right-3 border bg-primary w-[120px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
          >
            Add Milestones
          </button> */}
          {/* buttons ended */}

          {/* <h3 className="  f-f-r text-lg text-black-vlight mt-14  ">
            Average Daily Usage (kWh/day){" "}
            <span className=" float-right text-black ">
              {site?.average_daily_usage?.toFixed(2)}
            </span>{" "}
          </h3>
          <div className=" border border-white-vlight my-1 "></div> */}
          {/* start */}
          {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
            Max Continuous Load - Real Power (kW)
            <span className=" float-right text-black ">
              {site?.real_power_continuous?.toFixed(2)}
            </span>
          </h3>
          <div className=" border border-white-vlight my-1 "></div> */}
          {/* start */}
          {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
            Max Transient Apparent Power (kVA)
            <span className=" float-right text-black ">
              {site?.apparent_power_transient?.toFixed(2)}
            </span>{" "}
          </h3>
          <div className=" border border-white-vlight my-1 "></div> */}
        </div>
        {/* card ended */}

        <div className="grid grid-cols-12 mt-5 gap-4  ">
          <div className="   col-span-12 sm:col-span-6">
            {/* crd started */}
            <div className=" bg-white  shadow-lg p-[20px] rounded-[8px] mt-6  relative ">
              <button
                type="button"
                onClick={() => setAddDirectoryLinksModel(true)}
                className="absolute top-3 left-3 border bg-default w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-primary  "
              >
                Add
              </button>
              <button
                type="button"
                onClick={() => setEditDirectoryLinksModel(true)}
                className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
              >
                Edit
              </button>
              <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                Directory of Links
              </h2>

              <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={15}
                slidesPerView={1}
                //navigation={{ clickable: true }}
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                // onSwiper={(swiper) => console.log('swiper:',swiper)}
                // onSlideChange={() => console.log('slide change')}
                style={{ height: 500 }}
              >
                {directory_of_links.map((links, i) => (
                  <SwiperSlide key={i}>
                    {links?.map((link, j) => (
                      <div key={j}>
                        <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                          {link.name}:{" "}
                          <span className=" float-right text-black ">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={link.value}
                            >
                              {link.value}
                            </a>
                          </span>{" "}
                        </h3>
                        <div className=" border border-white-vlight my-1 "></div>
                      </div>
                    ))}
                  </SwiperSlide>
                ))}
              </Swiper>

              {/* <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >Clickup Spaces <span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div>
              
              <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >PSA Contact<span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div>
              
              <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >Consulting Contact<span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div>
              
              <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >Project Sharepoint<span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div>
              
              <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >Invoices<span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div>
              
              <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >Project  BOM Worksheet<span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div>
              
              <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >Project Pricing Worksheet<span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div>
              
              <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >Project Schedule<span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div>
              
              <h3 className='  f-f-r text-lg text-black-vlight mt-4  ' >Assumptions  & Exclusions<span className=' float-right text-black ' >www.example.com</span> </h3>
              <div className=' border border-white-vlight my-1 ' ></div> */}
            </div>
            {/* card ended */}
          </div>
          <div className="   col-span-12 sm:col-span-6">
            {/* crd started */}
            <div className=" bg-white  shadow-lg p-[20px] rounded-[8px] mt-6  relative ">
              <button
                type="button"
                onClick={() => setEditLogisticsModel(true)}
                className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white"
              >
                Edit
              </button>
              <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                Logistics
              </h2>
              {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Target Delivery Date
                <span className=" float-right text-black ">
                  {site?.target_delivery_date}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div> */}
              {/* start */}
              {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Equipment
                <span className=" float-right text-black ">
                  {site?.equipment}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div> */}
              {/* start */}
              {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Loading Method
                <span className=" float-right text-black ">
                  {site?.loading}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div> */}
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Shipping Method
                <span className=" float-right text-black ">
                  {site?.shipping}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Unloading Method
                <span className=" float-right text-black ">
                  {site?.unloading}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                System Placement
                <span className=" float-right text-black ">
                  {site?.system_placement}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Preferred Unloading Method
                <span className=" float-right text-black ">
                  {site?.preferred_unloading_method}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div> */}
              {/* start */}
              {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Customer Shipping Max Budget
                <span className=" float-right text-black ">
                  ${site?.customer_shipping_max_budget}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div> */}
              {/* start */}
              {/* <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Miscellaneous Items Being Shipped
                <span className=" float-right text-black ">
                  {site?.miscellaneous_items_being_shipped}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div> */}
              {/* New */}
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Site Directions
                <span className=" float-right text-black ">
                  {site?.site_directions}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Site Distance from HQ (miles)
                <span className=" float-right text-black ">
                  {site?.site_distance_miles}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Site Distance from HQ (drive time)
                <span className=" float-right text-black ">
                  {site?.site_distance_drive}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Access Road Flags or Cautions
                <span className=" float-right text-black ">
                  {site?.access_road_flags}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Publicly Accessible?
                <span className=" float-right text-black ">
                  {site?.publicly_accessible}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Locked Gated Access Road?
                <span className=" float-right text-black ">
                  {site?.locked_access_road}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Key/Lock Access Code
                <span className=" float-right text-black ">
                  {site?.lock_access_code}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Procurement Holding Yard
                <span className=" float-right text-black ">
                  {site?.procurement_yard}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
            </div>
            {/* card ended */}
          </div>
        </div>

        {/* table started */}
        {/* crd started */}
        <div className=" bg-white  shadow-lg p-[20px] rounded-[8px] my-6  relative ">
          <button
            type="button"
            onClick={() => setEditPowerDistributionSummaryModel(true)}
            className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white"
          >
            Edit
          </button>
          <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
            Power Distribution Summary
          </h2>

          <div className="overflow-x-auto">
            <table className=" w-full text-left power-table ">
              <thead>
                <tr>
                  <th className="f-f-r text-lg text-primary">
                    Service Conductor Path
                  </th>
                  <th className="f-f-r text-lg text-primary">Type</th>
                  <th className="f-f-r text-lg text-primary">New/Existing</th>
                  <th className="f-f-r text-lg text-primary">Distance</th>
                  <th className="f-f-r text-lg text-primary">Unit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="f-f-r text-lg text-black-vlight">
                    PCC to Transformer
                  </td>
                  <td className="f-f-r text-lg text-black-vlight">
                    {site?.pcc_to_transformer_type}
                  </td>
                  <td className="f-f-r text-lg text-black-vlight">
                    {site?.pcc_to_transformer_install}
                  </td>
                  <td className="f-f-r text-lg text-black-vlight">
                    {site?.pcc_to_transformer_distance}
                  </td>
                  <td className="f-f-r text-lg text-black-vlight">Feet</td>
                </tr>
                <tr>
                  <td className="f-f-r text-lg text-black-vlight">
                    Transformer to Meter
                  </td>
                  <td className="f-f-r text-lg text-black-vlight">
                    {site?.transformer_to_meter_type}
                  </td>
                  <td className="f-f-r text-lg text-black-vlight">
                    {site?.transformer_to_meter_install}
                  </td>
                  <td className="f-f-r text-lg text-black-vlight">
                    {site?.transformer_to_meter_distance}
                  </td>
                  <td className="f-f-r text-lg text-black-vlight">Feet</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* card ended */}
        {/* table ended */}
      </div>
      <Transition.Root show={editPowerDistributionSummaryModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditPowerDistributionSummaryModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Power Distribution Summary
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <h3 className=" f-f-r text-lg text-black-vlight text-center ">
                            Service Conductor Path <br />
                            (PCC To Transformer)
                          </h3>

                          <div className="mt-0 sm:col-span-12">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Type
                            </label>
                            <div className="mt-0">
                              <select
                                onChange={(e) =>
                                  setPCCToTransformerType(e.target.value)
                                }
                                value={PCCToTransformerType}
                                className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              >
                                <option value=""></option>
                                <option value="Underground">Underground</option>
                                <option value="Overhead">Overhead</option>
                              </select>
                            </div>
                          </div>

                          <div className="mt-3 space-y-0">
                            <div className="flex items-center gap-x-3">
                              <input
                                type="radio"
                                checked={PCCToTransformerInstall === "New"}
                                onChange={(e) =>
                                  setPCCToTransformerInstall(e.target.value)
                                }
                                value="New"
                                name="PCCToTransformerInstall"
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                New
                              </label>
                            </div>

                            <div className="mt-0 flex items-center gap-x-3">
                              <input
                                type="radio"
                                checked={PCCToTransformerInstall === "Existing"}
                                onChange={(e) =>
                                  setPCCToTransformerInstall(e.target.value)
                                }
                                value="Existing"
                                name="PCCToTransformerInstall"
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Existing
                              </label>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Distance (Feet)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setPCCToTransformerDistanceFeet(
                                      e.target.value
                                    )
                                  }
                                  value={PCCToTransformerDistanceFeet}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <h3 className="mt-10 f-f-r text-lg text-black-vlight text-center ">
                            (Transformer To Meter)
                          </h3>

                          <div className="mt-0 sm:col-span-12">
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                              Type
                            </label>
                            <div className="mt-0">
                              <select
                                onChange={(e) =>
                                  setTransformerToMeterType(e.target.value)
                                }
                                value={transformerToMeterType}
                                className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              >
                                <option value=""></option>
                                <option value="Underground">Underground</option>
                                <option value="Overhead">Overhead</option>
                              </select>
                            </div>
                          </div>

                          <div className="mt-3 space-y-0">
                            <div className="flex items-center gap-x-3">
                              <input
                                type="radio"
                                checked={transformerToMeterInstall === "New"}
                                onChange={(e) =>
                                  setTransformerToMeterInstall(e.target.value)
                                }
                                value="New"
                                name="transformerToMeterInstall"
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                New
                              </label>
                            </div>

                            <div className="mt-0 flex items-center gap-x-3">
                              <input
                                type="radio"
                                checked={
                                  transformerToMeterInstall === "Existing"
                                }
                                onChange={(e) =>
                                  setTransformerToMeterInstall(e.target.value)
                                }
                                value="Existing"
                                name="transformerToMeterInstall"
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              />
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Existing
                              </label>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Distance (Feet)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setTransformerToMeterDistanceFeet(
                                      e.target.value
                                    )
                                  }
                                  value={transformerToMeterDistanceFeet}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditPowerDistributionSummary ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={editPowerDistributionSummary}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() =>
                        setEditPowerDistributionSummaryModel(false)
                      }
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={addDirectoryLinksModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setAddDirectoryLinksModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Add Additional Links
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Link name
                              </label>
                              <div className="mt-0 relative">
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    let link = { ...additionalLink };
                                    link.name = e.target.value;
                                    setAdditionalLink(link);
                                  }}
                                  value={additionalLink.name}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>

                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Link value
                              </label>
                              <div className="mt-0 relative">
                                <input
                                  type="text"
                                  onChange={(e) => {
                                    let link = { ...additionalLink };
                                    link.value = e.target.value;
                                    setAdditionalLink(link);
                                  }}
                                  value={additionalLink.value}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingAddDirectoryLink ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={addDirectoryLinks}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setAddDirectoryLinksModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editDirectoryLinksModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditDirectoryLinksModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Directory Links
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          {additionalLinks?.map((link, i) => (
                            <div
                              key={i}
                              className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"
                            >
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  {link.name}
                                </label>
                                <div className="grid grid-cols-12 gap-0  ">
                                  <div className="col-span-12 md:col-span-11">
                                    <div className="mt-0 relative">
                                      <input
                                        type="text"
                                        id={i}
                                        onChange={(e) => {
                                          let links = [...additionalLinks];
                                          links[i].value = e.target.value;
                                          setAdditionalLinks(links);
                                        }}
                                        value={link.value}
                                        className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-span-12 md:col-span-1">
                                    <button
                                      onClick={(e) => {
                                        let links = [...additionalLinks];

                                        links.splice(i, 1);
                                        setAdditionalLinks(links);
                                      }}
                                      //className=" inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                                    >
                                      <FaRegTrashAlt
                                        size="1.8rem"
                                        className="inline-flex text-gray-500 text-center f-f-r pl-2 pt-2"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditDirectoryLinks ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={updateLinksWithShortenedUrls}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditDirectoryLinksModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editLogisticsModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditLogisticsModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Logistics
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Target Delivery Date
                              </label>
                              <div className="mt-0 relative">
                                <input
                                  type="date"
                                  id="birthday"
                                  name="birthday"
                                  onChange={(e) =>
                                    setTargetDeliveryDate(e.target.value)
                                  }
                                  value={targetDeliveryDate}
                                  className="p-3 easi-grid-datepicker block w-full pl-[30px] pr-4 relative rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div> */}

                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Equipment
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => setEquipment(e.target.value)}
                                  value={equipment}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div> */}

                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Loading Method
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setLoadingMethod(e.target.value)
                                  }
                                  value={loadingMethod}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div> */}

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Shipping Method
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setShippingMethod(e.target.value)
                                  }
                                  value={shippingMethod}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Unloading Method
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setUnloadingMethod(e.target.value)
                                  }
                                  value={unloadingMethod}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                System Placement
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setSystemPlacement(e.target.value)
                                  }
                                  value={systemPlacement}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Preferred Unloading Method
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setPreferredUnloadingMethod(e.target.value)
                                  }
                                  value={preferredUnloadingMethod}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div> */}

                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Customer Shipping Max Budget
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setCustomerShippingMaxBudget(e.target.value)
                                  }
                                  value={customerShippingMaxBudget}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div> */}

                          {/* <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Miscellaneous Items Being Shipped
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setMiscellaneousItemsBeingShipped(
                                      e.target.value
                                    )
                                  }
                                  value={miscellaneousItemsBeingShipped}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div> */}

                          {/* New */}

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Site Directions
                              </label>
                              <div className="mt-0">
                                <textarea
                                  onChange={(e) =>
                                    setSite_directions(e.target.value)
                                  }
                                  value={site_directions}
                                  rows="4"
                                  className="pr-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 smbase sm:leading-6"
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Site Distance from HQ (miles)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setSite_distance_miles(e.target.value)
                                  }
                                  value={site_distance_miles}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Site Distance from HQ (drive time)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setSite_distance_drive(e.target.value)
                                  }
                                  value={site_distance_drive}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Access Road Flags or Cautions
                              </label>
                              <div className="mt-0">
                                <textarea
                                  onChange={(e) =>
                                    setAccess_road_flags(e.target.value)
                                  }
                                  value={access_road_flags}
                                  rows="4"
                                  className="pr-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 smbase sm:leading-6"
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Publicly Accessible?
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setPublicly_accessible(e.target.value)
                                  }
                                  value={publicly_accessible}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Locked Gated Access Road?
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setLocked_access_road(e.target.value)
                                  }
                                  value={locked_access_road}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Key/Lock Access Code
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setLock_access_code(e.target.value)
                                  }
                                  value={lock_access_code}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Procurement Holding Yard
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setProcurement_yard(e.target.value)
                                  }
                                  value={procurement_yard}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditLogistics ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={editLogistics}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditLogisticsModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editElectricLoadModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditElectricLoadModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                      </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Electric Load
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Average Daily Usage (kWh/day)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setAverageDailyUsage(e.target.value)
                                  }
                                  value={averageDailyUsage}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          {/*                           <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Peak Demand
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setPeakDemand(e.target.value)
                                  }
                                  value={peakDemand}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Average Demand
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setAverageDemand(e.target.value)
                                  }
                                  value={averageDemand}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div> */}

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Max Continuous Load - Apparent Power (kVA)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setApparentPowerContinuous(e.target.value)
                                  }
                                  value={apparentPowerContinuous}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Max Continuous Load - Real Power (kW)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setRealPowerContinuous(e.target.value)
                                  }
                                  value={realPowerContinuous}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Max Continuous Load - Current (Amps)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setCurrentContinuous(e.target.value)
                                  }
                                  value={currentContinuous}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Max Transient Current (A)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setMaxCurrentTransient(e.target.value)
                                  }
                                  value={maxCurrentTransient}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Max Transient Apparent Power (kVA)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setApparentPowerTransient(e.target.value)
                                  }
                                  value={apparentPowerTransient}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Max Transient Duration (mSec)
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setMaxTransientDuration(e.target.value)
                                  }
                                  value={maxTransientDuration}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditElectricLoad ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={editElectricLoad}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditElectricLoadModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editMilestoneModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditMilestoneModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Milestone Date Achieved
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Milestone Name
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setMilestoneName(e.target.value)
                                  }
                                  value={milestoneName}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Target Date
                              </label>
                              <div className="mt-0">
                                <input
                                  type="date"
                                  onChange={(e) =>
                                    setTargetData(e.target.value)
                                  }
                                  value={targetData}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Date Achieved
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-2">
                                <input
                                  type="date"
                                  onChange={(e) =>
                                    setDateAchieved(e.target.value)
                                  }
                                  value={dateAchieved}
                                  rows="4"
                                  className="pr-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loading ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={submitMilestone}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditMilestoneModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
