import React, { Fragment, useRef, useState, useEffect } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import Config from "../../utils/Config";

import Dilogbox from "../NewProjectForm/Dilogbox";

import swal from "sweetalert";

import { useNavigate } from "react-router-dom";

const google = window.google;

export default function Actioncard(props) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.profile);
  const [site, setSite] = useState({ ...props.site });

  const [resourceDesignParametersModel, setResourceDesignParametersModel] =
    useState(false);

  const [editDesignParametersModel, setEditDesignParametersModel] =
    useState(false);
  const [editSiteInformationModel, setEditSiteInformationModel] =
    useState(false);
  const [editSiteConditionsModel, setEditSiteConditionsModel] = useState(false);

  const cancelButtonRef = useRef(null);

  const [minimumTemperature, setMinimumTemperature] = React.useState("");
  const [maximumTemperature, setMaximumTemperature] = React.useState("");
  const [minimumHumidity, setMinimumHumidity] = React.useState("");
  const [maximumHumidity, setMaximumHumidity] = React.useState("");
  const [snowLoad, setSnowLoad] = React.useState("");
  //changes

  const [windSpeed, setWindSpeed] = React.useState("");
  const [soilType, setSoilType] = React.useState("");
  const [spectralResponseShort, setSpectralResponseShort] = React.useState("");
  const [spectralResponseSecond, setSpectralResponseSecond] =
    React.useState("");
  const [seismicImportanceFactor, setSeismicImportanceFactor] =
    React.useState("");
  const [seismicSiteClass, setSeismicSiteClass] = React.useState("");
  const [designSpectralResponseDS, setDesignSpectralResponseDS] =
    React.useState("");
  const [designSpectralResponseD1, setDesignSpectralResponseD1] =
    React.useState("");
  const [seismicDesignCategory, setSeismicDesignCategory] = React.useState("");

  const [elevation, setElevation] = React.useState("");
  const [wind_exposure_category, setWind_exposure_category] =
    React.useState("C");
  const [snow_height, setSnow_height] = React.useState("");
  const [risk_category, setRisk_category] = React.useState("");
  const [geologic_hazard_zone, setGeologic_hazard_zone] = React.useState();
  const [state_seismic_landslide_zone, setState_seismic_landslide_zone] =
    React.useState();
  const [state_seismic_liquefaction_zone, setState_seismic_liquefaction_zone] =
    React.useState();

  const [siteName, setSiteName] = React.useState("");
  const [siteContacts, setSiteContacts] = React.useState(
    site?.contacts ? site?.contacts : []
  );
  const [siteAddress, setSiteAddress] = React.useState("");
  const [GPSCoordinates, setGPSCoordinates] = React.useState("");
  const [renewableFractionTarget, setRenewableFractionTarget] =
    React.useState("");

  const [county, setCounty] = React.useState("");
  const [ratepayerType, setRatepayerType] = React.useState("");
  const [landowner, setLandowner] = React.useState("");
  const [criticalLoad, setCriticalLoad] = React.useState("");
  const [generatorUse, setGeneratorUse] = React.useState("");
  const [containerWeight, setContainerWeight] = React.useState("");
  const [buildingCode, setBuildingCode] = React.useState("");
  const [siteFence, setSiteFence] = React.useState("");

  const [foundationType, setFoundationType] = React.useState(
    site?.foundation_type
  );
  const [
    additionalFoundationRequirements,
    setAdditionalFoundationRequirements,
  ] = React.useState(site?.foundation_requirements);
  const [serviceVoltage, setServiceVoltage] = React.useState(
    site?.service_voltage
  );
  const [connectionType, setConnectionType] = React.useState(
    site?.connection_type
  );
  const [powerPhase, setPowerPhase] = React.useState(site?.power_phase);
  const [numberOfWires, setNumberOfWires] = React.useState(
    site?.number_of_wires
  );
  const [wiringConfiguration, setWiringConfiguration] = React.useState(
    site?.wiring_configuration
  );

  const [miles_line_removed, setMiles_line_removed] = React.useState();

  const [loadingResourceDesignParameters, setLoadingResourceDesignParameters] =
    React.useState(false);

  const [loadingEditDesignParameters, setLoadingEditDesignParameters] =
    React.useState(false);
  const [loadingEditSiteInformation, setLoadingEditSiteInformation] =
    React.useState(false);
  const [loadingEditSiteConditions, setLoadingEditSiteConditions] =
    React.useState(false);

  const [siteDistanceMiles, setSiteDistanceMiles] = React.useState("");
  const [siteDistanceDrive, setSiteDistanceDrive] = React.useState("");

  const [temperature, setTemperature] = React.useState(true);
  const [wind, setWind] = React.useState(true);
  const [snow, setSnow] = React.useState(true);
  const [seismic, setSeismic] = React.useState(true);

  // Project Tracking States
  const [editGeneralStatusModal, setEditGeneralStatusModal] = useState(false);
  const [general_stage, set_general_stage] = useState("");
  const [general_status, set_general_status] = useState("");
  const [loadingGeneralStatus, setLoadingGeneralStatus] = useState(false);

  // my states
  const [riskLevel, setRiskLevel] = React.useState(4);
  const [siteClass, setSiteClass] = React.useState("D");

  useEffect(() => {
    setSite(props.site);

    setMinimumTemperature(props.site?.location_minimum_temperature);
    setMaximumTemperature(props.site?.location_maximum_temperature);
    setMinimumHumidity(props.site?.location_minimum_humidity);
    setMaximumHumidity(props.site?.location_maximum_humidity);
    setSnowLoad(props.site?.snow_load);
    setWindSpeed(props.site?.wind_speed);
    setSoilType(props.site?.soil_type);
    setSpectralResponseShort(props.site?.seismic_spectral_response_short);
    setSpectralResponseSecond(props.site?.seismic_spectral_response_second);
    setSeismicImportanceFactor(props.site?.seismic_importance_factor);
    setSeismicSiteClass(props.site?.seismic_site_class);
    setDesignSpectralResponseDS(props.site?.design_spectral_response_short);
    setDesignSpectralResponseD1(props.site?.design_spectral_response_second);
    setSeismicDesignCategory(props.site?.seismic_design_category);

    setElevation(props.site?.elevation);
    setWind_exposure_category(props.site?.wind_exposure_category);
    setSnow_height(props.site?.snow_height);
    setRisk_category(props.site?.risk_category);
    setGeologic_hazard_zone(props.site?.geologic_hazard_zone);
    setState_seismic_landslide_zone(props.site?.state_seismic_landslide_zone);
    setState_seismic_liquefaction_zone(
      props.site?.state_seismic_liquefaction_zone
    );

    setSiteName(props.site?.site_name);
    setSiteAddress(props.site?.site_address);
    setGPSCoordinates(props.site?.site_gps);
    setRenewableFractionTarget(props.site?.target_renewable);

    setCounty(props.site?.county);
    setRatepayerType(props.site?.ratepayer_type);
    setLandowner(props.site?.landowner);
    setCriticalLoad(props.site?.critical_load);
    setGeneratorUse(props.site?.generator_use);
    setContainerWeight(props.site?.container_weight);

    setFoundationType(props.site?.foundation_type);
    setAdditionalFoundationRequirements(props.site?.foundation_requirements);
    setServiceVoltage(props.site?.service_voltage);
    setConnectionType(props.site?.connection_type);
    setPowerPhase(props.site?.power_phase);
    setNumberOfWires(props.site?.number_of_wires);
    setWiringConfiguration(props.site?.wiring_configuration);

    setMiles_line_removed(props.site?.miles_line_removed);

    // Project Tracking States
    set_general_stage(props.site?.general_stage);
    set_general_status(props.site?.general_status);

    if (props.site?.contacts) {
      setSiteContacts(props.site?.contacts);
    }

    // console.log("props.site?.contacts:", props.site?.contacts);
  }, [props.site]);

  const _onReceiveContact = (contacts) => {
    setSiteContacts(contacts);
    // console.log('edit contacts:', contacts);
  };

  function convertToDecimalFormat(input) {
    // Normalize input: uppercase and remove unnecessary spaces
    input = input.replace(/\s+/g, " ").trim().toUpperCase();

    // Handle degree formats for both standard and reversed latitude-longitude entries
    const degreePattern =
      /(\d{1,3}\.\d+|\d{1,3})°?([NS]),?\s*(\d{1,3}\.\d+|\d{1,3})°?([EW])/i;
    const degreePatternReverse =
      /(\d{1,3}\.\d+|\d{1,3})°?([EW]),?\s*(\d{1,3}\.\d+|\d{1,3})°?([NS])/i;

    let match = input.match(degreePattern) || input.match(degreePatternReverse);

    if (match) {
      let latitude = parseFloat(match[1]);
      let longitude = parseFloat(match[3]);
      let latDirection = match[2];
      let longDirection = match[4];

      // Swap if the original input had longitude first
      if (degreePatternReverse.test(input)) {
        [latitude, longitude, latDirection, longDirection] = [
          longitude,
          latitude,
          longDirection,
          latDirection,
        ];
      }

      // Convert N/S to positive/negative latitude
      if (latDirection === "S") latitude = -latitude;

      // Convert E/W to positive/negative longitude
      if (longDirection === "W") longitude = -longitude;

      // Check for NaN values in coordinates
      if (isNaN(latitude) || isNaN(longitude)) {
        return input;
      }

      return `${latitude},${longitude}`;
    }

    // Handle normal decimal format, potentially reversing coordinates if necessary
    let parts = input.split(/,\s*|\s+/);
    if (parts.length === 2) {
      let lat = parseFloat(parts[0]);
      let lon = parseFloat(parts[1]);

      // Correct order based on usual latitude range
      if (Math.abs(lat) > 90) {
        [lat, lon] = [lon, lat];
      }

      // Check for NaN values in coordinates
      if (isNaN(lat) || isNaN(lon)) {
        return input;
      }

      return `${lat},${lon}`;
    }

    // Return the input as is if it is malformed or unrecognized
    return input;
  }

  const editGeneralStatus = () => {
    setLoadingGeneralStatus(true);
    const payload = {
      general_stage: general_stage,
      general_status: general_status,
    };

    fetch(`${Config.API.URL}/sites/${site.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingGeneralStatus(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          //localStorage.removeItem('token');
          //localStorage.removeItem('user');
          //history.push('/login');
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingGeneralStatus(false);
        // console.log("res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setEditGeneralStatusModal(false);
          setSite(res.data);
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingGeneralStatus(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  const editSiteConditions = () => {
    setLoadingEditSiteConditions(true);
    const payload = {
      // ...site,
      //additional_links: site?.additional_links ? site?.additional_links : [],
      // preferred_unloading_method: site?.preferred_unloading_method
      //   ? site?.preferred_unloading_method
      //   : "Nill",
      foundation_type: foundationType,
      foundation_requirements: additionalFoundationRequirements,
      service_voltage: serviceVoltage,
      connection_type: connectionType,
      power_phase: powerPhase,
      number_of_wires: numberOfWires,
      wiring_configuration: wiringConfiguration,
      miles_line_removed: miles_line_removed,
    };

    fetch(`${Config.API.URL}/sites/${site.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingEditSiteConditions(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          localStorage.removeItem("user");
          navigate("/login");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditSiteConditions(false);
        //console.log("res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setEditSiteConditionsModel(false);
          setSite(res.data);
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditSiteConditions(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  const editSiteInformation = () => {
    // console.log(siteContacts, siteName?.length, siteAddress?.length, GPSCoordinates?.length, renewableFractionTarget?.toString()?.length)
    if (
      siteContacts?.length &&
      siteName?.length &&
      siteAddress?.length &&
      GPSCoordinates?.length &&
      renewableFractionTarget?.toString()?.length
    ) {
      setLoadingEditSiteInformation(true);
      const site_contacts = [];
      for (let i = 0; i < siteContacts.length; i++) {
        const contact = siteContacts[i];
        site_contacts.push(contact.id);
      }
      const payload = {
        // ...site,
        // service_voltage: site?.service_voltage ? site?.service_voltage : "0",
        // additional_links: site?.additional_links ? site?.additional_links : [],
        // preferred_unloading_method: site?.preferred_unloading_method
        //   ? site?.preferred_unloading_method
        //   : "Nill",
        site_name: siteName,
        site_address: siteAddress,
        site_gps: GPSCoordinates, // "39.2216489,-121.0130471",
        site_contacts: site_contacts,
        target_renewable: renewableFractionTarget,
        county: county,
        ratepayer_type: ratepayerType,
        landowner: landowner,
        critical_load: criticalLoad,
        generator_use: generatorUse,
        container_weight: containerWeight,
        building_code: buildingCode,
        site_fence: siteFence?.length ? JSON.parse(siteFence) : false,
        others: [],

        site_distance_miles: siteDistanceMiles,
        site_distance_drive: siteDistanceDrive,
      };

      fetch(`${Config.API.URL}/sites/${site.id}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          setLoadingEditSiteInformation(false);
          // if 401 error (unauthenticated user, then log user out)
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");

            localStorage.removeItem("user");
            navigate("/login");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          setLoadingEditSiteInformation(false);
          // console.log("res:", res);

          // if request is succesful, alert user that project has been cloned
          if (res.status) {
            setEditSiteInformationModel(false);
            setSite(res.data);
            props.onReceiveSite(res.data);
          } else {
            swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          setLoadingEditSiteInformation(false);
          console.log("Error: ", error);

          swal(
            "Oh no!",
            "An error occured!!! while connecting to server. Please try again",
            "error"
          );
        });
    } else {
      swal("Oh no!", "All fields with asterisk(*) are required", "error");
    }
  };

  const editDesignParameters = () => {
    setLoadingEditDesignParameters(true);
    // setLoadingResourceDesignParameters(true);
    const payload = {
      location_minimum_temperature: minimumTemperature,
      location_maximum_temperature: maximumTemperature,
      location_minimum_humidity: minimumHumidity,
      location_maximum_humidity: maximumHumidity,
      wind_speed: windSpeed,
      snow_load: snowLoad,
      soil_type: soilType,
      seismic_spectral_response_short: spectralResponseShort,
      seismic_spectral_response_second: spectralResponseSecond,
      seismic_importance_factor: seismicImportanceFactor,
      seismic_site_class: seismicSiteClass,
      design_spectral_response_short: designSpectralResponseD1,
      design_spectral_response_second: designSpectralResponseDS,
      seismic_design_category: seismicDesignCategory,
      elevation: elevation,
      wind_exposure_category: wind_exposure_category,
      snow_height: snow_height,
      risk_category: risk_category,
      geologic_hazard_zone: parseInt(geologic_hazard_zone),
      state_seismic_landslide_zone: parseInt(state_seismic_landslide_zone),
      state_seismic_liquefaction_zone: parseInt(
        state_seismic_liquefaction_zone
      ),
    };

    fetch(`${Config.API.URL}/sites/${site.id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        setLoadingEditDesignParameters(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          //localStorage.removeItem('token');
          //localStorage.removeItem('user');
          //history.push('/login');
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoadingEditDesignParameters(false);
        // setLoadingResourceDesignParameters(false);
        // console.log("res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setSite(res.data);

          setEditDesignParametersModel(false);
          //setResourceDesignParametersModel(false);
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingEditDesignParameters(false);
        // setLoadingResourceDesignParameters(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  function calculateRelativeHumidity(temperature, dewPoint) {
    // Constants for the calculation
    const A = 17.625;
    const B = 243.04;

    // Calculate the saturation vapor pressure for the dew point
    const saturationVaporPressureDewPoint = Math.exp(
      (A * dewPoint) / (B + dewPoint)
    );

    // Calculate the saturation vapor pressure for the actual temperature
    const saturationVaporPressureTemperature = Math.exp(
      (A * temperature) / (B + temperature)
    );

    // Calculate the relative humidity
    let relativeHumidity =
      (saturationVaporPressureDewPoint / saturationVaporPressureTemperature) *
      100;

    // Cap the relative humidity between 0% and 100%
    relativeHumidity = Math.max(0, Math.min(relativeHumidity, 100));

    return relativeHumidity;
  }

  const editResourceDesignParameters = async () => {
    setLoadingResourceDesignParameters(true);

    try {
      const siteCoordinates = site?.site_gps?.trim().split(",");
      const [lat, lon] = siteCoordinates.map((coord) => Number(coord.trim()));
      const coordinates = `${lon} ${lat}`;

      const response = await fetch(
        `${Config.API.URLV1}/graphs/temperature?coordinates=${coordinates}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.status === 401) {
        console.log("401 Error: Unauthenticated");
        return;
      }

      const res = await response.json();
      console.log("temp-res", res);

      if (res.status) {
        let data = [];

        for (let i = 0; i < res.data.length; i++) {
          data[i] = res.data[i];
        }

        let temperatures = [];
        let dewpoints = [];

        if (data) {
          for (let i = 3; i < data.length; i++) {
            let nrel_data = data[i].split(",");
            temperatures.push(Number(nrel_data[6]));
            dewpoints.push(Number(nrel_data[7]));
          }

          let largestNum = temperatures[0];
          let smallestNum = temperatures[0];
          let largestDewPointNum = dewpoints[0];
          let smallestDewPointNum = dewpoints[0];

          for (let i = 1; i < temperatures.length; i++) {
            if (temperatures[i] > largestNum) {
              largestNum = temperatures[i];
            }
            if (temperatures[i] < smallestNum) {
              smallestNum = temperatures[i];
            }
            if (dewpoints[i] > largestDewPointNum) {
              largestDewPointNum = dewpoints[i];
            }
            if (dewpoints[i] < smallestDewPointNum) {
              smallestDewPointNum = dewpoints[i];
            }
          }

          const minHumidity = calculateRelativeHumidity(
            largestNum,
            smallestDewPointNum
          );
          const maxHumidity = calculateRelativeHumidity(
            smallestNum,
            largestDewPointNum
          );

          setMinimumTemperature(smallestNum);
          setMaximumTemperature(largestNum);
          setMaximumHumidity(maxHumidity);
          setMinimumHumidity(minHumidity);
          setWind_exposure_category("C");

          const standardsVersion = "7-22";
          const baseUrl = "https://vt8pmhgbpp.us-east-1.awsapprunner.com/v1/";
          const buildUrl = (endpoint, extraParams = "") =>
            `${baseUrl}${endpoint}?lat=${lat}&lon=${lon}&standardsVersion=${standardsVersion}&riskLevel=${riskLevel}&${extraParams}&token=${Config.asce_token}`;

          const urls = [
            seismic && buildUrl("seismic", `siteClass=${siteClass}`),
            wind && buildUrl("wind"),
            snow && buildUrl("snow"),
          ].filter(Boolean);

          const responsesArray = await Promise.all(
            urls.map((url) => fetch(url).then((res) => res.json()))
          );

          const payload = {
            location_minimum_temperature: smallestNum,
            location_maximum_temperature: largestNum,
            location_minimum_humidity: minHumidity.toFixed(2),
            location_maximum_humidity: maxHumidity.toFixed(2),
            wind_exposure_category: "C",
          };

          responsesArray.forEach((response, index) => {
            const source = urls[index].includes("seismic")
              ? "seismic"
              : urls[index].includes("wind")
              ? "wind"
              : "snow";

            switch (source) {
              case "seismic": {
                const seismicData = response?.seismic?.results?.data;
                if (seismicData) {
                  const riskCategory =
                    seismicData.request?.parameters?.riskCategory;
                  const siteClass = seismicData.request?.parameters?.siteClass;

                  Object.assign(payload, {
                    seismic_site_class: siteClass || "",
                    seismic_spectral_response_short:
                      seismicData.response?.data?.ss || 0,
                    seismic_spectral_response_second:
                      seismicData.response?.data?.s1 || 0,
                    design_spectral_response_short:
                      seismicData.response?.data?.sds || 0,
                    design_spectral_response_second:
                      seismicData.response?.data?.sd1 || 0,
                    seismic_design_category:
                      seismicData.response?.data?.sdc || "",
                    seismic_importance_factor: ["I", "II"].includes(
                      riskCategory
                    )
                      ? "1.0"
                      : riskCategory === "III"
                      ? "1.25"
                      : "1.5",
                    soil_type:
                      {
                        A: "Hard Rock",
                        B: "Rock",
                        C: "Very dense soil and soft rock",
                      }[siteClass] || "Stiff Soil",
                    risk_category: riskCategory || "",
                  });

                  setSeismicSiteClass(siteClass || "");
                  setSpectralResponseShort(seismicData.response?.data?.ss || 0);
                  setSpectralResponseSecond(
                    seismicData.response?.data?.s1 || 0
                  );
                  setDesignSpectralResponseD1(
                    seismicData.response?.data?.sd1 || 0
                  );
                  setDesignSpectralResponseDS(
                    seismicData.response?.data?.sds || 0
                  );
                  setSeismicDesignCategory(
                    seismicData.response?.data?.sdc || 0
                  );
                  setSeismicImportanceFactor(payload.seismic_importance_factor);
                  setSoilType(payload.soil_type);
                  setRisk_category(riskCategory || "");
                }
                break;
              }
              case "snow": {
                const snowResults = response?.snow?.snowResults;
                const siteInfo = response?.requestInfo?.siteInfo;

                if (snowResults) {
                  const extractNumber = (str) => {
                    const number = parseInt(str, 10); // Extracts and converts the number part to an integer
                    return number;
                  };
                  const elevation = extractNumber(siteInfo?.elevation) || 0;
                  /* const elevation =
                    snowResults.find((result) => result.key === "elevation")
                      ?.value || ""; */
                  const snowLoad = snowResults[4]?.value || "";
                  const snowHeight =
                    snowResults.find((result) => result.key === "winterWind")
                      ?.value || 0;

                  if (snowLoad && snowHeight) {
                    const calculatedSnowHeight =
                      snowLoad / (snowHeight * 15 + 10);
                    Object.assign(payload, {
                      snow_load: snowLoad,
                      elevation,
                      snow_height: calculatedSnowHeight.toFixed(2),
                    });

                    setElevation(elevation);
                    setSnowLoad(snowLoad);
                    setSnow_height(calculatedSnowHeight.toFixed(2));
                  }
                }
                break;
              }
              case "wind": {
                const windSpeed = response?.wind?.mriWindResults?.["3000"] || 0;
                if (windSpeed) {
                  payload.wind_speed = Number(windSpeed);
                  setWindSpeed(windSpeed);
                }
                break;
              }
              default:
                console.warn(`Unknown source: ${source}`);
            }
          });

          const patchRes = await fetch(`${Config.API.URL}/sites/${site.id}`, {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            body: JSON.stringify(payload),
          });

          const patchData = await patchRes.json();
          console.log("site-res:", patchData);

          if (patchData.status) {
            setSite(patchData.data);
            setResourceDesignParametersModel(false);
          } else {
            swal("Oh no!", patchData.message, "error");
          }
        }
      }
    } catch (error) {
      console.log("Error: ", error);
      swal(
        "Oh no!",
        "An error occurred while connecting to the server. Please try again",
        "error"
      );
    } finally {
      setLoadingResourceDesignParameters(false);
    }
  };

  const initMap = (streetA) => {
    // street_address
    // const streetA = document.querySelector("#street_address");
    // console.log('streetA:', streetA);
    if (streetA) {
      // results frpm Google Maps for address autocomplete feature
      const autocomplete = new google.maps.places.Autocomplete(streetA, {
        fields: ["address_components", "geometry"],
        types: ["address"],
      });

      // streetA.focus();

      // streetA.setAttribute("autoComplete", "nope");

      autocomplete.addListener("place_changed", () => {
        let street_number = "";
        let route = "";
        let zip = "";
        let city = "";
        let state = "";
        let country = "";
        const place = autocomplete.getPlace();
        const components = place.address_components;
        const position = JSON.parse(JSON.stringify(place.geometry.location));

        // setGPSCoordinates(`${position.lat}, ${position.lng}`);
        setGPSCoordinates(`${position.lat},${position.lng}`);

        for (let i = 0; i < components.length; i++) {
          const component = components[i];
          const type = component.types[0];

          switch (type) {
            case "street_number": {
              street_number = component.long_name + " ";
              break;
            }
            case "route": {
              route = component.long_name + ", ";
              break;
            }
            /*           case "postal_code_suffix": {
              location.zip = component.long_name;
              break;
            } */
            case "postal_code": {
              zip = component.long_name + ", ";
              break;
            }
            case "locality": {
              city = component.long_name + ", ";
              break;
            }
            case "administrative_area_level_1": {
              state = component.long_name + ", ";
              break;
            }
            case "country": {
              country = component.long_name;
              break;
            }
            case "administrative_area_level_2": {
              setCounty(component.long_name);
              break;
            }
          }

          if (components.length === i + 1) {
            setSiteAddress(
              street_number + route + city + state + zip + country
            );

            let destinations =
              street_number + route + city + state + zip + country;
            let origins = "12438 Loma Rica Drive, Grass Valley, CA 95945";

            new google.maps.DistanceMatrixService().getDistanceMatrix(
              {
                origins: [origins],
                destinations: [destinations],
                travelMode: "DRIVING",
                unitSystem: google.maps.UnitSystem.IMPERIAL,
              },
              (response, status) => {
                const distance = response?.rows[0]?.elements[0];

                console.log("response:", response.rows[0].elements[0]);
                setSiteDistanceMiles(distance.distance.text);
                setSiteDistanceDrive(distance.duration.text);

                // setSiteDistanceMiles((distance.distance.value / 1609).toString());
                // setSiteDistanceDrive((distance.duration.value / 3600).toString());
                props.onReceiveDistance(distance);
              }
            );
          }
        }
      });
    }
  };

  const streetAddress = useRef(null);

  React.useLayoutEffect(() => {
    initMap(streetAddress.current);
    // console.log("hi", editSiteInformationModel);
  }, [editSiteInformationModel, streetAddress.current]);

  return (
    <>
      <div className=" w-11/12 lg:w-full ml-auto mr-auto ">
        <div className="grid grid-cols-12 mt-5 gap-4  ">
          <div className="   col-span-12 sm:col-span-6">
            {/* crd started */}
            <div className=" bg-white  shadow-lg p-[20px] rounded-[8px] relative">
              <button
                type="button"
                onClick={() => setResourceDesignParametersModel(true)}
                className="absolute top-3 left-3 border  w-[100px] text-center h-[30px] rounded-[5px] f-f-r text-sm text-white bg-primary-dark "
              >
                Resources
              </button>

              <button
                onClick={() => setEditDesignParametersModel(true)}
                className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
              >
                Edit
              </button>
              {/* <button type='button' onClick={() => setEditDesignParametersModel(true)} style={{ position: "absolute", top: 10, right: 10 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                    </svg>
              </button> */}
              <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                Design Parameters
              </h2>
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Minimum Temperature (C){" "}
                <span className=" float-right text-black ">
                  {Number(site?.location_minimum_temperature).toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Maximum Temperature (C){" "}
                <span className=" float-right text-black ">
                  {Number(site?.location_maximum_temperature).toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Minimum Humidity (%){" "}
                <span className=" float-right text-black ">
                  {Number(site?.location_minimum_humidity).toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Maximum Humidity (%){" "}
                <span className=" float-right text-black ">
                  {Number(site?.location_maximum_humidity).toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Snow Load (lb/sqft)
                <span className=" float-right text-black ">
                  {Number(site?.snow_load).toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Wind Speed (mph)
                <span className=" float-right text-black ">
                  {Number(site?.wind_speed).toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Soil Type
                <span className=" float-right text-black ">
                  {site?.soil_type}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Spectral Response (Short, S/S)
                <span className=" float-right text-black ">
                  {Number(site?.seismic_spectral_response_short).toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Spectral Response (1 Sec, S/1)
                <span className=" float-right text-black ">
                  {Number(site?.seismic_spectral_response_second).toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Seismic Importance Factor
                <span className=" float-right text-black ">
                  {site?.seismic_importance_factor}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Seismic Site Class
                <span className=" float-right text-black ">
                  {site?.seismic_site_class}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Design Spectral Response (S/DS)
                <span className=" float-right text-black ">
                  {Number(site?.design_spectral_response_short).toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Design Spectral Response (S/D1)
                <span className=" float-right text-black ">
                  {Number(site?.design_spectral_response_second).toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Seismic Design Category
                <span className=" float-right text-black ">
                  {site?.seismic_design_category}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* New */}
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Elevation (ft.)
                <span className=" float-right text-black ">
                  {site?.elevation}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Wind Exposure Category
                <span className=" float-right text-black ">
                  {site?.wind_exposure_category}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Snow Height (ft.)
                <span className=" float-right text-black ">
                  {Number(site?.snow_height).toFixed(2)}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Risk Category
                <span className=" float-right text-black ">
                  {site?.risk_category}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                Geologic Hazard Zone
                <span className=" float-right text-black ">
                  {site?.geologic_hazard_zone === 1 ? "true" : "false"}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                State Seismic Landslide Zone
                <span className=" float-right text-black ">
                  {site?.state_seismic_landslide_zone === 1 ? "true" : "false"}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-2  ">
                State Seismic Liquefaction Zone
                <span className=" float-right text-black ">
                  {site?.state_seismic_liquefaction_zone === 1
                    ? "true"
                    : "false"}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
            </div>
            {/* crd started */}
            <div className=" bg-white  shadow-lg p-[20px] rounded-[8px] mt-6  relative ">
              <button
                type="button"
                onClick={() => setEditGeneralStatusModal(true)}
                className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white"
              >
                Edit
              </button>
              <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                Project Tracking
              </h2>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Project Type
                <span className=" float-right text-black ">
                  {site?.general_stage}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-4  ">
                Project Status
                <span className=" float-right text-black ">
                  {site?.general_status}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
            </div>
            {/* card ended */}
          </div>

          {/* second grid started */}
          <div className="   col-span-12 sm:col-span-6">
            {/* crd started */}
            <div
              className=" bg-white  shadow-lg p-[20px] rounded-[8px] "
              style={{ position: "relative" }}
            >
              <button
                type="button"
                onClick={() => setEditSiteInformationModel(true)}
                className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
              >
                Edit
              </button>
              <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                Site Information
              </h2>
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                Site Name
                <span className=" float-right text-black ">
                  {site?.site_name}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px] overflow-auto ">
                Site Contacts:
                <span className=" float-right text-black break-words ">
                  {site?.contacts?.map((contact, i) => (
                    <span key={i}>
                      {contact.first_name} {contact.last_name},{" "}
                    </span>
                  ))}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px] overflow-auto ">
                Site Address{" "}
                <span className=" float-right text-black break-words">
                  {site?.site_address}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                GPS Coordinates
                <span className=" float-right text-black ">
                  {site?.site_gps}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                County:
                <span className=" float-right text-black ">
                  {site?.county}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>

              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                Ratepayer Type
                <span className=" float-right text-black ">
                  {site?.ratepayer_type}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>

              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                Landowner
                <span className=" float-right text-black ">
                  {site?.landowner}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>

              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                Critical Load
                <span className=" float-right text-black ">
                  {site?.critical_load}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>

              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                Generator Use
                <span className=" float-right text-black ">
                  {site?.generator_use}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>

              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                Container Weight
                <span className=" float-right text-black ">
                  {site?.container_weight}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>

              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                Building Code
                <span className=" float-right text-black ">
                  {site?.building_code}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>

              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                Site Fence
                <span className=" float-right text-black ">
                  {site?.site_fence === 1 ? "True" : "False"}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
            </div>

            {/* crd started */}
            <div
              className=" bg-white  shadow-lg p-[20px] rounded-[8px] mt-[18px] "
              style={{ position: "relative" }}
            >
              <button
                type="button"
                onClick={() => setEditSiteConditionsModel(true)}
                className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
              >
                Edit
              </button>
              <h2 className=" text-center f-f-sm text-lg text-black my-2  ">
                Site Conditions
              </h2>
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                Foundation Type
                <span className=" float-right text-black ">
                  {site?.foundation_type}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                Additional Foundation Requirements
                <span className=" float-right text-black ">
                  {site?.foundation_requirements}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                Voltage
                <span className=" float-right text-black ">
                  {site?.service_voltage}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                Phase
                <span className=" float-right text-black ">
                  {site?.power_phase}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                Number of Wires
                <span className=" float-right text-black ">
                  {site?.number_of_wires}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                Wiring Configuration
                <span className=" float-right text-black ">
                  {site?.wiring_configuration}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                Connection Type
                <span className=" float-right text-black ">
                  {site?.connection_type}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>

              {/* start */}
              <h3 className="  f-f-r text-lg text-black-vlight mt-[18px]  ">
                Miles Line Removed
                <span className=" float-right text-black ">
                  {site?.miles_line_removed}
                </span>{" "}
              </h3>
              <div className=" border border-white-vlight my-1 "></div>
            </div>
          </div>
          {/* second grid ended */}
        </div>
      </div>

      <Transition.Root show={editSiteConditionsModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditSiteConditionsModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                                                      </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Site Conditions
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Foundation Type
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setFoundationType(e.target.value)
                                  }
                                  value={foundationType}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Additional Foundation Requirements
                              </label>
                              <div className="mt-0">
                                <textarea
                                  onChange={(e) =>
                                    setAdditionalFoundationRequirements(
                                      e.target.value
                                    )
                                  }
                                  value={additionalFoundationRequirements}
                                  rows="4"
                                  className="pr-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 smbase sm:leading-6"
                                ></textarea>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Voltage
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setServiceVoltage(e.target.value)
                                  }
                                  value={serviceVoltage}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="240/120VAC">240/120VAC</option>
                                  <option value="208/120VAC">208/120VAC</option>
                                  <option value="480/277VAC">480/277VAC</option>
                                  <option value="24VDC">24VDC</option>
                                  <option value="48VDC">48VDC</option>
                                  <option value="125VDC">125VDC</option>
                                  <option value="1000VDC">1000VDC</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Phase
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setPowerPhase(e.target.value)
                                  }
                                  value={powerPhase}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="1-Ph">1-Ph</option>
                                  <option value="Split-Phase">
                                    Split-Phase
                                  </option>
                                  <option value="2-Ph">2-Ph</option>
                                  <option value="3-Ph">3-Ph</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Number of Wires
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setNumberOfWires(e.target.value)
                                  }
                                  value={numberOfWires}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="1W">1W</option>
                                  <option value="2W">2W</option>
                                  <option value="3W">3W</option>
                                  <option value="4W">4W</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Wiring Configuration
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setWiringConfiguration(e.target.value)
                                  }
                                  value={wiringConfiguration}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="D">D</option>
                                  <option value="W">W</option>
                                  <option value="Wg">Wg</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Connection Type
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setConnectionType(e.target.value)
                                  }
                                  value={connectionType}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="Off-grid">Off-grid</option>
                                  <option value="Grid-tied">Grid-tied</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Miles Line Removed
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setMiles_line_removed(e.target.value)
                                  }
                                  value={miles_line_removed}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditSiteConditions ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={editSiteConditions}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditSiteConditionsModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editGeneralStatusModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditGeneralStatusModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Tracking
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Project Type
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    set_general_stage(e.target.value)
                                  }
                                  value={general_stage}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="Feasibility Study">
                                    Feasibility Study
                                  </option>
                                  <option value="Turnkey">Turnkey</option>
                                  <option value="O&M">O&M</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Project Status
                              </label>
                              {general_stage == "Feasibility Study" ? (
                                <div className="mt-0">
                                  <select
                                    onChange={(e) =>
                                      set_general_status(e.target.value)
                                    }
                                    value={general_status}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  >
                                    <option value=""></option>
                                    <option value="10% Design">
                                      10% Design
                                    </option>
                                    <option value="Contracting">
                                      Contracting
                                    </option>
                                    <option value="Paused">Paused</option>
                                    <option value="Disqualified">
                                      Disqualified
                                    </option>
                                  </select>
                                </div>
                              ) : (
                                <div className="mt-0">
                                  <select
                                    onChange={(e) =>
                                      set_general_status(e.target.value)
                                    }
                                    value={general_status}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  >
                                    <option value=""></option>
                                    <option value="30% Design">
                                      30% Design
                                    </option>
                                    <option value="90% Design">
                                      90% Design
                                    </option>
                                    <option value="IFC">IFC</option>
                                    <option value="Construction">
                                      Construction
                                    </option>
                                    <option value="Commissioning">
                                      Commissioning
                                    </option>
                                    <option value="As-Builts">As-Builts</option>
                                    <option value="Deployed">Deployed</option>
                                    <option value="Paused">Paused</option>
                                    <option value="Permitting">
                                      Permitting
                                    </option>
                                  </select>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingGeneralStatus ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={editGeneralStatus}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditGeneralStatusModal(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editSiteInformationModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditSiteInformationModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Site Information
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Site Name{" "}
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => setSiteName(e.target.value)}
                                  value={siteName}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Site Contacts{" "}
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                {site?.contacts?.length ? (
                                  <div
                                    style={{
                                      display: "inline-block",
                                      verticalAlign: "middle",
                                      width: "55%",
                                    }}
                                  >
                                    {(siteContacts || site?.contacts)?.map(
                                      (contact, i) => (
                                        <span key={i}>
                                          {contact.first_name},{" "}
                                        </span>
                                      )
                                    )}
                                  </div>
                                ) : null}

                                <div
                                  style={{
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                    width: "45%",
                                  }}
                                >
                                  <Dilogbox
                                    onReceiveContact={_onReceiveContact}
                                    contacts={site?.contacts}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Site Address{" "}
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setSiteAddress(e.target.value)
                                  }
                                  ref={streetAddress}
                                  autoComplete="nope"
                                  // id="street_address"
                                  //name="address"
                                  value={siteAddress}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                GPS Coordinates{" "}
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setGPSCoordinates(
                                      convertToDecimalFormat(e.target.value)
                                    )
                                  }
                                  value={GPSCoordinates}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Renewable Fraction Target (%){" "}
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setRenewableFractionTarget(e.target.value)
                                  }
                                  value={renewableFractionTarget}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                          {/* New */}

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                County
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => setCounty(e.target.value)}
                                  value={county}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Ratepayer Type
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setRatepayerType(e.target.value)
                                  }
                                  value={ratepayerType}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="Residential">
                                    Residential
                                  </option>
                                  <option value="Commercial">Commercial</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Landowner
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => setLandowner(e.target.value)}
                                  value={landowner}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Critical Load
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setCriticalLoad(e.target.value)
                                  }
                                  value={criticalLoad}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value={true}>True</option>
                                  <option value={false}>False</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Generator Use
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) =>
                                    setGeneratorUse(e.target.value)
                                  }
                                  value={generatorUse}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value="Standby">Standby</option>
                                  <option value="Prime">Prime</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Container Weight
                              </label>
                              <div className="mt-0">
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setContainerWeight(e.target.value)
                                  }
                                  value={containerWeight}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Building Code
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setBuildingCode(e.target.value)
                                  }
                                  value={buildingCode}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Site Fence
                              </label>
                              <div className="mt-0">
                                <select
                                  onChange={(e) => setSiteFence(e.target.value)}
                                  value={siteFence}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                  <option value=""></option>
                                  <option value={true}>True</option>
                                  <option value={false}>False</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditSiteInformation ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={editSiteInformation}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditSiteInformationModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={editDesignParametersModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditDesignParametersModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                                               <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                                               </div> */}
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Design Parameters
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <form>
                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Minimum Temperature (C)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setMinimumTemperature(e.target.value)
                                    }
                                    value={minimumTemperature}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Maximum Temperature (C)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setMaximumTemperature(e.target.value)
                                    }
                                    value={maximumTemperature}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Minimum Humidity (%)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setMinimumHumidity(e.target.value)
                                    }
                                    value={minimumHumidity}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Maximum Humidity (%)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setMaximumHumidity(e.target.value)
                                    }
                                    value={maximumHumidity}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Snow Load (lb/sqft)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setSnowLoad(e.target.value)
                                    }
                                    value={snowLoad}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Wind Speed (mph)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setWindSpeed(e.target.value)
                                    }
                                    value={windSpeed}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Soil Type
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      setSoilType(e.target.value)
                                    }
                                    value={soilType}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Spectral Response (Short, S/S)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setSpectralResponseShort(e.target.value)
                                    }
                                    value={spectralResponseShort}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Seismic Importance Factor
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setSeismicImportanceFactor(e.target.value)
                                    }
                                    value={seismicImportanceFactor}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Seismic Site Class
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      setSeismicSiteClass(e.target.value)
                                    }
                                    value={seismicSiteClass}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Spectral Response (1 Sec, S/1)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setSpectralResponseSecond(e.target.value)
                                    }
                                    value={spectralResponseSecond}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Design Spectral Response (S/DS)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setDesignSpectralResponseDS(
                                        e.target.value
                                      )
                                    }
                                    value={designSpectralResponseDS}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Design Spectral Response (S/D1)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setDesignSpectralResponseD1(
                                        e.target.value
                                      )
                                    }
                                    value={designSpectralResponseD1}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Seismic Design Category
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      setSeismicDesignCategory(e.target.value)
                                    }
                                    value={seismicDesignCategory}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            {/* Repl */}

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Elevation (ft.)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="number"
                                    onChange={(e) =>
                                      setElevation(e.target.value)
                                    }
                                    value={elevation}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Wind Exposure Category
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      setWind_exposure_category(e.target.value)
                                    }
                                    value={wind_exposure_category}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Snow Height (ft.)
                                </label>
                                <div className="mt-0">
                                  <input
                                    type="text"
                                    onChange={(e) =>
                                      setSnow_height(e.target.value)
                                    }
                                    value={Number(snow_height).toFixed(2)}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Risk Category
                                </label>
                                <div className="mt-0">
                                  <select
                                    onChange={(e) =>
                                      setRisk_category(e.target.value)
                                    }
                                    value={risk_category}
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  >
                                    <option value=""></option>
                                    <option value="I">I</option>
                                    <option value="II">II</option>
                                    <option value="III">III</option>
                                    <option value="IV">IV</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  Geologic Hazard Zone
                                </label>
                                <div className="mt-0">
                                  <select
                                    onChange={(e) =>
                                      setGeologic_hazard_zone(e.target.value)
                                    }
                                    value={
                                      geologic_hazard_zone === 1
                                        ? "true"
                                        : "false"
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  >
                                    <option value=""></option>
                                    <option value={true}>true</option>
                                    <option value={false}>false</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  State Seismic Landslide Zone
                                </label>
                                <div className="mt-0">
                                  <select
                                    onChange={(e) =>
                                      setState_seismic_landslide_zone(
                                        e.target.value
                                      )
                                    }
                                    value={
                                      state_seismic_landslide_zone === 1
                                        ? "true"
                                        : "false"
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  >
                                    <option value=""></option>
                                    <option value={true}>true</option>
                                    <option value={false}>false</option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="col-span-full">
                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                  State Seismic Liquefaction Zone
                                </label>
                                <div className="mt-0">
                                  <select
                                    onChange={(e) =>
                                      setState_seismic_liquefaction_zone(
                                        e.target.value
                                      )
                                    }
                                    value={
                                      state_seismic_liquefaction_zone === 1
                                        ? "true"
                                        : "false"
                                    }
                                    className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                  >
                                    <option value=""></option>
                                    <option value={true}>true</option>
                                    <option value={false}>false</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loadingEditDesignParameters ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={editDesignParameters}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditDesignParametersModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={resourceDesignParametersModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setResourceDesignParametersModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            fontSize: "1.4rem",
                          }}
                        >
                          Select a resource from the <br /> options below:
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <ul className=" mt-6">
                            {/* li started */}
                            <div className="flex justify-center items-center rounded-md bg-primary-dark">
                              <p className="text-white font-semibold p-1.5 ">
                                NREL
                              </p>
                            </div>
                            <li>
                              <div className="flex items-center mb-4">
                                <input
                                  type="checkbox"
                                  value=""
                                  onChange={() => setTemperature(!temperature)}
                                  checked={temperature}
                                  className="w-5 h-5 checked:accent-primary-dark bg-gray-100 border-gray-300 rounded-[4px] "
                                />
                                <label
                                  // htmlFor={i}
                                  className="ml-4 f-f-r text-lg pt-3 text-[#808080] "
                                  style={{ fontSize: 15 }}
                                >
                                  <strong>
                                    Temperature and Humidity <br /> National
                                    Solar Radiation Database TMY Data
                                  </strong>
                                </label>
                              </div>
                            </li>
                          </ul>
                          <div className="flex justify-center items-center rounded-md bg-primary-dark mb-5">
                            <p className="text-white font-semibold p-1.5 ">
                              ASCE 7-22
                            </p>
                          </div>

                          <div className="divide-y-2">
                            <div className=" pt-2">
                              <div className="grid sm:grid-cols-2 gap-4 mb-5">
                                <div className="w-full">
                                  <label
                                    htmlFor="risk-category"
                                    className="flex  gap-2 text-base  font-medium text-[#808080] "
                                  >
                                    <span className="flex  items-center">
                                      <svg
                                        width="16px"
                                        height="16px"
                                        viewBox="0 0 512 512"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <title>warning-filled</title>
                                        <g
                                          id="Page-1"
                                          stroke="none"
                                          strokeWidth="1"
                                          fill="none"
                                          fillRule="evenodd"
                                        >
                                          <g
                                            id="add"
                                            fill="#808080"
                                            transform="translate(32.000000, 42.666667)"
                                          >
                                            <path
                                              d="M246.312928,5.62892705 C252.927596,9.40873724 258.409564,14.8907053 262.189374,21.5053731 L444.667042,340.84129 C456.358134,361.300701 449.250007,387.363834 428.790595,399.054926 C422.34376,402.738832 415.04715,404.676552 407.622001,404.676552 L42.6666667,404.676552 C19.1025173,404.676552 7.10542736e-15,385.574034 7.10542736e-15,362.009885 C7.10542736e-15,354.584736 1.93772021,347.288125 5.62162594,340.84129 L188.099293,21.5053731 C199.790385,1.04596203 225.853517,-6.06216498 246.312928,5.62892705 Z M224,272 C208.761905,272 197.333333,283.264 197.333333,298.282667 C197.333333,313.984 208.415584,325.248 224,325.248 C239.238095,325.248 250.666667,313.984 250.666667,298.624 C250.666667,283.264 239.238095,272 224,272 Z M245.333333,106.666667 L202.666667,106.666667 L202.666667,234.666667 L245.333333,234.666667 L245.333333,106.666667 Z"
                                              id="Combined-Shape"
                                            ></path>
                                          </g>
                                        </g>
                                      </svg>
                                    </span>
                                    Risk Category
                                    <span className="flex justify-start">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        preserveAspectRatio="xMidYMid"
                                        width="12"
                                        height="12"
                                        viewBox="0 0 12 12"
                                      >
                                        <defs></defs>
                                        <path
                                          d="M10.072,1.717 C7.761,-0.594 4.016,-0.594 1.706,1.717 C-0.605,4.028 -0.605,7.775 1.706,10.087 C4.016,12.397 7.761,12.397 10.072,10.087 C12.382,7.775 12.382,4.028 10.072,1.717 M5.857,1.766 C6.465,1.766 6.959,2.256 6.959,2.861 C6.959,3.468 6.465,3.958 5.857,3.958 C5.249,3.958 4.755,3.468 4.755,2.861 C4.755,2.256 5.249,1.766 5.857,1.766 M4.005,9.868 C4.005,9.868 4.009,9.242 4.009,9.242 C4.009,9.242 5.028,8.865 5.028,8.865 C5.028,8.865 5.024,5.547 5.024,5.547 C5.024,5.547 4.009,5.839 4.009,5.839 C4.009,5.839 4.005,4.849 4.005,4.849 C4.005,4.849 6.798,4.849 6.798,4.849 C6.798,4.849 6.803,4.849 6.803,4.849 C6.803,4.849 6.798,8.865 6.798,8.865 C6.798,8.865 7.817,9.242 7.817,9.242 C7.817,9.242 7.822,9.868 7.822,9.868 C7.822,9.868 4.005,9.868 4.005,9.868 Z"
                                          id="path-1"
                                          fill="#7fb5e0"
                                          fillRule="evenodd"
                                        />
                                      </svg>
                                    </span>
                                  </label>

                                  <div className="relative">
                                    <select
                                      id="risk-category"
                                      className="cursor-pointer block w-full mt-1 p-2 font-medium text-[#808080] appearance-none pr-8"
                                      onChange={(e) =>
                                        setRiskLevel(Number(e.target.value))
                                      }
                                      value={riskLevel}
                                    >
                                      <option disabled value="">
                                        Select Risk
                                      </option>
                                      <option value="1">I</option>
                                      <option value="2">II</option>
                                      <option value="3">III</option>
                                      <option value="4">IV</option>
                                    </select>
                                    <div className="cursor-pointer pointer-events-none absolute bg-primary-dark inset-y-0 right-0 flex items-center px-2">
                                      <svg
                                        className="w-5 h-5 text-white"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M19 9l-7 7-7-7"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <div className="w-full">
                                  <label
                                    htmlFor="site-class"
                                    className="flex  gap-2 text-base  font-medium text-[#808080] "
                                  >
                                    <span className="flex  items-center">
                                      <svg
                                        fill="#808080"
                                        height="14px"
                                        width="14px"
                                        version="1.1"
                                        id="Capa_1"
                                        viewBox="0 0 208.294 208.294"
                                      >
                                        <path
                                          d="M205.88,186.845l-38.485-40.2c-6.377-6.661-14.107-11.816-22.545-15.233l3.569-11.01c1.277-3.94-0.882-8.17-4.822-9.447
	l-11.665-3.781l17.713-54.642c8.303-0.485,15.475-5.966,18.068-13.964l4.012-12.376c1.277-3.94-0.882-8.17-4.822-9.447
	l-24.508-7.944c-1.892-0.612-3.95-0.448-5.722,0.454c-1.772,0.904-3.112,2.476-3.726,4.368l-4.012,12.376
	c-1.668,5.146-1.232,10.634,1.228,15.452c1.293,2.534,3.075,4.712,5.211,6.46l-17.711,54.638l-11.666-3.782
	c-1.892-0.611-3.95-0.448-5.722,0.454c-1.772,0.904-3.112,2.476-3.726,4.368l-6.629,20.45c-0.282,0.87-0.504,1.754-0.696,2.644
	c-16.06,0.499-31.211,6.836-42.854,18.004L2.309,186.945c-2.21,2.12-2.909,5.37-1.768,8.211c1.143,2.841,3.897,4.702,6.959,4.702
	h193.274c0.008-0.001,0.013-0.001,0.02,0c4.143,0,7.5-3.357,7.5-7.5C208.294,190.178,207.365,188.216,205.88,186.845z
	 M143.203,30.624l1.699-5.241l10.238,3.319l-1.699,5.241c-0.705,2.175-2.712,3.637-4.994,3.637c-0.466,0-0.929-0.076-1.377-0.199
	c-0.119-0.045-0.234-0.096-0.357-0.136c-0.107-0.035-0.214-0.057-0.321-0.086c-1.246-0.457-2.265-1.339-2.872-2.527
	C142.883,33.382,142.771,31.959,143.203,30.624z M48.719,180.888l-3.775,3.775c-1.465,1.464-3.385,2.196-5.304,2.196
	s-3.839-0.732-5.304-2.196c-2.929-2.93-2.929-7.678,0-10.607l3.775-3.775c2.93-2.928,7.678-2.928,10.607,0
	C51.648,173.211,51.648,177.959,48.719,180.888z M75.341,180.888l-3.775,3.775c-1.465,1.464-3.385,2.196-5.304,2.196
	s-3.839-0.732-5.304-2.196c-2.929-2.93-2.929-7.678,0-10.607l3.775-3.775c2.93-2.928,7.678-2.928,10.607,0
	C78.27,173.211,78.27,177.959,75.341,180.888z M86.765,150.602l-3.775,3.775c-1.465,1.464-3.385,2.196-5.304,2.196
	s-3.839-0.732-5.304-2.196c-2.929-2.93-2.929-7.678,0-10.607l3.775-3.775c2.93-2.928,7.678-2.928,10.607,0
	C89.694,142.925,89.694,147.673,86.765,150.602z M128.585,180.888l-3.775,3.775c-1.465,1.464-3.385,2.196-5.304,2.196
	s-3.839-0.732-5.304-2.196c-2.929-2.93-2.929-7.678,0-10.607l3.775-3.775c2.93-2.928,7.678-2.928,10.607,0
	C131.514,173.211,131.514,177.959,128.585,180.888z"
                                        />
                                      </svg>
                                    </span>
                                    Site Soil Class
                                    <span className="flex justify-start">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        preserveAspectRatio="xMidYMid"
                                        width="12"
                                        height="12"
                                        viewBox="0 0 12 12"
                                      >
                                        <defs></defs>
                                        <path
                                          d="M10.072,1.717 C7.761,-0.594 4.016,-0.594 1.706,1.717 C-0.605,4.028 -0.605,7.775 1.706,10.087 C4.016,12.397 7.761,12.397 10.072,10.087 C12.382,7.775 12.382,4.028 10.072,1.717 M5.857,1.766 C6.465,1.766 6.959,2.256 6.959,2.861 C6.959,3.468 6.465,3.958 5.857,3.958 C5.249,3.958 4.755,3.468 4.755,2.861 C4.755,2.256 5.249,1.766 5.857,1.766 M4.005,9.868 C4.005,9.868 4.009,9.242 4.009,9.242 C4.009,9.242 5.028,8.865 5.028,8.865 C5.028,8.865 5.024,5.547 5.024,5.547 C5.024,5.547 4.009,5.839 4.009,5.839 C4.009,5.839 4.005,4.849 4.005,4.849 C4.005,4.849 6.798,4.849 6.798,4.849 C6.798,4.849 6.803,4.849 6.803,4.849 C6.803,4.849 6.798,8.865 6.798,8.865 C6.798,8.865 7.817,9.242 7.817,9.242 C7.817,9.242 7.822,9.868 7.822,9.868 C7.822,9.868 4.005,9.868 4.005,9.868 Z"
                                          id="path-1"
                                          fill="#7fb5e0"
                                          fillRule="evenodd"
                                        />
                                      </svg>
                                    </span>
                                  </label>

                                  <div className="relative">
                                    <select
                                      name="site-class"
                                      id="site-class"
                                      className="cursor-pointer block w-full mt-1 p-2 font-medium text-[#808080] appearance-none pr-8"
                                      onChange={(e) =>
                                        setSiteClass(e.target.value)
                                      }
                                      value={siteClass}
                                    >
                                      <option disabled>Default</option>
                                      <option value="A">A - Hard Rock</option>
                                      <option value="B">B - Rock</option>
                                      <option value="BC">BC</option>
                                      <option value="C">
                                        C - Very Dense Soil and Soft Rock
                                      </option>
                                      <option value="CD">CD</option>
                                      <option value="D">D - Stiff Soil</option>
                                      <option value="DE">DE</option>
                                      <option value="E">
                                        E - Soft Clay Soil
                                      </option>
                                    </select>
                                    <div className="cursor-pointer pointer-events-none  absolute bg-primary-dark inset-y-0 right-0 flex items-center px-2">
                                      <svg
                                        className="w-5 h-5 text-white"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M19 9l-7 7-7-7"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="pt-3">
                              <div className="flex justify-between">
                                <p className="flex items-center gap-2 text-base font-medium text-[#808080]">
                                  <span>
                                    <svg
                                      height="16px"
                                      width="16px"
                                      version="1.1"
                                      id="_x32_"
                                      viewBox="0 0 512 512"
                                    >
                                      <g>
                                        <path
                                          fill="#808080"
                                          d="M416.296,162.691c-0.037,0-0.074,0.009-0.121,0.009c0.056-1.594,0.121-3.187,0.121-4.79
		c0-76.646-62.126-138.771-138.762-138.771c-71.781,0-130.85,54.521-138.026,124.419c-10.066-3.113-20.755-4.791-31.846-4.791
		C48.202,138.766,0,186.968,0,246.429c0,59.46,48.202,107.662,107.662,107.662h71.268l18.435-143.543h164.597l-35.639,95.696h61.78
		l-32.395,47.847h60.588c52.852,0,95.704-42.842,95.704-95.695C512,205.533,469.148,162.691,416.296,162.691z"
                                        />
                                        <polygon
                                          fill="#808080"
                                          points="320.628,239.252 222.622,239.252 204.802,378.024 244.896,378.024 227.077,492.862 333.993,334.957 
		284.989,334.957 	"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                  Load Types
                                </p>
                              </div>
                              <ul className="flex justify-between py-2">
                                <li>
                                  <div className="flex items-center mb-4">
                                    <input
                                      type="checkbox"
                                      value=""
                                      onChange={() => setWind(!wind)}
                                      checked={wind}
                                      className="w-5 h-5 checked:accent-primary-dark  bg-gray-100 border-gray-300 rounded-[4px] "
                                    />
                                    <label
                                      className="ml-4 f-f-r  font-medium text-[#808080]"
                                      style={{ fontSize: 13 }}
                                    >
                                      <strong>Wind</strong>
                                    </label>
                                  </div>
                                </li>

                                <li>
                                  <div className="flex items-center mb-4">
                                    <input
                                      type="checkbox"
                                      value=""
                                      onChange={() => setSnow(!snow)}
                                      checked={snow}
                                      className="w-5 h-5  checked:accent-primary-dark bg-gray-100 border-gray-300 rounded-[4px] "
                                    />
                                    <label
                                      className="ml-4 f-f-r font-medium text-[#808080]"
                                      style={{ fontSize: 13 }}
                                    >
                                      <strong>Snow</strong>
                                    </label>
                                  </div>
                                </li>

                                <li>
                                  <div className="flex items-center mb-4">
                                    <input
                                      type="checkbox"
                                      value=""
                                      onChange={() => setSeismic(!seismic)}
                                      checked={seismic}
                                      className="w-5 h-5 checked:accent-primary-dark bg-gray-100 border-gray-300 rounded-[4px] "
                                    />
                                    <label
                                      className="ml-4 f-f-r font-medium text-[#808080]"
                                      style={{ fontSize: 13 }}
                                    >
                                      <strong>Seismic</strong>
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 pb-10 sm:flex sm:flex-row sm:px-6 justify-end gap-5">
                    {loadingResourceDesignParameters ? (
                      <button className="inline-flex w-full justify-center items-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-3xl border border-primary-dark px-10 py-2 text-base font-semibold text-primary-dark shadow-sm  sm:ml-3 sm:w-auto"
                        onClick={editResourceDesignParameters}
                      >
                        Import
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-3xl border border-orange-600  px-10 py-2 text-base font-semibold text-orange-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setResourceDesignParametersModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
