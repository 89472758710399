import React from 'react'
import { Link, useParams } from 'react-router-dom'


export default function Hero(props) {
    const { project_id } = useParams();
    const { scenario } = props
    return (
        <div className='bg-white rounded-[8px] p-4  mt-[90px] lg:mt-3 ' >

            <ul className=' md:inline-flex w-full ' >
                <li className='  mt-1 md:mt-3' >
                    <Link to="/dashboard">
                        <h3 className=' text-grey f-f-r text-base'>Dashboard</h3>
                    </Link>
                </li>
                <li className=' md:ml-3  mt-1 md:mt-3  ' >
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.25 16.5L13.75 11L8.25 5.5" stroke="#D0D1D2" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                </li>
                <li className=' md:ml-3  mt-1 md:mt-3  ' >
                    <Link to={`/project/view/${project_id}`}>
                        <h3 className=' text-grey f-f-r text-base'>Project</h3>
                    </Link>
                </li>
                <li className=' md:ml-3  mt-1 md:mt-3  ' >
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.25 16.5L13.75 11L8.25 5.5" stroke="#D0D1D2" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </li>
                <li className=' md:ml-3  mt-1 md:mt-3  ' >
                    <Link to={`/scenarios/view/${scenario?.site_id}`}>
                        <h3 className=' text-grey f-f-r text-base'>Site</h3>
                    </Link>
                </li>
                <li className=' md:ml-3  mt-1 md:mt-3  ' >
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.25 16.5L13.75 11L8.25 5.5" stroke="#D0D1D2" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </li>
                <li className=' md:ml-3  mt-1 md:mt-3  ' >
                    <Link to={`/scenarios/optimization/${project_id}/${scenario?.id}`}>
                        <h3 className=' text-primary f-f-r text-base'> Scenario</h3>
                    </Link>
                </li>
            </ul>
        </div>
    )
}
