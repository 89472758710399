import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    params_projects  : [],
    params_sites     : [],
    params_scenarios : [],
}

export const helperSlice = createSlice({
    name: 'helper',
    initialState,
    reducers: {
        setParamsProjects: (state, action) => {
            state.params_projects = action.payload;
        },
        setParamsSites: (state, action) => {
            state.params_sites = action.payload;
        },
        setParamsScenarios: (state, action) => {
            state.params_scenarios = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setParamsProjects, setParamsSites, setParamsScenarios } = helperSlice.actions

export default helperSlice.reducer;