import React, { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

import { Link, useParams } from "react-router-dom";

import Sidebardata from "../home/Sideebar";
import Header from "../home/header";
import Hero from "./Hero";

import Loading from "../../Website/Loading";
// import UniversalDataTable from "../Contacts/UniversalDataTable";
import DataTable from "./DataTable";
import EditProjectColumnsDropdown from "./EditProjectColumnsDropdown";
import EditSiteColumnsDropdown from "./EditSiteColumnsDropdown";
import EditScenarioColumnsDropdown from "./EditScenarioColumnsDropdown";
import Config from "../../utils/Config";

import { setParamsProjects, setParamsSites, setParamsScenarios } from "../../redux/slices/helperSlice";

import { useNavigate } from "react-router-dom";

import swal from "sweetalert";

import { useSelector, useDispatch } from "react-redux";

export default function Index() {
  // const { site_id } = useParams();
  const dispatch = useDispatch();
  const [ reports, setReports ] = React.useState([]);
  const { params_projects, params_sites, params_scenarios } = useSelector((state) => state.helper);
  
  const [ page, setPage ] = React.useState(1);
  const [ lastPage, setLastPage ] = React.useState(1);
  const [ currentPage, setCurrentPage ] = React.useState(1);

  const [ totalProject, setTotalPrject ] = React.useState(null);

  const [ reportsPage, setReportsPage ] = React.useState(1);
  const [ reportsLastPage, setReportsLastPage ] = React.useState(1);
  const [ reportsCurrentPage, setReportsCurrentPage ] = React.useState(1);
  
  // const [ totalReports, setTotalReports ] = React.useState(null);

  const [ selectingProjectsModel, setSelectingProjectsModel ] = React.useState(false);
  const [ selectingSitesModel, setSelectingSitesModel ] = React.useState(false);
  const [ selectingScenariosModel, setSelectingScenariosModel ] = React.useState(false);

  const [ loading, setLoading ] = React.useState(false);
  const [ reportLoading, setReportLoading ] = React.useState(false);
  
  const [ selectedProjects, setSelectedProjects ] = React.useState([]);
  const [ projects, setProjects ] = React.useState([]);
  const [ sites, setSites ] = React.useState([]);
  const [ scenarios, setScenarios ] = React.useState([]);
  
  const [ utilities, setUtilities ] = React.useState([]);

  //const [ startSectingParamsValues, setStartSectingParamsValues ] = React.useState(false);

  const [ tableHeaderFormat, setTableHeaderFormat ] = React.useState([
    { column: "project_name", label: "Project Name" }, 
    { column: "site_name", label: "Site Name" }, 
    { column: "contract_value", label: "Contract Value" }, 
    { column: "customer_type", label: "Customer Type" }, 
    { column: "utility", label: "Utility Name" }, 
    { column: "project_type", label: "Project Type" }, 
    { column: "solar_pv_capacity", label: "Solar PV Capacity" }, 
    { column: "battery_storage_capacity", label: "Battery Storage Capacity" }
  ]);
  const [ tableDataFormat, setTableDataFormat ] = React.useState([
    { column: "project_name", keys: ["project_name"] }, 
    { column: "site_name", keys: ["site_name"] }, 
    { column: "contract_value", keys: ["contract_value"] }, 
    { column: "customer_type", keys: ["customer_type"] }, 
    { column: "utility", keys: ["utility"] }, 
    { column: "project_type", keys: ["project_type"] }, 
    { column: "solar_pv_capacity", keys: ["solar_pv_capacity"] }, 
    { column: "battery_storage_capacity", keys: ["battery_storage_capacity"] }
  ]);

  const [ keywords, setKeywords ] = React.useState('');

  const cancelButtonRef = React.useRef(null);
  const navigate = useNavigate();
  const user     = useSelector((state) => state.user.profile);
  
  const [ projectColumns, setProjectColumns ] = React.useState({
    project_name: true,
    project_code: false,
    project_notes: false,
    project_summary: false,
    project_type: false,
    status: false,
    utility: true,
    project_budget: false,
    customer_type: false,
    contract_value: false,
    contract_number: false,
    contract_gfc_date: false,
    contract_date: false,

    // Newlins
    //project_contacts: false,
    //project_services: false,
    pm_number: false,
    po_number: false,
  })

  const _onEditProjectColumns = (e) => {
    const data = { ...projectColumns };
    data[e.key] = e.value;
    setProjectColumns(data);
  };

  const [ siteColumns, setSiteColumns ] = React.useState({
    site_code: false,
    site_name: true,
    site_directions: false,
    site_distance_drive: false,
    site_distance_miles: false,
    site_fence: false,
    site_gps: false,
    county: false,

    // Newlins
    site_address: false,
    site_contacts: false,
    target_renewable: false,

    ratepayer_type: false,
    landowner: false,
    critical_load: false,
    generator_use: false,
    container_weight: false,

    shipping: false,
    unloading: false,
    system_placement: false,

    access_road_flags: false,
    procurement_yard: false,

    location_minimum_temperature: false,
    location_maximum_temperature: false,
    location_minimum_humidity: false,
    location_maximum_humidity: false,
    wind_speed: false,
    snow_load: false,
    soil_type: false,
    seismic_spectral_response_short: false,
    seismic_spectral_response_second: false,
    seismic_importance_factor: false,
    seismic_site_class: false,
    design_spectral_response_short: false,
    design_spectral_response_second: false,
    seismic_design_category: false,

    elevation: false,
    wind_exposure_category: false,
    snow_height: false,
    risk_category: false,
    geologic_hazard_zone: false,
    state_seismic_landslide_zone: false,
    state_seismic_liquefaction_zone: false,

    peak_demand: false,
    average_demand: false,

    foundation_type: false,
    foundation_requirements: false,
    service_voltage: false,
    connection_type: false,
    miles_line_removed: false,
    pcc_to_transformer_type: false,
    pcc_to_transformer_install: false,
    pcc_to_transformer_distance: false,
    transformer_to_meter_type: false,
    transformer_to_meter_install: false,
    transformer_to_meter_distance: false,
    // additional_links: false,
  })

  const _onEditSiteColumns = (e) => {
    const data = { ...siteColumns };
    data[e.key] = e.value;
    setSiteColumns(data);
  };

  const [ scenarioColumns, setScenarioColumns ] = React.useState({
    scenario_name: true,
    solar_pv_capacity: true,
    battery_storage_capacity: true,
    battery_inverter_capacity: false,
    pv_azimuth: false,
    pv_tilt: false,

    // Newlins
    generator_quantity: false,
    generator_size: false,
    model_renewable_fraction: false,
    generator_runtime: false,
    fuel_consumption: false,
    fuel_tank_size: false,
    solar_product: false,
    battery_product: false,
    inverter_product: false,
    mounting_product: false,
    generator_product: false,
    chargecontroller_product: false,
    fence_product: false,
  })

  const _onEditScenarioColumns = (e) => {
    const data = { ...scenarioColumns };
    data[e.key] = e.value;
    setScenarioColumns(data);
  };

  
  const increasePage = () => {
    if (currentPage >= lastPage) {
      setPage(lastPage);
    } else {
      setPage(currentPage + 1);
    }
  };

  const decreasePage = () => {
    if (currentPage <= 1) {
      setPage(1);
    } else {
      setPage(currentPage - 1);
    }
  };

  React.useEffect(() => {

    // console.log(projectColumns, siteColumns, scenarioColumns);

    let tableHF = [];
    let tableDF = [];

    /**
     * Changing Project Columns
     */

    // Project Name Logic
    //console.log('projectColumns.project_name:', projectColumns.project_name);
    if (projectColumns.project_name) {
      tableHF.push({ column: "project_name", label: "Project Name" })
      tableDF.push({ column: "project_name", keys: ["project_name"] })
    }
    
    // Project Code Logic
    //console.log('projectColumns.project_code:', projectColumns.project_code);
    if (projectColumns.project_code) {
      tableHF.push({ column: "project_code", label: "Project Code" })
      tableDF.push({ column: "project_code", keys: ["project_code"] })
    }

    // Project Notes Logic
    if (projectColumns.project_notes) {
      tableHF.push({ column: "project_notes", label: "Project Notes" })
      tableDF.push({ column: "project_notes", keys: ["project_notes"] })
    }

    // Project Summary Logic
    if (projectColumns.project_summary) {
      tableHF.push({ column: "project_summary", label: "Project Summary" })
      tableDF.push({ column: "project_summary", keys: ["project_summary"] })
    }

    // Project Type Logic
    if (projectColumns.project_type) {
      tableHF.push({ column: "project_type", label: "Project Type" })
      tableDF.push({ column: "project_type", keys: ["project_type"] })
    }

    // Status Logic
    if (projectColumns.status) {
      tableHF.push({ column: "status", label: "Status" })
      tableDF.push({ column: "status", keys: ["status"] })
    }

    // utility name Logic
    if (projectColumns.utility) {
      tableHF.push({ column: "utility", label: "Utility Name" })
      tableDF.push({ column: "utility", keys: ["utility"] })
    }

    // project budget Logic
    if (projectColumns.project_budget) {
      tableHF.push({ column: "project_budget", label: "Project Name" })
      tableDF.push({ column: "project_budget", keys: ["project_budget"] })
    } 

    // customer type Logic
    if (projectColumns.customer_type) {
      tableHF.push({ column: "customer_type", label: "Customer Type" })
      tableDF.push({ column: "customer_type", keys: ["customer_type"] })
    }

    // Contract value Logic
    if (projectColumns.contract_value) {
      tableHF.push({ column: "contract_value", label: "Contract Value" })
      tableDF.push({ column: "contract_value", keys: ["contract_value"] })
    }

    // Contract Number Logic
    if (projectColumns.contract_number) {
      tableHF.push({ column: "contract_number", label: "Contract Number" })
      tableDF.push({ column: "contract_number", keys: ["contract_number"] })
    }

    // Contract GFC date Logic
    if (projectColumns.contract_gfc_date) {
      tableHF.push({ column: "contract_gfc_date", label: "Contract GFC Date" })
      tableDF.push({ column: "contract_gfc_date", keys: ["contract_gfc_date"] })
    }

    // Contract Date Logic
    if (projectColumns.contract_date) {
      tableHF.push({ column: "contract_date", label: "Contract Date" })
      tableDF.push({ column: "contract_date", keys: ["contract_date"] })
    }
    // Newlins
    // project_contacts: false,
    // project_services: false,
    // pm_number: false,
    // po_number: false,

    // Project Contacts Logic
    if (projectColumns.project_contacts) {
      // tableHF.push({ column: "project_contacts", label: "Project Contacts" })
      // tableDF.push({ column: "project_contacts", keys: ["project_contacts"] })
    }

    // Project Services Logic
    if (projectColumns.project_services) {
      // tableHF.push({ column: "project_services", label: "Project Services" })
      // tableDF.push({ column: "project_services", keys: ["project_services"] })
    }

    // PM Number Logic
    if (projectColumns.pm_number) {
      tableHF.push({ column: "pm_number", label: "PM Number" })
      tableDF.push({ column: "pm_number", keys: ["pm_number"] })
    }

    // PO Number Logic
    if (projectColumns.po_number) {
      tableHF.push({ column: "po_number", label: "PO Number" })
      tableDF.push({ column: "po_number", keys: ["po_number"] })
    }

    /**
     * Changing Site Columns
     */

    // site Code Logic
    if (siteColumns.site_code) {
      tableHF.push({ column: "site_code", label: "Site Code" })
      tableDF.push({ column: "site_code", keys: ["site_code"] })
    }

    // Site Name Logic
    if (siteColumns.site_name) {
      tableHF.push({ column: "site_name", label: "Site Name" })
      tableDF.push({ column: "site_name", keys: ["site_name"] })
    }

    // Site Directions Logic
    if (siteColumns.site_directions) {
      tableHF.push({ column: "site_directions", label: "Site Directions" })
      tableDF.push({ column: "site_directions", keys: ["site_directions"] })
    }

    // Site Distance Drive Logic
    if (siteColumns.site_distance_drive) {
      tableHF.push({ column: "site_distance_drive", label: "Site Distance Drive" })
      tableDF.push({ column: "site_distance_drive", keys: ["site_distance_drive"] })
    }

    // site Distance Miles Logic
    if (siteColumns.site_distance_miles) {
      tableHF.push({ column: "site_distance_miles", label: "Site Distance Miles" })
      tableDF.push({ column: "site_distance_miles", keys: ["site_distance_miles"] })
    } 

    // Site Fence Logic
    if (siteColumns.site_fence) {
      tableHF.push({ column: "site_fence", label: "Site Fence" })
      tableDF.push({ column: "site_fence", keys: ["site_fence"] })
    }

    // site GPS Logic
    if (siteColumns.site_gps) {
      tableHF.push({ column: "site_gps", label: "Site GPS" })
      tableDF.push({ column: "site_gps", keys: ["site_gps"] })
    }

    // Site Code Logic
    if (siteColumns.site_code) {
      tableHF.push({ column: "site_code", label: "Site Code" })
      tableDF.push({ column: "site_code", keys: ["site_code"] })
    }

    // Newlins
    // site_address: false,
    // site_contacts: false,
    // target_renewable: false,

    // ratepayer_type: false,
    // landowner: false,
    // critical_load: false,
    // generator_use: false,
    // weight: false,

    // shipping: false,
    // unloading: false,
    // system_placement: false,

    // access_road_flags: false,
    // procurement_yard: false,

    // location_minimum_temperature: false,
    // location_maximum_temperature: false,
    // location_minimum_humidity: false,
    // location_maximum_humidity: false,
    // wind_speed: false,
    // snow_load: false,
    // soil_type: false,
    // seismic_spectral_response_short: false,
    
    // seismic_spectral_response_second: false,
    // seismic_importance_factor: false,
    // seismic_site_class: false,
    // design_spectral_response_short: false,
    // design_spectral_response_second: false,
    // seismic_design_category: false,

    // elevation: false,
    // wind_exposure_category: false,
    // snow_height: false,
    // risk_category: false,
    // geologic_hazard_zone: false,
    // state_seismic_landslide_zone: false,
    // state_seismic_liquefaction_zone: false,
    
    // peak_demand: false,
    // average_demand: false,

    // foundation_type: false,
    // foundation_requirements: false,
    // service_voltage: false,
    // connection_type: false,
    // miles_line_removed: false,
    // pcc_to_transformer_type: false,
    // pcc_to_transformer_install: false,
    // pcc_to_transformer_distance: false,
    // transformer_to_meter_type: false,
    // transformer_to_meter_install: false,
    // transformer_to_meter_distance: false,
    

    // Site Address Logic
    if (siteColumns.site_address) {
      tableHF.push({ column: "site_address", label: "Site Address" })
      tableDF.push({ column: "site_address", keys: ["site_address"] })
    }

    // Site Contacts Logic
    if (siteColumns.site_contacts) {
      tableHF.push({ column: "site_contacts", label: "Site Contacts" })
      tableDF.push({ column: "site_contacts", keys: ["site_contacts"] })
    }

    // Target Renewable Logic
    if (siteColumns.target_renewable) {
      tableHF.push({ column: "target_renewable", label: "Target Renewable" })
      tableDF.push({ column: "target_renewable", keys: ["target_renewable"] })
    }

    // Ratepayer Type Logic
    if (siteColumns.ratepayer_type) {
      tableHF.push({ column: "ratepayer_type", label: "Ratepayer Type" })
      tableDF.push({ column: "ratepayer_type", keys: ["ratepayer_type"] })
    }

    // Landowner Logic
    if (siteColumns.landowner) {
      tableHF.push({ column: "landowner", label: "Landowner" })
      tableDF.push({ column: "landowner", keys: ["landowner"] })
    }

    // Critical Load Logic
    if (siteColumns.critical_load) {
      tableHF.push({ column: "critical_load", label: "Critical Load" })
      tableDF.push({ column: "critical_load", keys: ["critical_load"] })
    }

    // Generator Use Logic
    if (siteColumns.generator_use) {
      tableHF.push({ column: "generator_use", label: "Generator Use" })
      tableDF.push({ column: "generator_use", keys: ["generator_use"] })
    }

    // Container Weight Logic
    if (siteColumns.container_weight) {
      tableHF.push({ column: "container_weight", label: "Container Weight" })
      tableDF.push({ column: "container_weight", keys: ["container_weight"] })
    }

    // Shipping Logic
    if (siteColumns.shipping) {
      tableHF.push({ column: "shipping", label: "Shipping" })
      tableDF.push({ column: "shipping", keys: ["shipping"] })
    }

    // Unloading Logic
    if (siteColumns.unloading) {
      tableHF.push({ column: "unloading", label: "Unloading" })
      tableDF.push({ column: "unloading", keys: ["unloading"] })
    }

    // System Placement Logic
    if (siteColumns.system_placement) {
      tableHF.push({ column: "system_placement", label: "System Placement" })
      tableDF.push({ column: "system_placement", keys: ["system_placement"] })
    }

    // Access Road Flags Logic
    if (siteColumns.access_road_flags) {
      tableHF.push({ column: "access_road_flags", label: "Access Road Flags" })
      tableDF.push({ column: "access_road_flags", keys: ["access_road_flags"] })
    }

    // Procurement Yard Logic
    if (siteColumns.procurement_yard) {
      tableHF.push({ column: "procurement_yard", label: "Procurement Yard" })
      tableDF.push({ column: "procurement_yard", keys: ["procurement_yard"] })
    }

    // Location Minimum Temperature Logic
    if (siteColumns.location_minimum_temperature) {
      tableHF.push({ column: "location_minimum_temperature", label: "Location Minimum Temperature" })
      tableDF.push({ column: "location_minimum_temperature", keys: ["location_minimum_temperature"] })
    }

    // Location Maximum Temperature Logic
    if (siteColumns.location_maximum_temperature) {
      tableHF.push({ column: "location_maximum_temperature", label: "Location Maximum Temperature" })
      tableDF.push({ column: "location_maximum_temperature", keys: ["location_maximum_temperature"] })
    }

    // Location Minimum Humidity Logic
    if (siteColumns.location_minimum_humidity) {
      tableHF.push({ column: "location_minimum_humidity", label: "Location Minimum Humidity" })
      tableDF.push({ column: "location_minimum_humidity", keys: ["location_minimum_humidity"] })
    }

    // Location Maximum Humidity Logic
    if (siteColumns.location_maximum_humidity) {
      tableHF.push({ column: "location_maximum_humidity", label: "Location Maximum Humidity" })
      tableDF.push({ column: "location_maximum_humidity", keys: ["location_maximum_humidity"] })
    }

    // Wind Speed Logic
    if (siteColumns.wind_speed) {
      tableHF.push({ column: "wind_speed", label: "Wind Speed" })
      tableDF.push({ column: "wind_speed", keys: ["wind_speed"] })
    }

    // Snow Load Logic
    if (siteColumns.snow_load) {
      tableHF.push({ column: "snow_load", label: "Snow Load" })
      tableDF.push({ column: "snow_load", keys: ["snow_load"] })
    }

    // Soil Type Logic
    if (siteColumns.soil_type) {
      tableHF.push({ column: "soil_type", label: "Soil Type" })
      tableDF.push({ column: "soil_type", keys: ["soil_type"] })
    }

    // Seismic Spectral Response Short Logic
    if (siteColumns.seismic_spectral_response_short) {
      tableHF.push({ column: "seismic_spectral_response_short", label: "Seismic Spectral Response Short" })
      tableDF.push({ column: "seismic_spectral_response_short", keys: ["seismic_spectral_response_short"] })
    }

    // Seismic Spectral Response Second Logic
    if (siteColumns.seismic_spectral_response_second) {
      tableHF.push({ column: "seismic_spectral_response_second", label: "Seismic Spectral Response Second" })
      tableDF.push({ column: "seismic_spectral_response_second", keys: ["seismic_spectral_response_second"] })
    }

    // Seismic Importance Factor Logic
    if (siteColumns.seismic_importance_factor) {
      tableHF.push({ column: "seismic_importance_factor", label: "Seismic Importance Factor" })
      tableDF.push({ column: "seismic_importance_factor", keys: ["seismic_importance_factor"] })
    }

    // Seismic Site Class Logic
    if (siteColumns.seismic_site_class) {
      tableHF.push({ column: "seismic_site_class", label: "Seismic Site Class" })
      tableDF.push({ column: "seismic_site_class", keys: ["seismic_site_class"] })
    }

    // Design Spectral Response Short Logic
    if (siteColumns.design_spectral_response_short) {
      tableHF.push({ column: "design_spectral_response_short", label: "Design Spectral Response Short" })
      tableDF.push({ column: "design_spectral_response_short", keys: ["design_spectral_response_short"] })
    }

    // Design Spectral Response Second Logic
    if (siteColumns.design_spectral_response_second) {
      tableHF.push({ column: "design_spectral_response_second", label: "Design Spectral Response Second" })
      tableDF.push({ column: "design_spectral_response_second", keys: ["design_spectral_response_second"] })
    }

    // Seismic Design Category Logic
    if (siteColumns.seismic_design_category) {
      tableHF.push({ column: "seismic_design_category", label: "Seismic Design Category" })
      tableDF.push({ column: "seismic_design_category", keys: ["seismic_design_category"] })
    }

    // Elevation Logic
    if (siteColumns.elevation) {
      tableHF.push({ column: "elevation", label: "Elevation" })
      tableDF.push({ column: "elevation", keys: ["elevation"] })
    }

    // Wind Exposure Category Logic
    if (siteColumns.wind_exposure_category) {
      tableHF.push({ column: "wind_exposure_category", label: "Wind Exposure Category" })
      tableDF.push({ column: "wind_exposure_category", keys: ["wind_exposure_category"] })
    }

    // Snow Height Logic
    if (siteColumns.snow_height) {
      tableHF.push({ column: "snow_height", label: "Snow Height" })
      tableDF.push({ column: "snow_height", keys: ["snow_height"] })
    }

    // Risk Category Logic
    if (siteColumns.risk_category) {
      tableHF.push({ column: "risk_category", label: "Risk Category" })
      tableDF.push({ column: "risk_category", keys: ["risk_category"] })
    }

    // Geologic Hazard Zone Logic
    if (siteColumns.geologic_hazard_zone) {
      tableHF.push({ column: "geologic_hazard_zone", label: "Geologic Hazard Zone" })
      tableDF.push({ column: "geologic_hazard_zone", keys: ["geologic_hazard_zone"] })
    }

    // State Seismic Landslide Zone Logic
    if (siteColumns.state_seismic_landslide_zone) {
      tableHF.push({ column: "state_seismic_landslide_zone", label: "State Seismic Landslide Zone" })
      tableDF.push({ column: "state_seismic_landslide_zone", keys: ["state_seismic_landslide_zone"] })
    }

    // State Seismic Liquefaction Zone Logic
    if (siteColumns.state_seismic_liquefaction_zone) {
      tableHF.push({ column: "state_seismic_liquefaction_zone", label: "State Seismic Liquefaction Zone" })
      tableDF.push({ column: "state_seismic_liquefaction_zone", keys: ["state_seismic_liquefaction_zone"] })
    }

    // Peak Demand Logic
    if (siteColumns.peak_demand) {
      tableHF.push({ column: "peak_demand", label: "Peak Demand" })
      tableDF.push({ column: "peak_demand", keys: ["peak_demand"] })
    }

    // Average Demand Logic
    if (siteColumns.average_demand) {
      tableHF.push({ column: "average_demand", label: "Average Demand" })
      tableDF.push({ column: "average_demand", keys: ["average_demand"] })
    }

    // Foundation Type Logic
    if (siteColumns.foundation_type) {
      tableHF.push({ column: "foundation_type", label: "Foundation Type" })
      tableDF.push({ column: "foundation_type", keys: ["foundation_type"] })
    }

    // Foundation Requirements Logic
    if (siteColumns.foundation_requirements) {
      tableHF.push({ column: "foundation_requirements", label: "Foundation Requirements" })
      tableDF.push({ column: "foundation_requirements", keys: ["foundation_requirements"] })
    }

    // Service Voltage Logic
    if (siteColumns.service_voltage) {
      tableHF.push({ column: "service_voltage", label: "Service Voltage" })
      tableDF.push({ column: "service_voltage", keys: ["service_voltage"] })
    }

    // Connection Type Logic
    if (siteColumns.connection_type) {
      tableHF.push({ column: "connection_type", label: "Connection Type" })
      tableDF.push({ column: "connection_type", keys: ["connection_type"] })
    }

    // Miles Line Removed Logic
    if (siteColumns.miles_line_removed) {
      tableHF.push({ column: "miles_line_removed", label: "Miles Line Removed" })
      tableDF.push({ column: "miles_line_removed", keys: ["miles_line_removed"] })
    }

    // PCC To Transformer Type Logic
    if (siteColumns.pcc_to_transformer_type) {
      tableHF.push({ column: "pcc_to_transformer_type", label: "PCC To Transformer Type" })
      tableDF.push({ column: "pcc_to_transformer_type", keys: ["pcc_to_transformer_type"] })
    }

    // PCC To Transformer Install Logic
    if (siteColumns.pcc_to_transformer_install) {
      tableHF.push({ column: "pcc_to_transformer_install", label: "PCC To Transformer Install" })
      tableDF.push({ column: "pcc_to_transformer_install", keys: ["pcc_to_transformer_install"] })
    }

    // PCC To Transformer Distance Logic
    if (siteColumns.pcc_to_transformer_distance) {
      tableHF.push({ column: "pcc_to_transformer_distance", label: "PCC To Transformer Distance" })
      tableDF.push({ column: "pcc_to_transformer_distance", keys: ["pcc_to_transformer_distance"] })
    }

    // Transformer To_Meter Type Logic
    if (siteColumns.transformer_to_meter_type) {
      tableHF.push({ column: "transformer_to_meter_type", label: "Transformer To_Meter Type" })
      tableDF.push({ column: "transformer_to_meter_type", keys: ["transformer_to_meter_type"] })
    }

    // Transformer To Meter Install Logic
    if (siteColumns.transformer_to_meter_install) {
      tableHF.push({ column: "transformer_to_meter_install", label: "Transformer To Meter Install" })
      tableDF.push({ column: "transformer_to_meter_install", keys: ["transformer_to_meter_install"] })
    }

    // Transformer To Meter Distance Logic
    if (siteColumns.transformer_to_meter_distance) {
      tableHF.push({ column: "transformer_to_meter_distance", label: "Transformer To Meter Distance" })
      tableDF.push({ column: "transformer_to_meter_distance", keys: ["transformer_to_meter_distance"] })
    }

    // Site Code Logic
    // if (siteColumns.site_code) {
    //   tableHF.push({ column: "site_code", label: "Site Code" })
    //   tableDF.push({ column: "site_code", keys: ["site_code"] })
    // }

    /**
     * Changing Scenario Columns Logic
     */

    // Scenario name Logic
    if (scenarioColumns.scenario_name) {
      tableHF.push({ column: "scenario_name", label: "Scenario name" })
      tableDF.push({ column: "scenario_name", keys: ["scenario_name"] })
    }

    // Solar PV Capacity Logic
    if (scenarioColumns.solar_pv_capacity) {
      tableHF.push({ column: "solar_pv_capacity", label: "Solar PV Capacity" })
      tableDF.push({ column: "solar_pv_capacity", keys: ["solar_pv_capacity"] })
    }

    // Battery Storage Capacity Logic
    if (scenarioColumns.battery_storage_capacity) {
      tableHF.push({ column: "battery_storage_capacity", label: "Battery Storage Capacity" })
      tableDF.push({ column: "battery_storage_capacity", keys: ["battery_storage_capacity"] })
    } 

    // battery inverter capacity Logic
    if (scenarioColumns.battery_inverter_capacity) {
      tableHF.push({ column: "battery_inverter_capacity", label: "Battery Inverter Capacity" })
      tableDF.push({ column: "battery_inverter_capacity", keys: ["battery_inverter_capacity"] })
    } 

    // PV Azimuth Logic
    if (scenarioColumns.pv_azimuth) {
      tableHF.push({ column: "pv_azimuth", label: "PV Azimuth" })
      tableDF.push({ column: "pv_azimuth", keys: ["pv_azimuth"] })
    }

    // PV Tilt Logic
    if (scenarioColumns.pv_tilt) {
      tableHF.push({ column: "pv_tilt", label: "PV Tilt" })
      tableDF.push({ column: "pv_tilt", keys: ["pv_tilt"] })
    }

    // Newlins
    // generator_quantity: false,
    // generator_size: false,
    // model_renewable_fraction: false,
    // generator_runtime: false,
    // fuel_consumption: false,
    // fuel_tank_size: false,
    // solar_product: false,
    // battery_product: false,
    // inverter_product: false,
    // mounting_product: false,
    // generator_product: false,
    // chargecontroller_product: false,
    // fence_product: false,

    // Generator Quantity Logic
    if (scenarioColumns.generator_quantity) {
      tableHF.push({ column: "generator_quantity", label: "Generator Quantity" })
      tableDF.push({ column: "generator_quantity", keys: ["generator_quantity"] })
    }

    // Generator Size Logic
    if (scenarioColumns.generator_size) {
      tableHF.push({ column: "generator_size", label: "Generator Size" })
      tableDF.push({ column: "generator_size", keys: ["generator_size"] })
    }

    // Model Renewable Fraction Logic
    if (scenarioColumns.model_renewable_fraction) {
      tableHF.push({ column: "model_renewable_fraction", label: "Model Renewable Fraction" })
      tableDF.push({ column: "model_renewable_fraction", keys: ["model_renewable_fraction"] })
    }

    // Generator Runtime Logic
    if (scenarioColumns.generator_runtime) {
      tableHF.push({ column: "generator_runtime", label: "Generator Runtime" })
      tableDF.push({ column: "generator_runtime", keys: ["generator_runtime"] })
    }

    // Fuel Consumption Logic
    if (scenarioColumns.fuel_consumption) {
      tableHF.push({ column: "fuel_consumption", label: "Fuel Consumption" })
      tableDF.push({ column: "fuel_consumption", keys: ["fuel_consumption"] })
    }

    // Fuel Tank Size Logic
    if (scenarioColumns.fuel_tank_size) {
      tableHF.push({ column: "fuel_tank_size", label: "Fuel Tank Size" })
      tableDF.push({ column: "fuel_tank_size", keys: ["fuel_tank_size"] })
    }

    // Solar Product Logic
    if (scenarioColumns.solar_product) {
      tableHF.push({ column: "solar_product", label: "Solar Product Name" });
      tableHF.push({ column: "solar_product", label: "Solar Product Unit Capacity" });
      tableHF.push({ column: "solar_product", label: "Solar Product Unit Cost" });

      tableDF.push({ column: "solar_product", keys: ["solar_product", ["solar_product_name", "solar_product_unit_capacity", "solar_product_unit_cost"]] })
    }

    // Battery Product Logic
    if (scenarioColumns.battery_product) {
      tableHF.push({ column: "battery_product", label: "Battery Product Name" });
      tableHF.push({ column: "battery_product", label: "Battery Product Unit Capacity" });
      tableHF.push({ column: "battery_product", label: "Battery Product Unit Cost" });

      tableDF.push({ column: "battery_product", keys: ["battery_product", ["battery_product_name", "battery_product_unit_capacity", "battery_product_unit_cost"]] })
    }

    // Inverter Product Logic
    if (scenarioColumns.inverter_product) {
      tableHF.push({ column: "inverter_product", label: "Inverter Product Name" });
      tableHF.push({ column: "inverter_product", label: "Inverter Product Unit Capacity" });
      tableHF.push({ column: "inverter_product", label: "Inverter Product Unit Cost" });

      tableDF.push({ column: "inverter_product", keys: ["inverter_product", ["inverter_product_name", "inverter_product_unit_capacity", "inverter_product_unit_cost"]] })
    }

    // Mounting Product Logic
    if (scenarioColumns.mounting_product) {
      tableHF.push({ column: "mounting_product", label: "Mounting Product Name" });
      tableHF.push({ column: "mounting_product", label: "Mounting Product Unit Capacity" });
      tableHF.push({ column: "mounting_product", label: "Mounting Product Unit Cost" });

      tableDF.push({ column: "mounting_product", keys: ["mounting_product", ["mounting_product_name", "mounting_product_unit_capacity", "mounting_product_unit_cost"] ] })
    }

    // Generator Product Logic
    if (scenarioColumns.generator_product) {
      tableHF.push({ column: "generator_product", label: "Generator Product Name" });
      tableHF.push({ column: "generator_product", label: "Generator Product Unit Capacity" });
      tableHF.push({ column: "generator_product", label: "Generator Product Unit Cost" });

      tableDF.push({ column: "generator_product", keys: ["generator_product", ["generator_product_name", "generator_product_unit_capacity", "generator_product_unit_cost"]] })
    }

    // Chargecontroller Product Logic
    if (scenarioColumns.chargecontroller_product) {
      tableHF.push({ column: "chargecontroller_product", label: "Chargecontroller Product Name" });
      tableHF.push({ column: "chargecontroller_product", label: "Chargecontroller Product Unit Capacity" });
      tableHF.push({ column: "chargecontroller_product", label: "Chargecontroller Product Unit Cost" });

      tableDF.push({ column: "chargecontroller_product", keys: ["chargecontroller_product", ["chargecontroller_product_name", "chargecontroller_product_unit_capacity", "chargecontroller_product_unit_cost"]] })
    }

    // Fence Product Logic
    if (scenarioColumns.fence_product) {
      tableHF.push({ column: "fence_product", label: "Fence Product Name" });
      tableHF.push({ column: "fence_product", label: "Fence Product Unit Capacity" });
      tableHF.push({ column: "fence_product", label: "Fence Product Unit Cost" });

      tableDF.push({ column: "fence_product", keys: ["fence_product", ["fence_product_name", "fence_product_unit_capacity", "fence_product_unit_cost"]] })
    }


    setTableHeaderFormat(tableHF);
    setTableDataFormat(tableDF);
  }, [ projectColumns, siteColumns, scenarioColumns ]);

  function downloadTableAsCSV() {
    // Select the table element
    var table = document.getElementById("export-data-table-to-csv");
    var rows = table.querySelectorAll("tr");

    //console.log('table: ', table);
    //console.log('rows: ', rows);
    
    // Initialize CSV content
    var csv = [];

    // Iterate over rows
    rows.forEach(row => {
      // Initialize an array for the row's CSV values
      var rowValues = [];
      
      // Select the cells in the row
      var cells = row.querySelectorAll("th, td");

      // Iterate over cells
      cells.forEach(cell => {
        // Escape any double quotes in the cell value
        var cellValue = cell.innerText.replace(/"/g, '""');
        rowValues.push('"' + cellValue + '"');
      });

      // Join the row's values with commas and add to CSV content
      csv.push(rowValues.join(","));
    });

    // Join all rows with newlines
    var csvContent = csv.join("\n");

    // Create a Blob from the CSV content
    var blob = new Blob([csvContent], { type: "text/csv" });

    // Create a download link for the Blob
    var link = document.createElement("a");
    link.href = URL.createObjectURL(blob);

    // console.log('link:', link);
    link.download = "EASI-data-export.csv";

    // Append the link to the body, click it, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // const downloadCSVofSelectedData = () => {
  //   const payload = { projects: [] };
  
  //   setLoading(true);
  
  //   const filterFields = (columns, fields) => {
  //     return fields.filter(field => columns[field]);
  //   };
  
  //   for (let i = 0; i < selectedProjects.length; i++) {
  //     const project = selectedProjects[i];
      
  //     const sites_d = [];
  
  //     for (let j = 0; j < sites.length; j++) {
  //       const site = sites[j];
  
  //       if (site.checked && project.id === site.project_id) {
  //         const scenarios_d = [];
  
  //         for (let k = 0; k < scenarios.length; k++) {
  //           const scenario = scenarios[k];
  
  //           if (scenario.checked && site.id === scenario.site_id) {
  //             scenarios_d.push({ 
  //               "id"     : scenario.id,
  //               "fields" : filterFields(scenarioColumns, [
  //                 "scenario_name", 
  //                 "solar_pv_capacity", 
  //                 "battery_storage_capacity", 
  //                 "battery_inverter_capacity", 
  //                 "pv_azimuth", 
  //                 "pv_tilt",
  
  //                 "generator_quantity",
  //                 "generator_size",
  //                 "model_renewable_fraction",
  //                 "generator_runtime",
  //                 "fuel_consumption",
  //                 "fuel_tank_size",
  //                 "solar_product",
  //                 "battery_product",
  //                 "inverter_product",
  //                 "mounting_product",
  //                 "generator_product",
  //                 "chargecontroller_product",
  //                 "fence_product",
  //               ])
  //             });
  //           }
  //         }
  
  //         const site_id = site.id;
  //         const site_fields = filterFields(siteColumns, [
  //           "site_code", 
  //           "site_name", 
  //           "site_directions", 
  //           "site_distance_drive", 
  //           "site_distance_miles", 
  //           "site_fence", 
  //           "site_gps", 
  //           "county",
  
  //           "site_address",
  //           "site_contacts",
  //           "target_renewable",
  
  //           "ratepayer_type",
  //           "landowner",
  //           "critical_load",
  //           "generator_use",
  //           "container_weight",
  
  //           "shipping",
  //           "unloading",
  //           "system_placement",
  
  //           "access_road_flags",
  //           "procurement_yard",
  
  //           "location_minimum_temperature",
  //           "location_maximum_temperature",
  //           "location_minimum_humidity",
  //           "location_maximum_humidity",
  //           "wind_speed",
  //           "snow_load",
  //           "soil_type",
  //           "seismic_spectral_response_short",
            
  //           "seismic_spectral_response_second",
  //           "seismic_importance_factor",
  //           "seismic_site_class",
  //           "design_spectral_response_short",
  //           "design_spectral_response_second",
  //           "seismic_design_category",
  
  //           "elevation",
  //           "wind_exposure_category",
  //           "snow_height",
  //           "risk_category",
  //           "geologic_hazard_zone",
  //           "state_seismic_landslide_zone",
  //           "state_seismic_liquefaction_zone",
            
  //           "peak_demand",
  //           "average_demand",
  
  //           "foundation_type",
  //           "foundation_requirements",
  //           "service_voltage",
  //           "connection_type",
  //           "miles_line_removed",
  //           "pcc_to_transformer_type",
  //           "pcc_to_transformer_install",
  //           "pcc_to_transformer_distance",
  //           "transformer_to_meter_type",
  //           "transformer_to_meter_install",
  //           "transformer_to_meter_distance",
  //         ]);
  
  //         if (scenarios_d.length) {
  //           sites_d.push({ 
  //             "id"        : site_id,
  //             "fields"    : site_fields,
  //             "scenarios" : scenarios_d
  //           });
  //         } else {
  //           sites_d.push({ 
  //             "id"     : site_id,
  //             "fields" : site_fields
  //           });
  //         }
  //       } 
  //     }
  
  //     const project_id = project.id;
  //     const project_fields = filterFields(projectColumns, [
  //       "project_name", 
  //       "project_code", 
  //       "project_notes", 
  //       "project_summary", 
  //       "project_type", 
  //       "status", 
  //       "utility", 
  //       "project_budget", 
  //       "customer_type", 
  //       "contract_value", 
  //       "contract_number", 
  //       "contract_gfc_date", 
  //       "contract_date",
  
  //       "project_contacts",
  //       "project_services",
  //       "pm_number",
  //       "po_number",
  //     ]);
  
  //     if (sites_d?.length) {
  //       payload.projects.push({
  //         "id"     : project_id,
  //         "fields" : project_fields,
  //         "sites"  : sites_d
  //       })
  //     } else {
  //       payload.projects.push({
  //         "id"     : project_id,
  //         "fields" : project_fields
  //       })
  //     }
  //   }
  
  //   if (payload.projects.length) {
  //     fetch(`${Config.API.URL}/reports/custom/download`, { 
  //       method: "POST",
  //       headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
  //       body: JSON.stringify(payload)
  //     }).then((res) => {
  //       setLoading(false);
  //       if (res.status === 401) {
  //         console.log("401 Error: Unauthenticated");
  
  //         localStorage.removeItem("user");
  //         navigate("/");
  //       }
  //       return res;
  //     }).then((res) => res.json()).then(async (res) => {
  //       setLoading(false);
  //       console.log('download-res:', res);
  //       if (res.status) {
  //         var element = document.createElement('a');
  //         element.setAttribute('href', res?.data?.url);
  
  //         element.style.display = 'none';
  //         document.body.appendChild(element);
  
  //         element.click();
  
  //         document.body.removeChild(element);
  //       } else {
  //         swal("Oh no!", res.message, "error");
  //       }
  //     }).catch((error) => {
  //       setLoading(false);
  //       console.log("Error: ", error);
  
  //       swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
  //     });
  //   } else {
  //     setLoading(false);
  
  //     swal("Oh no!", "Please select project to download", "error");
  //   }
  // };

  const loadExportDataReport = () => {
    const payload = { projects: [] };

    //console.log('Hi...');

    setLoading(true);
    setReportLoading(true);

    for (let i = 0; i < selectedProjects.length; i++) {
      const project = selectedProjects[i];
      const sites_d = [];

      for (let j = 0; j < sites.length; j++) {
        const site = sites[j];

        if (site.checked && project.id === site.project_id) {
          const scenarios_d = [];

          for (let k = 0; k < scenarios.length; k++) {
            const scenario = scenarios[k];

            if (scenario.checked && site.id === scenario.site_id) {
              scenarios_d.push({ 
                "id"     : scenario.id,
                "fields" : [
                  "scenario_name", 
                  "solar_pv_capacity", 
                  "battery_storage_capacity", 
                  "battery_inverter_capacity", 
                  "pv_azimuth", 
                  "pv_tilt",

                  "generator_quantity",
                  "generator_size",
                  "model_renewable_fraction",
                  "generator_runtime",
                  "fuel_consumption",
                  "fuel_tank_size",
                  "solar_product",
                  "battery_product",
                  "inverter_product",
                  "mounting_product",
                  "generator_product",
                  "chargecontroller_product",
                  "fence_product",
                ]
              });
            }
          }

          const site_id     = site.id;
          const site_fields = [
            "site_code", "site_name", "site_directions", "site_distance_drive", "site_distance_miles", "site_fence", "site_gps", "county",
            
            "site_address",
          
            "target_renewable",

            "ratepayer_type",
            "landowner",
            "critical_load",
            "generator_use",
            "container_weight",

            "shipping",
            "unloading",
            "system_placement",

            "access_road_flags",
            "procurement_yard",

            "location_minimum_temperature",
            "location_maximum_temperature",
            "location_minimum_humidity",
            "location_maximum_humidity",
            "wind_speed",
            "snow_load",
            "soil_type",
            "seismic_spectral_response_short",
            
            "seismic_spectral_response_second",
            "seismic_importance_factor",
            "seismic_site_class",
            "design_spectral_response_short",
            "design_spectral_response_second",
            "seismic_design_category",

            "elevation",
            "wind_exposure_category",
            "snow_height",
            "risk_category",
            "geologic_hazard_zone",
            "state_seismic_landslide_zone",
            "state_seismic_liquefaction_zone",
            
            "peak_demand",
            "average_demand",

            "foundation_type",
            "foundation_requirements",
            "service_voltage",
            "connection_type",
            "miles_line_removed",
            "pcc_to_transformer_type",
            "pcc_to_transformer_install",
            "pcc_to_transformer_distance",
            "transformer_to_meter_type",
            "transformer_to_meter_install",
            "transformer_to_meter_distance",
          ];

          if (scenarios_d.length) {
            sites_d.push({ 
              "id"        : site_id,
              "fields"    : site_fields,
              "scenarios" : scenarios_d
            });
          } else {
            sites_d.push({ 
              "id"     : site_id,
              "fields" : site_fields
            });
          }
        } 
      }

      const project_id     = project.id;
      const project_fields = [ // "utility_name",
        "project_name", 
        "project_code", 
        "project_notes", 
        "project_summary", 
        "project_type", 
        "utility_id",
        "status", 
        "project_budget", 
        "customer_type", 
        "contract_value", 
        "contract_number", 
        "contract_gfc_date", 
        "contract_date",
        "pm_number",
        "po_number",
      ];

      if (sites_d?.length) {
        payload.projects.push({
          "id"     : project_id,
          "fields" : project_fields,
          "sites"  : sites_d
        })
      } else {
        payload.projects.push({
          "id"     : project_id,
          "fields" : project_fields
        })
      }
    }

    if (payload.projects.length) {
      // console.log('payload:', payload);
      fetch(`${Config.API.URL}/reports/custom/view?page=${reportsPage}`, { //&sort_by=first_name&sort_direction=ASC
        method: "POST",
        headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
        body: JSON.stringify(payload)
      }).then((res) => {
        setReportLoading(false);
        setLoading(false);
        setSelectingProjectsModel(false);
        setSelectingSitesModel(false);
        setSelectingScenariosModel(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          localStorage.removeItem("user");
          navigate("/");
        }
        return res;
      }).then((res) => res.json()).then(async (res) => {
        setReportLoading(false);
        setLoading(false);
        console.log('reports-custom-res:', res);
        if (res.status) {
          const site_d      = sites?.filter(x => x.checked );
          const scenarios_d = scenarios.filter(x => x.checked );

          setSites(site_d);
          setScenarios(scenarios_d);

          // console.log('site_d:', site_d);
          // console.log('scenarios_d:', scenarios_d);

          setReports(res.data);

          setReportsLastPage(res.data.last_page);
          setReportsCurrentPage(res.data.current_page);
        } else {
          swal("Oh no!", res.message, "error");
        }
      }).catch((error) => {
        setReportLoading(false);
        setLoading(false);
        console.log("Error: ", error);

        swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
      });
    } else {
      setReportLoading(false);
      setLoading(false);

      swal("Oh no!", "Please select project to load", "error");
    }
  };

  React.useEffect(() => {
    // console.log('page', page);
    setLoading(true);
    fetch(`${Config.API.URL}/projects?page=${page}&limit=20&sort_by=updated_at&sort_direction=DESC`, {
      method: "GET",
      headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
    }).then((res) => {
      // if 401 error (unauthenticated user, then log user out)
      if (res.status === 401) {
        setLoading(false);
        console.log("401 Error: Unauthenticated");
        localStorage.removeItem("user");
        //dispatch(setUserMiniProfile({}));
        navigate("/");
      }
      return res;
    }).then((res) => res.json()).then(async (res) => {
      // console.log('projects-res:', res);

      // if request is succesful, alert user that project has been cloned
      if (res.status) {
        setProjects(res.data.data);

        const data = res.data.data;

        let projects_data  = [];
        let sites_data     = [];
        let scenarios_data = [];

        // Selected record logic
        if (selectedProjects?.length) {
          //console.log('Selected record logic');
          for (let i = 0; i < selectedProjects?.length; i++) {
            const project = { ...selectedProjects[i] };

            for (let j = 0; j < project?.sites?.length; j++) {
              const site   = { ...project?.sites[j] };
              site.checked = true;

              sites_data.push(site);

              for (let k = 0; k < site?.scenarios?.length; k++) {
                const scenario   = { ...site?.scenarios[k] };
                scenario.checked = true;

                scenarios_data.push(scenario);
              }
            }
          }
        }

        // Fresh record logic
        for (let i = 0; i < data.length; i++) {
          //console.log('Fresh record logic')
          const project   = { ...data[i] };
          project.checked = false;

          projects_data.push(project);

          for (let j = 0; j < project?.sites?.length; j++) {
            const site   = { ...project?.sites[j] };
            site.checked = false;

            sites_data.push(site);

            for (let k = 0; k < site?.scenarios?.length; k++) {
              const scenario   = { ...site?.scenarios[k] };
              scenario.checked = false;

              scenarios_data.push(scenario);
            }
          }
        }

        if (params_projects.length) {
          const selectedProjects_d  = [ ...selectedProjects ];
          // Update projects_data based on params_projects
          for (let i = 0; i < params_projects.length; i++) {
            const paramProject = { ...params_projects[i] };
            let found = false;

            for (let j = 0; j < projects_data.length; j++) {
              if (projects_data[j].id === paramProject.id) {
                //projects_data[j].checked = true;
                selectedProjects_d.push(projects_data[j]);
                found = true;
                break;
              }
            }

            if (!found) {
              paramProject.checked = true;
              selectedProjects_d.push(paramProject);
              projects_data.push(paramProject);
            }
          }

          setSelectedProjects(selectedProjects_d);
        }

        if (params_sites.length) {
          // Update sites_data based on params_sites
          for (let i = 0; i < params_sites.length; i++) {
            const paramSite = { ...params_sites[i] };
            let found = false;

            for (let j = 0; j < sites_data.length; j++) {
              if (sites_data[j].id === paramSite.id) {
                sites_data[j].checked = true;
                found = true;
                break;
              }
            }

            if (!found) {
              paramSite.checked = true;
              sites_data.push(paramSite);
            }
          }
        }

        if (params_scenarios.length) {
          // Update scenarios_data based on params_scenarios
          for (let i = 0; i < params_scenarios.length; i++) {
            const paramScenario = { ...params_scenarios[i] };
            let found = false;

            for (let j = 0; j < scenarios_data.length; j++) {
              if (scenarios_data[j].id === paramScenario.id) {
                scenarios_data[j].checked = true;
                found = true;
                break;
              }
            }

            if (!found) {
              paramScenario.checked = true;
              scenarios_data.push(paramScenario);
            }
          }
          
          setSelectingSitesModel(true);
        }

        setProjects(projects_data);
        setSites(sites_data);
        setScenarios(scenarios_data);

        setTotalPrject(res.data.total);
        setLastPage(res.data.last_page);
        setCurrentPage(res.data.current_page);
        setPage(res.data.current_page);

        dispatch(setParamsProjects([]));
        dispatch(setParamsSites([]));
        dispatch(setParamsScenarios([]));
        
        // setLoading(false);
        loadUtilities()
      } else {
        if (res.message === 'Token is invalid.') {
          // localStorage.removeItem("user");
          // dispatch(setUserMiniProfile({}));
        } else {
          swal("Oh no!", res.message, "error");
        }
      }
    }).catch((error) => {
      setLoading(false);
      console.log("Error: ", error);

      swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
    });

    //loadProjects();
  }, [ user, page ]);

  const loadUtilities = () => {
    setLoading(true);
    fetch(`${Config.API.URL}/utilities?&sort_direction=ASC`, { // page=${page} sort_by=first_name&
      method: "GET",
      headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
    }).then((res) => {
      // if 401 error (unauthenticated user, then log user out)
      if (res.status === 401) {
        console.log("401 Error: Unauthenticated");

        localStorage.removeItem("user");
        navigate("/");
      }
      return res;
    }).then((res) => res.json()).then(async (res) => {
      console.log('res-util:', res);
      if (res.status) {
        setUtilities(res.data.data);
        //setLastPage(res.data.last_page);
        //setCurrentPage(res.data.current_page);
      } else {
        swal("Oh no!", res.message, "error");
      }
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      console.log("Error: ", error);

      swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
    });
  };

  const loadProjects = () => {
    setLoading(true);

    fetch(`${Config.API.URL}/projects?page=${page}&limit=20&sort_by=updated_at&sort_direction=DESC`, {
      method: "GET",
      headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
    }).then((res) => {
      setLoading(false);

      // if 401 error (unauthenticated user, then log user out)
      if (res.status === 401) {
        console.log("401 Error: Unauthenticated");
        localStorage.removeItem("user");
        //dispatch(setUserMiniProfile({}));
        navigate("/");
      }
      return res;
    }).then((res) => res.json()).then(async (res) => {
      setLoading(false);
      // console.log('projects-res:', res);

      // if request is succesful, alert user that project has been cloned
      if (res.status) {
        setSelectingProjectsModel(true);
        setProjects(res.data.data);

        const data = res.data.data;

        const projects_data  = [];
        const sites_data     = [];
        const scenarios_data = [];

        // Selected record logic
        if (selectedProjects?.length) {
          for (let i = 0; i < selectedProjects?.length; i++) {
            const project   = { ...selectedProjects[i] };

            for (let j = 0; j < project?.sites?.length; j++) {
              const site   = { ...project?.sites[j] };
              site.checked = true;

              sites_data.push(site);

              for (let k = 0; k < site?.scenarios?.length; k++) {
                const scenario   = { ...site?.scenarios[k] };
                scenario.checked = true;

                scenarios_data.push(scenario);
              }
            }
          }
        }

        // Fresh record logic
        for (let i = 0; i < data.length; i++) {
          const project   = { ...data[i] };
          project.checked = false;

          projects_data.push(project);

          for (let j = 0; j < project?.sites?.length; j++) {
            const site   = { ...project?.sites[j] };
            site.checked = false;

            sites_data.push(site);

            for (let k = 0; k < site?.scenarios?.length; k++) {
              const scenario   = { ...site?.scenarios[k] };
              scenario.checked = false;

              scenarios_data.push(scenario);
            }
          }
        }

        //setStartSectingParamsValues(true);

        setProjects(projects_data);
        setSites(sites_data);
        setScenarios(scenarios_data);

        setTotalPrject(res.data.total);
        setLastPage(res.data.last_page);
        setCurrentPage(res.data.current_page);
        setPage(res.data.current_page);
      } else {
        if (res.message === 'Token is invalid.') {
          // localStorage.removeItem("user");
          // dispatch(setUserMiniProfile({}));
        } else {
          swal("Oh no!", res.message, "error");
        }
      }
    }).catch((error) => {
      setLoading(false);
      console.log("Error: ", error);

      swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
    });
  
  }
  const _onSearchProjects = (e) => {
    const keywords = e.target.value;
    setKeywords(keywords);

    // console.log('keywords:', keywords);

    //return false

    if (keywords?.length >= 3) {
      fetch(`${Config.API.URL}/projects/search?keyword=${keywords}&page=1&limit=20`, {
        method: "GET",
        headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
      }).then((res) => {
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
          localStorage.removeItem("user");
          navigate("/");
        }
        return res;
      }).then((res) => res.json()).then(async (res) => {
        // console.log('search-res:', res);
        // if request is succesful, alert user that project has been cloned
        if (res.status) {

          const data = res.data;

          const projects_data  = [];
          const sites_data     = [];
          const scenarios_data = [];

          // Selected record logic
          if (selectedProjects?.length) {
            for (let i = 0; i < selectedProjects?.length; i++) {
              const project   = { ...selectedProjects[i] };

              for (let j = 0; j < project?.sites?.length; j++) {
                const site   = { ...project?.sites[j] };
                site.checked = true;

                sites_data.push(site);

                for (let k = 0; k < site?.scenarios?.length; k++) {
                  const scenario   = { ...site?.scenarios[k] };
                  scenario.checked = true;

                  scenarios_data.push(scenario);
                }
              }
            }
          }

          // Fresh record logic

          for (let i = 0; i < data.length; i++) {
            const project   = { ...data[i] };
            project.checked = false;

            //console.log('project:', project);

            projects_data.push(project);

            for (let j = 0; j < project?.sites?.length; j++) {
              const site   = { ...project?.sites[j] };
              site.checked = false;

              //console.log('site:', site);

              sites_data.push(site);

              for (let k = 0; k < site?.scenarios?.length; k++) {
                const scenario   = { ...site?.scenarios[k] };
                scenario.checked = false;

                scenarios_data.push(scenario);
              }
            }
          }

          setProjects(projects_data);
          setSites(sites_data);
          setScenarios(scenarios_data);

          // setTotalPrject(res.data.total);
          // setLastPage(res.data.last_page);
          // setCurrentPage(res.data.current_page);
          // setPage(res.data.current_page);
        } else {
          if (res.message === 'Token is invalid.') {
            // localStorage.removeItem("user");
            // dispatch(setUserMiniProfile({}));
          } else {
            swal("Oh no!", res.message, "error");
          }
        }
      }).catch((error) => {
        console.log("Error: ", error);

        swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
      });
    } else {
      if (!keywords.length) {
        loadProjects();
      }
    }
  };

  // const _onChangeProject = (e) => {
  //   const data = [ ...projects ];
    
  //   const item     = data[e.target.id];
  //   const selected = selectedProjects.find(x => x.id === item.id);
  //   const status   = !(selected ? true : false);

  //   const selectedProjects_d  = [ ...selectedProjects ];

  //   if (selected) {
  //     const index = selectedProjects_d?.findIndex(x => x.id === item.id);
  //     selectedProjects_d.splice(index, 1);

  //     setSelectedProjects(selectedProjects_d);
  //   } else {
  //     setSelectedProjects([ ...selectedProjects_d, data[e.target.id] ]);
  //   }

  //   const sites_d = [];

  //   for (let i = 0; i < sites.length; i++) {
  //     const site = { ...sites[i] };

  //     if (data[e.target.id].id === site.project_id) {
  //       site.checked = status;
  //       modifySiteScenarioByChangingProject(site, status);
  //     }

  //     sites_d.push(site);
  //   }
  //   setSites(sites_d);
  // };

  // const modifySiteScenarioByChangingProject = (site, status) => {
  //   //console.log('site?.scenarios', site?.scenarios);
  //   if (site?.scenarios?.length) {
  //     const scenarios_d = [ ...scenarios ];

  //     for (let j = 0; j < site?.scenarios?.length; j++) {
  //       const scenario     = { ...site?.scenarios[j] };
  //       scenario.checked   = status;
        
  //       const index        = scenarios_d.findIndex(x => x.id === scenario.id);
  //       scenarios_d[index] = scenario;
  //     }

  //     setScenarios(scenarios_d);
  //   }
  // }

  const _onChangeProject = (e) => {
    const data = [...projects];
  
    const item = data[e.target.id];
    const selected = selectedProjects.find(x => x.id === item.id);
    const status = !(selected ? true : false);
  
    const selectedProjects_d = [...selectedProjects];
    
    const sites_d = [];
    const scenarios_d = [ ...scenarios ];
  
    if (selected) {
      const index = selectedProjects_d?.findIndex(x => x.id === item.id);
      selectedProjects_d.splice(index, 1);
  
      setSelectedProjects(selectedProjects_d);
    } else {
      setSelectedProjects([...selectedProjects_d, data[e.target.id]]);

      // if (selectedProjects?.length) {
          const project = { ...data[e.target.id] };

          for (let j = 0; j < project?.sites?.length; j++) {
            const site   = { ...project?.sites[j] };
            if (!sites.find(x => x.id === site.id)) {
              site.checked = status;
              sites_d.push(site);
            }

            for (let k = 0; k < site?.scenarios?.length; k++) {
              const scenario = { ...site?.scenarios[k] };

              if (site.active_scenario_id === scenario.id) {
                if (!scenarios_d.find(x => x.id === scenario.id)) {
                  scenario.checked = status;
                  scenarios_d.push(scenario);
                }
              }
            }
          }
      //}
    }
  
    for (let i = 0; i < sites.length; i++) {
      const site = { ...sites[i] };
  
      if (data[e.target.id].id === site.project_id) {
        site.checked = status;
        modifySiteScenarioByChangingProject(site, status, scenarios_d);
      }
  
      sites_d.push(site);
    }
    setSites(sites_d);
    setScenarios(scenarios_d);
  };
  
  const modifySiteScenarioByChangingProject = (site, status, scenarios_d) => {
    if (site?.active_scenario_id) {
      const index = scenarios_d.findIndex(x => x.id === site.active_scenario_id);
  
      if (index !== -1) {
        scenarios_d[index] = { ...scenarios_d[index], checked: status };
      }
    }
  }

  const loadSites = () => {
    setSelectingSitesModel(true);
  }
  // const _onChangeSite = (e) => {
  //   const data = [ ...sites ];
  //   const site = data[e.target.id];

  //   if (site.checked === undefined) {
  //     data[e.target.id].checked = false;
  //   } 

  //   const status = !site.checked;
    
  //   data[e.target.id].checked = status;
  //   setSites(data);
    
  //   const scenarios_d = [];

  //   for (let i = 0; i < scenarios.length; i++) {
  //     const scenario = { ...scenarios[i] };

  //     if (data[e.target.id].id === scenario.site_id) {
  //       scenario.checked = status;
  //     }

  //     scenarios_d.push(scenario);
  //   }
  //   setScenarios(scenarios_d);
  // };

  const _onChangeSite = (e) => {
    const data = [...sites];
    const site = data[e.target.id];
  
    if (site.checked === undefined) {
      data[e.target.id].checked = false;
    }
  
    const status = !site.checked;
  
    data[e.target.id].checked = status;
    setSites(data);
  
    const scenarios_d = [...scenarios];
  
    for (let i = 0; i < scenarios_d.length; i++) {
      const scenario = { ...scenarios_d[i] };
  
      if (site.active_scenario_id === scenario.id) {
        scenario.checked = status;
        scenarios_d[i] = scenario;
      }
    }
    setScenarios(scenarios_d);
  };

  const loadScenarios = () => {
    setSelectingScenariosModel(true);
  }
  const _onChangeScenario = (e) => {
    const data = [ ...scenarios ];
    if (data[e.target.id].checked === undefined) {
      data[e.target.id].checked = false;
    } 

    data[e.target.id].checked = !data[e.target.id].checked;
    setScenarios(data);
  };
  return (
    <>
      <div className="grid grid-cols-12  ">
        <div className=" lg:col-span-2">
          <Sidebardata />
        </div>
        <div className=" transition-none col-span-12 lg:col-span-12 mr-3  ">
          <div className=" w-[85%] sm:w-[95%] ml-auto ">
            <Header />
            <Hero />

            { !projects.length || !selectedProjects.length ?
              <div className=" mt-10 bg-white rounded-[8px] p-4 h-[400px]" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button onClick={ loading ? () => console.log('Loading...') : ((projects.length) ? () => setSelectingProjectsModel(true) : loadProjects) } className=" f-f-r text-lg text-primary w-[199px] border border-orange-600 h-[54px] shadow-lg bg-white rounded-[4px]  ">
                  { loading ? 'Loading...' : 
                  <ul className=" inline-flex ">
                    <li className=" mt-1 ">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18 13H13V18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18V13H6C5.73478 13 5.48043 12.8946 5.29289 12.7071C5.10536 12.5196 5 12.2652 5 12C5 11.7348 5.10536 11.4804 5.29289 11.2929C5.48043 11.1054 5.73478 11 6 11H11V6C11 5.73478 11.1054 5.48043 11.2929 5.29289C11.4804 5.10536 11.7348 5 12 5C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6V11H18C18.2652 11 18.5196 11.1054 18.7071 11.2929C18.8946 11.4804 19 11.7348 19 12C19 12.2652 18.8946 12.5196 18.7071 12.7071C18.5196 12.8946 18.2652 13 18 13Z"
                          fill="rgb(237 88 59 / var(--tw-text-opacity))"
                        />
                      </svg>
                    </li>
                    <li className=" ml-3 mt-1 ">Select Project(s)</li>
                  </ul>
                  }
                </button>
              </div>
            :
              <>
                <div className="grid grid-cols-12">
                  <div className=" col-span-12 lg:col-span-4 mt-5 text-center">
                    <h1 style={{ fontSize: 25, fontWeight: 'bold' }}>Project</h1>
                    <ul className=" inline-flex ">
                      { projectColumns ?
                        <li className="ml-3 relative">
                          <EditProjectColumnsDropdown
                            columns={projectColumns}
                            onEditColumns={_onEditProjectColumns}
                          />
                        </li>
                      : null }
                      <li className=" ml-3 ">
                        <button onClick={ loading ? () => console.log('Loading...') : ( (projects.length) ? () => setSelectingProjectsModel(true) : loadProjects ) } className=" f-f-r text-lg text-white w-[169px] h-[44px] shadow-lg bg-primary rounded-[8px]  ">
                          { loading ? 'Loading...' : 
                          <ul className=" inline-flex ">
                            <li className=" mt-1 ">
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18 13H13V18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18V13H6C5.73478 13 5.48043 12.8946 5.29289 12.7071C5.10536 12.5196 5 12.2652 5 12C5 11.7348 5.10536 11.4804 5.29289 11.2929C5.48043 11.1054 5.73478 11 6 11H11V6C11 5.73478 11.1054 5.48043 11.2929 5.29289C11.4804 5.10536 11.7348 5 12 5C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6V11H18C18.2652 11 18.5196 11.1054 18.7071 11.2929C18.8946 11.4804 19 11.7348 19 12C19 12.2652 18.8946 12.5196 18.7071 12.7071C18.5196 12.8946 18.2652 13 18 13Z"
                                  fill="white"
                                />
                              </svg>
                            </li>
                            <li className=" ml-3 mt-1 ">Update</li>
                          </ul>

                          }
                        </button>
                      </li>
                    </ul>
                    <p>{ selectedProjects?.length } Projects Selected</p>
                  </div>
                  <div className=" col-span-12 lg:col-span-4 mt-5 text-center">
                    <h1 style={{ fontSize: 25, fontWeight: 'bold' }}>Site</h1>
                    <ul className=" inline-flex ">
                      { siteColumns ?
                        <li className="ml-3 relative">
                          <EditSiteColumnsDropdown
                            columns={siteColumns}
                            onEditColumns={_onEditSiteColumns}
                          />
                        </li>
                      : null }
                      <li className=" ml-3 ">
                        <button onClick={ loading ? () => console.log('Loading...') : loadSites } className=" f-f-r text-lg text-white w-[169px] h-[44px] shadow-lg bg-primary rounded-[8px]  ">
                          { loading ? 'Loading...' : 
                            <ul className=" inline-flex ">
                              <li className=" mt-1 ">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18 13H13V18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18V13H6C5.73478 13 5.48043 12.8946 5.29289 12.7071C5.10536 12.5196 5 12.2652 5 12C5 11.7348 5.10536 11.4804 5.29289 11.2929C5.48043 11.1054 5.73478 11 6 11H11V6C11 5.73478 11.1054 5.48043 11.2929 5.29289C11.4804 5.10536 11.7348 5 12 5C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6V11H18C18.2652 11 18.5196 11.1054 18.7071 11.2929C18.8946 11.4804 19 11.7348 19 12C19 12.2652 18.8946 12.5196 18.7071 12.7071C18.5196 12.8946 18.2652 13 18 13Z"
                                    fill="white"
                                  />
                                </svg>
                              </li>
                              <li className=" ml-3 mt-1 ">Update</li>
                            </ul>
                          }
                        </button>
                      </li>
                    </ul>
                    <p>{ sites?.filter(x => x.checked)?.length } Sites Selected</p>
                  </div>
                  <div className=" col-span-12 lg:col-span-4 mt-5 text-center">
                    <h1 style={{ fontSize: 25, fontWeight: 'bold' }}>Scenario</h1>
                    <ul className=" inline-flex ">
                      { scenarioColumns ?
                        <li className="ml-3 relative">
                          <EditScenarioColumnsDropdown
                            columns={scenarioColumns}
                            onEditColumns={_onEditScenarioColumns}
                          />
                        </li>
                      : null }
                      <li className=" ml-3 ">
                        <button onClick={ loading ? () => console.log('Loading...') : loadScenarios } className=" f-f-r text-lg text-white w-[169px] h-[44px] shadow-lg bg-primary rounded-[8px]  ">
                          { loading ? 'Loading...' : 
                            <ul className=" inline-flex ">
                              <li className=" mt-1 ">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M18 13H13V18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18V13H6C5.73478 13 5.48043 12.8946 5.29289 12.7071C5.10536 12.5196 5 12.2652 5 12C5 11.7348 5.10536 11.4804 5.29289 11.2929C5.48043 11.1054 5.73478 11 6 11H11V6C11 5.73478 11.1054 5.48043 11.2929 5.29289C11.4804 5.10536 11.7348 5 12 5C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6V11H18C18.2652 11 18.5196 11.1054 18.7071 11.2929C18.8946 11.4804 19 11.7348 19 12C19 12.2652 18.8946 12.5196 18.7071 12.7071C18.5196 12.8946 18.2652 13 18 13Z"
                                    fill="white"
                                  />
                                </svg>
                              </li>
                              <li className=" ml-3 mt-1 ">Update</li>
                            </ul>
                          }
                        </button>
                      </li>
                    </ul>
                    
                    <p >{ scenarios.filter(x => x.checked)?.length } Scenarios Selected</p>
                  </div>
                </div>

                {/* <Tableproject /> */}
                { reportLoading ?
                  <Loading />
                :
                  <div className=" mt-10 bg-white rounded-[8px] p-4">
                    <DataTable
                      table_label="Export Data"
                      header_format={tableHeaderFormat}
                      data={reports}
                      data_format={tableDataFormat}
                      columns_format={false}
                      setPage={setReportsPage}
                      lastPage={reportsLastPage}
                      currentPage={reportsCurrentPage}
                      onCreateItem={false}
                      onDeleteItem={false}
                      onEditItem={false}
                      onSearchData={(keywords) => console.log("Search:", keywords)}
                      utilities={utilities}
                    />
                    <div style={{textAlign: 'center', marginBottom: 20}}>
                      <button onClick={ loading ? () => console.log('Loading...') : downloadTableAsCSV } className=" f-f-r text-lg text-primary w-[299px] border border-orange-600 h-[54px] shadow-lg bg-white rounded-[4px]  ">
                        { loading ? 'Loading...' : 'Download CSV of Selected Data' }
                      </button>
                    </div>
                  </div>
                }
              </>
            }
          </div>
        </div>
      </div>

      <Transition.Root show={selectingProjectsModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setSelectingProjectsModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        {/* <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Contact Information
                        </Dialog.Title> */}
                        <div className="mt-2" style={{ width: "100%" }}>
                          <h1 style={{ fontSize: '1.0rem', textAlign: 'center', fontWeight: 'bold' }}>Select project(s) from the options below:</h1>
                          <div className=" mt-8 ">
                            {/* started */}
                            
                            <input className=" w-full  h-[46px] border border-grey bg-white rounded-[8px] px-4 outline-none " value={keywords} onChange={_onSearchProjects} />
                            <h4 className=" text-black-vlight text-tiny f-f-r mt-2 ">{totalProject} Results</h4>
                            {/* <div className="overflow-y-scroll h-[400px]"> */}
                            <div className="grid grid-cols-12 mt-5">
                              { projects.map((project, i) => {
                                const checked = selectedProjects.find(x => x.id === project.id);
                                return (
                                  <div key={i} className=" col-span-12 lg:col-span-6  text-left">
                                    <ul className="">
                                      
                                        <li key={i}>
                                          <div className="flex items-left mb-4">
                                            <input 
                                              type="checkbox" 
                                              value="" 
                                              onChange={_onChangeProject}
                                              id={i}
                                              checked={ checked ? true : false }
                                              //checked={project.checked === undefined ? false : project.checked }
                                              className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                                            />
                                            <label htmlFor={i} className="ml-4 w-[195px] f-f-r text-lg text-black truncate" style={{ fontSize: 14 }}>{ project.project_name }</label>
                                          </div>
                                        </li>
                                      
                                    </ul>
                                  </div>
                                )
                              }) }

                            </div>

                            {/* ended */}
                          </div>


                          { lastPage > 1 ?
                            <div className=" tabel-home py-5 px-3 w-full ">
                              <ul className=" inline-flex  w-full  justify-end ">
                                <li><h2 className=" inline-flex f-f-m text-tiny  text-black-vdark ">20: Items per page</h2></li>
                                <li className=" ml-5 ">
                                  <h2 className=" inline-flex f-f-m text-tiny  text-black-vdark ">
                                    Page {currentPage} of {lastPage}
                                  </h2>
                                </li>{" "}
                                <li className=" ml-5 ">
                                  {/* inner icon list started */}
                                  <ul className=" inline-flex ">
                                    <li>
                                      <span onClick={decreasePage} className="cursor-pointer">
                                        <svg
                                          width="6"
                                          height="10"
                                          viewBox="0 0 6 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M5.62044 1.10636C6.22943 0.51287 5.2652 -0.426818 4.65621 0.216126L0.190308 4.51891C-0.0634362 4.7662 -0.0634362 5.21131 0.190308 5.4586L4.65621 9.81084C5.2652 10.4043 6.22943 9.46464 5.62044 8.87115L1.66203 5.01348L5.62044 1.10636Z"
                                            fill="#808080"
                                          />
                                        </svg>
                                      </span>
                                    </li>
                                    <li className=" ml-8 ">
                                      <span onClick={increasePage} className="cursor-pointer">
                                        <svg
                                          width="7"
                                          height="10"
                                          viewBox="0 0 7 10"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M0.65983 1.10636C0.0508423 0.51287 1.01507 -0.426818 1.62406 0.216126L6.08996 4.51891C6.34371 4.7662 6.34371 5.21131 6.08996 5.4586L1.62406 9.81084C1.01507 10.4043 0.0508423 9.46464 0.65983 8.87115L4.61825 5.01348L0.65983 1.10636Z"
                                            fill="#808080"
                                          />
                                        </svg>
                                      </span>
                                    </li>
                                  </ul>
                                  {/* inner icon listended */}
                                </li>
                              </ul>
                            </div>
                          : null }



                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    { loading ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={() => loadExportDataReport() }
                      >
                        Update
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setSelectingProjectsModel(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={selectingSitesModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setSelectingSitesModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        {/* <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Contact Information
                        </Dialog.Title> */}
                        <div className="mt-2" style={{ width: "100%" }}>
                          <h1 style={{ fontSize: '1.0rem', textAlign: 'center', fontWeight: 'bold' }}>Select site(s) from the options below:</h1>
                          <div className=" mt-8 ">
                            {/* started */}
                            <h4 className=" text-black-vlight text-tiny f-f-r mt-2 ">{sites.length} Results</h4>

                            <div className="grid grid-cols-12 mt-5">
                              { sites.map((site, i) => (
                                <div key={i} className=" col-span-12 lg:col-span-6  text-left">
                                  <ul className="">
                                    
                                      <li key={i}>
                                        <div className="flex items-left mb-4">
                                          <input 
                                            type="checkbox" 
                                            value="" 
                                            onChange={_onChangeSite}
                                            id={i}
                                            checked={ site.checked === undefined ? false : site.checked }
                                            className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                                          />
                                          <label htmlFor={i} className="ml-4 w-[195px] f-f-r text-lg text-black truncate" style={{ fontSize: 14 }}>{ site.site_name }</label>
                                        </div>
                                      </li>
                                    
                                  </ul>
                                </div>
                              )) }

                            </div>
                            {/* <div className="overflow-y-scroll h-[400px]">
                              <ul className=" mt-6">
                                { sites.map((site, i) => (
                                  <li key={i}>
                                    <div className="flex items-center mb-4">
                                      <input 
                                        type="checkbox" 
                                        value="" 
                                        onChange={_onChangeSite}
                                        id={i}
                                        checked={site.checked === undefined ? false : site.checked }
                                        className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                                      />
                                      <label htmlFor={i} className="ml-4 f-f-r text-lg text-black">{ site.site_name }</label>
                                    </div>
                                  </li>
                                )) }
                              </ul>
                            </div> */}

                            {/* ended */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loading ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={() => loadExportDataReport() }
                      >
                        Update
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setSelectingSitesModel(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={selectingScenariosModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setSelectingScenariosModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        {/* <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Contact Information
                        </Dialog.Title> */}
                        <div className="mt-2" style={{ width: "100%" }}>
                          <h1 style={{ fontSize: '1.0rem', textAlign: 'center', fontWeight: 'bold' }}>Select scenario(s) from the options below:</h1>
                          <div className=" mt-8 ">
                            {/* started */}
                            <h4 className=" text-black-vlight text-tiny f-f-r mt-2 ">{scenarios.length} Results</h4>
                            <div className="grid grid-cols-12 mt-5">
                              { scenarios.map((scenario, i) => (
                                <div key={i} className=" col-span-12 lg:col-span-6  text-left">
                                  <ul className="">
                                    
                                      <li key={i}>
                                        <div className="flex items-left mb-4">
                                          <input 
                                            type="checkbox" 
                                            value="" 
                                            onChange={_onChangeScenario}
                                            id={i}
                                            checked={ scenario.checked === undefined ? false : scenario.checked }
                                            className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                                          />
                                          <label htmlFor={i} className="ml-4 w-[195px] f-f-r text-lg text-black truncate" style={{ fontSize: 14 }}>{ scenario.scenario_name }</label>
                                        </div>
                                      </li>
                                    
                                  </ul>
                                </div>
                              )) }

                            </div>

                            {/* <div className="overflow-y-scroll h-[400px]">
                              <ul className=" mt-6">
                                { scenarios.map((scenario, i) => (
                                  <li key={i}>
                                    <div className="flex items-center mb-4">
                                      <input 
                                        type="checkbox" 
                                        value="" 
                                        onChange={_onChangeScenario}
                                        id={i}
                                        checked={scenario.checked === undefined ? false : scenario.checked }
                                        className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                                      />
                                      <label htmlFor={i} className="ml-4 f-f-r text-lg text-black">{ scenario.scenario_name }</label>
                                    </div>
                                  </li>
                                )) }
                              </ul>
                            </div> */}

                            {/* ended */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loading ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={() => loadExportDataReport() }
                      >
                        Update
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setSelectingScenariosModel(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
