import React from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Config from '../../../utils/Config';
import { useSelector, useDispatch } from 'react-redux';

import SetTable from './SetTable';

import swal from 'sweetalert';

import { setSetbackNotesSetbackAssumptions } from "../../../redux/slices/basisOfDesignSlice";

const staticAssumptions = [
  {
    equipment: "General Equipment",
    setback: "5'",
    assumption:
      "Minimum of a 5' setback around all equipment for site safety",
  },
  {
    equipment: "Solar Container",
    setback: "5'",
    assumption:
      "Setback around Solar Container end doors for personnel access",
  },
  {
    equipment: "PV Array",
    setback: "5'",
    assumption:
      "Minimum setback around PV array for working clearances. Additionally there will be a 13' setback from existing buildings. 14' minimum clear height.",
  },
  {
    equipment: "PV Array Mounting",
    setback: "14'",
    assumption: "Minimum clear height from the ground",
  },
  {
    equipment: "PCC Switchgear",
    setback: "5'",
    assumption: "Setback in front of PCC/Switchgear for working clearances",
  },
  {
    equipment: "HVAC Unit",
    setback: "5'",
    assumption: "Setback in front of HVAC for working clearances",
  },
  {
    equipment: "Propane Generator",
    setback: "5'",
    assumption: "Setback from all other equipment",
  },
  {
    equipment: "Propane Tank",
    setback: "10'",
    assumption:
      "1,000 gallon propane tank requires a 10' setback from all other equipment per NFPA 58",
  },
  {
    equipment: "Perimeter Security Fence",
    setback: "Varies",
    assumption:
      "Fence must remain the minimum setback distance from any piece of equipment. Some equipment will have a larger setback area.",
  },
  {
    equipment: "30' Defensible Space",
    setback: "30'",
    assumption:
      "Minimum setback from the edges of all equipment for defensible space, actual perimeter was expanded to a more rectangular shape to facilitate easement process",
  },
  {
    equipment: "100' Defensible Space",
    setback: "100'",
    assumption:
      "Minimum setback from the edges of all equipment for defensible space, 70' setback from 30' defensible space perimeter",
  },
  {
    equipment: "Bollards",
    setback: "5'",
    assumption:
      "Bollards will be 42 inches apart, and have a 5' clear zone from any piece of equipment.",
  },
  {
    equipment: "First Responder Panel",
    setback: "3'",
    assumption:
      "Clean zone from the first responder panel for first responder safety and working clearances.",
  },
];

export default function Index() {
  const user     = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { site_id, design_id } = useParams();
  
  const { setback_notes_setback_assumptions } = useSelector((state) => state.basisOfDesign);

  const [ isSetTableEditable, setIsSetTableEditable ] = React.useState(false);

  const _onSaveSetbackAssumption = (assumptions) => {
    const payload = { design_id, assumptions: assumptions };

    // console.log("payload:", payload);

    fetch(`${Config.API.URL}/design-basis/setback-notes/setback-assumptions`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` },
      body: JSON.stringify(payload),
    }).then((res) => {
      // if 401 error (unauthenticated user, then log user out)
      if ((res.status) === 401) {
        console.log("401 Error: Unauthenticated");

        localStorage.removeItem('user');
        navigate('/');
      }
      return res;
    }).then((res) => res.json()).then( async (res) => {
      // console.log('res:', res);

      // if request is succesful, alert user that project has been cloned
      if (res.status) {
        dispatch(setSetbackNotesSetbackAssumptions(res.data));
      } else {
        swal("Oh no!", res.message, "error");
      }
    }).catch((error) => {
      console.log('Error: ', error);
      
      swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
    });
  }

  const nextPage = () => {
    if (isSetTableEditable) {
      swal({
        title: "Editable mode detected?",
        text: "When go to the next page, unsave changes will be lost. do you still want go to next page?",
        icon: "warning",
        buttons: ["No", "Next"],
        dangerMode: true,
      }).then((willProcced) => {
        if (willProcced) {
          if (!setback_notes_setback_assumptions.length) {
            _onSaveSetbackAssumption(staticAssumptions);
          }
          navigate(`/basis/shading/analysis/${site_id}/${design_id}`);
        } 
      });
    } else {
      if (!setback_notes_setback_assumptions.length) {
        _onSaveSetbackAssumption(staticAssumptions);
      }
      navigate(`/basis/shading/analysis/${site_id}/${design_id}`);
    }
  }

  return (
    <div className='' >
        <h2 className=' f-f-r text-lg text-black ' >PG&E Basis of Design</h2>
        <h4 className=' f-f-sm text-black text-2xl  sm:text-3xl ' >Setback Assumptions</h4>
        <h2 className=' f-f-m text-black text-lg mt-8 ' >Fill out the Setback Assumptions Table below</h2>

        {/*  table started */}

        <SetTable onEditSetTable={setIsSetTableEditable} onSaveSetbackAssumption={_onSaveSetbackAssumption} />
        {/* table ended */}

      
        {/* adjuest in bottom  */}
        <div className='  ' >

          <div className=' border border-gray-300 mt-20  ' ></div>
          <div className="grid grid-cols-12  gap-4  ">
            <div className="   col-span-3">
              <Link to={`/basis/site/discussion/${site_id}/${design_id}`} >
                <button className="  mt-8 relative border border-primary w-[110px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-primary  ">
                  <ul className=' inline-flex text-center justify-center mt-1 mr-3 ' >
                    <li>
                      <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.25 7.5L11.75 13L17.25 18.5" stroke="#ED583B" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </li>
                    <li className='ml-1' >Back</li>
                  </ul>
                  {/* inner ul ended */}
                </button>
              </Link>
            </div>
            <div className="   col-span-9">
              <div className=' text-end ' >
                <ul className=" inline-flex items-center  ">
                  <Link to={`/basis/shading/analysis/${site_id}/${design_id}`}>
                    <li >
                      <button className=" mt-8  text-center  f-f-r text-lg text-black-darkest  ">Skip</button>
                    </li>
                  
                  </Link>
                  <li className=" ml-6 ">
                    <button onClick={nextPage} className=" mt-8 relative border bg-primary w-[127px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-white  ">Next</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
        {/* adjuest in bottom ended  */}

    </div>
  )
}
