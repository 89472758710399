import React, { useState } from 'react'
import { Menu } from '@headlessui/react'
import { Link } from 'react-router-dom'
import Dilogboxbasic from './Dilogbox'


export default function MyDropdown() {
    const [showModal, setShowModal] = useState(false);
    return (
        <>

            <Menu  >
                <Menu.Button className=' absolute top-[12px] right-0 cursor-pointer ' >  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.99286 7.80488C11.1833 7.80488 12.1357 8.78049 12.1357 10C12.1357 11.2195 11.1833 12.1951 9.99286 12.1951C8.80239 12.1951 7.85001 11.2195 7.85001 10C7.85001 8.78049 8.80239 7.80488 9.99286 7.80488ZM7.85001 2.19512C7.85001 3.41463 8.80239 4.39024 9.99286 4.39024C11.1833 4.39024 12.1357 3.41463 12.1357 2.19512C12.1357 0.97561 11.1833 0 9.99286 0C8.80239 0 7.85001 0.97561 7.85001 2.19512ZM7.85001 17.8049C7.85001 19.0244 8.80239 20 9.99286 20C11.1833 20 12.1357 19.0244 12.1357 17.8049C12.1357 16.5854 11.1833 15.6098 9.99286 15.6098C8.80239 15.6098 7.85001 16.5854 7.85001 17.8049Z" fill="black" />
                </svg>
                </Menu.Button>
                <Menu.Items>
                  
                        <div id="dropdown" className="z-10 px-3 absolute  right-[10px]  w-[160px] bg-white divide-y divide-gray-100 rounded-lg shadow ">
                            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
                                <li className=' profilebrdr2 cursor-pointer ' onClick={() => setShowModal(true)} >
                                    {/* inner ul started */}
                                    <ul className=' inline-flex ' >
                                        <li className=' mt-[4px]  ' >
                                            <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11 2.5C12.8387 2.49389 14.6419 3.00678 16.2021 3.97973C17.7624 4.95267 19.0164 6.34616 19.82 8C18.17 11.37 14.8 13.5 11 13.5C7.2 13.5 3.83 11.37 2.18 8C2.98362 6.34616 4.23763 4.95267 5.79788 3.97973C7.35813 3.00678 9.16126 2.49389 11 2.5ZM11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 5.5C11.663 5.5 12.2989 5.76339 12.7678 6.23223C13.2366 6.70107 13.5 7.33696 13.5 8C13.5 8.66304 13.2366 9.29893 12.7678 9.76777C12.2989 10.2366 11.663 10.5 11 10.5C10.337 10.5 9.70107 10.2366 9.23223 9.76777C8.76339 9.29893 8.5 8.66304 8.5 8C8.5 7.33696 8.76339 6.70107 9.23223 6.23223C9.70107 5.76339 10.337 5.5 11 5.5ZM11 3.5C8.52 3.5 6.5 5.52 6.5 8C6.5 10.48 8.52 12.5 11 12.5C13.48 12.5 15.5 10.48 15.5 8C15.5 5.52 13.48 3.5 11 3.5Z" fill="black" />
                                            </svg>

                                        </li>
                                        <li className=' ml-2 ' >
                                            <h2 className=' text-lg text-black f-f-r   ' >View</h2>
                                        </li>
                                    </ul>
                                    {/* inner ul ended */}
                                </li>
                                <Link to="/basicdesignnew" >
                                <li className=' profilebrdr2 mt-1' >
                                    {/* inner ul started */}
                                    <ul className=' inline-flex ' >
                                        <li  >
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21 14V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H10V5H5V19H19V14H21Z" fill="black"/>
<path d="M21 7H17V3H15V7H11V9H15V13H17V9H21V7Z" fill="black"/>
</svg>

                                        </li>
                                        <li className=' ml-2 ' >
                                            <h2 className=' text-lg text-black f-f-r   ' >Create New</h2>
                                        </li>
                                    </ul>
                                    {/* inner ul ended */}
                                </li>
                                </Link>
                             
                            </ul>

                        </div>
                
                </Menu.Items>

            </Menu>

            <Dilogboxbasic showModal={showModal} setShowModal={setShowModal} />
        </>
    )
}