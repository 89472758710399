import React from "react";

export default function Electrictable({
  electrical_sizing_load_basis_summaries,
}) {
  return (
    <div className="pr-10 pl-10">
      <div className="relative overflow-x-auto mt-8 ">
        <table className=" table-site w-full border border-slate-500 text-left  ">
          <caption className="border border-slate-600 px-3 py-2 f-f-m text-lg text-center  bg-primary text-white ">
            LOAD BASIS SUMMARY
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b  text-sm text-black-dark ">
              <th className="border border-slate-600 px-6 py-3   ">Parameter</th>
              <th className="border border-slate-600 px-6 py-3  ">Primary Loads</th>
              <th className="border border-slate-600 px-6 py-3  ">Parasitic Loads</th>
              <th className="border border-slate-600 px-6 py-3  ">Total</th>
            </tr>
          </thead>
          <tbody>
            {electrical_sizing_load_basis_summaries.map((load_base, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-sm text-black-dark "
                >
                  <td className="border border-slate-600 px-6 py-3">{load_base.parameter}</td>
                  <td className="border border-slate-600 px-6 py-3">{load_base.primary_loads}</td>
                  <td className="border border-slate-600 px-6 py-3">{load_base.parasitic_loads}</td>
                  <td className="border border-slate-600 px-6 py-3">{load_base.total}</td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Average Daily Usage (kWh/day)
                            </td>
                            <td className="px-6 py-3   ">
                            18.97
                            </td>
                            <td className="px-6 py-3   ">
                            1.06
                            </td>
                            <td className="px-6 py-3   ">
                            20.03
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Peak Demand (kW)
                            </td>
                            <td className="px-6 py-3   ">
                            6.41
                            </td>
                            <td className="px-6 py-3   ">
                            0.09
                            </td>
                            <td className="px-6 py-3   ">
                            6.5
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Average (kW)
                            </td>
                            <td className="px-6 py-3   ">
                            0.79
                            </td>
                            <td className="px-6 py-3   ">
                            0.04
                            </td>
                            <td className="px-6 py-3   ">
                            0.83
                            </td>
                    
                        
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
