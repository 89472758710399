import React from "react";
import Logo from "../assets/images/Logo 2.svg";

export default function Loading() {
  return (
    <div className='bg-white ' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, height: 300, flexDirection: 'column' }} >
      <img src={Logo} className="w-[145px] h-auto 2xl:w-[196px] 2xl:h-[29px] mb-3" alt="" />
      <p>Loading...</p>
    </div>
  )
}
