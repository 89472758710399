import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import Config from "../../utils/Config";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import swal from "sweetalert";

export default function Contactform() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.profile);

  //const [value, setValue] = useState();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [company, setCompany] = useState("");
  const [title, setTitle] = useState("");
  const [label, setLabel] = useState("");
  const [type, setType] = useState("");

  const [loading, setLoading] = React.useState(false);

  const submitContact = () => {
    if (firstName.length && lastName.length && email.length) {
      setLoading(true);
      const payload = {
        title: title,
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phoneNumber,
        label: label,
        company: company,
        type: type,
      };

      fetch(`${Config.API.URL}/contacts`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          setLoading(false);

          // if 401 error (unauthenticated user, then log user out)
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");

            localStorage.removeItem("user");
            navigate("/");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          setLoading(false);
          // if request is succesful, alert user that project has been cloned
          if (res.status) {
            setFirstName("");
            setLastName("");
            setEmail("");
            setPhoneNumber("");
            setCompany("");
            setTitle("");
            setLabel("");
            setType("");

            navigate("/contacts");
          } else {
            swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error: ", error);

          swal(
            "Oh no!",
            "An error occured!!! while connecting to server. Please try again",
            "error"
          );
        });
    } else {
      swal("Oh no!", "All fields with asterisk(*) are required", "error");
    }
  };

  return (
    <div>
      <div className="bg-white rounded-[8px] p-4 mt-3 shadow-lg ">
        <h3 className=" text-center text-3xl text-black f-f-sm mt-6 ">
          Create Contact
        </h3>

        <div className="   md:mt-8  w-10/12 sm:w-9/12 ml-auto mr-auto ">
          <div className="grid grid-cols-12   ">
            {/*  col started */}
            <div className=" col-span-12 lg:col-span-3 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                First Name <span className="text-primary">*</span>
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-9 mt-5 ">
              <input
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>

            {/* col ended */}
            {/*  col started */}
            <div className=" col-span-12 lg:col-span-3 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Last Name <span className="text-primary">*</span>
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-9 mt-5 ">
              <input
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>

            {/* col ended */}
            {/*  col started */}
            <div className=" col-span-12 lg:col-span-3 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Email <span className="text-primary">*</span>
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-9 mt-5 ">
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>

            {/* col ended */}
            {/*  col started */}
            <div className=" col-span-12 lg:col-span-3 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Phone Number
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-9 mt-5 ">
              {/* <input  className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />*/}

              {/*               <input
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              /> */}

              <PhoneInput
                value={phoneNumber}
                onChange={setPhoneNumber}
                className="w-full phone-input  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none "
                flags={flags}
                defaultCountry="US"
              />
            </div>

            {/* col ended */}
            {/*  col started */}
            <div className=" col-span-12 lg:col-span-3 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Contact Type
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-9 mt-5 ">
              <select
                onChange={(e) => setType(e.target.value)}
                value={type}
                className="w-full  h-[56px] border cursor-pointer border-grey bg-white-dark rounded-[4px] px-4 outline-none"
              >
                <option value=""></option>
                <option value="Employee">Employee</option>
                <option value="Subcontractor">Subcontractor</option>
                <option value="Client">Client</option>
              </select>
            </div>
            {/* col ended */}
            {/*  col started */}
            <div className=" col-span-12 lg:col-span-3 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Company
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-9 mt-5 ">
              <input
                onChange={(e) => setCompany(e.target.value)}
                value={company}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>

            {/* col ended */}
            {/*  col started */}
            <div className=" col-span-12 lg:col-span-3 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Job Title
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-9 mt-5 ">
              <input
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>

            {/* col ended */}
            {/*  col started */}
            <div className=" col-span-12 lg:col-span-3 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Department/Scope
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-9 mt-5 ">
              <input
                onChange={(e) => setLabel(e.target.value)}
                value={label}
                placeholder="e.g. Civil & Electrical"
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>
            {/* col ended */}
          </div>
          <div className=" mt-10 text-end  ">
            {loading ? (
              <button className=" bg-gray text-lg f-f-r text-gray  w-[127px] h-[48px] rounded-[8px] captilize ">
                Loading...
              </button>
            ) : (
              <button
                onClick={submitContact}
                className=" bg-primary text-lg f-f-r text-white  w-[127px] h-[48px] rounded-[8px] captilize "
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
