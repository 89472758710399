import React from "react";
import { Menu } from "@headlessui/react";
import { Link } from "react-router-dom";

export default function MyDropdown({ columns, onEditColumns }) {
  return (
    <>
      <Menu>
        <Menu.Button className=" cursor-pointer">
          <button className=" f-f-r text-lg text-black w-[169px] h-[44px] shadow-lg bg-white rounded-[4px]  ">
            <ul className=" inline-flex ">
              <li className=" mt-2 ">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.0002 2.66667H9.3335"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.66667 2.66667H2"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 8H8"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.33333 8H2"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.9998 13.3333H10.6665"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 13.3333H2"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.3335 1.33333V4"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.3335 6.66667V9.33333"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.6665 12V14.6667"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </li>
              <li className=" ml-3 mt-1 ">Edit Columns</li>
            </ul>
          </button>
        </Menu.Button>
        <Menu.Items>
          <div
            id="dropdown"
            className="z-10 px-3 absolute w-[300px] bg-white divide-y divide-gray-100 rounded-lg shadow overflow-y-scroll h-[300px]"
          >
            <ul
              className="py-2 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownDefaultButton"
            >
              <li className=" profilebrdr2">
                {/* inner ul started */}
                <ul
                  className=" inline-flex mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "scenario_name",
                          value: !columns.scenario_name,
                        })
                      }
                      checked={columns.scenario_name}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Scenario name
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>
              <li className=" profilebrdr2 mt-1">
                <ul
                  className=" inline-flex cursor-pointer mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "solar_pv_capacity",
                          value: !columns.solar_pv_capacity,
                        })
                      }
                      checked={columns.solar_pv_capacity}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r">
                      Solar PV Capacity
                    </h2>
                  </li>
                </ul>
              </li>
              <li className=" profilebrdr2 mt-1">
                <ul
                  className=" inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "battery_storage_capacity",
                          value: !columns.battery_storage_capacity,
                        })
                      }
                      checked={columns.battery_storage_capacity}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Battery Storage Capacity
                    </h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1">
                <ul
                  className=" inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "battery_inverter_capacity",
                          value: !columns.battery_inverter_capacity,
                        })
                      }
                      checked={columns.battery_inverter_capacity}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Battery Inverter Capacity
                    </h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1">
                <ul
                  className=" inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "pv_azimuth",
                          value: !columns.pv_azimuth,
                        })
                      }
                      checked={columns.pv_azimuth}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">PV Azimuth</h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "pv_tilt",
                          value: !columns.pv_tilt,
                        })
                      }
                      checked={columns.pv_tilt}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">PV Tilt</h2>
                  </li>
                </ul>
              </li>

              {/* Newlins */}
              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "generator_quantity",
                          value: !columns.generator_quantity,
                        })
                      }
                      checked={columns.generator_quantity}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Generator Quantity</h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "generator_size",
                          value: !columns.generator_size,
                        })
                      }
                      checked={columns.generator_size}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Generator Size</h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "model_renewable_fraction",
                          value: !columns.model_renewable_fraction,
                        })
                      }
                      checked={columns.model_renewable_fraction}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Model Renewable Fraction</h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "generator_runtime",
                          value: !columns.generator_runtime,
                        })
                      }
                      checked={columns.generator_runtime}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Generator Runtime</h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "fuel_consumption",
                          value: !columns.fuel_consumption,
                        })
                      }
                      checked={columns.fuel_consumption}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Fuel Consumption</h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "fuel_tank_size",
                          value: !columns.fuel_tank_size,
                        })
                      }
                      checked={columns.fuel_tank_size}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Fuel Tank Size</h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "solar_product",
                          value: !columns.solar_product,
                        })
                      }
                      checked={columns.solar_product}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Solar Product</h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "battery_product",
                          value: !columns.battery_product,
                        })
                      }
                      checked={columns.battery_product}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Battery Product</h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "inverter_product",
                          value: !columns.inverter_product,
                        })
                      }
                      checked={columns.inverter_product}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Inverter Product</h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "mounting_product",
                          value: !columns.mounting_product,
                        })
                      }
                      checked={columns.mounting_product}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Mounting Product</h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "generator_product",
                          value: !columns.generator_product,
                        })
                      }
                      checked={columns.generator_product}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Generator Product</h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "chargecontroller_product",
                          value: !columns.chargecontroller_product,
                        })
                      }
                      checked={columns.chargecontroller_product}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Chargecontroller Product</h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "fence_product",
                          value: !columns.fence_product,
                        })
                      }
                      checked={columns.fence_product}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Fence Product</h2>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </Menu.Items>
      </Menu>
    </>
  );
}
