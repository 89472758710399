import { useEffect, useState } from 'react';
import './App.css';
import DashboardHome from './components/home/index';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Projectview from './components/Projectview/index';
import NewSiteForm from './components/NewSiteForm/index';
import NewProjectForm from './components/NewProjectForm/index';
import Scenarios from './components/Scenarios/index';
import ScenariosForm from './components/ScenariosForm/index';
import Optimization from './components/Optimization/index';
import Library from './components/Library/index';
import Contacts from './components/Contacts/index';
import ViewContact from './components/Contacts/ViewContact';
import ContactsForm from './components/ContactsForm/index';
import Userprofile from './components/Userprofile/index';
import WebsiteHome from './Website/Home/Index';
import AuthHome from './Website/Auth/Index';

// import Login from './Website/Login/Login';
// import Signup from './Website/Signup/Signup';
import Join from './Website/Join/Join';
// import Forgotpassword from './Website/Forgotpassword/Forgotpassword';
// import Emailsent from './Website/ForgotpasswordEmailsent/Emailsent';
// import Resetpassword from './Website/Resetpassword/Resetpassword';

import Deliverables from './components/Deliverables/index';
import BasicDesign from './components/BasicDesign/index';
import BasicDesignNew from './components/BasicDesignNew';

import BasisOfDesignFrame from './components/BasisOfDesign';

import DocumentOverview from './components/BasisOfDesign/DocumentOverview';
import SiteDiscussion from './components/BasisOfDesign/SiteDiscussion';
import Setbackasumption from './components/BasisOfDesign/SetbackAsumption';
import ElectricSizing from './components/BasisOfDesign/ElectricSizing';
import DesignAssupmtion from './components/BasisOfDesign/DesignAssupmtion';
import CivilDesign from './components/BasisOfDesign/CivilDesign';
import TemperatureDesign from './components/BasisOfDesign/TemperatureDesign';
import DesignDeviations from './components/BasisOfDesign/DesignDeviations';
import MonitoringOverview from './components/BasisOfDesign/MonitoringOverview';
import DesignLife from './components/BasisOfDesign/DesignLife';
import ShadingAnalysis from './components/BasisOfDesign/ShadingAnalysis';

import Preview from './components/Preview/Index';
import EditDocuments from './components/EditDoc/Index';

import ExportData from './components/ExportData/index';

import ViewBatteryProducts from './components/Library/ViewBatteryProducts';
import ViewChargecontrollerProducts from './components/Library/ViewChargecontrollerProducts';
import ViewGeneratorProducts from './components/Library/ViewGeneratorProducts';
import ViewInverterProducts from './components/Library/ViewInverterProducts';
import ViewMountingProducts from './components/Library/ViewMountingProducts';
import ViewSolarProducts from './components/Library/ViewSolarProducts';

import ViewUtilities from './components/Library/ViewUtilities';

import PreviewBatterySingleProduct from './components/Library/PreviewBatterySingleProduct';
import PreviewChargecontrollerSingleProduct from './components/Library/PreviewChargecontrollerSingleProduct';
import PreviewGeneratorSingleProduct from './components/Library/PreviewGeneratorSingleProduct';
import PreviewInverterSingleProduct from './components/Library/PreviewInverterSingleProduct';
import PreviewMountingSingleProduct from './components/Library/PreviewMountingSingleProduct';
import PreviewSolarSingleProduct from './components/Library/PreviewSolarSingleProduct';

import { useSelector, useDispatch } from 'react-redux';
import { setUserMiniProfile } from "./redux/slices/userSlice";

function App() {
  const [ loaded, setLoaded ] = useState(false);

  const dispatch = useDispatch();
  
  const user = useSelector((state) => state.user.profile);
  useEffect(() => {
    if (!user.id && localStorage.getItem('user')) {

      const data = JSON.parse(localStorage.getItem('user'));

      if (data.first_name?.length && data?.last_name?.length) {
        dispatch(setUserMiniProfile(data));
      }
    }

    setTimeout(() => setLoaded(true), 500); 
  }, [ user.id, dispatch ]);

  if (!loaded) {
    return <p>Loading...</p>
  }

  return (
    < >
      <Router>
        <Routes>
          {/* Website page routing started */}
          <Route exact path="/" element={<WebsiteHome />} />
          <Route exact path="/auth" element={<AuthHome />} />
          <Route exact path="/join" element={<Join />} />
          {/* 
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/signup" element={<Signup />} /> 
          */}
          {/* <Route exact path="/emailsent" element={<Emailsent />} /> */}
					{/* 
            <Route path="/forgot/password" element={<Forgotpassword />} />
            <Route path="/reset/password" element={<Resetpassword />} /> 
          */}
          {/* Website page routing ended */}

          {/* Dashboard pages rounting started */}
          <Route exact path="/dashboard" element={<DashboardHome />} />

          <Route path="/project/create" element={<NewProjectForm/>} />
          <Route path="/project/view/:id" element={<Projectview/>} />
          <Route path="/site/create/:project_id" element={<NewSiteForm/>} />
          <Route path="/scenarios/view/:site_id" element={<Scenarios/>} />
          <Route path="/scenarios/create/:site_id" element={<ScenariosForm/>} />
          <Route path="/scenarios/optimization/:project_id/:scenario_id" element={<Optimization />} />

          <Route path="/library" element={<Library />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/contact/create" element={<ContactsForm />} />
          <Route path="/contact/view/:contact_id" element={<ViewContact />} />
          <Route path="/profile" element={<Userprofile />} />

          <Route path="/deliverables" element={<Deliverables />} />
          <Route path="/basis/design/:site_id" element={<BasicDesign />} />
          <Route path="/basis/design/create/:project_id/:site_id" element={<BasicDesignNew />} />

          <Route path="/basis" element={<BasisOfDesignFrame />}>
            <Route path="/basis/document/overview/:site_id/:design_id" element={<DocumentOverview />} />
            <Route path="/basis/site/discussion/:site_id/:design_id" element={<SiteDiscussion />} />
            <Route path="/basis/setback/asumption/:site_id/:design_id" element={<Setbackasumption />} />
            <Route path="/basis/electric/sizing/:site_id/:design_id" element={<ElectricSizing />} />
            <Route path="/basis/design/assupmtion/:site_id/:design_id" element={<DesignAssupmtion />} />
            <Route path="/basis/civil/design/:site_id/:design_id" element={<CivilDesign />} />
            <Route path="/basis/temperature/design/:site_id/:design_id" element={<TemperatureDesign />} />
            <Route path="/basis/design/deviations/:site_id/:design_id" element={<DesignDeviations />} />
            <Route path="/basis/monitoring/overview/:site_id/:design_id" element={<MonitoringOverview />} />
            <Route path="/basis/design/life/:site_id/:design_id" element={<DesignLife />} />
            <Route path="/basis/shading/analysis/:site_id/:design_id" element={<ShadingAnalysis />} />
          </Route>

          <Route path="/preview/doc/:site_id/:design_id" element={<Preview />} />
          <Route path="/view/doc/:site_id/:design_id" element={<EditDocuments />} />
          {/* <Route path="/edit/doc/:site_id/:design_id" element={<EditDocuments />} /> */}

          <Route path="/export/data" element={<ExportData />} />
          {/* <Route path="/export/data/:site_id" element={<ExportData />} /> */}

          <Route path="/view/battery/products" element={<ViewBatteryProducts />} />
          <Route path="/view/chargecontroller/products" element={<ViewChargecontrollerProducts />} />
          <Route path="/view/generator/products" element={<ViewGeneratorProducts />} />
          <Route path="/view/inverter/products" element={<ViewInverterProducts />} />
          <Route path="/view/mounting/products" element={<ViewMountingProducts />} />
          <Route path="/view/solar/products" element={<ViewSolarProducts />} />
          
          <Route path="/view/utilities" element={<ViewUtilities />} />
          
          <Route path="/preview/battery/single/product/:product_id" element={<PreviewBatterySingleProduct />} />
          <Route path="/preview/chargecontroller/single/product/:product_id" element={<PreviewChargecontrollerSingleProduct />} />
          <Route path="/preview/generator/single/product/:product_id" element={<PreviewGeneratorSingleProduct />} />
          <Route path="/preview/inverter/single/product/:product_id" element={<PreviewInverterSingleProduct />} />
          <Route path="/preview/mounting/single/product/:product_id" element={<PreviewMountingSingleProduct />} />
          <Route path="/preview/solar/single/product/:product_id" element={<PreviewSolarSingleProduct />} />
          
          {/* Dashboard pages routing ended */}
        </Routes>
      </Router>

    </>
  );
}

export default App;
