import React, { Fragment, useRef, useState, useEffect } from "react";

// import { Dialog, Transition } from "@headlessui/react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Config from "../../utils/Config";

import DropdownBasis from "./DropdownBasis";

import Basicdesign from "../../assets/images/Basic-desigg.svg";
import Basicdesign2 from "../../assets/images/Basic-desigg2.svg";
// import Scopework from "../../assets/images/scope-work.svg";
// import Scopework2 from "../../assets/images/scope-work-2.svg";
// import Bill from "../../assets/images/Bill.svg";
// import Bill2 from "../../assets/images/Bill-2.svg";

import swal from "sweetalert";

export default function Projectsite(props) {
  const { site_id } = useParams();

  const [design_basis, setDesign_basis] = useState(null);

  const user = useSelector((state) => state.user.profile);
  const navigate = useNavigate();

  React.useEffect(() => {
    fetch(`${Config.API.URL}/design-basis/site/${site_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          localStorage.removeItem("user");
          navigate("/");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        // console.log('designs-res:', res);
        if (res.status) {
          let data = res.data;

          setDesign_basis(data);
        } else {
          //swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        console.log("Error: ", error);

        /* swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        ); */
      });
  }, []);

  const _onDeleteBasisDesign = (design) => {
    // console.log('design: ', design);
    // console.log('design_basis:', design_basis);

    swal({
      title: `Are you sure?`,
      text: `You want to delete "${design.title}"? because once deleted, you will not be able to recover this basis of design!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(`${Config.API.URL}/design-basis`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
          body: JSON.stringify({ ids: [{ id: design.id }] }),
        })
          .then((res) => {
            // if 401 error (unauthenticated user, then log user out)
            if (res.status === 401) {
              console.log("401 Error: Unauthenticated");
              localStorage.removeItem("user");
              navigate("/");
            }
            return res;
          })
          .then((res) => res.json())
          .then(async (res) => {
            //console.log("delete-res:", res);

            // if request is succesful, alert user that project has been cloned
            if (res.status) {
              swal(
                `The "${design.title}" basis of design has been successfully deleted!`,
                { icon: "success" }
              );
              let data = { ...design_basis };
              let designs = [...data.design_basis];
              let index = designs.findIndex((x) => x.id === design.id);

              designs.splice(index, 1);

              data.design_basis = designs;

              setDesign_basis(data);
            } else {
              swal("Oh no!", res.message, "error");
            }
          })
          .catch((error) => {
            console.log("Error: ", error);

            swal(
              "Oh no!",
              "An error occured!!! while connecting to server. Please try again",
              "error"
            );
          });
      }
    });
  };

  return (
    <>
      <div className=" w-11/12 lg:w-full ml-auto mr-auto ">
        <div className=" mt-10 ">
          <ul className=" inline-flex ">
            <li>
              <h3 className=" text-black text-2xl f-f-r ">Deliverables</h3>
            </li>
            <li className=" ml-2 mt-1">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.99988 16.5001C13.142 16.5001 16.4999 13.1422 16.4999 9.00006C16.4999 4.85793 13.142 1.50006 8.99988 1.50006C4.85774 1.50006 1.49988 4.85793 1.49988 9.00006C1.49988 13.1422 4.85774 16.5001 8.99988 16.5001Z"
                  stroke="#D0D1D2"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 6.00006V9.00006"
                  stroke="#D0D1D2"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle cx="8.99988" cy="12" r="0.75" fill="#D0D1D2" />
              </svg>
            </li>
          </ul>

          <div className="grid grid-cols-12 mt-3 gap-4 xl:gap-2 pb-5">
            <div
              className="h-[50px] col-span-12 sm:col-span-4 xl:col-span-3 relative bg-white rounded-[10px] shadow-lg flex items-center justify-between px-4 cursor-pointer"
              onClick={() => document.getElementById("dropdown-basis").click()}
            >
              <button className="text-primary f-f-r text-lg">
                + Basis of Design
              </button>
              <div className="flex justify-center relative">
                <DropdownBasis
                  site_id={site_id}
                  design_basis={design_basis}
                  project_id={props?.site?.project_id}
                  onDeleteBasisDesign={_onDeleteBasisDesign}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
