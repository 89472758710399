import React, { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

import { Link } from "react-router-dom";

import Sidebardata from "../home/Sideebar";
import Header from "../home/header";
import Hero from "./HeroViewProduct";
import Loading from "../../Website/Loading";
import UniversalDataTable from "../Contacts/UniversalDataTable";
import Config from "../../utils/Config";

import { useNavigate } from "react-router-dom";

import swal from "sweetalert";

import { useSelector } from "react-redux";

//import LoadingOverlay from 'react-loading-overlay-ts';

export default function ViewSolarArray() {
  const [ contacts, setContacts ] = React.useState([]);
  const [ page, setPage ] = React.useState(1);
  const [ lastPage, setLastPage ] = React.useState(1);
  const [ currentPage, setCurrentPage ] = React.useState(1);

  const [ contact, setContact ] = React.useState(null);

  const [ solar_product_name, set_solar_product_name ] = React.useState("S48MT");
  const [ solar_product_manufacturer, set_solar_product_manufacturer ] = React.useState("BoxPower");
  const [ solar_product_part_number, set_solar_product_part_number ] = React.useState("S48MT");
  const [ solar_product_certifications, set_solar_product_certifications ] = React.useState("UL 1703");

  // const [ solar_product_quantity, set_solar_product_quantity ] = React.useState(1);
  // const [ solar_product_label, set_solar_product_label ] = React.useState("S48MT");
  const [ solar_product_unit_capacity, set_solar_product_unit_capacity ] = React.useState(25.44);
  // const [ solar_product_total_capacity, set_solar_product_total_capacity ] = React.useState(25.44);
  const [ solar_product_unit_cost, set_solar_product_unit_cost ] = React.useState(29360);
  const [ solar_product_notes, set_solar_product_notes ] = React.useState("48 panel BoxPower SolarContainer");
  const [ solar_product_model, set_solar_product_model ] = React.useState("S48MT");
  const [ solar_product_tilt, set_solar_product_tilt ] = React.useState(30);
  const [ solar_product_azimuth, set_solar_product_azimuth ] = React.useState(180);
  const [ solar_product_string_length, set_solar_product_string_length ] = React.useState(8);
  const [ solar_product_string_quantity, set_solar_product_string_quantity ] = React.useState(6);
  const [ solar_product_pmax, set_solar_product_pmax ] = React.useState(25440);
  const [ solar_product_vmp, set_solar_product_vmp ] = React.useState(325.6);
  const [ solar_product_imp, set_solar_product_imp ] = React.useState(64.92);
  const [ solar_product_voc, set_solar_product_voc ] = React.useState(389.6);
  const [ solar_product_isc, set_solar_product_isc ] = React.useState(68.88);
  const [ solar_product_voc_min_temp, set_solar_product_voc_min_temp ] = React.useState(368);
  const [ solar_product_isc_min_temp, set_solar_product_isc_min_temp ] = React.useState(55.56);

  const [ editContactModel, setEditContactModel ] = React.useState(false);
  const [ viewContactModel, setViewContactModel ] = React.useState(false);
  const [ addContactModel, setAddContactModel ] = React.useState(false);

  const [ loading, setLoading ] = React.useState(false);

  const [ contactLoading, setContactLoading ] = React.useState(false);

  const cancelButtonRef = React.useRef(null);
  const navigate        = useNavigate();

  const user            = useSelector((state) => state.user.profile);

  // const [isActive, setActive] = React.useState(true)
  // const handleButtonClick = React.useCallback(() => {
  //   setActive(value => !value);
  // }, [])

  React.useEffect(() => {
    loadProducts();
  }, [user, page]);

  const loadProducts = () => {
    setContactLoading(true);
    fetch(`${Config.API.URL}/products/solar-products?page=${page}&sort_direction=ASC`, { //sort_by=first_name&
      method: "GET",
      headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
    }).then((res) => {
      setContactLoading(false);
      // if 401 error (unauthenticated user, then log user out)
      if (res.status === 401) {
        console.log("401 Error: Unauthenticated");

        localStorage.removeItem("user");
        navigate("/");
      }
      return res;
    }).then((res) => res.json()).then(async (res) => {
      // console.log('res', res);
      setContactLoading(false);
      if (res.status) {
        setContacts(res.data.data);
        setLastPage(res.data.last_page);
        setCurrentPage(res.data.current_page);
      } else {
        swal("Oh no!", res.message, "error");
      }
    }).catch((error) => {
      setContactLoading(false);
      console.log("Error: ", error);

      swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
    });
  };

  const _onDeleteProduct = (product) => {
    // console.log('product', product);
    // return false;
    swal({
      title: `Are you sure?`,
      text: `You want to delete "${product.solar_product_name}"? because once deleted, you will not be able to recover this product!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(`${Config.API.URL}/products/solar-products/${product.id}`, {
          method: "DELETE",
          headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
        })
          .then((res) => {
            // if 401 error (unauthenticated user, then log user out)
            if (res.status === 401) {
              console.log("401 Error: Unauthenticated");
              localStorage.removeItem("user");
              navigate("/");
            }
            return res;
          })
          .then((res) => res.json())
          .then(async (res) => {
            // if request is succesful, alert user that project has been cloned
            if (res.status) {
              let index = contacts.findIndex((x) => x.id === product.id);
              let data  = [ ...contacts ];

              data.splice(index, 1);
              setContacts(data);

              swal( `The "${product.solar_product_name}" product has been successfully deleted!`, { icon: "success" } );
            } else {
              swal("Oh no!", res.message, "error");
            }
          })
          .catch((error) => {
            console.log("Error: ", error);

            swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
          });
      }
    });
  };
  
  const _onEditProduct = (product) => {
    setContact(product);

    // console.log('product:', product);

    setEditContactModel(true);

    set_solar_product_name(product.solar_product_name ? product.solar_product_name : "");
    set_solar_product_manufacturer(product.solar_product_manufacturer ? product.solar_product_manufacturer : "");
    set_solar_product_part_number(product.solar_product_part_number ? product.solar_product_part_number : "");
    set_solar_product_certifications(product.solar_product_certifications ? product.solar_product_certifications : "");

    set_solar_product_unit_capacity(product.solar_product_unit_capacity ? product.solar_product_unit_capacity : "");
    set_solar_product_unit_cost(product.solar_product_unit_cost ? product.solar_product_unit_cost : "");
    set_solar_product_notes(product.solar_product_notes ? product.solar_product_notes : "");
    set_solar_product_model(product.solar_product_model ? product.solar_product_model : "");
    set_solar_product_tilt(product.solar_product_tilt ? product.solar_product_tilt : "");
    set_solar_product_azimuth(product.solar_product_azimuth ? product.solar_product_azimuth : "");
    set_solar_product_string_length(product.solar_product_string_length ? product.solar_product_string_length : "");
    set_solar_product_string_quantity(product.solar_product_string_quantity ? product.solar_product_string_quantity : "");
    set_solar_product_pmax(product.solar_product_pmax ? product.solar_product_pmax : "");
    set_solar_product_vmp(product.solar_product_vmp ? product.solar_product_vmp : "");
    set_solar_product_imp(product.solar_product_imp ? product.solar_product_imp : "");
    set_solar_product_voc(product.solar_product_voc ? product.solar_product_voc : "");
    set_solar_product_isc(product.solar_product_isc ? product.solar_product_isc : "");
    set_solar_product_voc_min_temp(product.solar_product_voc_min_temp ? product.solar_product_voc_min_temp : "");
    set_solar_product_isc_min_temp(product.solar_product_isc_min_temp ? product.solar_product_isc_min_temp : "");
  };
  const _onViewProduct = (product) => {
    // setContact(product);
    
    navigate(`/preview/solar/single/product/${product.id}`);
    // console.log('product:', product);

    // setViewContactModel(true);

    // set_solar_product_name(product.solar_product_name ? product.solar_product_name : "");
    // set_solar_product_manufacturer(product.solar_product_manufacturer ? product.solar_product_manufacturer : "");
    // set_solar_product_part_number(product.solar_product_part_number ? product.solar_product_part_number : "");
    // set_solar_product_certifications(product.solar_product_certifications ? product.solar_product_certifications : "");

    // set_solar_product_unit_capacity(product.solar_product_unit_capacity ? product.solar_product_unit_capacity : "");
    // set_solar_product_unit_cost(product.solar_product_unit_cost ? product.solar_product_unit_cost : "");
    // set_solar_product_notes(product.solar_product_notes ? product.solar_product_notes : "");
    // set_solar_product_model(product.solar_product_model ? product.solar_product_model : "");
    // set_solar_product_tilt(product.solar_product_tilt ? product.solar_product_tilt : "");
    // set_solar_product_azimuth(product.solar_product_azimuth ? product.solar_product_azimuth : "");
    // set_solar_product_string_length(product.solar_product_string_length ? product.solar_product_string_length : "");
    // set_solar_product_string_quantity(product.solar_product_string_quantity ? product.solar_product_string_quantity : "");
    // set_solar_product_pmax(product.solar_product_pmax ? product.solar_product_pmax : "");
    // set_solar_product_vmp(product.solar_product_vmp ? product.solar_product_vmp : "");
    // set_solar_product_imp(product.solar_product_imp ? product.solar_product_imp : "");
    // set_solar_product_voc(product.solar_product_voc ? product.solar_product_voc : "");
    // set_solar_product_isc(product.solar_product_isc ? product.solar_product_isc : "");
    // set_solar_product_voc_min_temp(product.solar_product_voc_min_temp ? product.solar_product_voc_min_temp : "");
    // set_solar_product_isc_min_temp(product.solar_product_isc_min_temp ? product.solar_product_isc_min_temp : "");
  };
  const submitProduct = () => {
    if (solar_product_name.length && solar_product_manufacturer.length && solar_product_part_number.length && solar_product_certifications.length) {
      setLoading(true);
      const payload = {
        solar_product_name,
        solar_product_manufacturer,
        solar_product_part_number,
        solar_product_certifications,

        solar_product_unit_capacity,
        solar_product_unit_cost,
        solar_product_notes,
        solar_product_model,
        solar_product_tilt,
        solar_product_azimuth,
        solar_product_string_length,
        solar_product_string_quantity,
        solar_product_pmax,
        solar_product_vmp,
        solar_product_imp,
        solar_product_voc,
        solar_product_isc,
        solar_product_voc_min_temp,
        solar_product_isc_min_temp
      };

      fetch(`${Config.API.URL}/products/solar-products/${contact?.id}`, {
        method: "PATCH",
        headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          setLoading(false);

          // if 401 error (unauthenticated user, then log user out)
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");
            localStorage.removeItem("user");
            navigate("/");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          setLoading(false);

          // if request is succesful, alert user that project has been cloned
          if (res.status) {
            setEditContactModel(false);

            let index   = contacts.findIndex((x) => x.id === contact.id);
            let data    = [ ...contacts ];

            data[index] = res.data;

            setContacts(data);
          } else {
            swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          setLoading(false);

          console.log("Error: ", error);

          swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
        });
    } else {
      swal("Oh no!", "All fields with asterisk(*) are required", "error");
    }
  };

  const _onCreateProduct = () => {
    setAddContactModel(true);

    set_solar_product_name("");
    set_solar_product_manufacturer("");
    set_solar_product_part_number("");
    set_solar_product_certifications("");

    set_solar_product_unit_capacity("");
    set_solar_product_unit_cost("");
    set_solar_product_notes("");
    set_solar_product_model("");
    set_solar_product_tilt("");
    set_solar_product_azimuth("");
    set_solar_product_string_length("");
    set_solar_product_string_quantity("");
    set_solar_product_pmax("");
    set_solar_product_vmp("");
    set_solar_product_imp("");
    set_solar_product_voc("");
    set_solar_product_isc("");
    set_solar_product_voc_min_temp("");
    set_solar_product_isc_min_temp("");
  };

  const createProduct = () => {
    if (solar_product_name.length && solar_product_manufacturer.length && solar_product_part_number.length && solar_product_certifications.length) {
      setLoading(true);
      const payload = {
        solar_product_name,
        solar_product_manufacturer,
        solar_product_part_number,
        solar_product_certifications,

        solar_product_unit_capacity,
        solar_product_unit_cost,
        solar_product_notes,
        solar_product_model,
        solar_product_tilt,
        solar_product_azimuth,
        solar_product_string_length,
        solar_product_string_quantity,
        solar_product_pmax,
        solar_product_vmp,
        solar_product_imp,
        solar_product_voc,
        solar_product_isc,
        solar_product_voc_min_temp,
        solar_product_isc_min_temp
      };

      fetch(`${Config.API.URL}/products/solar-products`, {
        method: "POST",
        headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          setLoading(false);

          // if 401 error (unauthenticated user, then log user out)
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");
            localStorage.removeItem("user");
            navigate("/");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          setLoading(false);

          // if request is succesful, alert user that project has been cloned
          if (res.status) {
            setAddContactModel(false);

            //let index   = contacts.findIndex((x) => x.id === contact.id);
            let data    = [ res.data, ...contacts ];

            //data[index] = res.data;

            setContacts(data);
          } else {
            swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          setLoading(false);

          console.log("Error: ", error);

          swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
        });
    } else {
      swal("Oh no!", "All fields with asterisk(*) are required", "error");
    }
  };

  const _onSearchContacts = (keywords) => {
    if (keywords.length >= 3) {
      fetch(`${Config.API.URL}/contacts/search?keyword=${keywords}&page=1&limit=10`, {
        method: "GET",
        headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
      }).then((res) => {
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
          localStorage.removeItem("user");
          navigate("/");
        }
        return res;
      }).then((res) => res.json()).then(async (res) => {
        if (res.status) {
          const result = res.data;
          result.data ? setContacts(result.data) : setContacts(result);
          setLastPage(result.last_page);
          setCurrentPage(result.current_page);
        } else {
          swal("Oh no!", res.message, "error");
        }
      }).catch((error) => {
        console.log("Error: ", error);

        swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error" );
      });
    } else {
      if (!keywords.length) {
        loadProducts();
      }
    }
  };
  return (
    <>
      {/* <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
        <LoadingOverlay
          active={isActive}
          spinner
          text='Loading your content...'
        >
          <div style={{ height: '100vh', position: 'relative' }}>
          </div>
        </LoadingOverlay>
      </div> */}
      <div className="grid grid-cols-12  ">
        <div className=" lg:col-span-2">
          <Sidebardata />
        </div>
        <div className=" transition-none col-span-12 lg:col-span-12 mr-3  ">
          <div className=" w-[85%] sm:w-[95%] ml-auto ">
            <Header />
            <Hero productsTitle="Solar Products" />
            { contactLoading ?
              <Loading />
            :
              <div className=" mt-10 bg-white rounded-[8px] p-4">
                <UniversalDataTable
                  table_label="Solar Products"
                  header_format={[ 
                    { column: "solar_product_name", label: "Product Name" }, 
                    { column: "solar_product_manufacturer", label: "Product Manufacturer" }, 
                    { column: "solar_product_part_number", label: "Product Part Number" }, 
                    { column: "solar_product_certifications", label: "Product Certifications" }, 
                  ]}
                  data={contacts}
                  data_format={[
                    { column: "solar_product_name", keys: ["solar_product_name"] }, 
                    { column: "solar_product_manufacturer", keys: ["solar_product_manufacturer"] }, 
                    { column: "solar_product_part_number", keys: ["solar_product_part_number"] }, 
                    { column: "solar_product_certifications", keys: ["solar_product_certifications"], button: true }, 
                  ]}
                  columns_format={false}
                  setPage={setPage}
                  lastPage={lastPage}
                  currentPage={currentPage}
                  onCreateItem={_onCreateProduct}
                  onViewItem={_onViewProduct }
                  onDeleteItem={_onDeleteProduct}
                  onEditItem={_onEditProduct}
                  onSearchData={_onSearchContacts}
                />
              </div>
            }
          </div>
        </div>
      </div>

      <Transition.Root show={editContactModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditContactModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Product Information
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Name{" "}
                                <span className="text-primary">*</span>
                              </label>
                              
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_name(e.target.value)}
                                  value={solar_product_name}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Manufacturer
                                <span className="text-primary">*</span>
                              </label>
        
                              <div className="mt-2">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_manufacturer(e.target.value)}
                                  value={solar_product_manufacturer}
                                  rows="4"
                                  className="pr-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Part Number
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_part_number(e.target.value)}
                                  value={solar_product_part_number}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Certifications
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_certifications(e.target.value)}
                                  value={solar_product_certifications}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product unit capacity
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_unit_capacity(e.target.value)}
                                  value={solar_product_unit_capacity}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Unit Cost
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_unit_cost(e.target.value)}
                                  value={solar_product_unit_cost}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Notes
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_notes(e.target.value)}
                                  value={solar_product_notes}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Model
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_model(e.target.value)}
                                  value={solar_product_model}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Tilt
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_tilt(e.target.value)}
                                  value={solar_product_tilt}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Azimuth
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_azimuth(e.target.value)}
                                  value={solar_product_azimuth}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product String Length
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_string_length(e.target.value)}
                                  value={solar_product_string_length}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product String Quantity
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_string_quantity(e.target.value)}
                                  value={solar_product_string_quantity}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product PMAX
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_pmax(e.target.value)}
                                  value={solar_product_pmax}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product VMP
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_vmp(e.target.value)}
                                  value={solar_product_vmp}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product IMP
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_imp(e.target.value)}
                                  value={solar_product_imp}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product VOC
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_voc(e.target.value)}
                                  value={solar_product_voc}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product ISC
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_isc(e.target.value)}
                                  value={solar_product_isc}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product VOC min temp
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_voc_min_temp(e.target.value)}
                                  value={solar_product_voc_min_temp}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product ISC min temp
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_isc_min_temp(e.target.value)}
                                  value={solar_product_isc_min_temp}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loading ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={submitProduct}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditContactModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={viewContactModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setViewContactModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          View Product Information
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Name{" "}
                                <span className="text-primary">*</span>
                              </label>
                              
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_name(e.target.value)}
                                  value={solar_product_name}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Manufacturer
                                <span className="text-primary">*</span>
                              </label>
        
                              <div className="mt-2">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_manufacturer(e.target.value)}
                                  value={solar_product_manufacturer}
                                  rows="4"
                                  className="pr-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Part Number
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_part_number(e.target.value)}
                                  value={solar_product_part_number}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Certifications
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_certifications(e.target.value)}
                                  value={solar_product_certifications}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product unit capacity
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_unit_capacity(e.target.value)}
                                  value={solar_product_unit_capacity}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Unit Cost
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_unit_cost(e.target.value)}
                                  value={solar_product_unit_cost}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Notes
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_notes(e.target.value)}
                                  value={solar_product_notes}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Model
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_model(e.target.value)}
                                  value={solar_product_model}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Tilt
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_tilt(e.target.value)}
                                  value={solar_product_tilt}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Azimuth
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_azimuth(e.target.value)}
                                  value={solar_product_azimuth}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product String Length
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_string_length(e.target.value)}
                                  value={solar_product_string_length}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product String Quantity
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_string_quantity(e.target.value)}
                                  value={solar_product_string_quantity}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product PMAX
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_pmax(e.target.value)}
                                  value={solar_product_pmax}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product VMP
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_vmp(e.target.value)}
                                  value={solar_product_vmp}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product IMP
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_imp(e.target.value)}
                                  value={solar_product_imp}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product VOC
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_voc(e.target.value)}
                                  value={solar_product_voc}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product ISC
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_isc(e.target.value)}
                                  value={solar_product_isc}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product VOC min temp
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_voc_min_temp(e.target.value)}
                                  value={solar_product_voc_min_temp}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product ISC min temp
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  readOnly
                                  type="text"
                                  onChange={(e) => set_solar_product_isc_min_temp(e.target.value)}
                                  value={solar_product_isc_min_temp}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setViewContactModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={addContactModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setAddContactModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Add Product Information
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Name{" "}
                                <span className="text-primary">*</span>
                              </label>
                              
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_name(e.target.value)}
                                  value={solar_product_name}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Manufacturer
                                <span className="text-primary">*</span>
                              </label>
        
                              <div className="mt-2">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_manufacturer(e.target.value)}
                                  value={solar_product_manufacturer}
                                  rows="4"
                                  className="pr-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Part Number
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_part_number(e.target.value)}
                                  value={solar_product_part_number}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Certifications
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_certifications(e.target.value)}
                                  value={solar_product_certifications}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product unit capacity
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_unit_capacity(e.target.value)}
                                  value={solar_product_unit_capacity}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Unit Cost
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_unit_cost(e.target.value)}
                                  value={solar_product_unit_cost}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Notes
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_notes(e.target.value)}
                                  value={solar_product_notes}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Model
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_model(e.target.value)}
                                  value={solar_product_model}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Tilt
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_tilt(e.target.value)}
                                  value={solar_product_tilt}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Azimuth
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_azimuth(e.target.value)}
                                  value={solar_product_azimuth}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product String Length
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_string_length(e.target.value)}
                                  value={solar_product_string_length}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product String Quantity
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_string_quantity(e.target.value)}
                                  value={solar_product_string_quantity}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product PMAX
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_pmax(e.target.value)}
                                  value={solar_product_pmax}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product VMP
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_vmp(e.target.value)}
                                  value={solar_product_vmp}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product IMP
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_imp(e.target.value)}
                                  value={solar_product_imp}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product VOC
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_voc(e.target.value)}
                                  value={solar_product_voc}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product ISC
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_isc(e.target.value)}
                                  value={solar_product_isc}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product VOC min temp
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_voc_min_temp(e.target.value)}
                                  value={solar_product_voc_min_temp}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product ISC min temp
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_solar_product_isc_min_temp(e.target.value)}
                                  value={solar_product_isc_min_temp}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loading ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={createProduct}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setAddContactModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
