import React from 'react';

import { Link, useParams, useNavigate } from 'react-router-dom';
import Config from '../../../utils/Config';
import { useSelector, useDispatch  } from 'react-redux';

import DesignTable from './DesignTable';

import { setDesignLife } from "../../../redux/slices/basisOfDesignSlice";

import swal from 'sweetalert';

const statusComponents = [
  {
    component: "Ampliphi 3.8kWh",
    supplier: "Simpliphi",
    system: "Battery Bank",
    warranty: "10Year",
  },
  {
    component: "CS3W-440MB-AG",
    supplier: "Canadian Solar",
    system: "Solar Array",
    warranty: "20Years",
  },
  {
    component: "Conext MPPT",
    supplier: "Schneider",
    system: "Charge Controller",
    warranty: "5Year",
  },
  {
    component: "XW Pro 6848NA",
    supplier: "Schneider",
    system: "Inverter",
    warranty: "5Years",
  },
  {
    component: "Ampliphi 3.8kWh",
    supplier: "Simpliphi",
    system: "Battery Bank",
    warranty: "10Year",
  },
  {
    component: "Stellar Edge",
    supplier: "New Sun Road",
    system: "Monitoring &Control",
    warranty: "2Years",
  },
  {
    component: "SolarContainer",
    supplier: "BoxPower",
    system: "Container",
    warranty: "10Years",
  },
  {
    component: "AIE-G 30",
    supplier: "AIE",
    system: "Generator",
    warranty: "2Year",
  },
];

export default function Index() {
  const user     = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [ isDesignTableEditable, setIsDesignTableEditable ] = React.useState(false);

  const { design_life } = useSelector((state) => state.basisOfDesign);
  const { site_id, design_id } = useParams();

  const _onSaveDesignLife = (components) => {
    const payload = { design_id, components: components };
    // console.log("payload:", payload);

    fetch(`${Config.API.URL}/design-basis/design-life`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` },
      body: JSON.stringify(payload),
    }).then((res) => {
      // if 401 error (unauthenticated user, then log user out)
      if ((res.status) === 401) {
        console.log("401 Error: Unauthenticated");

        localStorage.removeItem('user');
        navigate('/');
      }
      return res;
    }).then((res) => res.json()).then( async (res) => {
      // console.log('res:', res);

      // if request is succesful, alert user that project has been cloned
      if (res.status) {
        dispatch(setDesignLife(res.data));
      } else {
        swal("Oh no!", res.message, "error");
      }
    }).catch((error) => {
      console.log('Error: ', error);
      
      swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
    });
  }
  const nextPage = () => {
    if (isDesignTableEditable) {
      swal({
        title: "Editable mode detected?",
        text: "When go to the preview page, unsave changes will be lost. do you still want go to preview page?",
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willProcced) => {
        if (willProcced) {
          if (!design_life.length) {
            _onSaveDesignLife(statusComponents);
          }
          navigate(`/preview/doc/${site_id}/${design_id}`);
        } 
      });
    } else {
      if (!design_life.length) {
        _onSaveDesignLife(statusComponents);
      }
      navigate(`/preview/doc/${site_id}/${design_id}`);
    }
  }
  return (
    <div className='' >
        <h2 className=' f-f-r text-lg text-black ' >PG&E Basis of Design</h2>
        <h4 className=' f-f-sm text-black text-2xl  sm:text-3xl ' >Design Life</h4>
        <h2 className=' f-f-m text-black text-lg mt-4 ' >Fill out the Design Life Table below</h2>

   {/*  table started */}

    <DesignTable onEditDesignTable={setIsDesignTableEditable} onSaveDesignLife={_onSaveDesignLife} />

<div className='  ' >

        

<div className=' border border-gray-300 mt-20  ' ></div>
<div className="grid grid-cols-12  gap-4  ">
<div className="   col-span-3">
    <Link to={`/basis/monitoring/overview/${site_id}/${design_id}`} >
   
<button className="  mt-8 relative border border-primary w-[110px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-primary  ">
       {/* inner ul started */}
        <ul className=' inline-flex text-center justify-center mt-1 mr-3 ' >
            <li>
                        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.25 7.5L11.75 13L17.25 18.5" stroke="#ED583B" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
            </li>
            <li className='ml-1' >
    Back
    </li>
        </ul>
        {/* inner ul ended */}
       </button>
       </Link>
</div>
<div className="   col-span-9">
<div className=' text-end ' >
<ul className=" inline-flex items-center  ">

<Link to={`/preview/doc/${site_id}/${design_id}`}>
<li >
<button className=" mt-8  text-center  f-f-r text-lg text-black-darkest  ">
Skip</button>
  </li>
  </Link>
<li className=" ml-6 ">
<button onClick={nextPage} className=" mt-8 relative border bg-primary w-[127px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-white  ">Preview</button></li>

</ul>
</div>
</div>
</div>

</div>
{/* adjuest in bottom ended  */}

    </div>
  )
}
