import React from "react";

export default function Moniteringtable({ monitoring_overview }) {
  return (
    <div className="pr-10 pl-10">
      <div className="relative overflow-x-auto mt-8 ">
        <table className=" table-site w-full border border-slate-500 text-left  ">
          <caption className="border border-slate-600 px-3 py-2 f-f-m text-lg text-center  bg-primary text-white ">
            Monitoring & Controls Load Profile
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b  text-sm text-black-dark ">
              <th className="border border-slate-600 px-6 py-3">Component</th>
              <th className="border border-slate-600 px-6 py-3">Power Consumption (W)</th>
              <th className="border border-slate-600 px-6 py-3">Unit Qty</th>
            </tr>
          </thead>
          <tbody>
            {monitoring_overview.map((component, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-sm text-black-dark "
                >
                  <td className="border border-slate-600 px-6 py-3">{component.component}</td>
                  <td className="border border-slate-600 px-6 py-3">{component.power_consumption}</td>
                  <td className="border border-slate-600 px-6 py-3">{component.unit_qty}</td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Stellar Edge
                            </td>
                            <td className="px-6 py-3   ">
                            5
                            </td>
                            <td className="px-6 py-3   ">
                            2
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            SEL-751 Protection Relay
                            </td>
                            <td className="px-6 py-3   ">
                            25
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            PCC Meter / Acuvim IIR
                            </td>
                            <td className="px-6 py-3   ">
                            5
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Schneider Contactor Latch Block
                            </td>
                            <td className="px-6 py-3   ">
                            30
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Combustible Gas Sensor
                            </td>
                            <td className="px-6 py-3   ">
                            20.4
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Managed industrial switch, LTE M2M Router
                            </td>
                            <td className="px-6 py-3   ">
                            3.84
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Siemens 200A 120/240V Breaker, Shunt Trip
                            </td>
                            <td className="px-6 py-3   ">
                            5(1 Second)
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
