import React from "react";

import { useSelector } from 'react-redux';

export default function FilloutTable(props) {
  const [ isEditable, setIsEditable ] = React.useState(false);
  const [ isUpdatingFromScenario, setIsUpdatingFromScenario ] = React.useState(false);
  
  const { electrical_sizing_site_areas } = useSelector((state) => state.basisOfDesign);

  const [ areas, setAreas ] = React.useState([
    {
      parameter: "Solar PV Capacity (STC-kW)",
      value: 13.2,
    },
    {
      parameter: "Solar PV Tilt (degree)",
      value: 30.0,
    },
    {
      parameter: "Solar PV Azimuth",
      value: 180.0,
    },
    {
      parameter: "Nominal Battery Capacity(kWh)",
      value: 61.44,
    },
    {
      parameter: "Battery Inverter(kW-AC)",
      value: 27.2,
    },
    {
      parameter: "Generator(kVA)",
      value: 22.3,
    },
    {
      parameter: "Generator Runtime (hours/year)",
      value: 526.0,
    },
    {
      parameter: "Fuel Consumption (gal/year)",
      value: 850.0,
    },
    {
      parameter: "Propane Tank Capacity (gallons)",
      value: 1000.0,
    },
    {
      parameter: "Soiling Loss (%)",
      value: 3.0,
    },
    {
      parameter: "PV Derating Factor (%)",
      value: 94.2,
    },
    {
      parameter: "Thermal Loss Factor (w/m2k)",
      value: 29.0,
    },
    {
      parameter: "Battery Minimum State of Charge (%)",
      value: 38.3,
    },
    {
      parameter: "Renewable Fraction (%)",
      value: 71.2,
    },
  ]);

  React.useEffect(() => {
    if (electrical_sizing_site_areas?.length) {
      setAreas(electrical_sizing_site_areas);
    }
  }, [electrical_sizing_site_areas]);

  const _onChangeAreasInput = (e) => {
    let data = [...areas];

    data[e.target.id][e.target.name] = e.target.value;

    setAreas(data);
  };
  const saveChanges = () => {
    props.onSaveSiteAreas(areas);
    setIsEditable(!isEditable);
    props.onEditFilloutTable(!isEditable);
  };
  const _setIsEditable = () => {
    setIsEditable(!isEditable);
    props.onEditFilloutTable(!isEditable);
  }
  const _updateFromScenario = () => {
    setIsUpdatingFromScenario(true);
    setTimeout(async() => {
      await props.onExtractScenario();

      setIsUpdatingFromScenario(false);
    }, 2000);
  }
  return (
    <div className="  ">
      <div className="relative overflow-x-auto mt-3  ">
        <button
          onClick={ isUpdatingFromScenario ? () => console.log('Loading...')  : _updateFromScenario }
          className="absolute top-2 left-2 border bg-primary w-[65px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
        >
          { isUpdatingFromScenario ? 'Loading...' : 'Update' }
        </button>
        <button
          onClick={ isEditable ? saveChanges : _setIsEditable }
          className="absolute top-2 right-2 border bg-primary w-[45px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
        >
          {isEditable ? "Save" : "Edit"}
        </button>
        <table className=" table-site w-full  text-left  ">
          <caption className="px-3 py-2 f-f-m text-2xl text-center  bg-primary text-white ">
          System Sizing Summary
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b text-base text-black-dark ">
              <th className="px-6 py-3  min-w-[250px]  ">Parameter</th>
              <th className="px-6 py-3 min-w-[250px] w-[150px]   ">Value</th>
            </tr>
          </thead>
          <tbody>
            {areas.map((area, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-base text-black-dark "
                >
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeAreasInput}
                        className="input-equipment-table"
                        id={i}
                        name="parameter"
                        value={area.parameter}
                      />
                    ) : (
                      area.parameter
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeAreasInput}
                        className="input-equipment-table"
                        id={i}
                        name="value"
                        value={area.value}
                      />
                    ) : (
                      area.value
                    )}
                  </td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Solar PV Capacity (STC-kW)
                            </td>
                            <td className="px-6 py-3   ">
                            13.2
                            </td>
                            
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Solar PV Tilt (degree)
                            </td>
                            <td className="px-6 py-3   ">
                            30
                            </td>
                            
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Solar PV Azimuth
                            </td>
                            <td className="px-6 py-3   ">
                            180
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Nominal Battery Capacity(kWh)
                            </td>
                            <td className="px-6 py-3   ">
                            61.44
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Battery Inverter(kW-AC)
                            </td>
                            <td className="px-6 py-3   ">
                            27.2
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Generator(kVA)
                            </td>
                            <td className="px-6 py-3   ">
                            22.3
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Generator Runtime (hours/year)
                            </td>
                            <td className="px-6 py-3   ">
                            526
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Fuel Consumption (gal/year)
                            </td>
                            <td className="px-6 py-3   ">
                            850
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Propane Tank Capacity (gallons)
                            </td>
                            <td className="px-6 py-3   ">
                            1000
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Soiling Loss (%)
                            </td>
                            <td className="px-6 py-3   ">
                            3.0%
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            PV Derating Factor (%)
                            </td>
                            <td className="px-6 py-3   ">
                            94.2%
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Thermal Loss Factor (W/m2K)
                            </td>
                            <td className="px-6 py-3   ">
                            29
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Battery Minimum State of Charge (%)
                            </td>
                            <td className="px-6 py-3   ">
                            38.3
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Renewable Fraction (%)
                            </td>
                            <td className="px-6 py-3   ">
                            71.2
                            </td>
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
