import React from "react";

export default function FilloutTable(props) {
  const [isEditable, setIsEditable] = React.useState(false);
  const [areas, setAreas] = React.useState([
    { description: "Solar Container", area: 160 },
    { description: "Solar Array", area: 260 },
    { description: "Generator Room", area: 60 },
    { description: "Fenced Yard Area", area: 892 },
    { description: "Clear Zone (30 ft)", area: 10980 },
    { description: "Reduced Fuel Zone (100 ft)", area: 55270 },
    { description: "First Responder Panel Clear Zone", area: 70 },
  ]);
  const _onChangeAreaInput = (e) => {
    let data = [...areas];

    data[e.target.id][e.target.name] = e.target.value;

    setAreas(data);
  };
  const saveChanges = () => {
    props.onSaveSiteAreas(areas);
    setIsEditable(!isEditable);
    props.onEditFilloutTable(!isEditable);
  };
  const _setIsEditable = () => {
    setIsEditable(!isEditable);
    props.onEditFilloutTable(!isEditable);
  }
  return (
    <div className="  ">
      <div className="relative overflow-x-auto mt-3  ">
        <button
          onClick={ isEditable ? saveChanges : _setIsEditable }
          className="absolute top-2 right-2 border bg-primary w-[45px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
        >
          { isEditable ? "Save" : "Edit" }
        </button>
        <table className=" table-site w-full  text-left  ">
          <caption className="px-3 py-2 f-f-m text-2xl text-center  bg-primary text-white ">
            Site Areas
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b text-base text-black-dark ">
              <th className="px-6 py-3    ">Description</th>
              <th className="px-6 py-3 min-w-[250px] lg:min-w-full  ">
                Area (FT ²)
              </th>
            </tr>
          </thead>
          <tbody>
            {areas.map((item, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-base text-black-dark "
                >
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeAreaInput}
                        className="input-equipment-table"
                        id={i}
                        name="description"
                        value={item.description}
                      />
                    ) : (
                      item.description
                    )}
                  </td>
                  <td className="px-6 py-3" style={{ width: 150 }}>
                    {isEditable ? (
                      <input
                        type="number"
                        onChange={_onChangeAreaInput}
                        className="input-equipment-table"
                        id={i}
                        name="area"
                        value={item.area}
                      />
                    ) : (
                      item.area
                    )}
                  </td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">Solar Container</td>
                            <td className="px-6 py-3">160</td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">Solar Array</td>
                            <td className="px-6 py-3">260</td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">Generator Room</td>
                            <td className="px-6 py-3">60</td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">Fenced Yard Area</td>
                            <td className="px-6 py-3">892</td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">Clear Zone (30 ft)</td>
                            <td className="px-6 py-3">10,980</td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">Reduced Fuel Zone (100 ft)</td>
                            <td className="px-6 py-3">55,270</td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">First Responder Panel Clear Zone</td>
                            <td className="px-6 py-3">70</td>
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
