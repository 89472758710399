import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/userSlice';
import basisOfDesignSlice from './slices/basisOfDesignSlice';
import helperSlice from './slices/helperSlice';

export const store = configureStore({
  reducer: {
    user          : userSlice,
    helper        : helperSlice,
    basisOfDesign : basisOfDesignSlice
  },
})