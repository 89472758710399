import React, { Fragment, useRef, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import Logo from "../../assets/images/Logo 2.svg";
import { Link, useNavigate } from "react-router-dom";

import Config from "../../utils/Config";

import logo from "../../assets/boxpower-logo.png";

import { setUserMiniProfile } from "../../redux/slices/userSlice";

import { useDispatch, useSelector } from "react-redux";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ loading, setLoading ] = React.useState(false);
  const [ message, setMessage ] = React.useState(null);
  const [ user, setUser ] = React.useState({ first_name: "", last_name: "", company: "BoxPower Inc", job_title: "" });

  const userInfo = useSelector((state) => state.user.profile);

  // React.useEffect(() => {
  //   // setAccountActivateModel(true)
  //   if (localStorage.getItem("temp-signup")) {
  //     const res = JSON.parse(localStorage.getItem("temp-signup"));
  //     user.email = res.data.email;

  //     // setSubmit(false);
  //     setLoading(true);
  //     setUser(user);

  //     // this.setState({ submit: false, loading: true, user });

  //     setAccountActivateModel(true);
  //     // verifyEmailModel.show();
  //   } else {
  //     if (localStorage.getItem("user")) {
  //       navigate("/auth");
  //     }
  //   }
  // }, []);

  React.useEffect(() => {
    if (userInfo) {
      if (userInfo.first_name?.length && userInfo?.last_name?.length ) {
        navigate('/dashboard');
      }
    } else {
      navigate('/');
    }
  }, [userInfo]);

  const _onChange = (e) => {
    const user2 = { ...user };
    const target = e.target;

    user2[target.id] = target.value;

    setUser(user2);
  };

  /**
   * validate inputs before sending values to server
   */
  const validateInputs = (e) => {
    e.preventDefault();

    const { first_name, last_name } = user;

    if (first_name && last_name) {
      handleSignup();
    } else {
      reportMessage("All fields are required");
    }
  };

  /**
   * Creates user account
   */
  const handleSignup = () => {
    console.log("handleSignup...");
    setLoading(true);

    // submit API request to sign user up and create account
    fetch(`${Config.API.URL}/profiles/${userInfo.id}`, {
      method: "PATCH",
      headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${userInfo.token}` },
      body: JSON.stringify(user),
    }).then((res) => {
      // if 401 error (unauthenticated user, then log user out)
      if (res.status === 401) {
        console.log("401 Error: Unauthenticated");

        localStorage.removeItem("user");
        navigate("/");
      }
      return res;
    }).then((res) => res.json()).then(async (res) => {
      // console.log("Register-res:", res);

      // if successful, ask user to verify email
      if (res.status) {
        const data = { ...userInfo, ...res.data };

        // console.log('user-info:', userInfo);
        // console.log('reg-user-data:', data);

        localStorage.setItem('user', JSON.stringify(data));
        dispatch(setUserMiniProfile(data));
        
        navigate('/dashboard');
        
        // user2.email = res.data.email;
        // localStorage.setItem("temp-signup", JSON.stringify(res));
      } else {
        reportMessage(res.message);
      }

      //setSubmit(true);
      setLoading(false);
    }).catch((error) => {
      console.log("Error: ", error);

      setLoading(false);
      reportMessage("An error occurred!!! while connecting to server. Please try again");
    });
  };

  
  const reportMessage = (msg) => {
    setMessage(msg);
    setTimeout(() => setMessage(null), 5000);
  };

  return (
    <>
      <div className=" login-bg ">
        <div className="grid grid-cols-12   ">
          <div className=" col-span-6 hidden xl:block "> </div>
          <div className=" col-span-12 xl:col-span-6 ">
            <div className=" bg-white shadow-lg   xl:rounded-l-[35px] items-center  h-[1032px]">
              <div className="w-11/12 sm:w-8/12 ml-auto mr-auto  py-20 relative lg:top-[10%] ">
                <img
                  src={Logo}
                  className=" w-[190px] h-auto sm:w-[241px] sm:h-[36px]  "
                  alt=""
                />
                <h2 className="f-f-sm text-black text-4xl  sm:text-3xl mt-4 ">
                  Profile
                </h2>
                <h4 className=" text-black-vlight f-f-r text-lg ">
                  Complete your EASI profile
                </h4>
                { message ? (
                  <div style={{ color: "white", fontSize: 12, backgroundColor: "darkred", height: 40, right: 0, borderRadius: 5, padding: 10 }}> {message} </div>
                ) : null }
                <h4 className="  text-black-vlight f-f-r  text-base mt-8 ">
                  First Name
                </h4>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  value={user.first_name}
                  onChange={_onChange}
                  placeholder="First Name"
                  autoComplete="first_name"
                  className=" w-full  h-[47px] border border-grey  bg-white rounded-[4px] px-4 outline-none"
                />

                <h4 className="  text-black-vlight f-f-r  text-base mt-4 ">
                  Last Name
                </h4>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  value={user.last_name}
                  onChange={_onChange}
                  placeholder="Last Name"
                  autoComplete="last_name"
                  className=" w-full  h-[47px] border border-grey  bg-white rounded-[4px] px-4 outline-none  "
                />

                <h4 className="  text-black-vlight f-f-r  text-base mt-4 ">Organization</h4>
                <input
                  type="text"
                  name="company"
                  id="company"
                  value={user.company}
                  onChange={_onChange}
                  placeholder="Organization"
                  autoComplete="company"
                  className=" w-full  h-[47px] border border-grey  bg-white rounded-[4px] px-4 outline-none  "
                  readOnly
                />

                <h4 className="  text-black-vlight f-f-r  text-base mt-4 ">
                  Job Title
                </h4>
                <input
                  type="text"
                  name="job_title"
                  id="job_title"
                  value={user.job_title}
                  onChange={_onChange}
                  placeholder="Job Title"
                  autoComplete="job_title"
                  className=" w-full  h-[47px] border border-grey  bg-white rounded-[4px] px-4 outline-none  "
                />
                
                <button
                  type="button"
                  onClick={validateInputs}
                  className=" text-white w-full h-[52px] f-f-sm  text-lg 2xl:text-xl bg-primary  rounded-[9px]  text-center mt-10  "
                >
                  {loading ? "Loading..." : "Continue"}
                </button>
                {/* <h2 className=" text-black-vlight f-f-r  text-lg 2xl:text-xl mt-8 text-center ">
                  Already have an account?{" "}
                  <Link to="/login">
                    {" "}
                    <span className=" f-f-sm  text-primary ">Login</span>{" "}
                  </Link>
                </h2> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}
