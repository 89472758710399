import React, { useState } from "react";
import { Link } from "react-router-dom";
import DropdownBasic from "./Dilogropwdown";

export default function TestingDilog({ showModal, setShowModal }) {
  // const [showModal, setShowModal] = useState(false);

  return (
    <div>
      {showModal ? (
        <>
          <div className="dilog-box" onClick={() => setShowModal(false)}></div>

          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            className="fixed top-[7%]   right-0 z-[10000000]  w-full p-4 overflow-x-hidden overflow-y-auto inset-0 h-[calc(100%-1rem)] max-h-full"
          >
            <div className="relative w-full max-w-4xl max-h-full ml-auto mr-auto ">
              <div className="relative bg-grey-light  rounded-lg shadow  p-10 ">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className=" absolute top-[8px] right-[19px]  text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-hide="defaultModal"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clip-rule="evenodd"
                      d="M0.918759 13.0577C0.360088 13.6164 0.360088 14.5222 0.918759 15.0809C1.47743 15.6395 2.38321 15.6395 2.94189 15.0809L7.99981 10.0229L13.0579 15.0811C13.6166 15.6397 14.5224 15.6397 15.081 15.0811C15.6397 14.5224 15.6397 13.6166 15.081 13.0579L10.0229 7.99982L15.0806 2.94213C15.6393 2.38346 15.6393 1.47767 15.0806 0.919002C14.522 0.360332 13.6162 0.360333 13.0575 0.919003L7.99981 5.9767L2.9423 0.919193C2.38363 0.360523 1.47784 0.360523 0.919172 0.919193C0.360502 1.47786 0.360503 2.38365 0.919174 2.94232L5.97668 7.99982L0.918759 13.0577Z"
                      fill="#FA3030"
                    />
                  </svg>
                </button>

                <h3 className=" f-f-sm text-3xl text-black  text-center ">
                  Maroon Corgi Ranch
                </h3>
                <h4 className=" f-f-r text-primary text-2xl text-center my-2 ">
                  Below are all of the Basis of design Documents created for
                  this site{" "}
                </h4>

                {/* grid started */}
                <div className="grid grid-cols-12 mt-5 gap-4   ">
                  {[1, 2, 3, 4, 5, 6].map((_, index) => {
                    return (
                      <>
                        <div
                          className="   col-span-12 sm:col-span-6 xl:col-span-4 "
                          key={index}
                        >
                          {/* crd started */}
                          <div className="  bg-white rounded-[10px] shadow-lg p-[10px] relative ">
                            <ul className="inline-flex w-full">
                              <li className="w-full mt-1">
                                <h2 className="f-f-r text-base text-black ">
                                  10% Basis of Design
                                </h2>
                              </li>
                              <li>
                                {/* <button className=' bg-grey-dark text-sm f-f-dm-r w-[70px] h-[19px] rounded-[6px]  ' >
                    2 Scenarios
                  </button> */}
                                <DropdownBasic />
                              </li>
                            </ul>
                            <h2 className=" f-f-md-it text-tiny text-grey-vdark uppercase ">
                              Kevin Wagner
                            </h2>
                            <h2 className=" f-f-r  text-grey-vdark text-base ">
                              4517 Washington, Manchester,
                            </h2>
                            <h2 className=" f-f-r  text-grey-vdark  text-tiny ">
                              Kentucky, US 39495
                            </h2>
                            <ul className="inline-flex w-full mt-4 ">
                              <li className="w-full mt-2">
                                <h2 className="f-f-r text-sm text-grey-vdark ">
                                  Last Modified 5 Months Ago
                                </h2>
                              </li>
                              <li>
                                <button className="  text-primary f-f-r text-tiny   ">
                                  Details
                                </button>
                              </li>
                            </ul>
                          </div>
                          {/* crd ended */}
                        </div>
                      </>
                    );
                  })}
                </div>
                {/* grid ended */}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
