import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/Logo 2.svg";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Config from "../../utils/Config";

import { useNavigate } from "react-router-dom";

import swal from "sweetalert";

import { setUserMiniProfile } from "../../redux/slices/userSlice";

import { useDispatch, useSelector } from 'react-redux';

export default function Navbar() {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const [ loading, setLoading ] = useState(false);
  
  const { loginWithRedirect, user, isAuthenticated } = useAuth0();
  const [ navbarshow, setnavbarshow ] = useState("false");

  const [ isAuthed, setIsAuthed ] = useState(false);
  
  const userData = useSelector((state) => state.user.profile);

  React.useEffect(() => {
    // console.log('userData:', userData);
    if (!user && userData?.id) {
      if (userData.first_name?.length && userData?.last_name?.length) {
        // yirifo6566@funvane.com
        // nagojew736@em2lab.com
        setIsAuthed(true);
      } else {
        navigate('/join');
      }
    }
  }, [userData])


  React.useEffect(() => {
    // console.log('user:', user);
    
    if (user) {
      setIsAuthed(isAuthenticated);
      // authUser(user);
    }
  }, [isAuthenticated, user]);

  // const authUser = async (me) => {
  //   //console.log('me:', me);
  //   const token = await getAccessTokenSilently();

  //   setLoading(true);
  //   fetch(`${Config.API.URL}/profiles/me`, {
  //     method: "GET",
  //     headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${token}` },
  //   }).then((res) => {
  //     setLoading(false);

  //     // if 401 error (unauthenticated user, then log user out)
  //     if (res.status === 401) {
  //       console.log("401 Error: Unauthenticated");
  //       localStorage.removeItem("user");
  //       navigate("/");
  //     }
  //     return res;
  //   }).then((res) => res.json()).then(async (res) => {
  //     setLoading(false);
  //     console.log('user-res:', res);

  //     // if request is succesful, alert user that project has been cloned
  //     if (res.status) {
  //       const data = { ...res.data, token };
  //       console.log('user-data:', data);

  //       localStorage.setItem('user', JSON.stringify(data));
  //       dispatch(setUserMiniProfile(data));

  //       if (data.first_name?.length && data?.last_name?.length ) {
  //         navigate('/dashboard');
  //       } else {
  //         navigate('/join');
  //       }
        
  //     } else {
  //       swal("Oh no!", res.message, "error");
  //     }
  //   }).catch((error) => {
  //     setLoading(false);
  //     console.log("Error: ", error);

  //     swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
  //   });
  // };

  return (
    <div>
      <nav className=" bg-primary-dark  fixed w-full z-20 top-0 left-0  ">
        <div className="  w-11/12  2xl:max-w-[1256px] ml-auto mr-auto ">
          <div className=" flex flex-wrap items-center justify-between mx-auto py-4">
            <a href="#" className="flex items-center">
              <img
                src={Logo}
                className="  w-[145px] h-auto 2xl:w-[196px] 2xl:h-[29px]  "
                alt=""
              />
            </a>
            <div className="flex md:order-2">
              { console.log('isAuthed:', isAuthed) }
              { isAuthed ?
                <Link to={ (userData.first_name?.length && userData?.last_name?.length) ? "/dashboard" : "/join" }>
                  <button
                    type="button"
                    className=" hidden sm:block text-primary w-[184px] h-[40px] 2xl:w-[194px] 2xl:h-[44px] f-f-r text-base 2xl:text-lg border border-primary  rounded-[9px]  text-center mr-3 md:mr-0 "
                  >
                    Dashboard
                  </button>
                </Link>
              :
                <>
                  {/* <Link to="/signup"> */}
                    <button
                      type="button"
                      onClick={() => loginWithRedirect()}
                      className=" hidden sm:block text-primary w-[82px] h-[40px] 2xl:w-[97px] 2xl:h-[44px] f-f-r text-base 2xl:text-lg border border-primary  rounded-[9px]  text-center mr-3 md:mr-0 "
                    >
                      Sign Up
                    </button>
                  {/* </Link> */}
                  {/* <Link to="/login"> */}
                    <button
                      type="button"
                      onClick={() => loginWithRedirect()}
                      className="hidden sm:block text-white w-[82px] h-[40px] 2xl:w-[97px] 2xl:h-[44px] f-f-r text-base 2xl:text-lg bg-primary  rounded-[9px]  text-center mr-3 md:mr-0 ml-3  "
                    >
                      Log in
                    </button>
                  {/* </Link> */}
                </>
              }
              <button
                onClick={() => setnavbarshow(!navbarshow)}
                data-collapse-toggle="navbar-sticky"
                type="button"
                className="inline-flex items-center p-2 w-10 h-10 justify-center  rounded-lg md:hidden "
                aria-controls="navbar-sticky"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#ED583B"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="#ED583B"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1h15M1 7h15M1 13h15"
                  />
                </svg>
              </button>
            </div>
            <div
              className={` ${
                navbarshow ? " hidden" : "block"
              }  items-center justify-between  w-full md:flex md:w-auto md:order-1`}
              id="navbar-sticky"
            >
              <ul className="  flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg  md:flex-row md:space-x-6 lg:space-x-8 md:mt-0 md:border-0  ">
                <li>
                  <a
                    href="#"
                    className="block py-2 pl-3 pr-4 text-primary f-f-r  text-lg  2xl:text-xl  rounded  md:p-0 "
                    aria-current="page"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block py-2 pl-3 pr-4 text-white hover:text-primary  f-f-r  text-lg  2xl:text-xl rounded md:p-0 "
                  >
                    Features
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block py-2 pl-3 pr-4 text-white hover:text-primary  f-f-r  text-lg  2xl:text-xl rounded md:p-0 "
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="block py-2 pl-3 pr-4 text-white hover:text-primary  f-f-r  text-lg  2xl:text-xl rounded md:p-0 "
                  >
                    Contact Us
                  </a>
                </li>
                <Link to="/">
                  <li>
                    <a
                      href="#"
                      className="block text-center py-2 pl-3 pr-4 sm:hidden text-primary border border-primary f-f-r  text-lg  2xl:text-xl rounded md:p-0 "
                    >
                      Sign Up
                    </a>
                  </li>
                </Link>
                <Link to="/">
                  <li>
                    <a
                      href="#"
                      className=" mt-3 text-center block py-2 pl-3 pr-4 sm:hidden text-white bg-primary f-f-r  text-lg  2xl:text-xl rounded md:p-0 "
                    >
                      Log in
                    </a>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
