import React from 'react';
import DragDrop from './DragDrop';

import { Link, useParams, useNavigate } from 'react-router-dom';
import Config from '../../../utils/Config';
import { useSelector, useDispatch } from 'react-redux';

import { setShadingAnalysis } from "../../../redux/slices/basisOfDesignSlice";

import Dragicon from '../../../assets/images/icons8_upload-2.svg';

import swal from 'sweetalert';

export default function Index() {
  const user     = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [ isUploading, setIsUploading ] = React.useState(false);

  const { site_id, design_id } = useParams();

  const { shading_analysis } = useSelector((state) => state.basisOfDesign);

  const _onReceiveFiles = (files) => {
    const file = files[0];
    // console.log('file:', file);
    
    var formData   = new FormData();
    
    formData.append('file', file);
    formData.append('design_id', design_id);

    setIsUploading(true);

    fetch(Config.API.URL+'/design-basis/shading-analysis', {
      method: 'POST',
      headers: { Accept: 'application/json', Authorization: `Bearer ${user.token}` },
      body: formData,
    }).then((res) => {
      // if 401 error (unauthenticated user, then log user out)
      if ((res.status) === 401) {
        console.log("401 Error: Unauthenticated");
        
        localStorage.removeItem('user');
        navigate('/');
      }
      return res;
    }).then((res) => res.json()).then( async (res) => {
      // console.log('res:', res);
      setIsUploading(false);
      if (res.status) {
        
        dispatch(setShadingAnalysis(res.data));
      } else {
        swal("Oh no!", res.message, "error");
      }
    }).catch((error) => {
      setIsUploading(false);
      console.log('Error: ', error);
      swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
    });
  }

  const nextPage = () => {
    if (!shading_analysis?.shading_analysis_img_url) {
      swal({
        title: "Shading analysis is required!",
        text: "You are required to upload an image of the SunEye Horizon image. or do you still want go to skip this page?",
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willProcced) => {
        if (willProcced) {
          navigate(`/basis/electric/sizing/${site_id}/${design_id}`);
        } 
      });
    } else {
      navigate(`/basis/electric/sizing/${site_id}/${design_id}`);
    }
  }

  return (
    <div className='' >
        <h2 className=' f-f-r text-lg text-black ' >PG&E Basis of Design</h2>
        <h4 className=' f-f-sm text-black text-2xl  sm:text-3xl ' >Shading Analysis and Assumptions</h4>
        { shading_analysis?.shading_analysis_img_url ? 
          <img src={shading_analysis?.shading_analysis_img_url} className='h-[62px] mt-5'  />
        : null }
    
      <div className='border border-white-vlight bg-white rounded-[4px] p-2 mt-8'>
      <h2 className=' f-f-m text-black text-lg pl-2 ' >Upload an image of the SunEye Horizon image here</h2>
        <div className='border  border-white-vlight my-1' ></div>
        { isUploading ? 
          <div className=' drag-drop p-4 my-8 text-center w-11/12 sm:w-[298px] mx-auto cursor-pointer h-[200px]' >
            <img src={Dragicon} className='w-[62px] h-[62px] mx-auto '  />
            <h2 className=' f-f-m text-black text-lg'>Uploading...</h2>
          </div>
        :
          <DragDrop onReceiveFiles={_onReceiveFiles} />
        }
      </div>


      
{/* adjuest in bottom  */}
<div className='  ' >

        

<div className=' border border-gray-300 mt-20  ' ></div>
<div className="grid grid-cols-12  gap-4  ">
<div className="   col-span-3">
  <Link to={`/basis/setback/asumption/${site_id}/${design_id}`} >
<button className="  mt-8 relative border border-primary w-[110px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-primary  ">
       {/* inner ul started */}
        <ul className=' inline-flex text-center justify-center mt-1 mr-3 ' >
            <li>
                        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.25 7.5L11.75 13L17.25 18.5" stroke="#ED583B" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
            </li>
            <li className='ml-1' >
    Back
    </li>
        </ul>
        {/* inner ul ended */}
       </button>
       </Link>
</div>
<div className="   col-span-9">
<div className=' text-end ' >
<ul className=" inline-flex items-center  ">

<li >
<Link to={`/basis/electric/sizing/${site_id}/${design_id}`}>
<button className=" mt-8  text-center  f-f-r text-lg text-black-darkest  ">
Skip</button>
  </Link>
  </li>
<li className=" ml-6 ">
  
{/* <Link to={`/electric/sizing/${site_id}/${design_id}`}> */}
<button onClick={nextPage} className=" mt-8 relative border bg-primary w-[127px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-white  ">Next</button>
{/* </Link> */}
</li>

</ul>
</div>
</div>
</div>

</div>
{/* adjuest in bottom ended  */}

    </div>
  )
}
