import React from "react";

export default function Tableassumption({ design_parameters }) {
  return (
    <div className="pr-10 pl-10">
      <div className="relative overflow-x-auto mt-8 ">
        <table className=" table-site w-full border border-slate-500 text-left  ">
          <caption className="border border-slate-600 px-3 py-2 f-f-m  text-lg text-center  bg-primary text-white ">
            DESIGN PARAMETERS
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b  text-sm text-black-dark ">
              <th className="border border-slate-600 px-6 py-3     ">Parameter</th>
              <th className="border border-slate-600 px-6 py-3     ">Value</th>
              <th className="border border-slate-600 px-6 py-3     ">Source</th>
            </tr>
          </thead>

          <tbody>
            {design_parameters.map((parameter, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b f-f-r text-sm text-black-dark "
                >
                  <td className="border border-slate-600 px-6 py-3">{parameter.parameter}</td>
                  <td className="border border-slate-600 px-6 py-3">{parameter.value}</td>
                  <td className="border border-slate-600 px-6 py-3">{parameter.source}</td>
                </tr>
              );
            })}

            {/* <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Average Daily Usage (kWh/day)
                            </td>
                            <td className="px-6 py-3   ">
                            18.97
                            </td>
                            <td className="px-6 py-3   ">
                            1.06
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-b  text-sm text-black-dark ">
                            <td colSpan={3}  className="px-6 py-3   ">
                            Temperature
                            </td>
                          
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Minimum
                            </td>
                            <td className="px-6 py-3   ">
                            -4
                            </td>
                            <td className="px-6 py-3   ">
                            NREL NSRDB
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Maximum
                            </td>
                            <td className="px-6 py-3   ">
                            37
                            </td>
                            <td className="px-6 py-3   ">
                            NREL NSRDB
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-b  text-sm text-black-dark ">
                            <td colSpan={3}  className="px-6 py-3   ">
                            Humidity
                            </td>
                          
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Maximum
                            </td>
                            <td className="px-6 py-3   ">
                            62.5
                            </td>
                            <td className="px-6 py-3   ">
                            NREL NSRDB
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Minimum
                            </td>
                            <td className="px-6 py-3   ">
                            17.3
                            </td>
                            <td className="px-6 py-3   ">
                            NREL NSRDB
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-b  text-sm text-black-dark ">
                            <td colSpan={3}  className="px-6 py-3   ">
                            Wind Loading
                            </td>
                          
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Wind Speed (MPH)
                            </td>
                            <td className="px-6 py-3   ">
                            104
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Exposure Category
                            </td>
                            <td className="px-6 py-3   ">
                            C
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Enclosure Classification
                            </td>
                            <td className="px-6 py-3   ">
                            Open building
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-b  text-sm text-black-dark ">
                            <td colSpan={3}  className="px-6 py-3   ">
                            Snow Loading
                            </td>
                          
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Ground Snow Load (PSF)
                            </td>
                            <td className="px-6 py-3   ">
                            21
                            </td>
                            <td className="px-6 py-3   ">
                            AHJ
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-b  text-sm text-black-dark ">
                            <td colSpan={3}  className="px-6 py-3   ">
                            Seismic Loading
                            </td>
                          
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Spectral Response [Short, S/S] (g)
                            </td>
                            <td className="px-6 py-3   ">
                            2.04
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Spectral Response [1 Sec, S/1] (g)
                            </td>
                            <td className="px-6 py-3   ">
                            0.65
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Importance Factor
                            </td>
                            <td className="px-6 py-3   ">
                            1.5
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Site Class
                            </td>
                            <td className="px-6 py-3   ">
                            D
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Design Spectral Response [S/DS] (g)
                            </td>
                            <td className="px-6 py-3   ">
                            1.3
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Design Spectral Response [S/D1] (g)
                            </td>
                            <td className="px-6 py-3   ">
                            1.03
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Seismic Design Category
                            </td>
                            <td className="px-6 py-3   ">
                            D
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-b  text-sm text-black-dark ">
                            <td colSpan={3}  className="px-6 py-3   ">
                            Risk Category
                            </td>
                          
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            All components
                            </td>
                            <td className="px-6 py-3   ">
                            3
                            </td>
                            <td className="px-6 py-3   ">
                            PG&E
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-b  text-sm text-black-dark ">
                            <td colSpan={3}  className="px-6 py-3   ">
                            Occupancy Classifications
                            </td>
                          
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Solar Container
                            </td>
                            <td className="px-6 py-3   ">
                            S-1
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-b  text-sm text-black-dark ">
                            <td colSpan={3}  className="px-6 py-3   ">
                            Soil Condition
                            </td>
                          
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Maximum Soil Bearing Pressure (PSI)
                            </td>
                            <td className="px-6 py-3   ">
                            1250
                            </td>
                            <td className="px-6 py-3   ">
                            Geotech Report
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Soil Type
                            </td>
                            <td className="px-6 py-3   ">
                            Sandy-Clay Loam
                            </td>
                            <td className="px-6 py-3   ">
                            Geotech Report
                            </td>
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
