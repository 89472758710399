import React, { Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

import { Link } from "react-router-dom";

import Sidebardata from "../home/Sideebar";
import Header from "../home/header";
import Hero from "./HeroPreviewProduct";
import Loading from "../../Website/Loading";
import UniversalDataTable from "../Contacts/UniversalDataTable";
import Config from "../../utils/Config";

import { useNavigate, useParams } from "react-router-dom";

import swal from "sweetalert";

import { useSelector } from "react-redux";

//import LoadingOverlay from 'react-loading-overlay-ts';

export default function PreviewGeneratorSingleProduct() {
  const { product_id } = useParams();
  const [ contacts, setContacts ] = React.useState([]);

  const [ product, setProduct ] = React.useState(null);

  const [ loading, setLoading ] = React.useState(false);

  const [ contactLoading, setContactLoading ] = React.useState(false);
  
  const [ editProductModel, setEditProductModel ] = React.useState(false);
  
  const [ generator_product_name, set_generator_product_name ] = React.useState("PGP35");
  const [ generator_product_manufacturer, set_generator_product_manufacturer ] = React.useState("BoxPower");
  const [ generator_product_part_number, set_generator_product_part_number ] = React.useState("PGP35");
  const [ generator_product_certifications, set_generator_product_certifications ] = React.useState("UL 2200, UL 6200, UL 1236, UL 489");

  const [ generator_product_unit_capacity, set_generator_product_unit_capacity ] = React.useState("");
  const [ generator_product_unit_cost, set_generator_product_unit_cost ] = React.useState("");
  const [ generator_product_notes, set_generator_product_notes ] = React.useState("");
  const [ generator_product_model, set_generator_product_model ] = React.useState("");
  const [ generator_frequency, set_generator_frequency ] = React.useState("");
  const [ generator_phase, set_generator_phase ] = React.useState("");
  const [ generator_rated_voltage, set_generator_rated_voltage ] = React.useState("");
  const [ generator_real_standby_power, set_generator_real_standby_power ] = React.useState("");
  const [ generator_apparent_standby_power, set_generator_apparent_standby_power ] = React.useState("");
  const [ generator_real_prime_power, set_generator_real_prime_power ] = React.useState("");
  const [ generator_apparent_prime_power, set_generator_apparent_prime_power ] = React.useState("");
  const [ generator_power_factor, set_generator_power_factor ] = React.useState("");
  const [ generator_efficiency, set_generator_efficiency ] = React.useState("");
  const [ generator_max_current, set_generator_max_current ] = React.useState("");
  const [ generator_continuous_current, set_generator_continuous_current ] = React.useState("");
  const [ generator_breaker_current, set_generator_breaker_current ] = React.useState("");

  const cancelButtonRef = React.useRef(null);
  const navigate        = useNavigate();
  const user            = useSelector((state) => state.user.profile);

  // const [isActive, setActive] = React.useState(true)
  // const handleButtonClick = React.useCallback(() => {
  //   setActive(value => !value);
  // }, [])

  React.useEffect(() => {
    loadProduct();
  }, [user]);

  const loadProduct = () => {
    setContactLoading(true);
    fetch(`${Config.API.URL}/products/generator-products/${product_id}`, { //sort_by=first_name&
      method: "GET",
      headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
    }).then((res) => {
      setContactLoading(false);
      // if 401 error (unauthenticated user, then log user out)
      if (res.status === 401) {
        console.log("401 Error: Unauthenticated");

        localStorage.removeItem("user");
        navigate("/");
      }
      return res;
    }).then((res) => res.json()).then(async (res) => {
      console.log('res', res);
      setContactLoading(false);
      if (res.status) {
        setProduct(res.data);
      } else {
        swal("Oh no!", res.message, "error");
      }
    }).catch((error) => {
      setContactLoading(false);
      console.log("Error: ", error);

      swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
    });
  };

  const _onEditProduct = () => {
    //set(product);

    setEditProductModel(true);

    set_generator_product_name(product.generator_product_name ? product.generator_product_name : "");
    set_generator_product_manufacturer(product.generator_product_manufacturer ? product.generator_product_manufacturer : "");
    set_generator_product_part_number(product.generator_product_part_number ? product.generator_product_part_number : "");
    set_generator_product_certifications(product.generator_product_certifications ? product.generator_product_certifications : "");

    set_generator_product_unit_capacity(product.generator_product_unit_capacity ? product.generator_product_unit_capacity : "");
    set_generator_product_unit_cost(product.generator_product_unit_cost ? product.generator_product_unit_cost : "");
    set_generator_product_notes(product.generator_product_notes ? product.generator_product_notes : "");
    set_generator_product_model(product.generator_product_model ? product.generator_product_model : "");
    set_generator_frequency(product.generator_frequency ? product.generator_frequency : "");
    set_generator_phase(product.generator_phase ? product.generator_phase : "");
    set_generator_rated_voltage(product.generator_rated_voltage ? product.generator_rated_voltage : "");
    set_generator_real_standby_power(product.generator_real_standby_power ? product.generator_real_standby_power : "");
    set_generator_apparent_standby_power(product.generator_apparent_standby_power ? product.generator_apparent_standby_power : "");
    set_generator_real_prime_power(product.generator_real_prime_power ? product.generator_real_prime_power : "");
    set_generator_apparent_prime_power(product.generator_apparent_prime_power ? product.generator_apparent_prime_power : "");
    set_generator_power_factor(product.generator_power_factor ? product.generator_power_factor : "");
    set_generator_efficiency(product.generator_efficiency ? product.generator_efficiency : "");
    set_generator_max_current(product.generator_max_current ? product.generator_max_current : "");
    set_generator_continuous_current(product.generator_continuous_current ? product.generator_continuous_current : "");
    set_generator_breaker_current(product.generator_breaker_current ? product.generator_breaker_current : "");
  };

  const submitProduct = () => {
    if (generator_product_name.length && generator_product_manufacturer.length && generator_product_part_number.length && generator_product_certifications.length) {
      setLoading(true);
      const payload = {
        generator_product_name,
        generator_product_manufacturer,
        generator_product_part_number,
        generator_product_certifications,

        generator_product_unit_capacity,
        generator_product_unit_cost,
        generator_product_notes,
        generator_product_model,
        generator_frequency,
        generator_phase,
        generator_rated_voltage,
        generator_real_standby_power,
        generator_apparent_standby_power,
        generator_real_prime_power,
        generator_apparent_prime_power,
        generator_power_factor,
        generator_efficiency,
        generator_max_current,
        generator_continuous_current,
        generator_breaker_current
      };

      fetch(`${Config.API.URL}/products/generator-products/${product?.id}`, {
        method: "PATCH",
        headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          setLoading(false);

          // if 401 error (unauthenticated user, then log user out)
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");
            localStorage.removeItem("user");
            navigate("/");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          setLoading(false);

          // if request is succesful, alert user that project has been cloned
          if (res.status) {
            setEditProductModel(false);

            setProduct(res.data);

            //let index   = contacts.findIndex((x) => x.id === contact.id);
            //let data    = [ ...contacts ];

            //data[index] = res.data;

            //setContacts(data);
          } else {
            swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          setLoading(false);

          console.log("Error: ", error);

          swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
        });
    } else {
      swal("Oh no!", "All fields with asterisk(*) are required", "error");
    }
  };
  
  return (
    <>
      {/* <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
        <LoadingOverlay
          active={isActive}
          spinner
          text='Loading your content...'
        >
          <div style={{ height: '100vh', position: 'relative' }}>
          </div>
        </LoadingOverlay>
      </div> */}
      <div className="grid grid-cols-12  ">
        <div className=" lg:col-span-2">
          <Sidebardata />
        </div>
        <div className=" transition-none col-span-12 lg:col-span-12 mr-3  ">
          <div className=" w-[85%] sm:w-[95%] ml-auto ">
            <Header />
            <Hero productsTitle="Generator Products" product={product} productName={product?.generator_product_name} />
            { contactLoading ?
              <Loading />
            :
              <div className=" mt-10 mb-10 bg-white rounded-[8px] p-4 pb-7">
                <div className=" bg-white mt-2 shadow-lg p-[20px] rounded-[8px] relative w-[700px] " style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  <button
                    onClick={_onEditProduct}
                    className="absolute top-3 right-3 border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
                  >
                    Edit
                  </button>
                  
                  {/* inner stated */}
                  <h2 className=" text-center f-f-sm text-lg text-black my-2  ">Generator Product</h2>
                  
                  <div>
                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Product Name:{" "}
                      <span className=" float-right text-black ">
                        {product?.generator_product_name}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    
                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Unit Capacity:{" "}
                      <span className=" float-right text-black ">
                        {
                          product?.generator_product_unit_capacity
                        }
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    
                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Comments or Notes:{" "}
                      <span className=" float-right text-black ">
                        {product?.generator_product_notes}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    
                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Manufacturer:{" "}
                      <span className=" float-right text-black ">
                        {
                          product?.generator_product_manufacturer
                        }
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Model:{" "}
                      <span className=" float-right text-black ">
                        {product?.generator_product_model}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>

                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Part Number:{" "}
                      <span className=" float-right text-black ">
                        {
                          product?.generator_product_part_number
                        }
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Product Certifications:
                      <span className=" float-right text-black ">
                        {
                          product?.generator_product_certifications
                        }
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>

                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Frequency (Hz):{" "}
                      <span className=" float-right text-black ">
                        {product?.generator_frequency}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Phase/Wire (A):
                      <span className=" float-right text-black ">
                        {product?.generator_phase}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>

                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Rated Voltage (V):{" "}
                      <span className=" float-right text-black ">
                        {product?.generator_rated_voltage}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Real Standby Power (kW):
                      <span className=" float-right text-black ">
                        {
                          product?.generator_real_standby_power
                        }
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>

                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Apparent Standby Power (kVA):
                      <span className=" float-right text-black ">
                        {
                          product?.generator_apparent_standby_power
                        }
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Real Prime Power (kW):
                      <span className=" float-right text-black ">
                        {product?.generator_real_prime_power}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>

                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Apparent Prime Power (kVA):{" "}
                      <span className=" float-right text-black ">
                        {
                          product?.generator_apparent_prime_power
                        }
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Power Factor:
                      <span className=" float-right text-black ">
                        {product?.generator_power_factor}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>

                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Anticipated Efficiency (%):
                      <span className=" float-right text-black ">
                        {product?.generator_efficiency}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Max Current (A):
                      <span className=" float-right text-black ">
                        {product?.generator_max_current}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>

                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Continuous Current (A):
                      <span className=" float-right text-black ">
                        {
                          product?.generator_continuous_current
                        }
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                    <h3 className="  f-f-r text-lg text-black-vlight  mt-2  ">
                      Breaker Current (A):
                      <span className=" float-right text-black ">
                        {product?.generator_breaker_current}
                      </span>{" "}
                    </h3>
                    <div className=" border border-white-vlight my-1 "></div>
                  </div>
                          
                  
                </div>
              </div>
            }
          </div>
        </div>
      </div>

      <Transition.Root show={editProductModel} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setEditProductModel}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Edit Product Information
                        </Dialog.Title>
                        <div className="mt-2" style={{ width: "100%" }}>
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Name{" "}
                                <span className="text-primary">*</span>
                              </label>
                              
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_product_name(e.target.value)}
                                  value={generator_product_name}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Manufacturer
                                <span className="text-primary">*</span>
                              </label>
        
                              <div className="mt-2">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_product_manufacturer(e.target.value)}
                                  value={generator_product_manufacturer}
                                  rows="4"
                                  className="pr-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Part Number
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_product_part_number(e.target.value)}
                                  value={generator_product_part_number}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Certifications
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_product_certifications(e.target.value)}
                                  value={generator_product_certifications}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Unit Capacity
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_product_unit_capacity(e.target.value)}
                                  value={generator_product_unit_capacity}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Unit Cost
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_product_unit_cost(e.target.value)}
                                  value={generator_product_unit_cost}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Notes
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_product_notes(e.target.value)}
                                  value={generator_product_notes}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Model
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_product_model(e.target.value)}
                                  value={generator_product_model}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Frequency
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_frequency(e.target.value)}
                                  value={generator_frequency}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Phase
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_phase(e.target.value)}
                                  value={generator_phase}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          
                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Rated Voltage
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_rated_voltage(e.target.value)}
                                  value={generator_rated_voltage}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Real Standby Power
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_real_standby_power(e.target.value)}
                                  value={generator_real_standby_power}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Apparent Standby Power
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_apparent_standby_power(e.target.value)}
                                  value={generator_apparent_standby_power}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Real Prime Power
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_real_prime_power(e.target.value)}
                                  value={generator_real_prime_power}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Apparent Prime Power
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_apparent_prime_power(e.target.value)}
                                  value={generator_apparent_prime_power}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Power factor
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_power_factor(e.target.value)}
                                  value={generator_power_factor}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Efficiency
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_efficiency(e.target.value)}
                                  value={generator_efficiency}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product MAX current
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_max_current(e.target.value)}
                                  value={generator_max_current}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Continuous Current
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_continuous_current(e.target.value)}
                                  value={generator_continuous_current}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                              <label className="block text-sm font-medium leading-6 text-gray-900">
                                Product Breaker Current
                                <span className="text-primary">*</span>
                              </label>
                              <div className="mt-0">
                                <input
                                  type="text"
                                  onChange={(e) => set_generator_breaker_current(e.target.value)}
                                  value={generator_breaker_current}
                                  className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    {loading ? (
                      <button className=" inline-flex w-full justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto ">
                        Loading...
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
                        onClick={submitProduct}
                      >
                        Save
                      </button>
                    )}
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setEditProductModel(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      
    </>
  );
}
