import React, { useState } from 'react';
import Sidebardata from '../home/Sideebar'
import Header from '../home/header'
import Hero from './Hero'
import Actioncard from './Actioncard'
import SystemArchitecture from './SystemArchitecture'
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Config from '../../utils/Config';

import Loading from "../../Website/Loading";

export default function Index() {
    const { scenario_id } = useParams();
    const user = useSelector((state) => state.user.profile);
    const navigate = useNavigate();
    const [ scenario, setScenario ] = React.useState({});
    const [ site, setSite ] = React.useState({});
    const [ homer, setHomer ] = React.useState(null);
    const [ loading, setLoading ] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        fetch(`${Config.API.URL}/scenarios/${scenario_id}`, {
            method: 'GET',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` }
        }).then((res) => {
            setLoading(false);
            // if 401 error (unauthenticated user, then log user out)
            if ((res.status) === 401) {
                console.log("401 Error: Unauthenticated");

                localStorage.removeItem('user');
                navigate('/');
            }
            return res;
        }).then((res) => res.json()).then( async (res) => {
            setLoading(false);

            console.log('scenarios-res:', res);

            // if request is succesful, alert user that project has been cloned
            if (res.status) {
                const data = res.data;
                setScenario(data);

                const siteReq = await fetch(`${Config.API.URL}/sites/${data.site_id}`, { headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` }});
                const siteRes = await siteReq.json();

                if (siteRes.status) {
                    setSite(siteRes.data);
                } 
                
                // console.log('siteRes:', siteRes)

                const homerReq = await fetch(`${Config.API.URL}/system-optimization/homer-file-summary/${scenario_id}`, { headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` }});
                const homerRes = await homerReq.json();

                if (homerRes.status) {
                    setHomer(homerRes.data);
                } 

                console.log('homerRes 1:', homerRes);

                // const homersReq = await fetch(`${Config.API.URL}/system-optimization/homer-files-summaries/${scenario_id}`, { headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` }});
                // const homersRes = await homersReq.json();

                // console.log('homersRes 2:', homersRes)

                // target_renewable
                // site_fence
            } else {
                //swal("Oh no!", res.message, "error");
            }
        }).catch((error) => {
            setLoading(false);
            console.log('Error: ', error);
        });
        
    }, [scenario_id, user, navigate]);
    return (
        <>
            <div className='grid grid-cols-12  ' >
                <div className=' lg:col-span-2'>
                    <Sidebardata />
                </div>
                <div className=" transition-none col-span-12 lg:col-span-12 mr-3  ">
                    <div className=' w-[85%] sm:w-[95%] ml-auto ' >
                        <Header />
                        { loading ?
                            <Loading />
                        :
                            <>
                                <Hero scenario={scenario} />
                                <Actioncard scenario={scenario} site={site} homer={homer} onReceiveScenario={setScenario} onReceiveHomer={setHomer} />
                                {/* <SystemArchitecture scenario={scenario} /> */}
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
