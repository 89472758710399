import React from 'react'

import { Link, useParams, useNavigate } from 'react-router-dom';
import Config from '../../../utils/Config';
import { useSelector, useDispatch } from 'react-redux';

import TemperatureTable from './TemperatureTable';

import swal from 'sweetalert';

import { setTemperatureDesign } from "../../../redux/slices/basisOfDesignSlice";

const staticTemperatures = [
  {
    month: "Feb",
    temperature: 7.5,
    total_heating_demand: 54.72,
    total_cooling_demand: 56.49,
  },
  {
    month: "Mar",
    temperature: 9.8,
    total_heating_demand: 47.33,
    total_cooling_demand: 48.86,
  },
  {
    month: "Apr",
    temperature: 10.3,
    total_heating_demand: 43.33,
    total_cooling_demand: 44.73,
  },
  {
    month: "May",
    temperature: 16.4,
    total_heating_demand: 10.94,
    total_cooling_demand: 11.29,
  },
  {
    month: "Jun",
    temperature: 19.4,
    total_heating_demand: 0.0,
    total_cooling_demand: 5.64,
  },
  {
    month: "Jul",
    temperature: 21.2,
    total_heating_demand: 0.0,
    total_cooling_demand: 16.75,
  },
  {
    month: "Jul",
    temperature: 21.2,
    total_heating_demand: 0.0,
    total_cooling_demand: 16.75,
  },
  {
    month: "Aug",
    temperature: 20.2,
    total_heating_demand: 0.0,
    total_cooling_demand: 10.12,
  },
  {
    month: "Sep",
    temperature: 20.8,
    total_heating_demand: 0.0,
    total_cooling_demand: 14.22,
  },
  {
    month: "Oct",
    temperature: 15.7,
    total_heating_demand: 13.94,
    total_cooling_demand: 14.39,
  },
  {
    month: "Nov",
    temperature: 9.4,
    total_heating_demand: 47.68,
    total_cooling_demand: 49.22,
  },
  {
    month: "Dec",
    temperature: 7.6,
    total_heating_demand: 59.7,
    total_cooling_demand: 61.63,
  },
];

export default function Index() {
  const user     = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { temperature_design } = useSelector((state) => state.basisOfDesign);
  const { site_id, design_id } = useParams();
  const [ isTemperatureTableEditable, setIsTemperatureTableEditable ] = React.useState(false);

  const _onSaveTemperatureDesign = (temperatures) => {
    const payload = { site_id: site_id, design_id, temperatures: temperatures };

    // console.log("payload:", payload);

    fetch(`${Config.API.URL}/design-basis/temperature-design`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` },
      body: JSON.stringify(payload),
    }).then((res) => {
      // if 401 error (unauthenticated user, then log user out)
      if ((res.status) === 401) {
        console.log("401 Error: Unauthenticated");

        localStorage.removeItem('user');
        navigate('/');
      }
      return res;
    }).then((res) => res.json()).then( async (res) => {
      // console.log('res:', res);

      // if request is succesful, alert user that project has been cloned
      if (res.status) {
        dispatch(setTemperatureDesign(res.data));
      } else {
        swal("Oh no!", res.message, "error");
      }
    }).catch((error) => {
      console.log('Error: ', error);
      
      swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
    });
  }

  const nextPage = () => {
    if (isTemperatureTableEditable) {
      swal({
        title: "Editable mode detected?",
        text: "When go to the next page, unsave changes will be lost. do you still want go to next page?",
        icon: "warning",
        buttons: ["No", "Next"],
        dangerMode: true,
      }).then((willProcced) => {
        if (willProcced) {
          if (!temperature_design.length) {
            _onSaveTemperatureDesign(staticTemperatures)
          }
          navigate(`/basis/design/deviations/${site_id}/${design_id}`);
        } 
      });
    } else {
      if (!temperature_design.length) {
        _onSaveTemperatureDesign(staticTemperatures)
      }
      navigate(`/basis/design/deviations/${site_id}/${design_id}`);
    }
  }

  return (
    <div className='' >
        <h2 className=' f-f-r text-lg text-black ' >PG&E Basis of Design</h2>
        <h4 className=' f-f-sm text-black text-2xl  sm:text-3xl ' >Temperature Design Assumptions</h4>
        <h2 className=' f-f-m text-black text-lg mt-4 ' >Fill out the Temperature Design Table below</h2>

   {/*  table started */}
<TemperatureTable onEditTemperatureTable={setIsTemperatureTableEditable} onSaveTemperatureDesign={_onSaveTemperatureDesign} />


<div className='  ' >

        

<div className=' border border-gray-300 mt-20  ' ></div>
<div className="grid grid-cols-12  gap-4  ">
<div className="   col-span-3">
    <Link to={`/basis/civil/design/${site_id}/${design_id}`} >
    
<button className="  mt-8 relative border border-primary w-[110px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-primary  ">
       {/* inner ul started */}
        <ul className=' inline-flex text-center justify-center mt-1 mr-3 ' >
            <li>
                        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.25 7.5L11.75 13L17.25 18.5" stroke="#ED583B" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
            </li>
            <li className='ml-1' >
    Back
    </li>
        </ul>
        {/* inner ul ended */}
       </button>
       </Link>
</div>
<div className="   col-span-9">
<div className=' text-end ' >
<ul className=" inline-flex items-center  ">

<Link to={`/basis/design/deviations/${site_id}/${design_id}`}>
<li >
  
<button className=" mt-8  text-center  f-f-r text-lg text-black-darkest  ">
Skip</button>
  </li>
  </Link>
<li className=" ml-6 ">
<button onClick={nextPage} className=" mt-8 relative border bg-primary w-[127px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-white  ">Next</button></li>

</ul>
</div>
</div>
</div>

</div>
{/* adjuest in bottom ended  */}

    </div>
  )
}
