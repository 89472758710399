import React from "react";

export default function DesignTable(props) {
  const [isEditable, setIsEditable] = React.useState(false);

  const [components, setComponents] = React.useState([
    {
      component: "Ampliphi 3.8kWh",
      supplier: "Simpliphi",
      system: "Battery Bank",
      warranty: "10Year",
    },
    {
      component: "CS3W-440MB-AG",
      supplier: "Canadian Solar",
      system: "Solar Array",
      warranty: "20Years",
    },
    {
      component: "Conext MPPT",
      supplier: "Schneider",
      system: "Charge Controller",
      warranty: "5Year",
    },
    {
      component: "XW Pro 6848NA",
      supplier: "Schneider",
      system: "Inverter",
      warranty: "5Years",
    },
    {
      component: "Ampliphi 3.8kWh",
      supplier: "Simpliphi",
      system: "Battery Bank",
      warranty: "10Year",
    },
    {
      component: "Stellar Edge",
      supplier: "New Sun Road",
      system: "Monitoring &Control",
      warranty: "2Years",
    },
    {
      component: "SolarContainer",
      supplier: "BoxPower",
      system: "Container",
      warranty: "10Years",
    },
    {
      component: "AIE-G 30",
      supplier: "AIE",
      system: "Generator",
      warranty: "2Year",
    },
  ]);
  const _onChangeComponentsInput = (e) => {
    let data = [...components];

    data[e.target.id][e.target.name] = e.target.value;

    setComponents(data);
  };
  const saveChanges = () => {
    props.onSaveDesignLife(components);
    setIsEditable(!isEditable);
    props.onEditDesignTable(!isEditable);
  };
  const _setIsEditable = () => {
    setIsEditable(!isEditable);
    props.onEditDesignTable(!isEditable);
  };
  return (
    <div>
      <div className="relative overflow-x-auto mt-8 ">
        <button
          onClick={ isEditable ? saveChanges : _setIsEditable }
          className="absolute top-2 right-2 border bg-primary w-[45px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
        >
          {isEditable ? "Save" : "Edit"}
        </button>
        <table className=" table-site w-full  text-left  ">
          <caption className="px-3 py-2 f-f-m text-2xl text-center  bg-primary text-white ">
            DESIGN LIFE
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg">
            <tr className=" f-f-b text-base text-black-dark ">
              <th className="px-6 py-3 min-w-[120px]">Component</th>
              <th className="px-6 py-3 min-w-[120px]">Supplier</th>
              <th className="px-6 py-3 min-w-[120px]">System</th>
              <th className="px-6 py-3 min-w-[120px]">Warranty</th>
            </tr>
          </thead>
          <tbody>
            {components.map((component, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-base text-black-dark "
                >
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeComponentsInput}
                        className="input-equipment-table"
                        id={i}
                        name="component"
                        value={component.component}
                      />
                    ) : (
                      component.component
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeComponentsInput}
                        className="input-equipment-table"
                        id={i}
                        name="supplier"
                        value={component.supplier}
                      />
                    ) : (
                      component.supplier
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeComponentsInput}
                        className="input-equipment-table"
                        id={i}
                        name="system"
                        value={component.system}
                      />
                    ) : (
                      component.system
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeComponentsInput}
                        className="input-equipment-table"
                        id={i}
                        name="warranty"
                        value={component.warranty}
                      />
                    ) : (
                      component.warranty
                    )}
                  </td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Ampliphi 3.8 kWh
                            </td>
                            <td className="px-6 py-3   ">
                            Simpliphi
                            </td>
                            <td className="px-6 py-3   ">
                            Battery Bank 
                            </td>
                            <td className="px-6 py-3   ">
                            10 Year
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            CS3W-440MB-AG
                            </td>
                            <td className="px-6 py-3   ">
                            Canadian Solar
                            </td>
                            <td className="px-6 py-3   ">
                            Solar Array 
                            </td>
                            <td className="px-6 py-3   ">
                            20 Years
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Conext MPPT
                            </td>
                            <td className="px-6 py-3   ">
                            Schneider
                            </td>
                            <td className="px-6 py-3   ">
                            Charge Controller 
                            </td>
                            <td className="px-6 py-3   ">
                            5 Year
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            XW Pro 6848 NA
                            </td>
                            <td className="px-6 py-3   ">
                            Schneider
                            </td>
                            <td className="px-6 py-3   ">
                            Inverter 
                            </td>
                            <td className="px-6 py-3   ">
                            5 Years
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Ampliphi 3.8 kWh
                            </td>
                            <td className="px-6 py-3   ">
                            Simpliphi
                            </td>
                            <td className="px-6 py-3   ">
                            Battery Bank 
                            </td>
                            <td className="px-6 py-3   ">
                            10 Year
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Stellar Edge
                            </td>
                            <td className="px-6 py-3   ">
                            New Sun Road
                            </td>
                            <td className="px-6 py-3   ">
                            Monitoring & Control 
                            </td>
                            <td className="px-6 py-3   ">
                            2 Years
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            SolarContainer
                            </td>
                            <td className="px-6 py-3   ">
                            BoxPower
                            </td>
                            <td className="px-6 py-3   ">
                            Container   
                            </td>
                            <td className="px-6 py-3   ">
                            10 Years
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            AIE-G 30
                            </td>
                            <td className="px-6 py-3   ">
                            AIE
                            </td>
                            <td className="px-6 py-3   ">
                            Generator 
                            </td>
                            <td className="px-6 py-3   ">
                            2 Year
                            </td>
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
