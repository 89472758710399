import React from 'react'
import Sidebardata from '../home/Sideebar'
import Header from '../home/header'
import Hero from './Hero'
import Mainsection from './Mainsection';
import { useParams } from 'react-router-dom';

export default function Index() {
    const { site_id } = useParams();
    return (
        <>
            <div className='grid grid-cols-12  ' >
                <div className=' lg:col-span-2'>
                    <Sidebardata />
                </div>
                <div className=" transition-none col-span-12 lg:col-span-12 mr-3  ">
                <div className=' w-[85%] sm:w-[95%] ml-auto  ' >
                        <Header />
                        <Hero />
                        <Mainsection site_id={site_id} />
                    </div>
                </div>
            </div>
        </>
    )
}
