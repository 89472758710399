import React from "react";
import Generationtable from "./Generationtable";
import Fillouttable from "./Sitearea";
import SetTable from "./SetTable";
import graphdata from "../../assets/images/Graphdata.png";
import Electrictable from "./Electrictable";
import Electricfilltable from "./Sizingtable";
import Tableassumption from "./Tableassumption";
import Civiltable from "./Civiltable";
import Temparuretable from "./Temparuretable";
import Deviationtable from "./Deviationtable";
import Moniteringtable from "./Moniteringtable";
import Designtable from "./Designtable";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//import basisOfDesign from './basisOfDesign.json';

import Config from "../../utils/Config";

import {
  setTitle,
  setShadingAnalysis,
  setSiteDiscussionsGeneralEquips,
  setSiteDiscussionsSiteAreas,
  setSetbackNotesSetbackAssumptions,
  setElectricalSizingLoadBasisSummaries,
  setElectricalSizingSiteAreas,
  setDesignParameters,
  setCivilDesign,
  setTemperatureDesign,
  setDesignDeviation,
  setMonitoringOverview,
  setDesignLife,
} from "../../redux/slices/basisOfDesignSlice";

export default function Hero({ site_id, design_id }) {
  const user = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [siteName, setSiteName] = React.useState("");

  const {
    title,
    shading_analysis,
    site_discussions_general_equips,
    site_discussions_site_areas,
    setback_notes_setback_assumptions,
    electrical_sizing_load_basis_summaries,
    electrical_sizing_site_areas,
    design_parameters,
    civil_design,
    temperature_design,
    design_deviation,
    monitoring_overview,
    design_life,
  } = useSelector((state) => state.basisOfDesign);

  let report_content = React.createRef();

  const basisOfDesign = {
    site_discussions_general_equips: [
      {
        component: "Inverter",
        manufacturer_model: "Schneider / XW Pro 6848 NA",
        unit_rating: "6.8",
        units: "kVA",
        unit_qty: 4,
      },
      {
        component: "Charge Controller",
        manufacturer_model: "Schneider / MPPT 100",
        unit_rating: "600",
        units: "Vdc",
        unit_qty: 2,
      },
      {
        component: "Battery ESS",
        manufacturer_model: "SimpliPhi AmpliPhi Battery Modules",
        unit_rating: "3.8",
        units: "kWh-dc",
        unit_qty: 16,
      },
      {
        component: "Monitoring & Controls",
        manufacturer_model: "New Sun Road / Communications",
        unit_rating: "N/A",
        units: "N/A",
        unit_qty: 2,
      },
      {
        component: "HVAC",
        manufacturer_model: "Bard / W12AB Series",
        unit_rating: "1",
        units: "Ton",
        unit_qty: 1,
      },
      {
        component: "Fuel",
        manufacturer_model: "Propane Tank",
        unit_rating: "1000",
        units: "gallons",
        unit_qty: 1,
      },
    ],
    site_discussions_site_areas: [
      {
        description: "Solar Container",
        area: 160,
      },
      {
        description: "Solar Array",
        area: 260,
      },
      {
        description: "Generator Room",
        area: 60,
      },
      {
        description: "Fenced Yard Area",
        area: 892,
      },
      {
        description: "Clear Zone (30 ft)",
        area: 10980,
      },
      {
        description: "Reduced Fuel Zone (100 ft)",
        area: 55270,
      },
      {
        description: "First Responder Panel Clear Zone",
        area: 70,
      },
    ],
    setback_notes_setback_assumptions: [
      {
        equipment: "General Equipment",
        setback: "5'",
        assumption:
          "Minimum of a 5' setback around all equipment for site safety",
      },
      {
        equipment: "Solar Container",
        setback: "5'",
        assumption:
          "Setback around Solar Container end doors for personnel access",
      },
      {
        equipment: "PV Array",
        setback: "5'",
        assumption:
          "Minimum setback around PV array for working clearances. Additionally there will be a 13' setback from existing buildings. 14' minimum clear height.",
      },
      {
        equipment: "PV Array Mounting",
        setback: "14'",
        assumption: "Minimum clear height from the ground",
      },
      {
        equipment: "PCC Switchgear",
        setback: "5'",
        assumption: "Setback in front of PCC/Switchgear for working clearances",
      },
      {
        equipment: "HVAC Unit",
        setback: "5'",
        assumption: "Setback in front of HVAC for working clearances",
      },
      {
        equipment: "Propane Generator",
        setback: "5'",
        assumption: "Setback from all other equipment",
      },
      {
        equipment: "Propane Tank",
        setback: "10'",
        assumption:
          "1,000 gallon propane tank requires a 10' setback from all other equipment per NFPA 58",
      },
      {
        equipment: "Perimeter Security Fence",
        setback: "Varies",
        assumption:
          "Fence must remain the minimum setback distance from any piece of equipment. Some equipment will have a larger setback area.",
      },
      {
        equipment: "30' Defensible Space",
        setback: "30'",
        assumption:
          "Minimum setback from the edges of all equipment for defensible space, actual perimeter was expanded to a more rectangular shape to facilitate easement process",
      },
      {
        equipment: "100' Defensible Space",
        setback: "100'",
        assumption:
          "Minimum setback from the edges of all equipment for defensible space, 70' setback from 30' defensible space perimeter",
      },
      {
        equipment: "Bollards",
        setback: "5'",
        assumption:
          "Bollards will be 42 inches apart, and have a 5' clear zone from any piece of equipment.",
      },
      {
        equipment: "First Responder Panel",
        setback: "3'",
        assumption:
          "Clean zone from the first responder panel for first responder safety and working clearances.",
      },
    ],
    electrical_sizing_load_basis_summaries: [
      {
        parameter: "Average Daily Usage (kWh/day)",
        primary_loads: 18.97,
        parasitic_loads: 1.06,
        total: 20.03,
      },
      {
        parameter: "Peak Demand (kW)",
        primary_loads: 6.41,
        parasitic_loads: 0.09,
        total: 6.5,
      },
      {
        parameter: "Average (kW)",
        primary_loads: 0.79,
        parasitic_loads: 0.04,
        total: 0.83,
      },
    ],
    electrical_sizing_site_areas: [
      {
        parameter: "Solar PV Capacity (STC-kW)",
        value: 13.2,
      },
      {
        parameter: "Solar PV Tilt (degree)",
        value: 30.0,
      },
      {
        parameter: "Solar PV Azimuth",
        value: 180.0,
      },
      {
        parameter: "Nominal Battery Capacity(kWh)",
        value: 61.44,
      },
      {
        parameter: "Battery Inverter(kW-AC)",
        value: 27.2,
      },
      {
        parameter: "Generator(kVA)",
        value: 22.3,
      },
      {
        parameter: "Generator Runtime (hours/year)",
        value: 526.0,
      },
      {
        parameter: "Fuel Consumption (gal/year)",
        value: 850.0,
      },
      {
        parameter: "Propane Tank Capacity (gallons)",
        value: 1000.0,
      },
      {
        parameter: "Soiling Loss (%)",
        value: 3.0,
      },
      {
        parameter: "PV Derating Factor (%)",
        value: 94.2,
      },
      {
        parameter: "Thermal Loss Factor (w/m2k)",
        value: 29.0,
      },
      {
        parameter: "Battery Minimum State of Charge (%)",
        value: 38.3,
      },
      {
        parameter: "Renewable Fraction (%)",
        value: 71.2,
      },
    ],
    design_parameters: [
      {
        parameter: "Average Daily Usage (kwh/day)",
        value: "18.97",
        source: "1.06",
      },
      {
        section: "Temperature",
        parameter: "Minimum",
        value: "-4.0",
        source: "NREL NSRDB",
      },
      {
        section: "Temperature",
        parameter: "Maximum",
        value: "37.0",
        source: "NREL NSRDB",
      },
      {
        section: "Humidity",
        parameter: "Maximum",
        value: "62.5",
        source: "NREL NSRDB",
      },
      {
        section: "Humidity",
        parameter: "Minimum",
        value: "17.3",
        source: "NREL NSRDB",
      },
      {
        section: "Wind Loading",
        parameter: "Wind Speed (MPH)",
        value: "104.0",
        source: "ASCE Hazard Tool",
      },
      {
        section: "Wind Loading",
        parameter: "Exposure Category",
        value: "C",
        source: "ASCE Hazard Tool",
      },
      {
        section: "Wind Loading",
        parameter: "Enclosure Classification",
        value: "Open building",
        source: "ASCE Hazard Tool",
      },
      {
        section: "Snow Loading",
        parameter: "Ground Snow Load (PSF)",
        value: "21.0",
        source: "AHJ",
      },
      {
        section: "Seismic Loading",
        parameter: "Spectral Response [Short, s/s) (g)",
        value: "2.04",
        source: "ASCE Hazard Tool",
      },
      {
        section: "Seismic Loading",
        parameter: "Spectral Response [ Sec, s/1] (g)",
        value: "0.65",
        source: "ASCE Hazard Tool",
      },
      {
        section: "Seismic Loading",
        parameter: "Importance Factor",
        value: "1.5",
        source: "ASCE Hazard Tool",
      },
      {
        section: "Seismic Loading",
        parameter: "Site Class",
        value: "D",
        source: "ASCE Hazard Tool",
      },
      {
        section: "Seismic Loading",
        parameter: "Design Spectral Response [s/DS] (g)",
        value: "1.3",
        source: "ASCE Hazard Tool",
      },
      {
        section: "Seismic Loading",
        parameter: "Design Spectral Response [s/D1] (g)",
        value: "1.03",
        source: "ASCE Hazard Tool",
      },
      {
        section: "Seismic Loading",
        parameter: "Seismic Design Category",
        value: "D",
        source: "ASCE Hazard Tool",
      },
      {
        section: "Risk Category",
        parameter: "All components",
        value: "III",
        source: "PG&E",
      },
      {
        section: "Occupancy Classifications",
        parameter: "Solar Container",
        value: "S-1",
        source: "ASCE Hazard Tool",
      },
      {
        section: "Soil Condition",
        parameter: "Maximum Soil Bearing Pressure (PSI)",
        value: "1250.0",
        source: "Geotech Report",
      },
      {
        section: "Soil Condition",
        parameter: "Soil Type",
        value: "Sandy-Clay Loam",
        source: "Geotech Report",
      },
    ],
    civil_design: [
      {
        vehicle: "F-250 TRUCK",
        quantity: 2,
        dimensions: "23 feet X6 feet 4 inches",
        weight: "6100 lbs",
      },
      {
        vehicle: "SKID STEER",
        quantity: 1,
        dimensions: "8 feet 2 inches X7 feet 8 inches",
        weight: "8615 lbs",
      },
      {
        vehicle: "MINI EXCAVATOR",
        quantity: 1,
        dimensions: "17 feet 3 inches X5 feet 9 inches",
        weight: "7500 lbs",
      },
      {
        vehicle: "CONCRETE TRUCK",
        quantity: 1,
        dimensions: "24 feet X8 feet 6 inches",
        weight: "67000 lbs",
      },
      {
        vehicle: "DUMP TRUCK",
        quantity: 1,
        dimensions: "23 feet 8 inches X9 feet 6 inches",
        weight: "56000 lbs",
      },
      {
        vehicle: "CONTAINER DELIVERY TRUCK",
        quantity: 1,
        dimensions: "12 feet 8 inches X8 inches",
        weight: "10040 lbs",
      },
      {
        vehicle: "CONTAINER DELIVERY TRAILER",
        quantity: 1,
        dimensions: "31 feet X8 feet 6 inches",
        weight: "7730 lbs",
      },
      {
        vehicle: "BOOM TRUCK",
        quantity: 1,
        dimensions: "37 feet X8 feet",
        weight: "33000 lbs",
      },
      {
        vehicle: "CRANE",
        quantity: 1,
        dimensions: "45 feet X8 feet 6 inches",
        weight: "93000 lbs",
      },
      {
        vehicle: "SKIP LOADER",
        quantity: 1,
        dimensions: "23 feet x7 feet 5 inches",
        weight: "10500 lbs",
      },
      {
        vehicle: "WATER TRAILER",
        quantity: 1,
        dimensions: "13 feet 5 inches 6 feet 7 inches",
        weight: "2000 lbs",
      },
    ],
    temperature_design: [
      {
        month: "Feb",
        temperature: 7.5,
        total_heating_demand: 54.72,
        total_cooling_demand: 56.49,
      },
      {
        month: "Mar",
        temperature: 9.8,
        total_heating_demand: 47.33,
        total_cooling_demand: 48.86,
      },
      {
        month: "Apr",
        temperature: 10.3,
        total_heating_demand: 43.33,
        total_cooling_demand: 44.73,
      },
      {
        month: "May",
        temperature: 16.4,
        total_heating_demand: 10.94,
        total_cooling_demand: 11.29,
      },
      {
        month: "Jun",
        temperature: 19.4,
        total_heating_demand: 0.0,
        total_cooling_demand: 5.64,
      },
      {
        month: "Jul",
        temperature: 21.2,
        total_heating_demand: 0.0,
        total_cooling_demand: 16.75,
      },
      {
        month: "Jul",
        temperature: 21.2,
        total_heating_demand: 0.0,
        total_cooling_demand: 16.75,
      },
      {
        month: "Aug",
        temperature: 20.2,
        total_heating_demand: 0.0,
        total_cooling_demand: 10.12,
      },
      {
        month: "Sep",
        temperature: 20.8,
        total_heating_demand: 0.0,
        total_cooling_demand: 14.22,
      },
      {
        month: "Oct",
        temperature: 15.7,
        total_heating_demand: 13.94,
        total_cooling_demand: 14.39,
      },
      {
        month: "Nov",
        temperature: 9.4,
        total_heating_demand: 47.68,
        total_cooling_demand: 49.22,
      },
      {
        month: "Dec",
        temperature: 7.6,
        total_heating_demand: 59.7,
        total_cooling_demand: 61.63,
      },
    ],
    design_deviation: [
      {
        exception: "3.12.1.2 Audible Noise",
        justification:
          "Equipment generating noise for this SPS are the Inverters and the HVAC unit. The HVAC unit is rated by the manufacturer to produce 65.2 dBA at 10ft. This exceeds the noise limits for a residential zone (55 dBa). Since the inverters will be located inside of an insulated shipping container, BoxPower does not anticipate the inverter to contribute to the external nose levels.",
      },
      {
        exception: "3.9.1.6 Future Expansion",
        justification:
          "No additional foundations or future expansion has been requested by PG&E at this time.",
      },
      {
        exception: "3.9.1.14 PV Rapid Shutdown",
        justification:
          "Rapid shutdown for solar is not included in design because it is not installed on an occupied space, it is installed on a detached structure that is specifically built for solar.",
      },
      {
        exception: "3.9.2.2 Pre-approved Energy Storage System",
        justification:
          "BoxPower's proposed battery design is not on the pre-approved ESS list. Please see the provided spec sheet",
      },
      {
        exception:
          "3.9.4.1 Switchgear breaker for each load circuit exiting PCC",
        justification:
          "BoxPower currently assumes only one load circuit will be connected",
      },
      {
        exception: "3.9.10 CCTV System",
        justification: "CCTV not included in scope at this time",
      },
      {
        exception: "3.9.11.3 Chain-link fence",
        justification:
          "Unless otherwise specified by PG&E, BoxPower assumes the specified chain-link fence with mesh will be used for this site.",
      },
    ],
    monitoring_overview: [
      {
        component: "Stellar Edge",
        unit_qty: 2,
        power_consumption: "5",
      },
      {
        component: "SEL-751 Protection Relay",
        unit_qty: 1,
        power_consumption: "25",
      },
      {
        component: "PCC Meter / Acuvim IIR",
        unit_qty: 1,
        power_consumption: "5",
      },
      {
        component: "Schneider Contactor Latch Block",
        unit_qty: 1,
        power_consumption: "30",
      },
      {
        component: "Combustible Gas Sensor",
        unit_qty: 1,
        power_consumption: "20.4",
      },
      {
        component: "Managed industrial switch, LTE M2M Router",
        unit_qty: 1,
        power_consumption: "3.84",
      },
      {
        component: "Siemens 200A 120/240V Breaker, Shunt Trip",
        unit_qty: 1,
        power_consumption: "5(1 Second)",
      },
    ],
    design_life: [
      {
        component: "Ampliphi 3.8kWh",
        supplier: "Simpliphi",
        system: "Battery Bank",
        warranty: "10Year",
      },
      {
        component: "CS3W-440MB-AG",
        supplier: "Canadian Solar",
        system: "Solar Array",
        warranty: "20Years",
      },
      {
        component: "Conext MPPT",
        supplier: "Schneider",
        system: "Charge Controller",
        warranty: "5Year",
      },
      {
        component: "XW Pro 6848NA",
        supplier: "Schneider",
        system: "Inverter",
        warranty: "5Years",
      },
      {
        component: "Ampliphi 3.8kWh",
        supplier: "Simpliphi",
        system: "Battery Bank",
        warranty: "10Year",
      },
      {
        component: "Stellar Edge",
        supplier: "New Sun Road",
        system: "Monitoring &Control",
        warranty: "2Years",
      },
      {
        component: "SolarContainer",
        supplier: "BoxPower",
        system: "Container",
        warranty: "10Years",
      },
      {
        component: "AIE-G 30",
        supplier: "AIE",
        system: "Generator",
        warranty: "2Year",
      },
    ],
  };

  React.useEffect(() => {
    // console.log(
    //   "basisOfDesign.site_discussions_general_equips:",
    //   basisOfDesign.site_discussions_general_equips
    // );
    fetch(`${Config.API.URL}/design-basis/${design_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          localStorage.removeItem("user");
          navigate("/");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        console.log("res:", res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          let data = res.data;

          const reqSite = await fetch(`${Config.API.URL}/sites/${site_id}`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          });
          const resSite = await reqSite.json();

          setSiteName(resSite?.data?.site_name);

          console.log("resSite:", resSite);

          dispatch(setTitle(data.title));
          dispatch(setShadingAnalysis(data.shading_analysis));
          dispatch(
            setSiteDiscussionsGeneralEquips(
              data?.site_discussions_general_equips?.length
                ? data.site_discussions_general_equips
                : basisOfDesign?.site_discussions_general_equips
            )
          );
          dispatch(
            setSiteDiscussionsSiteAreas(
              data.site_discussions_site_areas.length
                ? data.site_discussions_site_areas
                : basisOfDesign?.site_discussions_site_areas
            )
          );
          dispatch(
            setSetbackNotesSetbackAssumptions(
              data.setback_notes_setback_assumptions.length
                ? data.setback_notes_setback_assumptions
                : basisOfDesign?.setback_notes_setback_assumptions
            )
          );
          dispatch(
            setElectricalSizingLoadBasisSummaries(
              data.electrical_sizing_load_basis_summaries.length
                ? data.electrical_sizing_load_basis_summaries
                : basisOfDesign?.electrical_sizing_load_basis_summaries
            )
          );
          dispatch(
            setElectricalSizingSiteAreas(
              data.electrical_sizing_site_areas.length
                ? data.electrical_sizing_site_areas
                : basisOfDesign?.electrical_sizing_site_areas
            )
          );
          dispatch(
            setDesignParameters(
              data.design_parameters.length
                ? data.design_parameters
                : basisOfDesign?.design_parameters
            )
          );
          dispatch(
            setCivilDesign(
              data.civil_design.length
                ? data.civil_design
                : basisOfDesign?.civil_design
            )
          );
          dispatch(
            setTemperatureDesign(
              data.temperature_design.length
                ? data.temperature_design
                : basisOfDesign?.temperature_design
            )
          );
          dispatch(
            setDesignDeviation(
              data.design_deviation.length
                ? data.design_deviation
                : basisOfDesign?.design_deviation
            )
          );
          dispatch(
            setMonitoringOverview(
              data.monitoring_overview.length
                ? data.monitoring_overview
                : basisOfDesign?.monitoring_overview
            )
          );
          dispatch(
            setDesignLife(
              data.design_life.length
                ? data.design_life
                : basisOfDesign?.design_life
            )
          );
        } else {
          // swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        console.log("Error: ", error);

        //swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
      });
  }, []);

  return (
    <>
      {/* Cover Page */}
      <div className="document-bg" id="document-bg" ref={report_content}>
        <section className="sheet cover">
          <article>
            <img src={require("../../assets/images/cover-photo.png")} alt="" />
            <div className="linear-line"></div>
            <div className="cover-footer">
              <div>
                <h1>BASIS OF DESIGN</h1>
                <h2>{siteName ? siteName : "Loading..."}</h2>
                <h3>{title}</h3>
                {/* <h2>{ is_editable ? <input type="text" onChange={this._onChangeReport.bind(this)} id="template" name="project_type" defaultValue={report_text_content?.project?.project_name} />  : report_text_content?.project?.project_name } </h2> */}
              </div>
              <div style={{ paddingTop: 10 }}>
                <img
                  src={require("../../assets/images/star-left.png")}
                  alt=""
                />
              </div>
            </div>
          </article>
        </section>

        <section className="sheet">
          <article>
            <div className="doc-page-content pt-20">
              <div className="pr-10 pl-10">
                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl text-center ">
                  Site-Specific Discussion
                </h2>
                <h3 className=" text-primary-dark f-f-sm text-base sm:text-xl mt-4 ">
                  Site Layout Narrative{" "}
                </h3>
                <p className=" text-black f-f-r text-xs sm:text-tiny">
                  The site layout features a SolarContainer designed to
                  accommodate an interconnected BESS, inverters, charge
                  controllers, communications hardware, and system controls. A
                  comprehensive overview of the microgrid and its key components
                  is outlined below:
                </p>
              </div>

              <Generationtable
                site_discussions_general_equips={
                  site_discussions_general_equips
                }
              />
              <div className="pr-10 pl-10">
                <p className=" text-black f-f-r text-xs sm:text-tiny pt-6">
                  The BoxPower Standalone Power System will be located to the
                  north of the existing residence and will include a single
                  customer disconnect that will be mounted on the southwest
                  exterior wall of the container. The point of common coupling
                  will be in the switchgear which will be pad mounted to the
                  north of the container. The battery array will be housed on
                  the container's west side, and the inverter system in a
                  walk-in space in the middle of the container.{" "}
                </p>

                <p className=" text-black f-f-r text-xs sm:text-tiny py-2">
                  As shown in the site layout, a propane tank will be installed
                  at the site (refer to the table above for the total capacity
                  of the propane tank). Propane tanks can utilize only about 80%
                  of their max fill. The propane tank will be within a mini-mesh
                  chain link fenced perimeter and have a minimum 10-foot setback
                  from the security fence and all other equipment. Additionally,
                  there will be a First Responder Panel that will house safety
                  control features for trouble men. A 14-foot gate on the
                  southwest side will provide propane tank access, while another
                  on the southeast side will allow entry to the Solar Container
                  and First Responder Panel. The hammerhead turnaround designed
                  for this project complies with full PRC 4290 Code requirements
                  and will be constructed perpendicular to the access road. For
                  site specific details and general project layout refer to the
                  “General Site Plan” in the provided Planset.
                </p>
                <p className=" text-black f-f-r text-xs sm:text-tiny py-2">
                  Specific site areas are outlined below and shown in general
                  site plans.
                </p>
              </div>
            </div>
          </article>
        </section>

        <section className="sheet">
          <article>
            <div className="doc-page-content pt-20">
              <Fillouttable
                site_discussions_site_areas={site_discussions_site_areas}
              />
              <div className="pr-10 pl-10">
                <p className=" text-black f-f-r text-xs sm:text-tiny pt-10 pb-4">
                  The Remote SPS at the project site has two lockable features
                  providing additional security to the system.{" "}
                </p>

                <ul className="list-disc w-11/12 mx-auto">
                  <li>
                    <h2 className=" text-black f-f-b text-base ">
                      Facilities Key:
                      <span className=" f-f-r text-tiny">
                        {" "}
                        It opens the Knox box on the first responder panel. The
                        keys within this box provide access to the AC disconnect
                        and system E-stop and Vent Start buttons that are also
                        mounted on the first responder panel. All authorized
                        personnel will have this key including first responders
                        to fire.{" "}
                      </span>
                    </h2>
                  </li>
                  <li className=" mt-3">
                    <h2 className=" text-black f-f-b text-base ">
                      SolarContainer Key:{" "}
                      <span className=" f-f-r text-tiny">
                        It opens the door of the SolarContainer. Only BoxPower
                        personnel and the PG&E site manager will have access to
                        this key. All keys will be kept on site in a lockbox
                        inside the Switchgear for emergency use. For key access
                        and obtaining keys to the facility, contact BoxPower’s
                        Service Department.
                      </span>
                    </h2>
                  </li>
                </ul>
              </div>
            </div>
          </article>
        </section>

        <section className="sheet">
          <article>
            <div className="doc-page-content pt-20">
              <div className="pr-10 pl-10">
                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl text-center my-4 ">
                  Summary of Setback Assumptions
                </h2>
                <p className=" text-black f-f-r text-xs sm:text-tiny ">
                  The following equipment spacing will be utilized for safety
                  and working clearance.
                </p>
              </div>
              <SetTable
                setback_notes_setback_assumptions={
                  setback_notes_setback_assumptions
                }
              />
            </div>
          </article>
        </section>

        <section className="sheet">
          <article>
            <div className="doc-page-content pt-10">
              <div className="pr-10 pl-10">
                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl text-center my-4 ">
                  Shading Analysis and Assumptions
                </h2>
                <h3 className=" text-primary-dark f-f-sm text-base sm:text-xl mt-4 ">
                  Methodology{" "}
                </h3>
                <ul className="list-disc w-11/12 mx-auto">
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      During the site visit, captures were taken at the proposed
                      array location using a Solmetric SunEye analysis tool.
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      The array location was then analyzed in a dedicated
                      session, with multiple horizon captures performed at
                      different points of the array location.
                    </h2>
                  </li>

                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      The SunEye Analysis software was used to refine the
                      horizon captures, accounting for potential shading
                      obstructions in the vicinity. Care was taken to ensure a
                      conservative approach in identifying and removing shading
                      obstructions.
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      The refined captures were exported and average obstruction
                      heights uploaded into the PVSyst solar system design
                      software for a detailed study.
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      The estimated annual energy output derived from the
                      proposed solar array was subsequently imported into HOMER
                      Pro.
                    </h2>
                  </li>
                </ul>
                <h3 className=" text-primary-dark f-f-sm text-base sm:text-xl mt-4 ">
                  SunEye Session Elevations vs Azimuth{" "}
                </h3>
                <div className="w-full h-[450px] my-2">
                  <img
                    src={
                      shading_analysis?.shading_analysis_img_url
                        ? shading_analysis?.shading_analysis_img_url
                        : graphdata
                    }
                    alt=""
                    style={{
                      height: "100%",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                    // className=" w-full h-auto my-2 "
                  />
                </div>
                {/*  */}
                <h3 className=" text-primary-dark f-f-sm text-base sm:text-xl mt-4 ">
                  Assumptions
                </h3>
                <ul className="list-disc w-11/12 mx-auto">
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Shading conditions will remain largely consistent from the
                      time of the capture and any changes made will only improve
                      solar availability
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Captures taken represent a conservative shading profile
                      for the site. The actual average height of the array will
                      differ, potentially resulting in a more favorable shading
                      profile.
                    </h2>
                  </li>

                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      The array will not be shaded by any new constructions,
                      considering both the array height and the heights and
                      distances of shading obstructions.
                    </h2>
                  </li>
                </ul>
              </div>
            </div>
          </article>
        </section>

        <section className="sheet">
          <article>
            <div className="doc-page-content pt-20">
              <div className="pr-10 pl-10">
                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl text-center mt-4 mb-2 ">
                  Electrical Sizing Methodology
                </h2>
                <h3 className=" text-primary-dark f-f-sm text-base sm:text-xl  ">
                  Load Basis
                </h3>

                <p className=" text-black f-f-r text-xs sm:text-tiny ">
                  PG&E provided the project team with a design load year
                  intended for the sizing of the renewable energy system. This
                  load served as the primary electric load for the microgrid
                  model. The engineering team at BoxPower calculated the
                  additional parasitic loads which will need to be met by the
                  proposed microgrid and added it to the HOMER simulation as a
                  secondary load profile. The sum of the primary and secondary
                  electric loads formed the load basis which was used for sizing
                  the system.
                </p>
              </div>

              <Electrictable
                electrical_sizing_load_basis_summaries={
                  electrical_sizing_load_basis_summaries
                }
              />
              <div className="pr-10 pl-10">
                <h3 className=" text-primary-dark f-f-sm text-base sm:text-xl pt-8  ">
                  System Sizing
                </h3>

                <p className=" text-black f-f-r text-xs sm:text-tiny ">
                  Before the site visit, an initial microgrid simulation was
                  performed to determine preliminary sizing for the Microgrid
                  components and provide insights into the space required for
                  the standalone power system.
                </p>
                <p className=" text-black f-f-r text-xs sm:text-tiny py-3 ">
                  Following this, BoxPower carried out a site visit to assess
                  the meteorological conditions at the intended deployment
                  location and validate the initial solar access assumptions.
                  The team used a Solmetric SunEye device to capture several
                  images of the entire horizon in order to determine solar
                  access percentage and details about obstructions. Using
                  PVSyst, these captures were processed to obtain net production
                  from the solar arrays after losses from shading, soiling and
                  thermal losses have been accounted for. BoxPower then
                  conducted techno-economic optimizations in HOMER, integrating
                  the load basis and solar production data from PVSyst. The
                  proposed standalone power system meets all the goals of the
                  project. A detailed summary of the microgrid system, along
                  with the assumptions made during the simulation, can be found
                  in the table below.
                </p>
              </div>
            </div>
          </article>
        </section>

        <section className="sheet">
          <article>
            <div className="doc-page-content pt-10">
              <Electricfilltable
                electrical_sizing_site_areas={electrical_sizing_site_areas}
              />
              <div className="pr-10 pl-10">
                <h3 className=" text-primary-dark f-f-sm text-base sm:text-xl pt-8  ">
                  System Description and Product Design
                </h3>

                <p className=" text-black f-f-r text-xs sm:text-tiny ">
                  The solar PV system is a containerized solar array
                  manufactured by BoxPower. This design attaches the solar
                  panels to the structural corners of a standard container and
                  is optimized to maximize the solar PV that can be affixed to
                  any given shipping container. The PV array sits on a
                  reinforced concrete pad that's engineered and poured onsite.
                  This BoxPower design has already been successfully implemented
                  and approved in California.
                </p>
                <p className=" text-black f-f-r text-xs sm:text-tiny py-2 ">
                  The Energy Storage System (ESS) includes inverters,
                  communications system and a battery rack, all housed within
                  the BoxPower shipping container. A dedicated externally
                  accessible battery cabinet is part of the container, which is
                  also insulated to ensure optimal performance within
                  recommended temperature ranges. The battery bank utilizes a
                  pre-wired, rack-mounted system to securely integrate batteries
                  into the container’s battery compartment..
                </p>
                <p className=" text-black f-f-r text-xs sm:text-tiny py-2 ">
                  Solar energy, managed by charge controllers, is the primary
                  source for battery charging. The integrated generator is the
                  ultimate backup for the microgrid system and can provide power
                  indefinitely as long as there is fuel in the pad-mounted tank.
                  Due to the cycle charging configuration, excess power from the
                  genset is used to charge the batteries after the primary
                  electric loads have been met. In rare occasions where the
                  battery power is depleted due to a lack of fuel to run the
                  generators, DC coupling facilitates a solar black-start,
                  allowing autonomous system restoration after the solar panels
                  recharge the batteries sufficiently. The inverter setup
                  features bi-directional pure sine wave inverters which have
                  been sized to meet the site loads.. All of the generation
                  equipment is cohesively integrated, monitored, and controlled
                  through a dedicated cloud-based portal, offering remote access
                  into the performance of the microgrid.
                </p>
              </div>
              <div className="pr-10 pl-10"></div>
            </div>
          </article>
        </section>

        <section className="sheet">
          <article>
            <div className="doc-page-content pt-10">
              <div className="pr-10 pl-10">
                <p className=" text-black f-f-r text-xs sm:text-tiny py-2 ">
                  For a detailed breakdown of the components in the proposed
                  BoxPower Microgrid, including quantities, please refer to the
                  Site Specific Discussion section.
                </p>
                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl text-center my-1">
                  Design Assumptions and Parameters
                </h2>
                <p className=" text-black f-f-r text-xs sm:text-tiny py-1 ">
                  The following load conditions in accordance with ACSE7-16 were
                  used in the design of the Microgrid
                </p>
              </div>

              <Tableassumption design_parameters={design_parameters} />
            </div>
          </article>
        </section>

        <section className="sheet">
          <article>
            <div className="doc-page-content pt-20">
              <div className="pr-10 pl-10">
                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl text-center mt-4">
                  Civil and Structural Design
                </h2>

                <h3 className=" text-primary-dark f-f-sm text-base sm:text-xl pt-8  ">
                  SolarContainer Array Design
                </h3>

                <p className=" text-black f-f-r text-xs sm:text-tiny ">
                  BoxPower provides pre-engineered solar racking solutions for
                  BoxPower arrays, utilizing proprietary racking hardware. The
                  engineering of brackets, coupling sleeves, bracing, and pipe
                  supports is customized according to the specifications of each
                  site. Standard methods are employed to interconnect bracing
                  and pipe supports. These pre-engineered structural designs and
                  calculations are executed by our reliable engineering
                  partners, who seamlessly integrate them into their foundation
                  design calculations.
                </p>
                <h3 className=" text-primary-dark f-f-sm text-base sm:text-xl pt-2  ">
                  Access Road Details
                </h3>

                <p className=" text-black f-f-r text-xs sm:text-tiny ">
                  In many instances, BoxPower enhances existing access roads to
                  optimize the layout of our Solar Power System (SPS)
                  installations. Upon completion, the new access road adheres to
                  all required specifications. Where applicable, adjustments are
                  made to existing access roads to meet traffic and parking
                  prerequisites. Our designs consistently ensure adequate turn
                  radii to facilitate unobstructed passage for emergency
                  vehicles. Our access roads are characterized by robustness,
                  constructed with high-quality materials compacted to meet
                  established standards. For precise details and designs, please
                  refer to the Site Plan Narrative of the specific project,
                  provided by our esteemed civil engineering partners.
                </p>
                <h3 className=" text-primary-dark f-f-sm text-base sm:text-xl pt-2  ">
                  Construction Plan Narrative
                </h3>

                <p className=" text-black f-f-r text-xs sm:text-tiny ">
                  The civil site work for our projects encompasses various
                  tasks, including but not limited to area clearing, access road
                  construction, and grading. Integral safety measures, such as
                  fencing, are systematically integrated to safeguard the site.
                  Designated zones within the project vicinity serve as
                  reception and staging areas for all requisite materials and
                  equipment. Our designs prioritize seamless vehicular access.
                  Subsequent to initial site preparations, installation of the
                  SPS equipment commences. Each construction phase culminates in
                  meticulous commissioning, acceptance, and witness testing
                  overseen by both BoxPower and PG&E. For a comprehensive grasp,
                  please consult the project-specific Construction Plan.
                </p>
                <h3 className=" text-primary-dark f-f-sm text-base sm:text-xl pt-2  ">
                  Construction Vehicles
                </h3>

                <p className=" text-black f-f-r text-xs sm:text-tiny pt-4 ">
                  As part of construction, BoxPower intends to have the
                  following vehicles on site:
                </p>
              </div>
            </div>
          </article>
        </section>

        <section className="sheet">
          <article>
            <div className="doc-page-content pt-20">
              <Civiltable civil_design={civil_design} />
              <div className="pr-10 pl-10">
                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl text-center mt-4">
                  Expansion Plan
                </h2>
                <p className=" text-black f-f-r text-xs sm:text-tiny pt-4 ">
                  There are currently no plans to augment the SPS over its
                  lifetime. BoxPower has designed this site as per the
                  customer's renewable energy specifications. Any expansions to
                  the system will be processed through a change order via a
                  written request to the project manager. Additional battery
                  racks can be added if the max capacity per container has not
                  been exceeded. Aged batteries that fall below the
                  performance-guarantee capacity will be replaced.
                </p>
                <p className=" text-black f-f-r text-xs sm:text-tiny pt-4 ">
                  The PV array for the current design has been maxed out and
                  cannot be expanded without changes to the proposed site
                  layout. Adding additional modules would result in an increased
                  footprint for the system proposed.
                </p>
                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl text-center mt-4">
                  Temperature Design Assumptions
                </h2>
                <p className=" text-black f-f-r text-xs sm:text-tiny pt-4 ">
                  The meteorological and solar radiation data used in simulating
                  the output of the solar panels at the sample project site was
                  obtained from the PSM v3 TMY-2020 dataset in the NREL NSRDB
                  database. TMY stands for "typical meteorological year" and it
                  refers to data that best represents median weather conditions
                  over a multiyear period. The following attributes were
                  downloaded from the database and uploaded into PVSyst for
                  analysis:
                </p>
              </div>
              <div className="pr-10 pl-10"></div>
            </div>
          </article>
        </section>

        <section className="sheet">
          <article>
            <div className="doc-page-content pt-20">
              <div className="pr-10 pl-10">
                <ul className="list-disc w-11/12 mx-auto">
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      DHI
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      DNI
                    </h2>
                  </li>

                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      GHI
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Dew Point
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Surface Albedo
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Wind Direction
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Wind Speed
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Temperature 
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Pressure
                    </h2>
                  </li>
                </ul>
                <p className=" text-black f-f-r text-xs sm:text-tiny pt-4 ">
                  The table below shows the average monthly temperature at the
                  project site - as obtained from the tmy-2020 dataset.
                </p>
                <h3 className=" text-primary-dark f-f-sm text-base sm:text-xl pt-2  ">
                  Heating and Cooling Loads Calculations
                </h3>
              </div>

              <Temparuretable temperature_design={temperature_design} />

              <div className="pr-10 pl-10">
                <p className=" text-black f-f-r text-xs sm:text-tiny pt-4 ">
                  The heating and cooling loads were estimated using the thermal
                  load analysis method and assumptions outlined below:
                </p>
              </div>
            </div>
          </article>
        </section>

        <section className="sheet">
          <article>
            <div className="doc-page-content pt-10">
              <div className="pr-10 pl-10">
                <p className=" text-black f-f-b text-tiny pt-4 ">
                  Assumptions: 
                </p>

                <ul className="list-disc w-11/12 mx-auto">
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      The system is expected to provide power to the project
                      site all year. The battery cabinet is to be kept at an
                      average operational temperature of 65°F from January to
                      December when the system is expected to provide power at
                      the site.
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      The HVAC system will increase the temperature of the
                      cabinet when it drops below 65°F and decrease it when it
                      goes above this setpoint. (Setpoint of 65°F was used for
                      modeling purposes, setpoints in the field will be 60°F
                      minimum to 80°F maximum)
                    </h2>
                  </li>

                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      0.75 air changes per hour for the battery cabinet due to
                      the HVAC system’s ventilation.
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      R-value of 10.5 for the swinging doors of the cabinet.
                      <a
                        href="https://www.insofast.com/products/shipping-container-door-insulation-bundle.html "
                        className=" cursor-pointer  text-blue-600 "
                      >
                        {" "}
                        https://www.insofast.com/products/shipping-container-door-insulation-bundle.html{" "}
                      </a>
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      R-value of 16 (2 layers of batting with R-value of 8 each)
                      for the ceiling and walls of the cabinet{" "}
                      <span>
                        {" "}
                        <a
                          href="https://www.grainger.com/product/ROXUL-2-in-x-48-in-x-24-in-Mineral-19NE78 "
                          className=" cursor-pointer  text-blue-600 "
                        >
                          {" "}
                          https://www.grainger.com/product/ROXUL-2-in-x-48-in-x-24-in-Mineral-19NE78{" "}
                        </a>{" "}
                      </span>
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Minimal heat loss through the floor of the cabinet.
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Partition wall will have no thermal insulation but will
                      have non-flammable fire-rated sheetrock facing the battery
                      compartment.
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      The thermal properties of the container were modeled using
                      conservative estimates.
                    </h2>
                  </li>
                </ul>
                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl text-center mt-4">
                  Battery Reserve
                </h2>

                <p className=" text-black f-f-r text-xs sm:text-tiny pt-4 ">
                  The minimum SoC for the battery will be set to 38.3 percent.
                  This percentage can be lowered during emergency situations to
                  provide backup. An absolute minimum threshold SoC of 5% will
                  be set to protect the batteries and also aid in system
                  recovery during a black start.
                </p>
                <h3 className=" text-primary-dark f-f-sm text-base sm:text-xl pt-2  ">
                  Battery Charging Stages
                </h3>
                <p className=" text-black f-f-r text-xs sm:text-tiny  ">
                  The Schneider inverters and MPPTs are configured for a
                  three-stage battery charging cycle. The three stages are Bulk,
                  Absorption, and Float.
                </p>
                <p className=" text-black f-f-r text-xs sm:text-tiny pt-2 ">
                  In the Bulk stage, the DC voltage is adjusted to maintain a
                  target charging current. As the batteries charge, this DC
                  voltage rises. When the DC voltage reaches the Bulk/Boost
                  Voltage Setpoint, the system transitions to the Absorption
                  stage. The Bulk/Boost Voltage Setpoint is set to 56.0V.
                </p>
                <p className=" text-black f-f-r text-xs sm:text-tiny pt-2 ">
                  In the Absorption stage, the DC voltage is held constant at
                  the Absorption voltage setpoint. The Absorption voltage
                  setpoint is set to 56.0V. As the batteries approach a full
                  state of charge, the charge current drops. When either of the
                  following occurs, the system transitions to Float stage:
                </p>
                <ul className="list-disc w-11/12 mx-auto">
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      The system has been in Absorption stage for the configured
                      Absorption Time (1 hour)
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      The charge current drops below 2% of programmed battery
                      bank Ah capacity
                    </h2>
                  </li>
                </ul>

                <p className=" text-black f-f-r text-xs sm:text-tiny pt-2 ">
                  In the Float stage, the DC voltage is held constant at the
                  Float voltage setpoint. The Float voltage setpoint is set to
                  53.32V. These charging stages are shown in the figure below.
                  Note that the “Boost” feature is not used in this system.
                </p>
                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl text-center mt-4">
                  Maximum Noise Levels
                </h2>
                <p className=" text-black f-f-r text-xs sm:text-tiny pt-2 ">
                  Equipment generating noise for this SPS are the Inverters and
                  the HVAC unit. The HVAC unit is rated by the manufacturer to
                  produce 65.2 dBA at 10ft. BoxPower has oriented the container
                  to position the HVAC as far away from existing structures as
                  possible. Schneider has not formally measured noise generated
                  from inverters, but engineers at Schneider estimate the
                  maximum noise level of 60 dBA for normal operation. Since
                  inverters will be located inside of an insulated shipping
                  container, the inverters will not contribute to the external
                  noise levels. 
                </p>
                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl text-center mt-4">
                  Summary of Design Deviations
                </h2>
                <p className=" text-black f-f-r text-xs sm:text-tiny pt-2 ">
                  BoxPower reviewed the most recent{" "}
                  <span className=" f-f-b "> Purchase and Sale Agreement </span>{" "}
                  to create the table below.
                </p>
              </div>
            </div>
          </article>
        </section>

        <section className="sheet">
          <article>
            <div className="doc-page-content pt-20">
              <Deviationtable design_deviation={design_deviation} />
              <div className="pr-10 pl-10">
                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl text-center mt-4">
                  Sequence of Operations
                </h2>
                <p className=" text-black f-f-r text-xs sm:text-tiny pt-2 ">
                  The sequence of operations is divided into three sections.
                  Refer to the sequence of operations document for more
                  information on these conditions.
                </p>

                <ul className="list-disc w-11/12 mx-auto">
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      <span className="f-f-b"> Normal conditions </span> that
                      are expected to occur on a typical day
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      <span className="f-f-b">
                        (Planned) Abnormal conditions{" "}
                      </span>
                      that can be planned or scheduled in advance or are carried
                      out intentionally by an operator
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      <span className="f-f-b">
                        (Unplanned) Abnormal conditions{" "}
                      </span>
                      caused by a failure or fault that are unplanned
                    </h2>
                  </li>
                </ul>

                <h3 className=" text-primary-dark f-f-sm text-base sm:text-xl pt-2  ">
                  Preventative Maintenance
                </h3>

                <p className=" text-black f-f-r text-xs sm:text-tiny ">
                  Procedures for shutting down certain equipment for
                  preventative maintenance are described in BoxPower’s Operation
                  & Maintenance Manual.
                </p>

                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl text-center mt-4">
                  Protection Scenarios
                </h2>
                <p className=" text-black f-f-r text-xs sm:text-tiny pt-2 ">
                  The protective relay for the PCC will be the SEL-751 Intertie
                  relay with a 24V battery backup. There will be low voltage
                  relay and battery alarms reporting to the New Sun Road Stellar
                  Edge backup battery sized to back up all monitoring and relay
                  equipment. Electrical line diagram can be viewed in the
                  electrical drawing sheets.
                </p>
              </div>
              <div className="pr-10 pl-10"></div>
            </div>
          </article>
        </section>

        <section className="sheet">
          <article>
            <div className="doc-page-content pt-20">
              <div className="pr-10 pl-10">
                <h3 className=" text-primary-dark f-f-sm text-tiny  pt-2  ">
                  Key relay implementation requirements as designated by PG&E:
                </h3>

                <p className=" text-black f-f-r text-xs sm:text-tiny ">
                  This section is intended to outline relay requirements taken
                  into consideration; Specific model & manufacturing numbers can
                  be found in the Bill of Materials.
                </p>

                <ul className="list-disc w-11/12 mx-auto">
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      SEL-751 Protection Relay (following the guidance from SEL,
                      partial part number and a PG&E firmware designation)
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Main breaker with some form of relay trip input – shunt
                      trip module OK 
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Power supply for relay and trip coil (DC battery and
                      charger)  
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Battery low voltage monitoring and undervoltage alarm -
                      Monitoring of minimum battery low voltage by separate
                      voltage relay or through charger and provide critical
                      alarm to monitoring system 
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      CT shorting blocks, voltage tap fuse blocks, and test
                      switches  
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Station Battery sizing calculation included in the
                      “Station Battery Submittal Package”
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Coordination study with 51C relay settings and settings
                      file – PG&E to assist with coordination study inputs
                    </h2>
                  </li>
                </ul>

                <h3 className=" text-primary-dark f-f-sm text-tiny  pt-2  ">
                  Power Relay Wiring for 120/240V
                </h3>

                <p className=" text-black f-f-r text-xs sm:text-tiny ">
                  The use of a single-phase voltage reference is only necessary
                  for the SEL 751 protective relay per its instructions: “In
                  addition, the SEL-751 supports single voltage input. For
                  customers with a single PT input, the SEL-751 assumes balanced
                  voltage input for all protection and metering functions.” 
                </p>

                <ul className="list-disc w-11/12 mx-auto">
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Connect power supply voltage to (A01, A02), (A01, A03) to
                      be wired together and A04 is connected to the PCC breaker.
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      (C09, C10) will provide LBC position indication and (C11,
                      C12) will provide PCC breaker status.
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Connect single phase CT ‘I1’ to IA (Z01, Z02) 
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      Connect single phase CT ‘I2’ to IB (Z03, Z04) 
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      PT1/PT2 will provide a single-phase input reference (Z09,
                      Z10) with neutrals landing elsewhere (Z12) and (Z10,Z11)
                      to be wired together.
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      PT3, after the PCC, provides single phase voltage input
                      reference (E01, E02 [Neutral])  
                    </h2>
                  </li>
                </ul>

                <h3 className=" text-primary-dark f-f-sm text-tiny  pt-2  ">
                  Power Quality
                </h3>

                <p className=" text-black f-f-r text-xs sm:text-tiny ">
                  The following power quality requirements are provided by the
                  Conext XWPro inverter and will be monitored by the NSR
                  equipment to determine the reliability of the system. 
                </p>

                <ul className="list-disc w-11/12 mx-auto">
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      The steady-state voltage distortion shall be within the
                      limits specified by IEEE 519-2014 Clause 5.1. 
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      The voltage fluctuations (rapid voltage changes and
                      flicker) shall be compliant with IEEE 1547-2018 clause
                      7.2. 
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      The steady-state voltage shall be regulated to between
                      0.95 and 1.05 per unit (“Voltage Range A”, from ANSI C84.1
                      “A” range service voltage for systems below 600V). During
                      load switching or other transient events, the voltage must
                      remain between 0.884 and 1.20 pu (herein called “Voltage
                      Range B”) and must be regulated to back within Voltage
                      Range A in no more than 30 seconds. 
                    </h2>
                  </li>
                  <li>
                    <h2 className=" text-black f-f-r text-xs sm:text-tiny ">
                      The steady-state frequency shall be regulated to within
                      59.3 Hz ≤ f ≤ 60.5 Hz, referred to here as “Frequency
                      Range A”.  During transient events the frequency shall
                      remain within the band 57 Hz ≤ f ≤ 63 Hz (for this
                      document, “Frequency Range B”), and shall be regulated
                      back to within Frequency Range A within 5 seconds.  
                    </h2>
                  </li>
                </ul>

                <h3 className=" text-primary-dark f-f-sm text-tiny  pt-2  ">
                  ESS Control Design
                </h3>

                <p className=" text-black f-f-r text-xs sm:text-tiny ">
                  All basic ESS control comes from the Conext control system.
                  This includes charging of the battery from the solar via MPPT
                  charge controllers, inverting AC power for loads, and battery
                  monitoring. Parameter adjustments and ESS control setpoints
                  can be initiated remotely via New Sun Road Stellar Edge on
                  either the Stellar or Schneider Insight platform for the ESS
                  system. The primary platform will be the Stellar platform.
                  There is a direct link to the Insight platform from within
                  Stellar. PG&E can be provided access to both, and parameters
                  for the Conext system can be initiated from either platform.
                  BoxPower recommends using Stellar as the primary platform.
                </p>
              </div>
              <div className="pr-10 pl-10"></div>
            </div>
          </article>
        </section>

        <section className="sheet">
          <article>
            <div className="doc-page-content pt-20">
              <div className="pr-10 pl-10">
                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl text-center mt-4">
                  Monitoring Overview
                </h2>
                <h3 className=" text-primary-dark f-f-sm text-base sm:text-xl mt-4 ">
                  Design Overview{" "}
                </h3>

                <p className=" text-black f-f-r text-xs sm:text-tiny ">
                  The monitoring for the SPS is provided by New Sun Road via the
                  Stellar Edge equipment and Stellar online portal. Conext
                  Insight Facility is connected to the NSR Stellar Edge by
                  ethernet through the Network switch. The Stellar Edge
                  equipment will be located in the communications enclosure
                  inside the SolarContainerTM. It will be the hub for connecting
                  all analog devices and equipment controllers.
                </p>
              </div>

              <Moniteringtable monitoring_overview={monitoring_overview} />
              <div className="pr-10 pl-10">
                <h3 className=" text-primary-dark f-f-sm text-base sm:text-xl mt-4 ">
                  Monitoring Alarms Point List{" "}
                </h3>

                <p className=" text-black f-f-r text-xs sm:text-tiny ">
                  Data will be collected and stored on a database hosted by New
                  Sun Road. This data can be accessed via the web terminal.
                  Monitoring alarms and points list detailed in the Site
                  Acceptance Test document
                </p>

                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl text-center mt-4">
                  Description of Design Life
                </h2>
                <h3 className=" text-primary-dark f-f-sm text-base sm:text-xl mt-4 ">
                  Full Wrap Warranty
                </h3>

                <p className=" text-black f-f-r text-xs sm:text-tiny py-2 ">
                  BoxPower provides a limited 10-year warranty on all BoxPower
                  system component integrations and its workmanship for the
                  containerized system. BoxPower warrants to its original
                  end-user Owner that all BoxPower containerized system
                  component integrations and BoxPower workmanship will be free
                  from defects for 10 years.  
                </p>
                <p className=" text-black f-f-r text-xs sm:text-tiny py-2 ">
                  This limited warranty does not apply to any defect resulting
                  from any (a) abuse, misuse, modification or repair (or
                  attempted modification or repair) of the BoxPower
                  containerized system or any of its components, (b) accidents
                  or force majeure events, or (c) failure to properly operate or
                  maintain the BoxPower containerized system.   
                </p>
                <p className=" text-black f-f-r text-xs sm:text-tiny pt-2 ">
                  Below is a summary of the warranties for the major equipment. 
                </p>
              </div>
            </div>
          </article>
        </section>

        <section className="sheet">
          <article>
            <div className="doc-page-content pt-10">
              <Designtable design_life={design_life} />
              <div className="pr-10 pl-10">
                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl  mt-6">
                  Fire Detection Features Overview
                </h2>

                <p className=" text-black f-f-r text-xs sm:text-tiny py-2 ">
                  The BESS (BATTERY ENERGY STORAGE SYSTEM) container consists of
                  two compartments: a non-walk-in battery compartment and an
                  inverter and communications room. The battery compartment is
                  equipped with both thermal and gas sensors, while the inverter
                  room contains a thermostat and a smoke detector. In case of a
                  fire inside the container, the thermal and smoke sensors can
                  trigger an emergency response. The automated emergency
                  response is activated by a two-factor system in which 2 out of
                  3 alarms are triggered, the alarms can come from either the
                  smoke alarm, the battery temperature sensor detecting a
                  temperature of at least 160 F, or the Hydrogen gas sensor
                  being triggered. The hydrogen gas sensor is designed to
                  trigger an active ventilation system capable of expelling
                  explosive levels of hydrogen gas, which becomes combustible at
                  a 4% concentration. The sensor sends an alarm to the NSR
                  Stellar Edge system when it detects a hydrogen concentration
                  representing 25% of the flammability limit of hydrogen. In
                  this case, 25% of the 4% flammability limit equates to a
                  hydrogen concentration of 1% (0.25 * 4% = 1%). Upon receiving
                  the alarm, the ventilation system is activated to prevent the
                  hydrogen levels from reaching the combustible threshold. If
                  the hydrogen concentration increases to 50% of the
                  flammability limit, which corresponds to a 2% concentration
                  (0.5 * 4% = 2%), the sensor triggers the fire emergency
                  response to address the heightened risk. Ventilation rates and
                  specific calculations can be found in the Fire Engineering
                  Report. A passive pressure relief vent will allow for venting
                  from the presence of gas and/or pressure differential. All
                  sensors and controls will be routed through the New Sun Road
                  Stellar monitoring platform and will provide notification
                  warning for any emergency conditions. This response is sent to
                  the BoxPower’s service team where a 24/7 operator trained in
                  emergency response protocol. The operator will initiate an
                  emergency fire response to the site after validating the
                  automated response via monitoring. Based on BoxPower’s
                  experience in numerous jurisdictions, active fire suppression
                  should not be a required aspect of this system because it is
                  not an occupied room or “walk-in” space.
                </p>

                <p className=" text-black f-f-r text-xs sm:text-tiny py-4  ">
                  Both compartments inside the SolarContainer are isolated with
                  automatic fire dampers, Vulcan Vents, to prevent embers from
                  traveling between compartments in the event of a fire. When
                  the Vulcan Vent is exposed to fire, the coating on the mesh
                  expands and self-closes the vent. Additionally, Vulcan vents
                  will be installed on the HVAC unit to suppress fire
                  propagation from the HVAC unit. Manual fire extinguishers will
                  be mounted inside and outside the container.
                </p>
              </div>
              <div className="pr-10 pl-10"></div>
            </div>
          </article>
        </section>

        <section className="sheet">
          <article>
            <div className="doc-page-content pt-10">
              <div className="pr-10 pl-10">
                <p className=" text-black f-f-r text-xs sm:text-tiny py-4  ">
                  <span className=" f-f-b text-primary-dark text-base ">
                    {" "}
                    Sequence of Operations Automatic Fire Protection:
                  </span>
                  If the combustible gas sensor detects a concentration of
                  hydrogen gas greater than 1% inside the battery enclosure, the
                  ventilation fan will automatically start and the motorized
                  intake damper will open. The fan and damper will operate for a
                  minimum of 30 minutes to ensure adequate ventilation of the
                  enclosure. If, after 30 minutes of operation the hydrogen
                  sensor still detects a concentration of hydrogen gas greater
                  than 1%, the ventilation fan will continue to operate
                  indefinitely until the hydrogen concentration is reduced to an
                  acceptable level. This safety feature is designed to prevent
                  potential hazards caused by the buildup of hydrogen gas within
                  the battery enclosure.
                </p>

                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl  mt-6 text-center ">
                  Vessels Storing Hazardous Liquids
                </h2>

                <p className=" text-black f-f-r text-xs sm:text-tiny py-2 ">
                  For the initial deployment of the renewable generation
                  enclosure (SolarContainer), there are no hazardous materials
                  present. One item to mention would be the lithium
                  ferro-phosphate (LFP) batteries. Under normal use, this
                  battery is not expected to expose the user to hazardous
                  ingredients. USA: This battery is an article pursuant to 29
                  CFR 1910.1200 and, as such, is not subject to the OSHA Hazard
                  Communication Standard Requirement. This product meets the
                  definition of a “Manufactured Article” and is not subject to
                  the regulations of the Hazardous Products Act.
                </p>
                <p className=" text-black f-f-r text-xs sm:text-tiny py-2 ">
                  For the deployment of a renewable propane genset, the propane
                  fuel would be considered hazardous by the OSHA Hazard
                  Communication Standard (29 CFR 1910.1200). FLAMMABLE GASSES -
                  Category 1 GASSES UNDER PRESSURE - Liquefied gas. Danger
                  Hazard statements: Extremely flammable gas. Contains gas under
                  pressure; may explode if heated.
                </p>

                <p className=" text-black f-f-r text-xs sm:text-tiny py-2 ">
                  The fuel tank will be set on a pad with a 10-foot setback from
                  all other equipment and the fence line. It is BoxPower’s
                  understanding that LPG (Liquified Petroleum Gas) does not
                  require any secondary containment. In the event of a leak in
                  the LPG tank, the LPG would vaporize at all temperatures in
                  the temperature range being considered for this location.  LPG
                  vaporizes above -42°C, -44°F
                </p>

                <h2 className=" f-f-sm text-primary  text-2xl  sm:text-4xl  mt-6 text-center ">
                  First Responder Emergency Procedure
                </h2>

                <p className=" text-black f-f-r text-xs sm:text-tiny py-2 ">
                  The SolarContainerTM is equipped with a fire alarm system that
                  can initiate an emergency response in the event of a fire
                  inside the container. The automated response is activated by a
                  multi-factor system, in which at least two of the three
                  identification methods indicate a fire. For example, high
                  battery temperature and oxygen alarm occur together, or if
                  high battery temperature and fire alarm are triggered
                  simultaneously, or if oxygen alarm and fire alarm are detected
                  at the same time, it is likely there is a fire. Upon detecting
                  the presence of a fire through this multi-factor system, the
                  response is sent to a 24/7 operator trained in emergency
                  response protocol. The operator will initiate emergency fire
                  response to the site after validating the automated response
                  via monitoring.
                </p>

                <p className=" text-black f-f-r text-xs sm:text-tiny py-2 ">
                  Emergency first responders will have access to and knowledge
                  of the Knox Box location for emergency access. The Knox Box
                  will house keys to the site gates and equipment on the First
                  Responder Panel (AC Disconnect & E-Stop button). The personnel
                  access door to the inverter room has a window installed to
                  check for the presence of a fire. If there is a fire inside
                  the SPS container, all doors should remain closed, and the
                  area should be monitored until it burns out. If a fire is
                  present at the first responder panel or the solar array,
                  maintain a safe distance, if possible, extinguish fire with
                  the fire extinguishers located inside and outside the
                  SolarContainer. If possible, the first responder panel can be
                  safely disconnected from the system by utilizing the AC
                  Disconnect and System E-Stop button, while the AIMS PV
                  disconnect can be employed to safely disconnect the solar
                  array from the system. Taking these safety measures will
                  minimize the risk of injury to individuals and potential
                  damage to the system during an emergency.
                </p>

                <p className=" text-black f-f-r text-xs sm:text-tiny py-2 ">
                  The First Responder Panel (FRP) will be approximately 30 feet
                  to the west of the SolarContainer and serves as a critical
                  access point for emergency personnel and utility worker
                  access. The first responder panel houses the locked AC
                  disconnect between the SPS PCC and the customer
                  distribution/meter, allowing for a quick halt to the flow of
                  energy to the customer, de-energizing the customer loads and
                  isolating the SPS from the customer loads.
                </p>
              </div>
            </div>
          </article>
        </section>

        <section className="sheet">
          <article>
            <div className="doc-page-content pt-20">
              <div className="pr-10 pl-10">
                <p className=" text-black f-f-r text-xs sm:text-tiny py-2 ">
                  The FRP is equipped with a single Emergency-Stop button that
                  stops the generation of power from the inverters and manually
                  starts the active ventilation system composed of a ventilation
                  fan and motorized intake. This system can rapidly expel
                  harmful fumes and gases from the SolarContainer. A green
                  indicator light will illuminate anytime the ventilation system
                  is active. Upon activating the E-Stop, the SEL is
                  tripped/opened, an alarm is sent to Stellar, the Bard HVAC is
                  disabled, the Charge Controller is disabled, there is no
                  charge or discharge to/from the Main BESS, and an amber
                  indicator light will illuminate, indicating the system has
                  stopped outputting power to the customer.
                </p>
                <p className=" text-black f-f-r text-xs sm:text-tiny py-2 ">
                  The E-Stop button will be locked and accessible to first
                  responders; the customer would not be able to use this button.
                  A coded lockbox for keys will be mounted near the site
                  entrance gate. The keys to access the previously mentioned AC
                  disconnect and E-Stop button will be in this box. More details
                  regarding the positioning of safety features can be found in
                  the Emergency Access Plan provided
                </p>
              </div>
            </div>
          </article>
        </section>
      </div>

      {/* <div className=' bg-white w-10/12 sm:w-9/12  lg:w-7/12   mx-auto m-3 p-7 rounded-[4px]   overflow-y-scroll overflow-x-scroll ' >
        <div className=' w-11/12 mx-auto ' >

        </div>
      </div> */}
    </>
  );
}
