import React, { useState } from "react";
import { Link } from "react-router-dom";
import DropdownBasic from "./DialogDropdown";

export default function TestingDilog({ showModal2, setshowModal2 }) {
  // const [showModal2, setshowModal2] = useState(false);

  return (
    <div>
      {showModal2 ? (
        <>
          <div className="dilog-box" onClick={() => setshowModal2(false)}></div>

          <div
            id="defaultModal"
            tabindex="-1"
            aria-hidden="true"
            className="fixed top-[30%]   right-0 z-[10000000]  w-full p-4 overflow-x-hidden overflow-y-auto inset-0 h-[calc(100%-1rem)] max-h-full"
          >
            <div className="relative w-full max-w-xs max-h-full ml-auto mr-auto ">
              <div className="relative bg-grey-light  rounded-lg shadow  p-4 ">
                <h2 className=" f-f-r text-lg text-black text-center ">
                  Are you sure you want to delete this document?
                </h2>
                <div className="grid grid-cols-12 gap-4  mt-5 ">
                  <div className=" col-span-12 md:col-span-6  ">
                    <button
                      onClick={() => setshowModal2(false)}
                      className=" rounded-[8px] bg-primary-vdark  f-f-r text-lg text-white text-center w-full h-[48px]  "
                    >
                      Delete
                    </button>
                  </div>
                  <div className=" col-span-12 md:col-span-6  ">
                    <button
                      onClick={() => setshowModal2(false)}
                      className=" rounded-[8px] bg-black-vlight  f-f-r text-lg text-white text-center w-full h-[48px]  "
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
