import React from "react";

import { useSelector } from 'react-redux';

export default function GenerationTable(props) {
  const [ isUpdatingFromScenario, setIsUpdatingFromScenario ] = React.useState(false);
  const [ isEditable, setIsEditable ] = React.useState(false);
  const [ equipments, setEquipments ] = React.useState([
    {
      component: "Inverter",
      manufacturer_model: "Schneider / XW Pro 6848 NA",
      unit_rating: "6.8",
      units: "kVA",
      unit_qty: 4,
    },
    {
      component: "Charge Controller",
      manufacturer_model: "Schneider / MPPT 100",
      unit_rating: "600",
      units: "Vdc",
      unit_qty: 2,
    },
    {
      component: "Battery ESS",
      manufacturer_model: "SimpliPhi AmpliPhi Battery Modules",
      unit_rating: "3.8",
      units: "kWh-dc",
      unit_qty: 16,
    },
    {
      component: "Monitoring & Controls",
      manufacturer_model: "New Sun Road / Communications",
      unit_rating: "N/A",
      units: "N/A",
      unit_qty: 2,
    },
    {
      component: "HVAC",
      manufacturer_model: "Bard / W12AB Series",
      unit_rating: "1",
      units: "Ton",
      unit_qty: 1,
    },
    {
      component: "Fuel",
      manufacturer_model: "Propane Tank",
      unit_rating: "1000",
      units: "gallons",
      unit_qty: 1,
    },
  ]);
  const { site_discussions_general_equips } = useSelector((state) => state.basisOfDesign);

  React.useEffect(() => {
    // console.log("site_discussions_general_equips:", site_discussions_general_equips);
    if (site_discussions_general_equips?.length) {
      setEquipments(site_discussions_general_equips);
    }
  }, [site_discussions_general_equips]);

  const _onChangeEquipmentInput = (e) => {
    let data = [...equipments];

    data[e.target.id][e.target.name] = e.target.value;

    setEquipments(data);
  };
  const saveChanges = () => {
    props.onSaveGenerationEquipmentSummary(equipments);
    setIsEditable(!isEditable);
    props.onEditGenerationTable(!isEditable);
  };
  const _setIsEditable = () => {
    setIsEditable(!isEditable);
    props.onEditGenerationTable(!isEditable);
  }
  const _updateFromScenarioProducts = () => {
    setIsUpdatingFromScenario(true);
    setTimeout(async() => {
      await props.onExtractScenarioProducts();
      await props.onEditGenerationTable(!isEditable);

      setIsUpdatingFromScenario(false);
    }, 2000);
  }
  return (
    <div>
      <div className="relative overflow-x-auto mt-8 ">
        <button
          onClick={ isUpdatingFromScenario ? () => console.log('Loading...')  : _updateFromScenarioProducts }
          className="absolute top-2 left-2 border bg-primary w-[65px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
        >
          { isUpdatingFromScenario ? 'Loading...' : 'Update' }
        </button>
        <button
          onClick={ isEditable ? saveChanges : _setIsEditable }
          className="absolute top-2 right-2 border bg-primary w-[45px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
        >
          { isEditable ? "Save" : "Edit" }
        </button>
        <table className=" table-site w-full  text-left  ">
          <caption className="px-3 py-2 f-f-m text-2xl text-center  bg-primary text-white ">
            Generation Equipment Summary 
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b text-base text-black-dark ">
              <th className="px-6 py-3">Component</th>
              <th className="px-6 py-3 min-w-[250px]">Manufacturer / Model</th>
              <th className="px-6 py-3" style={{ width: 150 }}>Unit Rating</th>
              <th className="px-6 py-3" style={{ width: 130 }}>Units</th>
              <th className="px-6 py-3" style={{ width: 120 }}>Unit Qty</th>
            </tr>
          </thead>
          <tbody>
            {equipments.map((equipment, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-base text-black-dark "
                >
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeEquipmentInput}
                        className="input-equipment-table"
                        id={i}
                        name="component"
                        value={equipment.component}
                      />
                    ) : (
                      equipment.component
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeEquipmentInput}
                        className="input-equipment-table"
                        id={i}
                        name="manufacturer_model"
                        value={equipment.manufacturer_model}
                      />
                    ) : (
                      equipment.manufacturer_model
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeEquipmentInput}
                        className="input-equipment-table"
                        id={i}
                        name="unit_rating"
                        value={equipment.unit_rating}
                      />
                    ) : (
                      equipment.unit_rating
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeEquipmentInput}
                        className="input-equipment-table"
                        id={i}
                        name="units"
                        value={equipment.units}
                      />
                    ) : (
                      equipment.units
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="number"
                        onChange={_onChangeEquipmentInput}
                        className="input-equipment-table"
                        id={i}
                        name="unit_qty"
                        value={equipment.unit_qty}
                      />
                    ) : (
                      equipment.unit_qty
                    )}
                  </td>
                </tr>
              );
            })}
            
          </tbody>
        </table>
      </div>
    </div>
  );
}
