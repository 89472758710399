import React from "react";

import { useParams } from "react-router-dom";

import Config from "../../utils/Config";

import Loading from "../../Website/Loading";
import Sidebardata from "../home/Sideebar";
import Header from "../home/header";
import Hero from "./Hero";
import Projectsite from "./Projectsite";
import Deliverables from "./Deliverables";
import Actioncard from "./Actioncard";
import ElectricloadCrd from "./ElectricloadCrd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

import {
  setParamsProjects,
  setParamsSites,
  setParamsScenarios,
} from "../../redux/slices/helperSlice";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function Index() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.profile);

  const navigate = useNavigate();
  const { site_id } = useParams();
  const [site, setSite] = React.useState({});

  const [siteDistance, setSiteDistance] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [loadingSiteProject, setLoadingSiteProject] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    fetch(`${Config.API.URL}/sites/${site_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        setLoading(false);
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          localStorage.removeItem("user");
          navigate("/");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        setLoading(false);
        //console.log('res.site:', res.data);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          setSite(res.data);

          // setScenarios(res.data.data);
        } else {
          //swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error: ", error);
      });
  }, [site_id, user, navigate]);

  const _onDeleteSite = () => {
    console.log("site:", site);

    swal({
      title: `Are you sure?`,
      text: `You want to delete "${site.site_name}"? because once deleted, you will not be able to recover this site!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(`${Config.API.URL}/sites/${site.id}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        })
          .then((res) => {
            // if 401 error (unauthenticated user, then log user out)
            if (res.status === 401) {
              console.log("401 Error: Unauthenticated");
              localStorage.removeItem("user");
              navigate("/");
            }
            return res;
          })
          .then((res) => res.json())
          .then(async (res) => {
            console.log("res:", res);

            // if request is succesful, alert user that project has been cloned
            if (res.status) {
              navigate(`/project/view/${site.project_id}`);
              //swal(`The "${site.site_name}" site has been successfully deleted!`, { icon: "success" });
            } else {
              swal("Oh no!", res.message, "error");
            }
          })
          .catch((error) => {
            console.log("Error: ", error);

            swal(
              "Oh no!",
              "An error occured!!! while connecting to server. Please try again",
              "error"
            );
          });
      }
    });
  };
  const _onDeleteScenario = (scenario) => {
    // console.log("scenario:", scenario);

    const data = { ...site };
    const scenarios = [...data?.scenarios];
    const index = scenarios.findIndex((x) => x.id === scenario.id);

    swal({
      title: `Are you sure?`,
      text: `You want to delete "${scenario.scenario_name}"? because once deleted, you will not be able to recover this scenario!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(`${Config.API.URL}/scenarios/${scenario.id}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        })
          .then((res) => {
            // if 401 error (unauthenticated user, then log user out)
            if (res.status === 401) {
              console.log("401 Error: Unauthenticated");
              localStorage.removeItem("user");
              navigate("/");
            }
            return res;
          })
          .then((res) => res.json())
          .then(async (res) => {
            console.log("res-DELETE:", res);

            // if request is succesful, alert user that project has been cloned
            if (res.status) {
              scenarios.splice(index, 1);
              data.scenarios = scenarios;

              setSite(data);

              swal(
                `The "${scenario.scenario_name}" scenario has been successfully deleted!`,
                { icon: "success" }
              );
            } else {
              swal("Oh no!", res.message, "error");
            }
          })
          .catch((error) => {
            console.log("Error: ", error);

            swal(
              "Oh no!",
              "An error occured!!! while connecting to server. Please try again",
              "error"
            );
          });
      }
    });
  };
  const _onExportSiteData = () => {
    setLoadingSiteProject(true);
    fetch(`${Config.API.URL}/projects/${site.project_id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
          localStorage.removeItem("user");
          navigate("/");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          const project_d = res.data;

          //console.log('project_d:', project_d);

          dispatch(setParamsProjects([project_d]));

          const site_d = { ...site };
          site_d.checked = true;

          //console.log('site_d:', site_d);

          dispatch(setParamsSites([site_d]));
          if (site_d?.scenarios?.length) {
            const scenarios_d = [];

            for (let k = 0; k < site_d?.scenarios?.length; k++) {
              const scenario = { ...site_d?.scenarios[k] };
              scenario.checked = true;

              //console.log('scenario:', k, scenario);

              scenarios_d.push(scenario);
            }

            dispatch(setParamsScenarios(scenarios_d));
          }
          setLoadingSiteProject(false);
          navigate("/export/data");
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoadingSiteProject(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });

    // fetch(`${Config.API.URL}/exports/site-with-scenario/${site.id}`, {
    //     method: 'GET',
    //     headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` }
    // }).then((res) => {
    //     // if 401 error (unauthenticated user, then log user out)
    //     if ((res.status) === 401) {
    //       console.log("401 Error: Unauthenticated");
    //       localStorage.removeItem('user');
    //       navigate('/');
    //     }
    //     return res;
    // }).then((res) => res.json()).then( async (res) => {
    //     console.log('res:', res);

    //     // if request is succesful, alert user that project has been cloned
    //     if (res.status) {
    //         var element = document.createElement('a');
    //         element.setAttribute('href', res.data.url);

    //         element.style.display = 'none';
    //         document.body.appendChild(element);

    //         element.click();

    //         document.body.removeChild(element);
    //         //navigate(`/project/view/${site.project_id}`);
    //         //swal(`The "${site.site_name}" site has been successfully deleted!`, { icon: "success" });

    //     } else {
    //       swal("Oh no!", res.message, "error");
    //     }
    // }).catch((error) => {
    //     console.log('Error: ', error);

    //     swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
    // });
  };

  if (!site) {
    <p>Loading...</p>;
  }

  return (
    <>
      <div className="grid grid-cols-12  ">
        <div className=" lg:col-span-2">
          <Sidebardata />
        </div>
        <div className=" transition-none col-span-12 lg:col-span-12 mr-3  ">
          <div className=" w-[85%] sm:w-[95%] ml-auto ">
            <Header />
            {loading ? (
              <Loading />
            ) : (
              <>
                <Hero site={site} />
                <Deliverables
                  onExportSiteData={_onExportSiteData}
                  loadingSiteProject={loadingSiteProject}
                  onDeleteSite={_onDeleteSite}
                  onDeleteScenario={_onDeleteScenario}
                  site={site}
                  scenarios={site?.scenarios}
                />
                <Projectsite
                  onExportSiteData={_onExportSiteData}
                  loadingSiteProject={loadingSiteProject}
                  onDeleteSite={_onDeleteSite}
                  onDeleteScenario={_onDeleteScenario}
                  site={site}
                  scenarios={site?.scenarios}
                />
                {site ? (
                  <>
                    <Actioncard
                      site={site}
                      onReceiveSite={setSite}
                      onReceiveDistance={setSiteDistance}
                    />
                    <ElectricloadCrd
                      site={site}
                      onReceiveSite={setSite}
                      siteDistance={siteDistance}
                    />
                  </>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
