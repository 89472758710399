import React from "react";

export default function FilloutTable({ electrical_sizing_site_areas }) {
  return (
    <div className=" pr-10 pl-10 ">
      {/*  table started */}

      <div className="relative overflow-x-auto mt-3  ">
        <table className=" table-site w-full border border-slate-500 text-left  ">
          <caption className="border border-slate-600 px-3 py-2 f-f-m text-lg text-center  bg-primary text-white ">
            Site Areas
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b  text-sm text-black-dark ">
              <th className="border border-slate-600 px-6 py-3    ">Parameter</th>
              <th className="border border-slate-600 px-6 py-3    ">Value</th>
            </tr>
          </thead>
          <tbody>
            {electrical_sizing_site_areas.map((area, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-sm text-black-dark "
                >
                  <td className="border border-slate-600 px-6 py-3">{area.parameter}</td>
                  <td className="border border-slate-600 px-6 py-3">{area.value}</td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Solar PV Capacity (STC-kW)
                         </td>
                         <td className="px-6 py-3   ">
                         13.2
                         </td>
                         
                     
                     </tr>
                     <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Solar PV Tilt (degree)
                         </td>
                         <td className="px-6 py-3   ">
                         30
                         </td>
                         
                     
                     </tr>
                     <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Solar PV Azimuth
                         </td>
                         <td className="px-6 py-3   ">
                         180
                         </td>
                     </tr>
                     <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Nominal Battery Capacity(kWh)
                         </td>
                         <td className="px-6 py-3   ">
                         61.44
                         </td>
                     </tr>
                     <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Battery Inverter(kW-AC)
                         </td>
                         <td className="px-6 py-3   ">
                         27.2
                         </td>
                     </tr>
                     <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Generator(kVA)
                         </td>
                         <td className="px-6 py-3   ">
                         22.3
                         </td>
                     </tr>
                     <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Generator Runtime (hours/year)
                         </td>
                         <td className="px-6 py-3   ">
                         526
                         </td>
                     </tr>
                     <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Fuel Consumption (gal/year)
                         </td>
                         <td className="px-6 py-3   ">
                         850
                         </td>
                     </tr>
                     <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Propane Tank Capacity (gallons)
                         </td>
                         <td className="px-6 py-3   ">
                         1000
                         </td>
                     </tr>
                     <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Soiling Loss (%)
                         </td>
                         <td className="px-6 py-3   ">
                         3.0%
                         </td>
                     </tr>
                     <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         PV Derating Factor (%)
                         </td>
                         <td className="px-6 py-3   ">
                         94.2%
                         </td>
                     </tr>
                     <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Thermal Loss Factor (W/m2K)
                         </td>
                         <td className="px-6 py-3   ">
                         29
                         </td>
                     </tr>
                     <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Battery Minimum State of Charge (%)
                         </td>
                         <td className="px-6 py-3   ">
                         38.3
                         </td>
                     </tr>
                     <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Renewable Fraction (%)
                         </td>
                         <td className="px-6 py-3   ">
                         71.2
                         </td>
                     </tr> */}
          </tbody>
        </table>
      </div>

      {/* table ended */}
    </div>
  );
}
