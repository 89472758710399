import React from "react";

export default function DeviationTable(props) {
  const [isEditable, setIsEditable] = React.useState(false);

  const [deviations, setDeviations] = React.useState([
    {
      exception: "3.12.1.2 Audible Noise",
      justification:
        "Equipment generating noise for this SPS are the Inverters and the HVAC unit. The HVAC unit is rated by the manufacturer to produce 65.2 dBA at 10ft. This exceeds the noise limits for a residential zone (55 dBa). Since the inverters will be located inside of an insulated shipping container, BoxPower does not anticipate the inverter to contribute to the external nose levels.",
    },
    {
      exception: "3.9.1.6 Future Expansion",
      justification:
        "No additional foundations or future expansion has been requested by PG&E at this time.",
    },
    {
      exception: "3.9.1.14 PV Rapid Shutdown",
      justification:
        "Rapid shutdown for solar is not included in design because it is not installed on an occupied space, it is installed on a detached structure that is specifically built for solar.",
    },
    {
      exception: "3.9.2.2 Pre-approved Energy Storage System",
      justification:
        "BoxPower's proposed battery design is not on the pre-approved ESS list. Please see the provided spec sheet",
    },
    {
      exception: "3.9.4.1 Switchgear breaker for each load circuit exiting PCC",
      justification:
        "BoxPower currently assumes only one load circuit will be connected",
    },
    {
      exception: "3.9.10 CCTV System",
      justification: "CCTV not included in scope at this time",
    },
    {
      exception: "3.9.11.3 Chain-link fence",
      justification:
        "Unless otherwise specified by PG&E, BoxPower assumes the specified chain-link fence with mesh will be used for this site.",
    },
  ]);
  const _onChangeDeviationsInput = (e) => {
    let data = [...deviations];

    data[e.target.id][e.target.name] = e.target.value;

    setDeviations(data);
  };
  const saveChanges = () => {
    props.onSaveDesignDeviations(deviations);
    setIsEditable(!isEditable);
    props.onEditDeviationTable(!isEditable);
  };
  const _setIsEditable = () => {
    setIsEditable(!isEditable);
    props.onEditDeviationTable(!isEditable);
  };
  return (
    <div>
      <div className="relative overflow-x-auto mt-8 ">
        <button
          onClick={ isEditable ? saveChanges : _setIsEditable }
          className="absolute top-2 right-2 border bg-primary w-[45px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
        >
          {isEditable ? "Save" : "Edit"}
        </button>
        <table className=" table-site w-full  text-left  ">
          <caption className="px-3 py-2 f-f-m text-2xl text-center  bg-primary text-white ">
            DESIGN DEVIATIONS
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b text-base text-black-dark ">
              <th className="px-6 py-3 w-[350px]   ">Exception / Deviation</th>
              <th className="px-6 py-3 min-w-[350px]   ">Justification</th>
            </tr>
          </thead>
          <tbody>
            {deviations.map((deviation, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-base text-black-dark "
                >
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <textarea
                        onChange={_onChangeDeviationsInput}
                        className="input-equipment-table"
                        id={i}
                        name="exception"
                        rows="6"
                        value={deviation.exception}
                      ></textarea>
                    ) : (
                      deviation.exception
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <textarea
                        onChange={_onChangeDeviationsInput}
                        className="input-equipment-table"
                        id={i}
                        name="temperature"
                        rows="6"
                        value={deviation.justification}
                      ></textarea>
                    ) : (
                      deviation.justification
                    )}
                  </td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            3.12.1.2 Audible Noise 
                            </td>
                            <td className="px-6 py-3   ">
                            Equipment generating noise for this SPS are the Inverters and the HVAC unit. The HVAC unit is rated by the manufacturer to produce 65.2 dBA at 10ft. This exceeds the noise limits for a residential zone (55 dBa). Since the inverters will be located inside of an insulated shipping container, BoxPower does not anticipate the inverter to contribute to the external nose levels.
                        
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            3.9.1.6 Future Expansion
                            </td>
                            <td className="px-6 py-3   ">
                            No additional foundations or future expansion has been requested by PG&E at this time.   
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            3.9.1.14 PV Rapid Shutdown
                            </td>
                            <td className="px-6 py-3   ">
                            Rapid shutdown for solar is not included in design because it is not installed on an occupied space, it is installed on a detached structure that is specifically built for solar.
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            3.9.2.2 Pre-approved Energy Storage System 
                            </td>
                            <td className="px-6 py-3   ">
                            BoxPower’s proposed battery design is not on the pre-approved ESS list. Please see the provided spec sheet. 
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            3.9.4.1 Switchgear breaker for each load circuit exiting PCC
                            </td>
                            <td className="px-6 py-3   ">
                            BoxPower currently assumes only one load circuit will be connected.  
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            3.9.10 CCTV System
                            </td>
                            <td className="px-6 py-3   ">
                            CCTV not included in scope at this time
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            3.9.11.3 Chain-link fence
                            </td>
                            <td className="px-6 py-3   ">
                            Unless otherwise specified by PG&E, BoxPower assumes the specified chain-link fence with mesh will be used for this site.
                            </td>
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
