import React from "react";
import Slectdilog from "./Dilogbox";
import { useParams } from "react-router-dom";

import Dilogbox from "../NewProjectForm/Dilogbox";

import Config from "../../utils/Config";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import swal from "sweetalert";
//import axios from "axios";

const google = window.google;

export default function Hero() {
  const user = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const { project_id } = useParams();

  const [location, setLocation] = React.useState({
    street_address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    latitude: 39.652239,
    longitude: -122.726481,
  });

  const [averageDailyUsage, setAverageDailyUsage] = React.useState(0);
  const [peakDemand, setPeakDemand] = React.useState(0);
  const [averageDemand, setAverageDemand] = React.useState(0);
  const [minimumTemperature, setMinimumTemperature] = React.useState(0);
  const [maximumTemperature, setMaximumTemperature] = React.useState(0);
  const [minimumHumidity, setMinimumHumidity] = React.useState(0);
  const [maximumHumidity, setMaximumHumidity] = React.useState(0);
  const [snowLoad, setSnowLoad] = React.useState(0);
  const [windSpeed, setWindSpeed] = React.useState(0);
  const [spectralResponseShort, setSpectralResponseShort] = React.useState(0);
  const [spectralResponseSecond, setSpectralResponseSecond] = React.useState(0);
  const [seismicImportanceFactor, setSeismicImportanceFactor] =
    React.useState(0);
  const [seismicSiteClass, setSeismicSiteClass] = React.useState("");
  const [designSpectralResponseDS, setDesignSpectralResponseDS] =
    React.useState(0);
  const [designSpectralResponseD1, setDesignSpectralResponseD1] =
    React.useState(0);
  const [seismicDesignCategory, setSeismicDesignCategory] = React.useState("");
  const [siteName, setSiteName] = React.useState("");
  const [siteContacts, setSiteContacts] = React.useState([]);
  const [siteAddress, setSiteAddress] = React.useState("");
  const [GPSCoordinates, setGPSCoordinates] = React.useState("");
  const [renewableFractionTarget, setRenewableFractionTarget] =
    React.useState("");
  const [foundationType, setFoundationType] = React.useState("");
  const [
    additionalFoundationRequirements,
    setAdditionalFoundationRequirements,
  ] = React.useState("");
  const [serviceVoltage, setServiceVoltage] = React.useState("");
  const [connectionType, setConnectionType] = React.useState("");
  const [PCCToTransformerType, setPCCToTransformerType] = React.useState("");
  const [PCCToTransformerInstall, setPCCToTransformerInstall] =
    React.useState("");
  const [PCCToTransformerDistanceFeet, setPCCToTransformerDistanceFeet] =
    React.useState(0);
  const [transformerToMeterType, setTransformerToMeterType] =
    React.useState("");
  const [transformerToMeterInstall, setTransformerToMeterInstall] =
    React.useState("");
  const [transformerToMeterDistanceFeet, setTransformerToMeterDistanceFeet] =
    React.useState(0);
  const [targetDeliveryDate, setTargetDeliveryDate] = React.useState("");
  const [equipment, setEquipment] = React.useState("");
  const [loadingMethod, setLoadingMethod] = React.useState("");
  const [shippingMethod, setShippingMethod] = React.useState("");
  const [unloadingMethod, setUnloadingMethod] = React.useState("");
  const [systemPlacement, setSystemPlacement] = React.useState("");
  const [preferredUnloadingMethod, setPreferredUnloadingMethod] =
    React.useState("");
  const [customerShippingMaxBudget, setCustomerShippingMaxBudget] =
    React.useState(0);
  const [miscellaneousItemsBeingShipped, setMiscellaneousItemsBeingShipped] =
    React.useState("");
  const [clickupSpaces, setClickupSpaces] = React.useState(
    "https://example.com"
  );
  const [PSAContact, setPSAContact] = React.useState("https://example.com");
  const [engineeringPhotos, setEngineeringPhotos] = React.useState(
    "https://example.com"
  );
  const [projectSharepoint, setProjectSharepoint] = React.useState(
    "https://example.com"
  );
  const [constructionPhotos, setConstructionPhotos] = React.useState(
    "https://example.com"
  );
  const [projectBOMWorksheet, setProjectBOMWorksheet] = React.useState(
    "https://example.com"
  );
  const [cableSchedule, setCableSchedule] = React.useState(
    "https://example.com"
  );
  const [projectSchedule, setProjectSchedule] = React.useState(
    "https://example.com"
  );
  const [ahjConfirmation, setAhjConfirmation] = React.useState(
    "https://example.com"
  );

  const [loading, setLoading] = React.useState(false);

  const [siteDistanceMiles, setSiteDistanceMiles] = React.useState("");
  const [siteDistanceDrive, setSiteDistanceDrive] = React.useState("");
  const [siteCounty, setSiteCounty] = React.useState("");

  // const [ connectionType, setConnectionType ] = React.useState("");

  const _onReceiveContact = (contacts) => {
    setSiteContacts(contacts);
    //console.log('contacts:', contacts);
  };

  function convertToDecimalFormat(input) {
    // Normalize input: uppercase and remove unnecessary spaces
    input = input.replace(/\s+/g, " ").trim().toUpperCase();

    // Handle degree formats for both standard and reversed latitude-longitude entries
    const degreePattern =
      /(\d{1,3}\.\d+|\d{1,3})°?([NS]),?\s*(\d{1,3}\.\d+|\d{1,3})°?([EW])/i;
    const degreePatternReverse =
      /(\d{1,3}\.\d+|\d{1,3})°?([EW]),?\s*(\d{1,3}\.\d+|\d{1,3})°?([NS])/i;

    let match = input.match(degreePattern) || input.match(degreePatternReverse);

    if (match) {
      let latitude = parseFloat(match[1]);
      let longitude = parseFloat(match[3]);
      let latDirection = match[2];
      let longDirection = match[4];

      // Swap if the original input had longitude first
      if (degreePatternReverse.test(input)) {
        [latitude, longitude, latDirection, longDirection] = [
          longitude,
          latitude,
          longDirection,
          latDirection,
        ];
      }

      // Convert N/S to positive/negative latitude
      if (latDirection === "S") latitude = -latitude;

      // Convert E/W to positive/negative longitude
      if (longDirection === "W") longitude = -longitude;

      // Check for NaN values in coordinates
      if (isNaN(latitude) || isNaN(longitude)) {
        return input;
      }

      return `${latitude},${longitude}`;
    }

    // Handle normal decimal format, potentially reversing coordinates if necessary
    let parts = input.split(/,\s*|\s+/);
    if (parts.length === 2) {
      let lat = parseFloat(parts[0]);
      let lon = parseFloat(parts[1]);

      // Correct order based on usual latitude range
      if (Math.abs(lat) > 90) {
        [lat, lon] = [lon, lat];
      }

      // Check for NaN values in coordinates
      if (isNaN(lat) || isNaN(lon)) {
        return input;
      }

      return `${lat},${lon}`;
    }

    // Return the input as is if it is malformed or unrecognized
    return input;
  }

  const submitSite = () => {
    if (
      siteContacts.length &&
      siteName.length &&
      siteAddress.length &&
      GPSCoordinates.length &&
      renewableFractionTarget.length &&
      connectionType.length
    ) {
      setLoading(true);
      const site_contacts = [];
      for (let i = 0; i < siteContacts.length; i++) {
        const contact = siteContacts[i];
        site_contacts.push(contact.id);
      }
      const payload = {
        project_id: project_id,
        site_name: siteName,
        site_address: siteAddress,
        site_gps: GPSCoordinates, // "39.2216489,-121.0130471",
        site_contacts: site_contacts,
        target_renewable: renewableFractionTarget,

        county: siteCounty,
        ratepayer_type: "",
        landowner: "",
        critical_load: "",
        generator_use: "",
        weight: 0,
        others: [],

        // target_delivery_date: targetDeliveryDate,
        // equipment: equipment,
        // loading: loadingMethod,
        shipping: shippingMethod,
        unloading: unloadingMethod,
        system_placement: systemPlacement,
        // customer_shipping_max_budget: customerShippingMaxBudget,
        // miscellaneous_items_being_shipped: miscellaneousItemsBeingShipped,

        site_directions: "",
        site_distance_miles: siteDistanceMiles,
        site_distance_drive: siteDistanceDrive,
        access_road_flags: "",
        procurement_yard: "",

        location_minimum_temperature: minimumTemperature,
        location_maximum_temperature: maximumTemperature,
        location_minimum_humidity: minimumHumidity,
        location_maximum_humidity: maximumHumidity,
        wind_speed: windSpeed,
        snow_load: snowLoad,
        soil_type: "",
        seismic_spectral_response_short: spectralResponseShort,
        seismic_spectral_response_second: spectralResponseSecond,
        seismic_importance_factor: seismicImportanceFactor,
        seismic_site_class: seismicSiteClass,
        design_spectral_response_short: designSpectralResponseD1,
        design_spectral_response_second: designSpectralResponseDS,
        seismic_design_category: seismicDesignCategory,

        elevation: 0,
        wind_exposure_category: "",
        snow_height: 0,
        risk_category: "",
        geologic_hazard_zone: false,
        state_seismic_landslide_zone: false,
        state_seismic_liquefaction_zone: false,
        others: [],

        //average_daily_usage: averageDailyUsage,
        peak_demand: peakDemand,
        average_demand: averageDemand,

        foundation_type: foundationType,
        foundation_requirements: additionalFoundationRequirements,
        service_voltage: serviceVoltage,
        connection_type: connectionType,
        miles_line_removed: 0,
        pcc_to_transformer_type: PCCToTransformerType,
        pcc_to_transformer_install: PCCToTransformerInstall,
        pcc_to_transformer_distance: PCCToTransformerDistanceFeet,
        transformer_to_meter_type: transformerToMeterType,
        transformer_to_meter_install: transformerToMeterInstall,
        transformer_to_meter_distance: transformerToMeterDistanceFeet,
        additional_links: [
          { name: "Clickup Space", value: clickupSpaces },
          { name: "PSA Contract", value: PSAContact },
          { name: "Project Sharepoint", value: projectSharepoint },
          { name: "Major Equipment BOM", value: projectBOMWorksheet },
          { name: "Project Schedule", value: projectSchedule },
          { name: "Design Submissions", value: "https://example.com" },
          { name: "Subcontractor Sharepoint", value: "https://example.com" },
          { name: "Engineering Photos", value: engineeringPhotos },
          { name: "Construction Photos", value: constructionPhotos },
          {
            name: "Electrical - Cable & Conduit Schedule",
            value: cableSchedule,
          },
          {
            name: "AHJ confirmation of Design Requirements",
            value: ahjConfirmation,
          },
        ],
      };

      fetch(`${Config.API.URL}/sites`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          setLoading(false);

          // if 401 error (unauthenticated user, then log user out)
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");

            localStorage.removeItem("user");
            navigate("/");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          setLoading(false);

          // if request is succesful, alert user that project has been cloned
          if (res.status) {
            navigate(`/project/view/${project_id}`);
          } else {
            swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error: ", error);

          swal(
            "Oh no!",
            "An error occured!!! while connecting to server. Please try again",
            "error"
          );
        });
    } else {
      swal("Oh no!", "All fields with asterisk(*) are required", "error");
    }
  };

  React.useEffect(() => {
    initMap();
  }, []);

  const initMap = () => {
    // street_address
    const streetA = document.querySelector("#street_address");
    // results frpm Google Maps for address autocomplete feature
    const autocomplete = new google.maps.places.Autocomplete(streetA, {
      fields: ["address_components", "geometry"],
      types: ["address"],
    });

    streetA.focus();

    // streetA.setAttribute("autoComplete", "nope");

    autocomplete.addListener("place_changed", async () => {
      let street_number = "";
      let route = "";
      const place = autocomplete.getPlace();
      const components = place.address_components;
      const position = JSON.parse(JSON.stringify(place.geometry.location));

      // setGPSCoordinates(`${position.lat}, ${position.lng}`);
      setGPSCoordinates(`${position.lat}, ${position.lng}`);

      for (let i = 0; i < components.length; i++) {
        const component = components[i];
        const type = component.types[0];
        //console.log("Full Geocode: ", component);

        switch (type) {
          case "street_number": {
            street_number = component.long_name + " ";
            break;
          }
          case "route": {
            route = component.long_name + ", ";
            break;
          }
          /*           case "postal_code_suffix": {
            location.zip = component.long_name;
            break;
          } */
          case "postal_code": {
            location.zip = component.long_name + ", ";
            break;
          }
          case "locality": {
            location.city = component.long_name + ", ";
            break;
          }
          case "administrative_area_level_1": {
            location.state = component.long_name + ", ";
            break;
          }
          case "country": {
            location.country = component.long_name;
            break;
          }
          case "administrative_area_level_2": {
            setSiteCounty(component.long_name);
            break;
          }
        }

        if (components.length === i + 1) {
          let destinations =
            street_number +
            route +
            location.city +
            location.state +
            location.zip +
            location.country;
          let origins = "12438 Loma Rica Drive, Grass Valley, CA 95945";
          setSiteAddress(destinations);

          try {
            // let distanceReq = await fetch(`https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=${destinations}&origins=${origins}&key=AIzaSyBWc5qs4BwCh-soN59z0cJmpwy3U26K0qI`);
            // let distanceRes = distanceReq.json();

            // let distanceReq = await axios.get(`https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&destinations=${destinations}&origins=${origins}&key=AIzaSyBWc5qs4BwCh-soN59z0cJmpwy3U26K0qI`);
            //console('distanceRes:', distanceReq);

            new google.maps.DistanceMatrixService().getDistanceMatrix(
              {
                origins: [origins],
                destinations: [destinations],
                travelMode: "DRIVING",
                unitSystem: google.maps.UnitSystem.IMPERIAL,
              },
              (response, status) => {
                const distance = response?.rows[0]?.elements[0];

                console.log("response:", response.rows[0].elements[0]);
                setSiteDistanceMiles(distance.distance.text);
                setSiteDistanceDrive(distance.duration.text);

                // setSiteDistanceMiles(distance.distance.value / 1609);
                // setSiteDistanceDrive(distance.duration.value / 3600);
              }
            );
          } catch (error) {
            console.log("error");
          }
        }
      }
    });
  };

  const _onChangeGPSCoordinates = (e) => {
    const formattedGps = convertToDecimalFormat(e.target.value);
    setGPSCoordinates(formattedGps);

    // if coordinates are being updated
    const coordinates = formattedGps.split(",");

    const lat = parseFloat(coordinates[0]);
    const lng = parseFloat(coordinates[1]);
    console.log("lat: ", lat);
    console.log("lng: ", lng);

    // if latitude and longitude are provided
    if (lat && lng) {
      // location.coordinates = `${lat}, ${lng}`;

      // let lngRegex = new RegExp('/^-?(([-+]?)([\d]{1,3})((\.)(\d+))?)/g');
      // let latRegex = new RegExp('/^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,15}/g');

      // console.log('Invalid lat', latRegex.test(lat))
      // console.log('Invalid lng', lngRegex.test(lng));

      let street_number = "";
      let route = "";

      // const url = `https://maps.googleapis.com/maps/api/js?latlng=${lat},${lng}&key=${Config.google_map_key}`;
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${Config.google_map_key}`;
      // console.log("url new:", url);

      // retrieve formatted address from Google Maps API
      fetch(url, {
        method: "GET",
        headers: { Accept: "application/json" },
      })
        .then((res) => res.json())
        .then(async (res) => {
          console.log("coordinates-res:", res);

          // if successful response, fill address portion of form with results
          if (res.status === "OK") {
            const place = res.results[0]; // 51.5370938, -0.1833814
            const components = place.address_components;

            // location.street_address = place.formatted_address;

            for (let i = 0; i < components.length; i++) {
              const component = components[i];
              const type = component.types[0];

              // console.log("component-2:", component)

              switch (type) {
                case "street_number": {
                  street_number = component.long_name + " ";
                  break;
                }
                case "route": {
                  route = component.long_name + ", ";
                  break;
                }
                /*           case "postal_code_suffix": {
                location.zip = component.long_name;
                break;
              } */
                case "postal_code": {
                  location.zip = component.long_name + ", ";
                  break;
                }
                case "locality": {
                  location.city = component.long_name + ", ";
                  break;
                }
                case "administrative_area_level_1": {
                  location.state = component.long_name + ", ";
                  break;
                }
                case "country": {
                  location.country = component.long_name;
                  break;
                }
                case "administrative_area_level_2": {
                  setSiteCounty(component.long_name);
                  break;
                }
              }

              if (components.length === i + 1) {
                let destinations =
                  street_number +
                  route +
                  location.city +
                  location.state +
                  location.zip +
                  location.country;
                let origins = "12438 Loma Rica Drive, Grass Valley, CA 95945";
                setSiteAddress(destinations);

                try {
                  new google.maps.DistanceMatrixService().getDistanceMatrix(
                    {
                      origins: [origins],
                      destinations: [place.formatted_address],
                      travelMode: "DRIVING",
                      unitSystem: google.maps.UnitSystem.IMPERIAL,
                    },
                    (response, status) => {
                      const distance = response?.rows[0]?.elements[0];

                      console.log("response:", response.rows[0].elements[0]);
                      setSiteDistanceMiles(
                        distance?.distance?.text ? distance?.distance?.text : ""
                      );
                      setSiteDistanceDrive(
                        distance?.duration?.text ? distance?.duration?.text : ""
                      );
                    }
                  );
                } catch (error) {
                  console.log("error");
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
        });

      // initMap();
    }
  };

  return (
    <>
      <div className="bg-white rounded-[8px] p-6  mt-[90px] lg:mt-3 ">
        <h2 className=" f-f-sm text-3xl text-black text-center ">
          {" "}
          Create New Site{" "}
        </h2>
        <div className="2xl:px-20 ">
          <div className="grid grid-cols-12 mt-10   ">
            <div className=" col-span-12 lg:col-span-5 "></div>
            {/* <div className=" col-span-12 lg:col-span-7 ">
              <ul className="inline-flex w-full"  >
                <li className=' w-full mt-2 ' >
                  <h2 className=" f-f-sm text-lg capitalize  text-primary " >Electric Load</h2>
                </li>
                <li>
                  <Slectdilog />
                </li>
              </ul>
            </div> */}

            {/* .....................  Electric input started ..............*/}

            {/* input section started */}
            {/* <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Average Daily Usage (kWh/day)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input type='number' onChange={e => setAverageDailyUsage(e.target.value)} value={averageDailyUsage} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Peak Demand (kW)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input type='number' onChange={e => setPeakDemand(e.target.value)} value={peakDemand} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Average Demand (kW)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input type="number" onChange={e => setAverageDemand(e.target.value)} value={averageDemand} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div> */}
          </div>
        </div>
        {/* .....................  Electric input ended ..............*/}
        {/* ................ Parameters started */}

        {/* <div className='2xl:px-20 ' >
          <div className="grid grid-cols-12 mt-10   ">
            <div className=" col-span-12 lg:col-span-5 "></div>
            <div className=" col-span-12 lg:col-span-7 ">
              <ul className="inline-flex w-full"  >
                <li className=' w-full mt-2 ' >
                  <h2 className=" f-f-sm text-lg capitalize  text-primary " >Design Parameters</h2>
                </li>
              </ul>
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Minimum Temperature (C)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input type="number" onChange={e => setMinimumTemperature(e.target.value)} value={minimumTemperature} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Maximum Temperature (C)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input type="number" onChange={e => setMaximumTemperature(e.target.value)} value={maximumTemperature} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Minimum Humidity (%)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input type="number" onChange={e => setMinimumHumidity(e.target.value)} value={minimumHumidity} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Maximum Humidity (%)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input type="number" onChange={e => setMaximumHumidity(e.target.value)} value={maximumHumidity} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Snow Load (lb/sqft)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input type='number' onChange={e => setSnowLoad(e.target.value)} value={snowLoad} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Wind Speed (mph) Soil Type
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input type='number' onChange={e => setWindSpeed(e.target.value)} value={windSpeed} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Spectral Response (Short, S/S)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input type='number' onChange={e => setSpectralResponseShort(e.target.value)} value={spectralResponseShort} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Seismic Importance Factor
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input type='number' onChange={e => setSeismicImportanceFactor(e.target.value)} value={seismicImportanceFactor} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Seismic Site Class
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setSeismicSiteClass(e.target.value)} value={seismicSiteClass} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Spectral Response (1 Sec, S/1)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input type='number' onChange={e => setSpectralResponseSecond(e.target.value)} value={spectralResponseSecond} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Design Spectral Response (S/DS)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input type="number" onChange={e => setDesignSpectralResponseDS(e.target.value)} value={designSpectralResponseDS} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Design Spectral Response (S/D1)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input type="number" onChange={e => setDesignSpectralResponseD1(e.target.value)} value={designSpectralResponseD1} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Seismic Design Category
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setSeismicDesignCategory(e.target.value)} value={seismicDesignCategory} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
          </div>
        </div> */}

        {/* ..............Design Parameters end .................... */}
        {/* ................ Site Information started */}

        <div className="2xl:px-20 ">
          <div className="grid grid-cols-12 mt-10   ">
            <div className=" col-span-12 lg:col-span-5 "></div>
            <div className=" col-span-12 lg:col-span-7 ">
              <ul className="inline-flex w-full">
                <li className=" w-full mt-2 ">
                  <h2 className=" f-f-sm text-lg capitalize  text-primary ">
                    Site Information
                  </h2>
                </li>
              </ul>
            </div>
            {/* input section started */}
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Site Name <span className="text-primary">*</span>
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input
                onChange={(e) => setSiteName(e.target.value)}
                value={siteName}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>
            {/* input section ended */}
            {/* input section started */}
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Site Contacts <span className="text-primary">*</span>
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              {siteContacts.length ? (
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                    width: "68%",
                  }}
                >
                  {siteContacts.map((contact, i) => (
                    <span key={i}>{contact.first_name}, </span>
                  ))}
                </div>
              ) : null}
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  width: "30%",
                }}
              >
                <Dilogbox onReceiveContact={_onReceiveContact} />
              </div>
              {/* <button className="relative border bg-primary w-[222px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-white  ">
                <svg className=" absolute left-[20px] top-[11px] " width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 13H13V18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18V13H6C5.73478 13 5.48043 12.8946 5.29289 12.7071C5.10536 12.5196 5 12.2652 5 12C5 11.7348 5.10536 11.4804 5.29289 11.2929C5.48043 11.1054 5.73478 11 6 11H11V6C11 5.73478 11.1054 5.48043 11.2929 5.29289C11.4804 5.10536 11.7348 5 12 5C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6V11H18C18.2652 11 18.5196 11.1054 18.7071 11.2929C18.8946 11.4804 19 11.7348 19 12C19 12.2652 18.8946 12.5196 18.7071 12.7071C18.5196 12.8946 18.2652 13 18 13Z" fill="white" />
                </svg>

                Select Contact
              </button> */}
            </div>
            {/* input section ended */}

            {/* input section started */}
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Site Address <span className="text-primary">*</span>
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              {/** onChange={e => setSiteAddress(e.target.value)} value={siteAddress} */}
              <input
                onChange={(e) => setSiteAddress(e.target.value)}
                value={siteAddress}
                placeholder="Enter a valid address only, No GPS coordinates"
                autoComplete="nope"
                id="street_address"
                name="address"
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>
            {/* input section ended */}

            {/* input section started */}
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                GPS Coordinates <span className="text-primary">*</span>
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input
                // onChange={(e) => setGPSCoordinates(e.target.value)}
                onChange={_onChangeGPSCoordinates}
                value={GPSCoordinates}
                placeholder="Enter only valid GPS coordinates Eg: 39.3133154,-121.4059035"
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>
            {/* input section ended */}
            {/* input section started */}
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                Renewable Fraction Target (%){" "}
                <span className="text-primary">*</span>
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input
                type="number"
                onChange={(e) => setRenewableFractionTarget(e.target.value)}
                value={renewableFractionTarget}
                className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
              />
            </div>
            {/* input section ended */}

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Connection Type
                <span className="text-primary">*</span>
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <select onChange={e => setConnectionType(e.target.value)} value={connectionType} className='border border-grey bg-white-dark rounded-[4px] px-4 outline-none w-full h-[56px] ' >
                <option value=""></option>
                <option value="Off-grid">Off-grid</option>
                <option value="Grid-tied">Grid-tied</option>
              </select>
            </div>
          </div>
        </div>

        {/* ..............Site Information end .................... */}

        {/* ................ Site Conditions started */}

        {/* <div className='2xl:px-20 ' >
          <div className="grid grid-cols-12 mt-10   ">
            <div className=" col-span-12 lg:col-span-5 "></div>
            <div className=" col-span-12 lg:col-span-7 ">
              <ul className="inline-flex w-full"  >
                <li className=' w-full mt-2 ' >
                  <h2 className=" f-f-sm text-lg capitalize  text-primary " >Site Conditions</h2>
                </li>
              </ul>
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Foundation Type
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <select onChange={e => setFoundationType(e.target.value)} value={foundationType} className='border border-grey bg-white-dark rounded-[4px] px-4 outline-none w-full h-[56px] ' >
                <option value=""></option>
                <option value="Concrete">Concrete</option>
                <option value="Anchored">Anchored</option>
                <option value="Internal Ballast">Internal Ballast</option>
                <option value="External Ballast">External Ballast</option>
              </select>
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Additional Foundation Requirements
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setAdditionalFoundationRequirements(e.target.value)} value={additionalFoundationRequirements} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Service Voltage
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <select onChange={e => setServiceVoltage(e.target.value)} value={serviceVoltage} className='border border-grey bg-white-dark rounded-[4px] px-4 outline-none w-full h-[56px] ' >
                <option value=""></option>
                <option value="120/240V Split Phase">120/240V Split Phase</option>
                <option value="208V Three Phase">208V Three Phase</option>
                <option value="480V Three Phase">480V Three Phase</option>
              </select>
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Connection Type
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <select onChange={e => setConnectionType(e.target.value)} value={connectionType} className='border border-grey bg-white-dark rounded-[4px] px-4 outline-none w-full h-[56px] ' >
                <option value=""></option>
                <option value="Off-grid">Off-grid</option>
                <option value="Grid-tied">Grid-tied</option>
              </select>
            </div>
          </div>
        </div> */}

        {/* <div className='2xl:px-20 ' >
          <div className="grid grid-cols-12 mt-10   ">

            <div className=" col-span-12  ">
              <h2 className=" f-f-r text-lg text-black-vlight text-center " >Service Conductor Path <br />
                (PCC To Transformer)</h2>
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Type
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">

              <select onChange={e => setPCCToTransformerType(e.target.value)} value={PCCToTransformerType} className='border border-grey bg-white-dark rounded-[4px] px-4 outline-none w-full h-[56px] ' >
                <option value=""></option>
                <option value="Underground">Underground</option>
                <option value="Overhead">Overhead</option>
              </select>
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                New/Existing
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">

              <ul className=' inline-flex mt-5 ' >
                <li>
                  <div className="flex items-center mb-4">
                    <input onChange={(e) => setPCCToTransformerInstall(e.target.value)} id="default-radio-1" type="radio" value="New" name="PCCToTransformerInstall" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " />
                    <label htmlFor="default-radio-1" className="ml-2 f-f-r text-lg  ">New</label>
                  </div>
                </li>
                <li className=' ml-10' >
                  <div className="flex items-center">
                    <input onChange={(e) => setPCCToTransformerInstall(e.target.value)} id="default-radio-2" type="radio" value="Existing" name="PCCToTransformerInstall" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " />
                    <label htmlFor="default-radio-2" className="ml-2 f-f-r text-lg  ">Existing</label>
                  </div>
                </li>
              </ul>
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Distance (Feet)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input type='number' onChange={e => setPCCToTransformerDistanceFeet(e.target.value)} value={PCCToTransformerDistanceFeet} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
          </div>
        </div> */}
        {/* ..............Service conductor end .................... */}
        {/* ................  Transformer to meter started */}
        {/* <div className='2xl:px-20 ' >

          <div className="grid grid-cols-12 mt-10   ">

            <div className=" col-span-12  ">
              <h2 className=" f-f-r text-lg text-black-vlight text-center " > Transformer to meter Path <br />
                (Transformer To Meter)</h2>
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Type
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">

              <select onChange={e => setTransformerToMeterType(e.target.value)} value={transformerToMeterType} className='border border-grey bg-white-dark rounded-[4px] px-4 outline-none w-full h-[56px] ' >
                <option value=""></option>
                <option value="Underground">Underground</option>
                <option value="Overhead">Overhead</option>
              </select>
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                New/Existing
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">

              <ul className=' inline-flex mt-5 ' >
                <li>
                  <div className="flex items-center mb-4">
                    <input onChange={(e) => setTransformerToMeterInstall(e.target.value)} id="default-radio-1" type="radio" value="New" name="transformerToMeterInstall" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " />
                    <label htmlFor="default-radio-1" className="ml-2 f-f-r text-lg  ">New</label>
                  </div>
                </li>
                <li className=' ml-10' >
                  <div className="flex items-center">
                    <input onChange={(e) => setTransformerToMeterInstall(e.target.value)} id="default-radio-2" type="radio" value="Existing" name="transformerToMeterInstall" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 " />
                    <label htmlFor="default-radio-2" className="ml-2 f-f-r text-lg  ">Existing</label>
                  </div>
                </li>
              </ul>

            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Distance (Feet)
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input type='number' onChange={e => setTransformerToMeterDistanceFeet(e.target.value)} value={transformerToMeterDistanceFeet} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
          </div>
        </div> */}
        {/* .............. Transformer to meter end .................... */}

        {/* ................ Logistics started */}

        {/* <div className='2xl:px-20 ' >
          <div className="grid grid-cols-12 mt-10   ">

            <div className=" col-span-12 lg:col-span-5 "></div>
            <div className=" col-span-12 lg:col-span-7 ">
              <ul className="inline-flex w-full"  >
                <li className=' w-full mt-2 ' >
                  <h2 className=" f-f-sm text-lg capitalize  text-primary " >Logistics</h2>
                </li>
              </ul>
            </div>
            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Target Delivery Date
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input  onChange={e => setTargetDeliveryDate(e.target.value)} value={targetDeliveryDate} type="date" id="birthday" name="birthday" className=" w-full  relative h-[56px] border border-grey bg-white-dark rounded-[4px] pl-[47px] pr-4 outline-none" placeholder="Select date" />
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Equipment
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setEquipment(e.target.value)} value={equipment} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Loading Method
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setLoadingMethod(e.target.value)} value={loadingMethod} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Shipping Method
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setShippingMethod(e.target.value)} value={shippingMethod} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Unloading Method
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setUnloadingMethod(e.target.value)} value={unloadingMethod} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                System Placement
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setSystemPlacement(e.target.value)} value={systemPlacement} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Preferred Unloading Method
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setPreferredUnloadingMethod(e.target.value)} value={preferredUnloadingMethod} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Customer Shipping Max Budget
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input type="number" onChange={e => setCustomerShippingMaxBudget(e.target.value)} value={customerShippingMaxBudget} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Miscellaneous Items Being Shipped
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setMiscellaneousItemsBeingShipped(e.target.value)} value={miscellaneousItemsBeingShipped} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
          </div>
        </div> */}
        {/* ..............Logistics end .................... */}

        {/* ................ directory of Links started */}

        {/* <div className='2xl:px-20 ' >
          <div className="grid grid-cols-12 mt-10   ">
            <div className=" col-span-12 lg:col-span-5 "></div>
            <div className=" col-span-12 lg:col-span-7 ">
              <ul className="inline-flex w-full"  >
                <li className=' w-full mt-2 ' >
                  <h2 className=" f-f-sm text-lg capitalize  text-primary " >Directory of Links</h2>
                </li>
              </ul>
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Clickup Spaces
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setClickupSpaces(e.target.value)} value={clickupSpaces} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                PSA Contact
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setPSAContact(e.target.value)} value={PSAContact} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Consulting Contact
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setConsultingContact(e.target.value)} value={consultingContact} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Project Sharepoint
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setProjectSharepoint(e.target.value)} value={projectSharepoint} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Invoices
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setInvoices(e.target.value)} value={invoices} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Project BOM Worksheet
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setProjectBOMWorksheet(e.target.value)} value={projectBOMWorksheet} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Project Pricing Worksheet
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setProjectPricingWorksheet(e.target.value)} value={projectPricingWorksheet} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Project Schedule
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setProjectSchedule(e.target.value)} value={projectSchedule} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>

            <div className=" col-span-12 lg:col-span-5 mt-5 ">
              <h2 className=' f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ' >
                Assumptions & Exclusions
              </h2>
            </div>
            <div className=" col-span-12 lg:col-span-7 mt-5 ">
              <input onChange={e => setAssumptionsAndExclusions(e.target.value)} value={assumptionsAndExclusions} className=' w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  ' />
            </div>
          </div>
        </div> */}
        {/* ..............directory of Links end .................... */}

        {/* button started */}
        <div className=" mt-10 text-end  ">
          <ul className=" inline-flex  ">
            <li>
              {loading ? (
                <button className=" bg-gray text-lg f-f-r text-gray  w-[127px] h-[48px] rounded-[8px] captilize ">
                  Loading...
                </button>
              ) : (
                <button
                  onClick={submitSite}
                  className=" bg-primary text-lg f-f-r text-white  w-[127px] h-[48px] rounded-[8px] captilize "
                >
                  Done
                </button>
              )}
            </li>
            <li className=" ml-6 ">
              <button
                onClick={() => navigate(-1)}
                className=" border border-primary text-lg f-f-r text-primary  w-[127px] h-[48px] rounded-[8px] captilize "
              >
                Back
              </button>
            </li>
          </ul>
        </div>
        {/* button ended */}
      </div>
    </>
  );
}
