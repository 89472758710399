import React from "react";
import { Menu } from "@headlessui/react";
import { Link } from "react-router-dom";

export default function MyDropdown({ columns, onEditColumns }) {
  return (
    <>
      <Menu>
        <Menu.Button className=" cursor-pointer">
          <button className=" f-f-r text-lg text-black w-[169px] h-[44px] shadow-lg bg-white rounded-[4px]  ">
            <ul className=" inline-flex ">
              <li className=" mt-2 ">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.0002 2.66667H9.3335"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.66667 2.66667H2"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 8H8"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.33333 8H2"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M13.9998 13.3333H10.6665"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8 13.3333H2"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.3335 1.33333V4"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.3335 6.66667V9.33333"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.6665 12V14.6667"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </li>
              <li className=" ml-3 mt-1 ">Edit Columns</li>
            </ul>
          </button>
        </Menu.Button>
        <Menu.Items>
          <div
            id="dropdown"
            className="z-10 px-3 absolute w-[344px] bg-white divide-y divide-gray-100 rounded-lg shadow overflow-y-scroll h-[300px]"
          >
            <ul
              className="py-2 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownDefaultButton"
            >
              <li className=" profilebrdr2">
                {/* inner ul started */}
                <ul
                  className=" inline-flex mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "site_code",
                          value: !columns.site_code,
                        })
                      }
                      checked={columns.site_code}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Site Code
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>
              <li className=" profilebrdr2 mt-1">
                <ul
                  className=" inline-flex cursor-pointer mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "site_name", value: !columns.site_name })
                      }
                      checked={columns.site_name}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r">Site Name</h2>
                  </li>
                </ul>
              </li>
              <li className=" profilebrdr2 mt-1">
                <ul
                  className=" inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "site_directions",
                          value: !columns.site_directions,
                        })
                      }
                      checked={columns.site_directions}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Site Directions
                    </h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1">
                <ul
                  className=" inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "site_distance_drive",
                          value: !columns.site_distance_drive,
                        })
                      }
                      checked={columns.site_distance_drive}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Site Distance Drive</h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1">
                <ul
                  className=" inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({
                          key: "site_distance_miles",
                          value: !columns.site_distance_miles,
                        })
                      }
                      checked={columns.site_distance_miles}
                      className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Site Distance Miles
                    </h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "site_fence", value: !columns.site_fence })
                      }
                      checked={columns.site_fence}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">Site Fence</h2>
                  </li>
                </ul>
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "site_gps", value: !columns.site_gps })
                      }
                      checked={columns.site_gps}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Site GPS
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "county", value: !columns.county })
                      }
                      checked={columns.county}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      County
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>


              {/* Newlinks */}
              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "site_address", value: !columns.site_address })
                      }
                      checked={columns.site_address}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Site Address
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "site_contacts", value: !columns.site_contacts })
                      }
                      checked={columns.site_contacts}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Site Contacts
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "target_renewable", value: !columns.target_renewable })
                      }
                      checked={columns.target_renewable}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Target Renewable
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "ratepayer_type", value: !columns.ratepayer_type })
                      }
                      checked={columns.ratepayer_type}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Ratepayer Type
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "landowner", value: !columns.landowner })
                      }
                      checked={columns.landowner}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Landowner
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "critical_load", value: !columns.critical_load })
                      }
                      checked={columns.critical_load}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Critical Load
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "generator_use", value: !columns.generator_use })
                      }
                      checked={columns.generator_use}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Generator Use
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "container_weight", value: !columns.container_weight })
                      }
                      checked={columns.container_weight}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Container Weight
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "shipping", value: !columns.shipping })
                      }
                      checked={columns.shipping}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Shipping
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "unloading", value: !columns.unloading })
                      }
                      checked={columns.unloading}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Unloading
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "system_placement", value: !columns.system_placement })
                      }
                      checked={columns.system_placement}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      System Placement
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "access_road_flags", value: !columns.access_road_flags })
                      }
                      checked={columns.access_road_flags}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Access Road Flags
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "procurement_yard", value: !columns.procurement_yard })
                      }
                      checked={columns.procurement_yard}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Procurement Yard
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "location_minimum_temperature", value: !columns.location_minimum_temperature })
                      }
                      checked={columns.location_minimum_temperature}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Location Minimum Temperature
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "location_maximum_temperature", value: !columns.location_maximum_temperature })
                      }
                      checked={columns.location_maximum_temperature}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Location Maximum Temperature
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "location_minimum_humidity", value: !columns.location_minimum_humidity })
                      }
                      checked={columns.location_minimum_humidity}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Location Minimum Humidity
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "location_maximum_humidity", value: !columns.location_maximum_humidity })
                      }
                      checked={columns.location_maximum_humidity}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Location Maximum Humidity
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "wind_speed", value: !columns.wind_speed })
                      }
                      checked={columns.wind_speed}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Wind Speed
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "snow_load", value: !columns.snow_load })
                      }
                      checked={columns.snow_load}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Snow Load
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "soil_type", value: !columns.soil_type })
                      }
                      checked={columns.soil_type}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Soil Type
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "seismic_spectral_response_short", value: !columns.seismic_spectral_response_short })
                      }
                      checked={columns.seismic_spectral_response_short}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Seismic Spectral Response Short
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "seismic_spectral_response_second", value: !columns.seismic_spectral_response_second })
                      }
                      checked={columns.seismic_spectral_response_second}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Seismic Spectral Response Second
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "seismic_importance_factor", value: !columns.seismic_importance_factor })
                      }
                      checked={columns.seismic_importance_factor}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Seismic Importance Factor
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "seismic_site_class", value: !columns.seismic_site_class })
                      }
                      checked={columns.seismic_site_class}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Seismic Site Class
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "design_spectral_response_short", value: !columns.design_spectral_response_short })
                      }
                      checked={columns.design_spectral_response_short}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Design Spectral Response Short
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "design_spectral_response_second", value: !columns.design_spectral_response_second })
                      }
                      checked={columns.design_spectral_response_second}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Design Spectral Response Second
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "seismic_design_category", value: !columns.seismic_design_category })
                      }
                      checked={columns.seismic_design_category}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Seismic Design Category
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "elevation", value: !columns.elevation })
                      }
                      checked={columns.elevation}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Elevation
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "wind_exposure_category", value: !columns.wind_exposure_category })
                      }
                      checked={columns.wind_exposure_category}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Wind Exposure Category
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "snow_height", value: !columns.snow_height })
                      }
                      checked={columns.snow_height}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Snow Height
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "risk_category", value: !columns.risk_category })
                      }
                      checked={columns.risk_category}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Risk Category
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "geologic_hazard_zone", value: !columns.geologic_hazard_zone })
                      }
                      checked={columns.geologic_hazard_zone}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Geologic Hazard Zone
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "state_seismic_landslide_zone", value: !columns.state_seismic_landslide_zone })
                      }
                      checked={columns.state_seismic_landslide_zone}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      State Seismic Landslide Zone
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "state_seismic_liquefaction_zone", value: !columns.state_seismic_liquefaction_zone })
                      }
                      checked={columns.state_seismic_liquefaction_zone}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      State Seismic Liquefaction Zone
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "peak_demand", value: !columns.peak_demand })
                      }
                      checked={columns.peak_demand}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Peak Demand
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "average_demand", value: !columns.average_demand })
                      }
                      checked={columns.average_demand}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Average Demand
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "foundation_type", value: !columns.foundation_type })
                      }
                      checked={columns.foundation_type}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Foundation Type
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "foundation_requirements", value: !columns.foundation_requirements })
                      }
                      checked={columns.foundation_requirements}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Foundation Requirements
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "service_voltage", value: !columns.service_voltage })
                      }
                      checked={columns.service_voltage}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Service Voltage
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "connection_type", value: !columns.connection_type })
                      }
                      checked={columns.connection_type}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Connection Type
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "miles_line_removed", value: !columns.miles_line_removed })
                      }
                      checked={columns.miles_line_removed}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Miles Line Removed
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "pcc_to_transformer_type", value: !columns.pcc_to_transformer_type })
                      }
                      checked={columns.pcc_to_transformer_type}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      PCC To Transformer Type
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "pcc_to_transformer_install", value: !columns.pcc_to_transformer_install })
                      }
                      checked={columns.pcc_to_transformer_install}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      PCC TO Transformer Install
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "pcc_to_transformer_distance", value: !columns.pcc_to_transformer_distance })
                      }
                      checked={columns.pcc_to_transformer_distance}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      PCC To Transformer Distance
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "transformer_to_meter_type", value: !columns.transformer_to_meter_type })
                      }
                      checked={columns.transformer_to_meter_type}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Transformer To Meter Type
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "transformer_to_meter_install", value: !columns.transformer_to_meter_install })
                      }
                      checked={columns.transformer_to_meter_install}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Transformer To Meter Install
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "transformer_to_meter_distance", value: !columns.transformer_to_meter_distance })
                      }
                      checked={columns.transformer_to_meter_distance}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Transformer To Meter Distance
                    </h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>

              {/* <li className=" profilebrdr2 mt-1 cursor-pointer">
                <ul
                  className="inline-flex  mb-2 mt-2 "
                  style={{ width: "100%" }}
                >
                  <li className=" mt-[4px]  ">
                    <input
                      type="checkbox"
                      onChange={() =>
                        onEditColumns({ key: "additional_links", value: !columns.additional_links })
                      }
                      checked={columns.additional_links}
                      className="w-5 h-5 text-primary bg-gray-100 border-gray-300 rounded-[4px] "
                    />
                  </li>
                  <li className=" ml-4 mt-[1px] ">
                    <h2 className=" text-lg text-black f-f-r   ">
                      Additional Links
                    </h2>
                  </li>
                </ul>
              </li> */}

            </ul>
          </div>
        </Menu.Items>
      </Menu>
    </>
  );
}
