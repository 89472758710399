import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import Config from "../../utils/Config";
import { useSelector } from "react-redux";

import swal from "sweetalert";

export default function UtilityDilogbox(props) {
  const user = useSelector((state) => state.user.profile);

  const [showModal, setShowModal] = useState(false);
  const [on, seton] = useState("Add");
  // const [ value, setValue ] = useState();
  const data = (get) => seton(get);
  const [utilities, setUtilities] = useState([]);
  const [page, setPage] = React.useState(1);
  const [lastPage, setLastPage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  
  const [ utility_name, set_utility_name ] = React.useState("");
  const [ utility_full_name, set_utility_full_name ] = React.useState("");

  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [email, setEmail] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [company, setCompany] = useState("");
  // const [title, setTitle] = useState("");
  // const [label, setLabel] = useState("");
  // const [type, setType] = useState("");

  const [loading, setLoading] = useState("");

  const increasePage = () => {
    if (currentPage >= lastPage) {
      setPage(lastPage);
    } else {
      setPage(currentPage + 1);
    }
  };

  // const decreasePage = () => {
  //   if (currentPage <= 1) {
  //     setPage(1);
  //   } else {
  //     setPage(currentPage - 1);
  //   }
  // };

  useEffect(() => {
    loadUtility();
  }, [user]);

  const loadUtility = () => {
    setLoading(true);
    fetch(`${Config.API.URL}/utilities?page=${page}&sort_direction=ASC`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");
          //localStorage.removeItem('user');
          //history.push('/');
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        // console.log('res:', res);
        setLoading(false);
        if (res.status) {
          let data = [];
          let items =
            props.utilities?.map((x) => ({ ...x, checked: true })) || [];

          for (let i = 0; i < res.data.data.length; i++) {
            const utility = { ...res.data.data[i] };
            if (!props.utilities?.find((x) => x.id === utility.id)) {
              data.push({ ...utility, checked: false });
            }
          }

          setUtilities([...items, ...data]);
          setLastPage(res.data.last_page);
          setCurrentPage(res.data.current_page);
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  }

  useEffect(() => {
    if (page > 1) {
      setLoading(true);
      fetch(`${Config.API.URL}/utilities?page=${page}&sort_direction=ASC`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      })
        .then((res) => {
          // if 401 error (unauthenticated user, then log user out)
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          //console.log('res:', res);
          setLoading(false);
          if (res.status) {
            let data = [];

            for (let i = 0; i < res.data.data.length; i++) {
              const utility = { ...res.data.data[i] };
              if (!props.utilities?.find((x) => x.id === utility.id)) {
                data.push({ ...utility, checked: false });
              }
            }
            setUtilities([...utilities, ...data]);
            setLastPage(res.data.last_page);
            setCurrentPage(res.data.current_page);
          } else {
            //swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error: ", error);
        });
    }
  }, [user, page]);

  useEffect(() => {
    const project_utilities = [];
    for (let i = 0; i < utilities.length; i++) {
      const utility = utilities[i];
      if (utility.checked) {
        project_utilities.push(utility);
      }
    }

    props.onReceiveUtility(project_utilities);
  }, [utilities]);

  const _onChangeUtility = (e) => {
    const data = [ ...utilities ];
    for (let i = 0; i < data.length; i++) {
      if (data[i].checked) {
        data[i].checked = false;
      }
    }
    data[e.target.id].checked = true;
    setUtilities(data);
  };

  const utility_selected = utilities.find((x) => x.checked);

  const submitUtility = () => {

    if (utility_name.length && utility_full_name.length) {
      const payload = {
        utility_name,
        utility_full_name,
        utility_conta_cts: []
      };

      fetch(`${Config.API.URL}/utilities`, {
        method: "POST",
        headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${user.token}` },
        body: JSON.stringify(payload),
      })
        .then((res) => {
          // if 401 error (unauthenticated user, then log user out)
          if (res.status === 401) {
            console.log("401 Error: Unauthenticated");

            //localStorage.removeItem('token');
            //localStorage.removeItem('user');
            //history.push('/');
          }
          return res;
        })
        .then((res) => res.json())
        .then(async (res) => {
          // console.log('res:', res);
          setShowModal(false);

          // if request is succesful, alert user that project has been cloned
          if (res.status) {
            const data = [...utilities];

            for (let i = 0; i < data.length; i++) {
              if (data[i].checked) {
                data[i].checked = false;
              }
            }

            data.push({ ...res.data, checked: true });

            setUtilities(data);

            set_utility_name("");
            set_utility_full_name("");

            //swal('The "' + payload.name + '" project has been successfully cloned!', { icon: "success" });
          } else {
            //swal("Oh no!", res.message, "error");
          }
        })
        .catch((error) => {
          console.log("Error: ", error);

          //swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
        });
    }
  };
  
  return (
    <div>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="relative border bg-primary w-[222px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-white  "
      >
        <svg
          className=" absolute left-[20px] top-[11px] "
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 13H13V18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18V13H6C5.73478 13 5.48043 12.8946 5.29289 12.7071C5.10536 12.5196 5 12.2652 5 12C5 11.7348 5.10536 11.4804 5.29289 11.2929C5.48043 11.1054 5.73478 11 6 11H11V6C11 5.73478 11.1054 5.48043 11.2929 5.29289C11.4804 5.10536 11.7348 5 12 5C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6V11H18C18.2652 11 18.5196 11.1054 18.7071 11.2929C18.8946 11.4804 19 11.7348 19 12C19 12.2652 18.8946 12.5196 18.7071 12.7071C18.5196 12.8946 18.2652 13 18 13Z"
            fill="white"
          />
        </svg>

        {utility_selected ? "Change Utility" : "Select Utility"}
      </button>

      {showModal ? (
        <>
          <div className="dilog-box" onClick={() => setShowModal(false)}></div>
          <div
            id="defaultModal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed top-[16%] lg:top-0  right-0 z-50  ml-auto w-[85%] md:w-[93%] lg:w-full  p-4 overflow-x-hidden overflow-y-auto inset-0 h-[calc(100%-1rem)] max-h-full"
          >
            <div className="relative w-full max-w-2xl max-h-full ml-auto mr-auto ">
              <div className="relative bg-white rounded-lg shadow  p-10 ">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className=" absolute top-[19px] right-[19px]  text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-hide="defaultModal"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.918759 13.0577C0.360088 13.6164 0.360088 14.5222 0.918759 15.0809C1.47743 15.6395 2.38321 15.6395 2.94189 15.0809L7.99981 10.0229L13.0579 15.0811C13.6166 15.6397 14.5224 15.6397 15.081 15.0811C15.6397 14.5224 15.6397 13.6166 15.081 13.0579L10.0229 7.99982L15.0806 2.94213C15.6393 2.38346 15.6393 1.47767 15.0806 0.919002C14.522 0.360332 13.6162 0.360333 13.0575 0.919003L7.99981 5.9767L2.9423 0.919193C2.38363 0.360523 1.47784 0.360523 0.919172 0.919193C0.360502 1.47786 0.360503 2.38365 0.919174 2.94232L5.97668 7.99982L0.918759 13.0577Z"
                      fill="#FA3030"
                    />
                  </svg>
                </button>
                {/* button started */}
                <div className="grid grid-cols-12  mt-8  ">
                  <div className=" col-span-12 sm:col-span-6 ">
                    <button
                      onClick={() => data("create")}
                      className={` ${
                        on === "create" ? " bg-white-light" : " bg-white"
                      } w-full f-f-r text-base text-black    h-[40px] border border-grey `}
                    >
                      Create New
                    </button>
                  </div>
                  <div className=" col-span-12 sm:col-span-6 ">
                    <button
                      onClick={() => data("Add")}
                      className={`${
                        on === "Add" ? " bg-white-light" : " bg-white"
                      } w-full f-f-r text-base text-black    h-[40px] border border-grey `}
                    >
                      Add Existing
                    </button>
                  </div>
                </div>
                {/*button edned  */}
                {on === "Add" ? (
                  <>
                    {/*.................  add exisiting started ................*/}

                    <div className=" mt-8 ">
                      {/* started */}
                      {/* <input className=" w-full  h-[46px] border border-grey bg-white rounded-[8px] px-4 outline-none " onChange={searchutilities} /> */}
                      <h4 className=" text-black-vlight text-tiny f-f-r mt-2 ">
                        {utilities.length} Results
                      </h4>
                      <div className="overflow-y-scroll h-[400px]">
                        <ul className=" mt-6">
                          {/* li started */}
                          {utilities.map((utility, i) => (
                            <li key={i}>
                              <div className="flex items-center mb-4">
                                <input
                                  id={i}
                                  type="checkbox"
                                  value=""
                                  onChange={_onChangeUtility}
                                  checked={
                                    utility.checked ? utility.checked : false
                                  }
                                  className="w-5 h-5 text-primary  bg-gray-100 border-gray-300 rounded-[4px] "
                                />
                                <label
                                  htmlFor={i}
                                  className="ml-4 f-f-r text-lg text-black  "
                                >
                                  {`${utility.utility_full_name} (${utility.utility_name})`}
                                </label>
                              </div>
                            </li>
                          ))}
                        </ul>
                        <div className="inline-flex w-full justify-center">
                          { console.log("lastPage:", lastPage) }
                          { lastPage > 1 ?
                            ( loading ? (
                              "Loading..."
                            ) : currentPage < lastPage ? (
                              <button
                                onClick={increasePage}
                                className=" bg-white text-base text-black border border-black  w-[127px] h-[40px] rounded-[20px] mr-1 captilize "
                              >
                                Load More
                              </button>
                            ) : null ) 
                          : null }
                        </div>
                      </div>

                      {/* ended */}
                    </div>

                    {/* button started */}
                    <div className=" mt-3 text-end  ">
                      {/* <div className=" tabel-home py-4 px-3 w-full mb-4">
                        <ul className=" inline-flex  w-full  justify-center ">
                          <li className=" ml-5 ">
                            <h2 className=" inline-flex f-f-m text-tiny  text-black-vdark ">
                              page {currentPage} of {lastPage}
                            </h2>
                          </li>
                          <li className=" ml-5 ">
                            <ul className=" inline-flex ">
                              <li>
                                <span
                                  onClick={decreasePage}
                                  className="cursor-pointer"
                                >
                                  <svg
                                    width="6"
                                    height="10"
                                    viewBox="0 0 6 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.62044 1.10636C6.22943 0.51287 5.2652 -0.426818 4.65621 0.216126L0.190308 4.51891C-0.0634362 4.7662 -0.0634362 5.21131 0.190308 5.4586L4.65621 9.81084C5.2652 10.4043 6.22943 9.46464 5.62044 8.87115L1.66203 5.01348L5.62044 1.10636Z"
                                      fill="#808080"
                                    />
                                  </svg>
                                </span>
                              </li>
                              <li className=" ml-8 ">
                                <span
                                  onClick={increasePage}
                                  className="cursor-pointer"
                                >
                                  <svg
                                    width="7"
                                    height="10"
                                    viewBox="0 0 7 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M0.65983 1.10636C0.0508423 0.51287 1.01507 -0.426818 1.62406 0.216126L6.08996 4.51891C6.34371 4.7662 6.34371 5.21131 6.08996 5.4586L1.62406 9.81084C1.01507 10.4043 0.0508423 9.46464 0.65983 8.87115L4.61825 5.01348L0.65983 1.10636Z"
                                      fill="#808080"
                                    />
                                  </svg>
                                </span>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div> */}
                      <button
                        onClick={() => setShowModal(false)}
                        className=" bg-white text-base text-primary border border-primary w-[127px] h-[40px] rounded-[20px] ml-1 captilize "
                      >
                        Done
                      </button>
                      {/* <button
                        onClick={() => setShowModal(false)}
                        className=" bg-primary text-lg text-white  w-[127px] h-[48px] rounded-[8px] captilize "
                      >
                        Done
                      </button> */}
                    </div>

                    {/* .......................... addd existing ended ............... */}
                  </>
                ) : (
                  <>
                    <div className=" mt-8 ">
                      <div className="grid grid-cols-12   ">
                        {/*  col started */}
                        <div className=" col-span-12 lg:col-span-4 mt-5 ">
                          <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                            Utility Abbr
                          </h2>
                        </div>
                        <div className=" col-span-12 lg:col-span-8 mt-5 ">
                          <input
                            onChange={(e) => set_utility_name(e.target.value)}
                            value={utility_name}
                            className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
                          />
                        </div>

                        {/* col ended */}
                        {/*  col started */}
                        <div className=" col-span-12 lg:col-span-4 mt-5 ">
                          <h2 className=" f-f-r  xl:text-2xl text-xl  text-black-vlight mt-3 ">
                            Utility fullname:
                          </h2>
                        </div>
                        <div className=" col-span-12 lg:col-span-8 mt-5 ">
                          <input
                            onChange={(e) => set_utility_full_name(e.target.value)}
                            value={utility_full_name}
                            className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
                          />
                        </div>

                      </div>
                      <div className=" mt-10 text-end  ">
                        <button
                          onClick={submitUtility}
                          className=" bg-primary text-lg f-f-r text-white  w-[127px] h-[48px] rounded-[8px] captilize "
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
