import React from "react";

export default function ElectricTable(props) {
  const [isEditable, setIsEditable] = React.useState(false);

  const [load_bases, setLoad_bases] = React.useState([
    {
      parameter: "Average Daily Usage (kWh/day)",
      primary_loads: 18.97,
      parasitic_loads: 1.06,
      total: 20.03,
    },
    {
      parameter: "Peak Demand (kW)",
      primary_loads: 6.41,
      parasitic_loads: 0.09,
      total: 6.5,
    },
    {
      parameter: "Average (kW)",
      primary_loads: 0.79,
      parasitic_loads: 0.04,
      total: 0.83,
    },
  ]);
  const _onChangeLoad_basesInput = (e) => {
    let data = [...load_bases];

    data[e.target.id][e.target.name] = e.target.value;

    setLoad_bases(data);
  };
  const saveChanges = () => {
    props.onSaveElectricalSizingMethodology(load_bases);
    setIsEditable(!isEditable);
    props.onEditElectricTable(!isEditable);
  };
  const _setIsEditable = () => {
    setIsEditable(!isEditable);
    props.onEditElectricTable(!isEditable);
  }
  return (
    <div>
      <div className="relative overflow-x-auto mt-8 ">
        <button
          onClick={ isEditable ? saveChanges : _setIsEditable }
          className="absolute top-2 right-2 border bg-primary w-[45px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
        >
          {isEditable ? "Save" : "Edit"}
        </button>
        <table className=" table-site w-full  text-left  ">
          <caption className="px-3 py-2 f-f-m text-2xl text-center  bg-primary text-white ">
            LOAD BASIS SUMMARY
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b text-base text-black-dark ">
              <th className="px-6 py-3 min-w-[200px]  sm:min-w-auto  ">
                Parameter
              </th>
              <th className="px-6 py-3 min-w-[150px]  sm:min-w-auto  ">
                Primary Loads
              </th>
              <th className="px-6 py-3  min-w-[150px]  sm:min-w-auto ">
                Parasitic Loads
              </th>
              <th className="px-6 py-3 min-w-[150px]  sm:min-w-auto  ">
                {" "}
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {load_bases.map((load_base, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-base text-black-dark "
                >
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeLoad_basesInput}
                        className="input-equipment-table"
                        id={i}
                        name="parameter"
                        value={load_base.parameter}
                      />
                    ) : (
                      load_base.parameter
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeLoad_basesInput}
                        className="input-equipment-table"
                        id={i}
                        name="primary_loads"
                        value={load_base.primary_loads}
                      />
                    ) : (
                      load_base.primary_loads
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeLoad_basesInput}
                        className="input-equipment-table"
                        id={i}
                        name="parasitic_loads"
                        value={load_base.parasitic_loads}
                      />
                    ) : (
                      load_base.parasitic_loads
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeLoad_basesInput}
                        className="input-equipment-table"
                        id={i}
                        name="total"
                        value={load_base.total}
                      />
                    ) : (
                      load_base.total
                    )}
                  </td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Average Daily Usage (kWh/day)
                            </td>
                            <td className="px-6 py-3   ">
                            18.97
                            </td>
                            <td className="px-6 py-3   ">
                            1.06
                            </td>
                            <td className="px-6 py-3   ">
                            20.03
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Peak Demand (kW)
                            </td>
                            <td className="px-6 py-3   ">
                            6.41
                            </td>
                            <td className="px-6 py-3   ">
                            0.09
                            </td>
                            <td className="px-6 py-3   ">
                            6.5
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Average (kW)
                            </td>
                            <td className="px-6 py-3   ">
                            0.79
                            </td>
                            <td className="px-6 py-3   ">
                            0.04
                            </td>
                            <td className="px-6 py-3   ">
                            0.83
                            </td>
                    
                        
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
