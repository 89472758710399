import React from "react";
import logodocument from "../../assets/images/logodocument.svg";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";

import {
  setTitle,
  setShadingAnalysis,
  setSiteDiscussionsGeneralEquips,
  setSiteDiscussionsSiteAreas,
  setSetbackNotesSetbackAssumptions,
  setElectricalSizingLoadBasisSummaries,
  setElectricalSizingSiteAreas,
  setDesignParameters,
  setCivilDesign,
  setTemperatureDesign,
  setDesignDeviation,
  setMonitoringOverview,
  setDesignLife,
  setOthers,
} from "../../redux/slices/basisOfDesignSlice";

import { useSelector, useDispatch } from "react-redux";
import Config from "../../utils/Config";

import swal from "sweetalert";

export default function BasicDesignsiderbar({ basicdesign, setbasicdesign }) {
  const { site_id, design_id } = useParams();
  const { pathname } = useLocation();
  const [progress, serProgress] = React.useState(0);
  const user = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const basisOfDesign = useSelector((state) => state.basisOfDesign);

  const {
    title,
    shading_analysis,
    site_discussions_general_equips,
    site_discussions_site_areas,
    setback_notes_setback_assumptions,
    electrical_sizing_load_basis_summaries,
    electrical_sizing_site_areas,
    design_parameters,
    civil_design,
    temperature_design,
    design_deviation,
    monitoring_overview,
    design_life,
  } = basisOfDesign;

  React.useEffect(() => {
    let progress = 0;

    if (title.length) progress += 7.7;
    if (shading_analysis) progress += 7.7;
    if (site_discussions_general_equips.length) progress += 7.7;
    if (site_discussions_site_areas.length) progress += 7.7;
    if (setback_notes_setback_assumptions.length) progress += 7.7;
    if (electrical_sizing_load_basis_summaries.length) progress += 7.7;
    if (electrical_sizing_site_areas.length) progress += 7.7;
    if (design_parameters.length) progress += 7.7;
    if (civil_design.length) progress += 7.7;
    if (temperature_design.length) progress += 7.7;
    if (design_deviation.length) progress += 7.7;
    if (monitoring_overview.length) progress += 7.7;
    if (design_life.length) progress += 7.7;

    if (
      title.length &&
      shading_analysis &&
      site_discussions_general_equips.length &&
      site_discussions_site_areas.length &&
      setback_notes_setback_assumptions.length &&
      electrical_sizing_load_basis_summaries.length &&
      electrical_sizing_site_areas.length &&
      design_parameters.length &&
      civil_design.length &&
      temperature_design.length &&
      design_deviation.length &&
      monitoring_overview.length &&
      design_life.length
    ) {
      progress = 100;
    }

    serProgress(progress);
  }, [basisOfDesign]);

  React.useEffect(() => {
    fetch(`${Config.API.URL}/design-basis/${design_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          localStorage.removeItem("user");
          navigate("/");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        console.log("res:", res);
        if (res.status) {
          let data = res.data;

          dispatch(setTitle(data.title));
          dispatch(setShadingAnalysis(data.shading_analysis));
          dispatch(
            setSiteDiscussionsGeneralEquips(
              data.site_discussions_general_equips
            )
          );
          dispatch(
            setSiteDiscussionsSiteAreas(data.site_discussions_site_areas)
          );
          dispatch(
            setSetbackNotesSetbackAssumptions(
              data.setback_notes_setback_assumptions
            )
          );
          dispatch(
            setElectricalSizingLoadBasisSummaries(
              data.electrical_sizing_load_basis_summaries
            )
          );
          dispatch(
            setElectricalSizingSiteAreas(data.electrical_sizing_site_areas)
          );
          dispatch(setDesignParameters(data.design_parameters));
          dispatch(setCivilDesign(data.civil_design));
          dispatch(setTemperatureDesign(data.temperature_design));
          dispatch(setDesignDeviation(data.design_deviation));
          dispatch(setMonitoringOverview(data.monitoring_overview));
          dispatch(setDesignLife(data.design_life));
          dispatch(setOthers(data.others));
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  }, []);

  // console.log('pathname:', pathname);

  return (
    <>
      <div
        onClick={() => setbasicdesign(false)}
        className={` ${basicdesign ? "navbar-white" : ""}  lg:hidden`}
      ></div>
      <div
        className={` bg-white p-3 ${
          basicdesign ? " w-[260px] " : " w-[60px] sm:w-[80px] "
        }  z-10  rounded-[8px] sticky top-5  mt-3  h-[calc(100vh-30px)]  transition-[width] `}
      >
        <ul
          className={` ${
            basicdesign ? " " : " w-full justify-center "
          } inline-flex items-center mt-5`}
        >
          <li>
            <img alt="" src={logodocument} className=" w-[33px] h-[38px] " />
          </li>
          {basicdesign && (
            <li className=" ml-3 ">
              <h3 className=" f-f-r text-black text-2xl ">Basis Of Design</h3>
            </li>
          )}
        </ul>
        <div className="border  border-primary-light my-3 "></div>

        {basicdesign && (
          <ul className=" inline-flex  w-full items-center">
            <li className=" w-full ">
              <div className="w-full bg-gray-200 rounded-full h-1.5  ">
                <div
                  className="bg-primary h-1.5 rounded-full "
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </li>
            <li className=" ml-2 f-f-r text-black text-sm ">
              {Math.round(progress)}%
            </li>
          </ul>
        )}

        <div className=" bg-white-light sm:p-4 my-4 rounded-[8px] h-[63%]  overflow-y-scroll w-full ">
          <ul>
            <Link to={`/basis/document/overview/${site_id}/${design_id}`}>
              <li>
                {/* button started */}
                <button
                  className={` ${basicdesign ? " px-3   " : " w-[26px]  "} ${
                    pathname ===
                    `/basis/document/overview/${site_id}/${design_id}`
                      ? "bg-red-100"
                      : ""
                  } hover:bg-red-100 w-full rounded-[8px] h-[40px]  f-f-m text-black   text-base`}
                >
                  <ul className=" inline-flex items-center mt-1 ">
                    <li>
                      <div
                        className={` w-[20px] h-[20px] bg-${
                          title.length ? "primary" : "gray-200"
                        } rounded-full   `}
                      ></div>
                    </li>

                    {basicdesign && (
                      <li className=" ml-3"> Document Overview</li>
                    )}
                  </ul>
                </button>

                {/* button edned */}
              </li>
            </Link>
            <Link to={`/basis/site/discussion/${site_id}/${design_id}`}>
              <li className=" mt-2">
                {/* button started */}
                <button
                  className={` ${
                    basicdesign ? " px-3   " : " w-[26px] pl-[3px] px-3 "
                  } ${
                    pathname ===
                    `/basis/site/discussion/${site_id}/${design_id}`
                      ? "bg-red-100"
                      : ""
                  } hover:bg-red-100 w-full rounded-[8px] h-[40px]  f-f-m text-black  text-left text-base`}
                >
                  <ul className=" inline-flex items-center mt-1 ">
                    <li>
                      <div
                        className={` w-[20px] h-[20px] bg-${
                          site_discussions_general_equips.length &&
                          site_discussions_site_areas.length
                            ? "primary"
                            : "gray-200"
                        } rounded-full   `}
                      ></div>
                    </li>

                    {basicdesign && <li className=" ml-3">Site Discussion</li>}
                  </ul>
                </button>

                {/* button edned */}
              </li>
            </Link>
            <Link to={`/basis/setback/asumption/${site_id}/${design_id}`}>
              <li className=" mt-2">
                {/* button started */}
                <button
                  className={` ${
                    basicdesign ? " px-3  " : " w-[26px] pl-[3px] "
                  } ${
                    pathname ===
                    `/basis/setback/asumption/${site_id}/${design_id}`
                      ? "bg-red-100"
                      : ""
                  } hover:bg-red-100 w-full rounded-[8px] h-[40px]  f-f-m text-black  text-left text-base`}
                >
                  <ul className=" inline-flex items-center mt-1 ">
                    <li>
                      <div
                        className={` w-[20px] h-[20px] bg-${
                          setback_notes_setback_assumptions.length
                            ? "primary"
                            : "gray-200"
                        } rounded-full   `}
                      ></div>
                    </li>
                    {basicdesign && <li className=" ml-3">Setback Notes</li>}
                  </ul>
                </button>

                {/* button edned */}
              </li>
            </Link>
            <Link to={`/basis/shading/analysis/${site_id}/${design_id}`}>
              <li className=" mt-2">
                {/* button started */}
                <button
                  className={` ${
                    basicdesign ? " px-3  " : " w-[26px] pl-[3px] "
                  } ${
                    pathname ===
                    `/basis/shading/analysis/${site_id}/${design_id}`
                      ? "bg-red-100"
                      : ""
                  } hover:bg-red-100 w-full rounded-[8px] h-[40px]  f-f-m text-black  text-left text-base`}
                >
                  <ul className=" inline-flex items-center mt-1 ">
                    <li>
                      <div
                        className={` w-[20px] h-[20px] bg-${
                          shading_analysis ? "primary" : "gray-200"
                        } rounded-full   `}
                      ></div>
                    </li>

                    {basicdesign && <li className=" ml-3">Shading Analysis</li>}
                  </ul>
                </button>

                {/* button edned */}
              </li>
            </Link>
            <Link to={`/basis/electric/sizing/${site_id}/${design_id}`}>
              <li className=" mt-2">
                {/* button started */}
                <button
                  className={`${
                    basicdesign ? " px-3 " : " w-[26px] pl-[3px] "
                  } ${
                    pathname ===
                    `/basis/electric/sizing/${site_id}/${design_id}`
                      ? "bg-red-100"
                      : ""
                  } hover:bg-red-100 w-full rounded-[8px] h-[40px]  f-f-m text-black  text-left text-base`}
                >
                  <ul className=" inline-flex items-center mt-1 ">
                    <li>
                      <div
                        className={` w-[20px] h-[20px] bg-${
                          electrical_sizing_load_basis_summaries.length &&
                          electrical_sizing_site_areas.length
                            ? "primary"
                            : "gray-200"
                        } rounded-full   `}
                      ></div>
                    </li>
                    {basicdesign && (
                      <li className=" ml-3">Electrical Sizing</li>
                    )}
                  </ul>
                </button>

                {/* button edned */}
              </li>
            </Link>
            <Link to={`/basis/design/assupmtion/${site_id}/${design_id}`}>
              <li className=" mt-2">
                {/* button started */}
                <button
                  className={` ${
                    basicdesign ? " px-3  " : " w-[26px] pl-[3px] "
                  } ${
                    pathname ===
                    `/basis/design/assupmtion/${site_id}/${design_id}`
                      ? "bg-red-100"
                      : ""
                  } hover:bg-red-100 w-full rounded-[8px] h-[40px]  f-f-m text-black  text-left text-base`}
                >
                  <ul className=" inline-flex items-center mt-1 ">
                    <li>
                      <div
                        className={` w-[20px] h-[20px] bg-${
                          design_parameters.length ? "primary" : "gray-200"
                        } rounded-full   `}
                      ></div>
                    </li>
                    {basicdesign && (
                      <li className=" ml-3">Design Parameters</li>
                    )}
                  </ul>
                </button>

                {/* button edned */}
              </li>
            </Link>
            <Link to={`/basis/civil/design/${site_id}/${design_id}`}>
              <li className=" mt-2">
                {/* button started */}
                <button
                  className={` ${
                    basicdesign ? " px-3  " : " w-[26px] pl-[3px] "
                  } ${
                    pathname === `/basis/civil/design/${site_id}/${design_id}`
                      ? "bg-red-100"
                      : ""
                  } hover:bg-red-100 w-full rounded-[8px] h-[40px]  f-f-m text-black  text-left text-base`}
                >
                  <ul className=" inline-flex items-center mt-1 ">
                    <li>
                      <div
                        className={` w-[20px] h-[20px] bg-${
                          civil_design.length ? "primary" : "gray-200"
                        } rounded-full   `}
                      ></div>
                    </li>
                    {basicdesign && <li className=" ml-3">Civil Design</li>}
                  </ul>
                </button>

                {/* button edned */}
              </li>
            </Link>

            <Link to={`/basis/temperature/design/${site_id}/${design_id}`}>
              <li className=" mt-2">
                {/* button started */}
                <button
                  className={` ${
                    basicdesign ? " px-3   w-[209px] " : " w-[26px] pl-[3px] "
                  }  ${
                    pathname ===
                    `/basis/temperature/design/${site_id}/${design_id}`
                      ? "bg-red-100"
                      : ""
                  } hover:bg-red-100  rounded-[8px] h-[40px]  f-f-m text-black  text-left text-base`}
                >
                  <ul className=" inline-flex items-center mt-1 ">
                    <li>
                      <div
                        className={` w-[20px] h-[20px] bg-${
                          temperature_design.length ? "primary" : "gray-200"
                        } rounded-full   `}
                      ></div>
                    </li>

                    {basicdesign && (
                      <li className=" ml-3">Temperature Design</li>
                    )}
                  </ul>
                </button>

                {/* button edned */}
              </li>
            </Link>
            <Link to={`/basis/design/deviations/${site_id}/${design_id}`}>
              <li className=" mt-2">
                {/* button started */}
                <button
                  className={` ${
                    basicdesign ? " px-3  " : " w-[26px] pl-[3px] "
                  } ${
                    pathname ===
                    `/basis/design/deviations/${site_id}/${design_id}`
                      ? "bg-red-100"
                      : ""
                  } hover:bg-red-100 w-full rounded-[8px] h-[40px]  f-f-m text-black  text-left text-base`}
                >
                  <ul className=" inline-flex items-center mt-1 ">
                    <li>
                      <div
                        className={` w-[20px] h-[20px] bg-${
                          design_deviation.length ? "primary" : "gray-200"
                        } rounded-full   `}
                      ></div>
                    </li>
                    {basicdesign && (
                      <li className=" ml-3">Design Deviations</li>
                    )}
                  </ul>
                </button>

                {/* button edned */}
              </li>
            </Link>
            <Link to={`/basis/monitoring/overview/${site_id}/${design_id}`}>
              <li className=" mt-2">
                {/* button started */}
                <button
                  className={` ${
                    basicdesign ? " px-3   w-[209px] " : " w-[26px] pl-[3px] "
                  } ${
                    pathname ===
                    `/basis/monitoring/overview/${site_id}/${design_id}`
                      ? "bg-red-100"
                      : ""
                  } hover:bg-red-100  rounded-[8px] h-[40px]  f-f-m text-black  text-left text-base`}
                >
                  <ul className=" inline-flex items-center mt-1 ">
                    <li>
                      <div
                        className={` w-[20px] h-[20px] bg-${
                          monitoring_overview.length ? "primary" : "gray-200"
                        } rounded-full   `}
                      ></div>
                    </li>
                    {basicdesign && (
                      <li className=" ml-3">Monitoring Overview</li>
                    )}
                  </ul>
                </button>

                {/* button edned */}
              </li>
            </Link>
            <Link to={`/basis/design/life/${site_id}/${design_id}`}>
              <li className=" mt-2">
                {/* button started */}
                <button
                  className={` ${
                    basicdesign ? " px-3  " : " w-[26px] pl-[3px] "
                  } ${
                    pathname === `/basis/design/life/${site_id}/${design_id}`
                      ? "bg-red-100"
                      : ""
                  } hover:bg-red-100 w-full rounded-[8px] h-[40px] px-3 f-f-m text-black  text-left text-base`}
                >
                  <ul className=" inline-flex items-center mt-1 ">
                    <li>
                      <div
                        className={` w-[20px] h-[20px] bg-${
                          design_life.length ? "primary" : "gray-200"
                        } rounded-full   `}
                      ></div>
                    </li>
                    {basicdesign && <li className=" ml-3">Design Life</li>}
                  </ul>
                </button>

                {/* button edned */}
              </li>
            </Link>
          </ul>
        </div>
        {basicdesign && (
          <>
            <div className=" absolute w-[91%] bottom-[20px] ">
              <Link to={`/preview/doc/${site_id}/${design_id}`}>
                <button className=" mt-5 relative border bg-primary w-full text-center h-[48px] rounded-[8px] f-f-r text-lg text-white  ">
                  Preview
                </button>
              </Link>
              <Link to={`/scenarios/view/${site_id}`}>
                <button className=" mt-3 relative border border-primary w-full text-center h-[48px] rounded-[8px] f-f-r text-lg text-primary  ">
                  Back to site
                </button>
              </Link>
            </div>
          </>
        )}

        {/* open close started */}
        <div
          onClick={() => setbasicdesign(!basicdesign)}
          className=" w-[28px] h-[28px] bg-white rounded-[8px] text-center absolute top-[40px] right-[-13px]  cursor-pointer shadow-lg  "
        >
          <svg
            data-accordion-icon
            fill="#000000"
            className={` ${
              basicdesign ? "rotate-[-90deg]" : "rotate-[90deg]"
            }  transition-rotate w-3 h-3 relative top-2  shrink-0 ml-auto mr-auto `}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5 5 1 1 5"
            />
          </svg>
        </div>
        {/* open close ended */}
      </div>
    </>
  );
}
