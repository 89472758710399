import React from 'react'
import Tableproject from './Tableproject'

export default function Mainsection() {
  return (
    <div className='bg-white rounded-[8px] p-4 mt-[90px] lg:mt-3 ' >
          <h2 className=' f-f-sm text-3xl text-black text-center mt-4 ' >Basis of Design</h2>
          <h4 className=' f-f-r text-2xl text-primary text-center  my-2 ' >Select a project below to start creating a basis of design</h4>
          <Tableproject/>
    </div>
  )
}
