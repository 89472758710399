import React from "react";

export default function SetTable({ setback_notes_setback_assumptions }) {
  return (
    <div className="pr-10 pl-10">
      <div className="relative overflow-x-auto mt-3 ">
        <table className=" table-site w-full border border-slate-500 text-left  ">
          <caption className="border border-slate-600 px-3 py-2 f-f-m  text-lg text-center  bg-primary text-white ">
            Setback Assumption
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b  text-sm text-black-dark ">
              <th className="border border-slate-600 px-6 py-3">Equipment</th>
              <th className="border border-slate-600 px-6 py-3">Setback</th>
              <th className="border border-slate-600 px-6 py-3">Assumption</th>
            </tr>
          </thead>
          <tbody>
            {setback_notes_setback_assumptions.map((assumption, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-sm text-black-dark "
                >
                  <td className="border border-slate-600 px-6 py-3">{assumption.equipment}</td>
                  <td className="border border-slate-600 px-6 py-3">{assumption.setback}</td>
                  <td className="border border-slate-600 px-6 py-3">{assumption.assumption}</td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            General Equipment 
                            </td>
                            <td className="px-6 py-3   ">
                            5’
                            </td>
                            <td className="px-6 py-3   ">
                            Minimum of a 5’ setback around all equipment for site safety 
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Solar Container 
                            </td>
                            <td className="px-6 py-3   ">
                            5’
                            </td>
                            <td className="px-6 py-3   ">
                            Setback around Solar Container end doors for personnel access 
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            PV Array
                            </td>
                            <td className="px-6 py-3   ">
                            5’
                            </td>
                            <td className="px-6 py-3   ">
                            Minimum setback around PV array for working clearances. Additionally there will be a 13’ setback from existing buildings. 14’ minimum clear height.
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            PV Array Mounting
                            </td>
                            <td className="px-6 py-3   ">
                            14’
                            </td>
                            <td className="px-6 py-3   ">
                            Minimum clear height from the ground
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            PCC Switchgear 
                            </td>
                            <td className="px-6 py-3   ">
                            5’
                            </td>
                            <td className="px-6 py-3   ">
                            Setback in front of PCC/Switchgear for working clearances 
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            HVAC Unit 
                            </td>
                            <td className="px-6 py-3   ">
                            5’
                            </td>
                            <td className="px-6 py-3   ">
                            Setback in front of HVAC for working clearances 
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Propane Generator 
                            </td>
                            <td className="px-6 py-3   ">
                            5’
                            </td>
                            <td className="px-6 py-3   ">
                            Setback from all other equipment
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Propane Tank 
                            </td>
                            <td className="px-6 py-3   ">
                            10’
                            </td>
                            <td className="px-6 py-3   ">
                            1,000 gallon propane tank requires a 10’ setback from all other equipment per NFPA 58 
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Perimeter Security Fence 
                            </td>
                            <td className="px-6 py-3   ">
                            Varies
                            </td>
                            <td className="px-6 py-3   ">
                            Fence must remain the minimum setback distance from any piece of equipment. Some equipment will have a larger setback area. 
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            30’ Defensible Space 
                            </td>
                            <td className="px-6 py-3   ">
                            30’
                            </td>
                            <td className="px-6 py-3   ">
                            Minimum setback from the edges of all equipment for defensible space, actual perimeter was expanded to a more rectangular shape to facilitate easement process 
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            100’ Defensible Space 
                            </td>
                            <td className="px-6 py-3   ">
                            100’
                            </td>
                            <td className="px-6 py-3   ">
                            Minimum setback from the edges of all equipment for defensible space, 70’ setback from 30’ defensible space perimeter
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Bollards
                            </td>
                            <td className="px-6 py-3   ">
                            5’
                            </td>
                            <td className="px-6 py-3   ">
                            Bollards will be 42 inches apart, and have a 5’ clear zone from any piece of equipment.
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            First Responder Panel
                            </td>
                            <td className="px-6 py-3   ">
                            3’
                            </td>
                            <td className="px-6 py-3   ">
                            Clean zone from the first responder panel for first responder safety and working clearances.
                            </td>
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
