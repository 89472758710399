import React from "react";

export default function TemperatureTable(props) {
  const [isEditable, setIsEditable] = React.useState(false);

  const [temperatures, setTemperatures] = React.useState([
    {
      month: "Feb",
      temperature: 7.5,
      total_heating_demand: 54.72,
      total_cooling_demand: 56.49,
    },
    {
      month: "Mar",
      temperature: 9.8,
      total_heating_demand: 47.33,
      total_cooling_demand: 48.86,
    },
    {
      month: "Apr",
      temperature: 10.3,
      total_heating_demand: 43.33,
      total_cooling_demand: 44.73,
    },
    {
      month: "May",
      temperature: 16.4,
      total_heating_demand: 10.94,
      total_cooling_demand: 11.29,
    },
    {
      month: "Jun",
      temperature: 19.4,
      total_heating_demand: 0.0,
      total_cooling_demand: 5.64,
    },
    {
      month: "Jul",
      temperature: 21.2,
      total_heating_demand: 0.0,
      total_cooling_demand: 16.75,
    },
    {
      month: "Jul",
      temperature: 21.2,
      total_heating_demand: 0.0,
      total_cooling_demand: 16.75,
    },
    {
      month: "Aug",
      temperature: 20.2,
      total_heating_demand: 0.0,
      total_cooling_demand: 10.12,
    },
    {
      month: "Sep",
      temperature: 20.8,
      total_heating_demand: 0.0,
      total_cooling_demand: 14.22,
    },
    {
      month: "Oct",
      temperature: 15.7,
      total_heating_demand: 13.94,
      total_cooling_demand: 14.39,
    },
    {
      month: "Nov",
      temperature: 9.4,
      total_heating_demand: 47.68,
      total_cooling_demand: 49.22,
    },
    {
      month: "Dec",
      temperature: 7.6,
      total_heating_demand: 59.7,
      total_cooling_demand: 61.63,
    },
  ]);
  const _onChangeTemperaturesInput = (e) => {
    let data = [...temperatures];

    data[e.target.id][e.target.name] = e.target.value;

    setTemperatures(data);
  };
  const saveChanges = () => {
    props.onSaveTemperatureDesign(temperatures);
    setIsEditable(!isEditable);
    props.onEditTemperatureTable(!isEditable);
  };
  const _setIsEditable = () => {
    setIsEditable(!isEditable);
    props.onEditTemperatureTable(!isEditable);
  };
  return (
    <div>
      <div className="relative overflow-x-auto mt-8 ">
        <button
          onClick={ isEditable ? saveChanges : _setIsEditable }
          className="absolute top-2 right-2 border bg-primary w-[45px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
        >
          {isEditable ? "Save" : "Edit"}
        </button>
        <table className=" table-site w-full  text-left  ">
          <caption className="px-3 py-2 f-f-m text-2xl text-center  bg-primary text-white ">
            TEMPERATURE DESIGN
          </caption>

          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b text-base text-black-dark ">
              <th className="px-6 py-3 min-w-[170px]">Month</th>
              <th className="px-6 py-3 min-w-[170px]">Temperature (°C)</th>
              <th className="px-6 py-3  min-w-[170px]">
                Total Heating Demand (kWh)
              </th>
              <th className="px-6 py-3 min-w-[170px]">
                Total Cooling Demand (kWh)
              </th>
              {/* <th className="px-6 py-3 min-w-[170px]"> Total Combined Heating and Cooling (kWh)</th>
                            <th className="px-6 py-3 min-w-[170px]">Daily Combined Heating and Cooling (kWh)</th> */}
            </tr>
          </thead>
          <tbody>
            {temperatures.map((temperature, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-base text-black-dark "
                >
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeTemperaturesInput}
                        className="input-equipment-table"
                        id={i}
                        name="month"
                        value={temperature.month}
                      />
                    ) : (
                      temperature.month
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeTemperaturesInput}
                        className="input-equipment-table"
                        id={i}
                        name="temperature"
                        value={temperature.temperature}
                      />
                    ) : (
                      temperature.temperature
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeTemperaturesInput}
                        className="input-equipment-table"
                        id={i}
                        name="total_heating_demand"
                        value={temperature.total_heating_demand}
                      />
                    ) : (
                      temperature.total_heating_demand
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeTemperaturesInput}
                        className="input-equipment-table"
                        id={i}
                        name="total_cooling_demand"
                        value={temperature.total_cooling_demand}
                      />
                    ) : (
                      temperature.total_cooling_demand
                    )}
                  </td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Feb
                            </td>
                            <td className="px-6 py-3   ">
                            7.5
                            </td>
                            <td className="px-6 py-3   ">
                            54.72
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            54.72
                            </td>
                            <td className="px-6 py-3   ">
                            1.77
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Mar
                            </td>
                            <td className="px-6 py-3   ">
                            9.8
                            </td>
                            <td className="px-6 py-3   ">
                            47.33
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            47.33
                            </td>
                            <td className="px-6 py-3   ">
                            1.53
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Apr
                            </td>
                            <td className="px-6 py-3   ">
                            10.3
                            </td>
                            <td className="px-6 py-3   ">
                            43.33
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            43.33
                            </td>
                            <td className="px-6 py-3   ">
                            1.40
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            May
                            </td>
                            <td className="px-6 py-3   ">
                            16.4
                            </td>
                            <td className="px-6 py-3   ">
                            10.94
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            10.94
                            </td>
                            <td className="px-6 py-3   ">
                            0.35
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Jun
                            </td>
                            <td className="px-6 py-3   ">
                            19.4
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            5.46
                            </td>
                            <td className="px-6 py-3   ">
                            5.46
                            </td>
                            <td className="px-6 py-3   ">
                            0.18
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Jul
                            </td>
                            <td className="px-6 py-3   ">
                            21.2
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            16.23
                            </td>
                            <td className="px-6 py-3   ">
                            16.23
                            </td>
                            <td className="px-6 py-3   ">
                            0.52
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Jul
                            </td>
                            <td className="px-6 py-3   ">
                            21.2
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            16.23
                            </td>
                            <td className="px-6 py-3   ">
                            16.23
                            </td>
                            <td className="px-6 py-3   ">
                            0.52
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Aug
                            </td>
                            <td className="px-6 py-3   ">
                            20.2
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            9.80
                            </td>
                            <td className="px-6 py-3   ">
                            9.80
                            </td>
                            <td className="px-6 py-3   ">
                            0.32
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Sep
                            </td>
                            <td className="px-6 py-3   ">
                            20.8
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            13.78
                            </td>
                            <td className="px-6 py-3   ">
                            13.78
                            </td>
                            <td className="px-6 py-3   ">
                            0.44
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Oct
                            </td>
                            <td className="px-6 py-3   ">
                            15.7
                            </td>
                            <td className="px-6 py-3   ">
                            13.94
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            13.94
                            </td>
                            <td className="px-6 py-3   ">
                            0.45
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Nov
                            </td>
                            <td className="px-6 py-3   ">
                            9.4
                            </td>
                            <td className="px-6 py-3   ">
                            47.68
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            47.68
                            </td>
                            <td className="px-6 py-3   ">
                            1.54
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Dec
                            </td>
                            <td className="px-6 py-3   ">
                            7.6
                            </td>
                            <td className="px-6 py-3   ">
                            59.70
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            59.70
                            </td>
                            <td className="px-6 py-3   ">
                            1.93
                            </td>
                    
                        
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
