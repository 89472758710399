import React, { useState } from "react";
import "react-phone-number-input/style.css";
import Config from "../../utils/Config";
import { useSelector } from "react-redux";

import swal from "sweetalert";

export default function SiteMilestonesDialogBox(props) {
  const user = useSelector((state) => state.user.profile);

  const [showModal, setShowModal] = useState(false);
  const [on, seton] = useState("Add");
  // const [ value, setValue ] = useState();
  const data = (get) => seton(get);

  const [targetData, setTargetData] = useState("");
  const [dateAchieved, setDateAchieved] = useState("");
  const [milestoneName, setMilestoneName] = useState("");

  const [loading, setLoading] = useState(false);

  const submitMilestone = () => {
    setLoading(true);
    const payload = [
      {
        site_id: props.site.id,
        target_date: targetData,
        // achieved_date: dateAchieved,
        title: milestoneName,
      },
    ];

    fetch(`${Config.API.URL}/site-milestones`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          //localStorage.removeItem('token');
          //localStorage.removeItem('user');
          //history.push('/');
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        // console.log('res:', res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          props.onReceiveMilestone(res.data);

          setTargetData("");
          //setDateAchieved("");
          setMilestoneName("");

          setShowModal(false);

          //swal('The "' + payload.name + '" project has been successfully cloned!', { icon: "success" });
        } else {
          alert(res.message);
          //swal("Oh no!", res.message, "error");
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);

        alert(
          "An error occured!!! while connecting to server. Please try again"
        );

        //swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
      });
  };

  return (
    <div>
      <button
        onClick={() => setShowModal(true)}
        className="absolute top-3 right-3 border bg-primary w-[120px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
      >
        Add Milestones
      </button>

      {showModal ? (
        <>
          <div className="dilog-box" onClick={() => setShowModal(false)}></div>
          <div
            id="defaultModal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed top-[16%] lg:top-0  right-0 z-50  ml-auto w-[85%] md:w-[93%] lg:w-full  p-4 overflow-x-hidden overflow-y-auto inset-0 h-[calc(100%-1rem)] max-h-full"
          >
            <div className="relative w-full max-w-2xl max-h-full ml-auto mr-auto ">
              <div className="relative bg-white rounded-lg shadow  p-10 ">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className=" absolute top-[19px] right-[19px]  text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-hide="defaultModal"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.918759 13.0577C0.360088 13.6164 0.360088 14.5222 0.918759 15.0809C1.47743 15.6395 2.38321 15.6395 2.94189 15.0809L7.99981 10.0229L13.0579 15.0811C13.6166 15.6397 14.5224 15.6397 15.081 15.0811C15.6397 14.5224 15.6397 13.6166 15.081 13.0579L10.0229 7.99982L15.0806 2.94213C15.6393 2.38346 15.6393 1.47767 15.0806 0.919002C14.522 0.360332 13.6162 0.360333 13.0575 0.919003L7.99981 5.9767L2.9423 0.919193C2.38363 0.360523 1.47784 0.360523 0.919172 0.919193C0.360502 1.47786 0.360503 2.38365 0.919174 2.94232L5.97668 7.99982L0.918759 13.0577Z"
                      fill="#FA3030"
                    />
                  </svg>
                </button>
                {/* button started */}
                <div className="grid grid-cols-12  mt-8  ">
                  <div className=" col-span-12 sm:col-span-6 ">
                    <button
                      onClick={() => data("create")}
                      className={` ${
                        on === "create" ? " bg-white-light" : " bg-white"
                      } w-full f-f-r text-base text-black    h-[40px] border border-grey `}
                    >
                      Create New
                    </button>
                  </div>
                  <div className=" col-span-12 sm:col-span-6 ">
                    <button
                      onClick={() => data("Add")}
                      className={`${
                        on === "Add" ? " bg-white-light" : " bg-white"
                      } w-full f-f-r text-base text-black    h-[40px] border border-grey `}
                    >
                      Add Existing
                    </button>
                  </div>
                </div>
                {/*button edned  */}

                <>
                  <div className=" mt-8 ">
                    <div className="grid grid-cols-12   ">
                      {/*  col started */}
                      <div className=" col-span-12 lg:col-span-12 mt-5 ">
                        <h2 className=" f-f-r  xl:text-2xl text-xl  text-primary mt-3 ">
                          Milestone Name
                        </h2>
                      </div>
                      <div className=" col-span-12 lg:col-span-12 ">
                        {on === "Add" ? (
                          <select
                            onChange={(e) => setMilestoneName(e.target.value)}
                            value={milestoneName}
                            className="w-full  h-[56px] border cursor-pointer border-grey bg-white-dark rounded-[4px] px-4 outline-none"
                          >
                            <option value=""></option>
                            {props?.site?.general_stage ===
                            "Feasibility Study" ? (
                              <>
                                <option value="Project Contracted">
                                  Project Contracted
                                </option>
                                <option value="Data Transfer">
                                  Data Transfer
                                </option>
                                <option value="Site Visit">Site Visit</option>
                                <option value="Feasibility Report Submission">
                                  Feasibility Report Submission
                                </option>
                                <option value="EPC Contract Finalized">
                                  EPC Contract Finalized
                                </option>
                              </>
                            ) : null}

                            {props?.site?.general_stage === "Turnkey" ? (
                              <>
                                <option value="Contract NTP">
                                  Contract NTP
                                </option>
                                <option value="Design and Engineering">
                                  Design and Engineering
                                </option>
                                <option value="Major Equipment">
                                  Major Equipment
                                </option>
                                <option value="Major Equipment Delivery">
                                  Major Equipment Delivery
                                </option>
                                <option value="Mechanical Completion">
                                  Mechanical Completion
                                </option>
                                <option value="Final Completion">
                                  Final Completion
                                </option>
                                <option value="Guaranteed Final Completion">
                                  Guaranteed Final Completion
                                </option>
                              </>
                            ) : null}

                            {!props?.site?.general_stage ? (
                              <>
                                <option value="Project Contracted">
                                  Project Contracted
                                </option>
                                <option value="Data Transfer">
                                  Data Transfer
                                </option>
                                <option value="Site Visit">Site Visit</option>
                                <option value="Feasibility Report Submission">
                                  Feasibility Report Submission
                                </option>
                                <option value="EPC Contract Finalized">
                                  EPC Contract Finalized
                                </option>

                                <option value="Contract NTP">
                                  Contract NTP
                                </option>
                                <option value="Design and Engineering">
                                  Design and Engineering
                                </option>
                                <option value="Major Equipment">
                                  Major Equipment
                                </option>
                                <option value="Major Equipment Delivery">
                                  Major Equipment Delivery
                                </option>
                                <option value="Mechanical Completion">
                                  Mechanical Completion
                                </option>
                                <option value="Final Completion">
                                  Final Completion
                                </option>
                                <option value="Guaranteed Final Completion">
                                  Guaranteed Final Completion
                                </option>
                              </>
                            ) : null}
                          </select>
                        ) : (
                          <input
                            type="text"
                            onChange={(e) => setMilestoneName(e.target.value)}
                            value={milestoneName}
                            className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
                          />
                        )}
                      </div>
                      {/* col ended */}

                      {/*  col started */}
                      <div className=" col-span-12 lg:col-span-12 mt-5 ">
                        <h2 className=" f-f-r  xl:text-2xl text-xl  text-primary mt-3 ">
                          Target Date
                        </h2>
                      </div>
                      <div className=" col-span-12 lg:col-span-12">
                        <input
                          type="date"
                          onChange={(e) => setTargetData(e.target.value)}
                          value={targetData}
                          className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
                        />
                      </div>

                      {/* col ended */}
                      {/*  col started */}
                      {/* <div className=" col-span-12 lg:col-span-12 mt-5 ">
                          <h2 className=" f-f-r  xl:text-2xl text-xl  text-primary mt-3 ">
                            Date Achieved
                          </h2>
                        </div>
                        <div className=" col-span-12 lg:col-span-12 ">
                          <input
                            type="date"
                            onChange={(e) => setDateAchieved(e.target.value)}
                            value={dateAchieved}
                            className=" w-full  h-[56px] border border-grey bg-white-dark rounded-[4px] px-4 outline-none  "
                          />
                        </div> */}

                      {/* col ended */}

                      {/* col ended */}
                    </div>
                    <div className=" mt-10 text-end  ">
                      {loading ? (
                        <button className=" bg-gray text-lg f-f-r text-gray  w-[127px] h-[48px] rounded-[8px] captilize ">
                          Loading...
                        </button>
                      ) : (
                        <button
                          onClick={submitMilestone}
                          className=" bg-primary text-lg f-f-r text-white  w-[127px] h-[48px] rounded-[8px] captilize "
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
