import React from 'react';

import { Link, useParams, useNavigate } from 'react-router-dom';
import Config from '../../../utils/Config';
import { useSelector, useDispatch } from 'react-redux';

import { setTitle } from "../../../redux/slices/basisOfDesignSlice";

import swal from 'sweetalert';

export default function Index() {
    const [ title, setTitleLocal ] = React.useState('');

    const user = useSelector((state) => state.user.profile);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { site_id, design_id } = useParams();

    const { title: basis_title, others } = useSelector((state) => state.basisOfDesign);

    const [ loading, setLoading  ] = React.useState(false);

    React.useEffect(() => {
        setTitleLocal(basis_title);
    }, [basis_title]);

    const saveChanges = () => {
        setLoading(true);
        const payload = { site_id, design_id, title: title, others };

        fetch(`${Config.API.URL}/design-basis/${design_id}`, {
            method: 'PATCH',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` },
            body: JSON.stringify(payload),
        }).then((res) => {
            setLoading(false);
            // if 401 error (unauthenticated user, then log user out)
            if ((res.status) === 401) {
                console.log("401 Error: Unauthenticated");

                localStorage.removeItem('user');
                navigate('/');
            }
            return res;
        }).then((res) => res.json()).then( async (res) => {
            setLoading(false);
            // console.log('res:', res);

            // if request is succesful, alert user that project has been cloned
            if (res.status) {
                let data = res.data;
                
                dispatch(setTitle(data.title));
                
                navigate(`/basis/site/discussion/${site_id}/${data.id}`);
            } else {
                swal("Oh no!", res.message, "error");
            }
        }).catch((error) => {
            setLoading(false);
            console.log('Error: ', error);
            
            swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
        });
    }
    
    return (
        <div className='relative' style={{ height: 300 }} >
            <h2 className=' f-f-r text-lg text-black ' >PG&E Basis of Design</h2>
            <h4 className=' f-f-sm text-black text-2xl  sm:text-3xl ' >Document Overview</h4>
            <div className=' border border-grey-borderclr rounded-[4px] p-2 bg-white ' >
                <h2 className=' f-f-m text-black text-lg pl-2 ' >Basis of Design Title</h2>
                <div className=' border border-grey-borderclr my-2  ' ></div>
                <input type='text' value={title} onChange={(e) => setTitleLocal(e.target.value)} className=' bg-white w-full  placeholder:text-grey px-2 text-black  outline-none text-xl ' placeholder='Enter a Title for Basis of Design'   />
            </div>

            <div className='  editign absolute bottom-0 w-full'>
                <div className=' border border-gray-300 mt-4'></div>
                <div className="grid grid-cols-12  gap-4">
                    <div className="   col-span-3">
                        <Link to={`/scenarios/view/${site_id}`} > {/* /basis/design/create/${site_id}/${design_id} */}
                            <button className="  mt-8 relative border border-primary w-[110px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-primary  ">
                                {/* inner ul started */}
                                <ul className=' inline-flex text-center justify-center mt-1 mr-3 ' >
                                    <li>
                                        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.25 7.5L11.75 13L17.25 18.5" stroke="#ED583B" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </li>
                                    <li className='ml-1' >Back</li>
                                </ul>
                                {/* inner ul ended */}
                            </button>
                        </Link>
                    </div>
                    <div className="   col-span-9">
                        <div className=' text-end ' >
                            <ul className=" inline-flex items-center  ">
                                <Link to={`/basis/site/discussion/${site_id}/${design_id}`}>
                                    <li><button className="mt-8 text-center f-f-r text-lg text-black-darkest">Skip</button></li>
                                </Link>
                                <li className=" ml-6 ">
                                    { loading ?
                                        <button className=" mt-8 relative border w-[127px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-primary">Loading...</button>
                                    :
                                        <button onClick={saveChanges} className=" mt-8 relative border bg-primary w-[127px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-white  ">Next</button>
                                    }
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
        
            </div>
    
        </div>
    )
}
