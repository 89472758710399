import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import Logo from "../../assets/images/Logo 2.svg";

import Config from "../../utils/Config";

import { useNavigate } from "react-router-dom";

import swal from "sweetalert";

import { setUserMiniProfile } from "../../redux/slices/userSlice";

import { useDispatch, useSelector } from 'react-redux';


export default function Index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();

  // const [ navbarshow, setnavbarshow ] = useState("false");
  // const [ isAuthed, setIsAuthed ] = useState(false);
  
  const [ loading, setLoading ] = useState(false);
  
  const userData = useSelector((state) => state.user.profile);

  React.useEffect(() => {
    // console.log('loading:', loading);
    // console.log('userData:', userData);
    if (!loading && userData?.id) {
      if (userData.first_name?.length && userData?.last_name?.length) {
        navigate('/dashboard');
      } else {
        navigate('/join');
      }
    }
  }, [userData]);

  React.useEffect(() => {
    setLoading(true);
    if (user) {
      authUser(user);
    }
  }, [isAuthenticated, user]);

  const authUser = async (me) => {
    const token = await getAccessTokenSilently();

    setLoading(true);
    fetch(`${Config.API.URL}/profiles/me`, {
      method: "GET",
      headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: `Bearer ${token}` },
    }).then((res) => {
      setLoading(false);

      // if 401 error (unauthenticated user, then log user out)
      if (res.status === 401) {
        console.log("401 Error: Unauthenticated");
        localStorage.removeItem("user");
        navigate("/");
      }
      return res;
    }).then((res) => res.json()).then(async (res) => {
      setLoading(false);
      // console.log('user-res:', res);

      // if request is succesful, alert user that project has been cloned
      if (res.status) {
        const data = { ...res.data, token };
        // console.log('user-data:', data);

        localStorage.setItem('user', JSON.stringify(data));
        dispatch(setUserMiniProfile(data));

        if (data.first_name?.length && data?.last_name?.length ) {
          navigate('/dashboard');
        } else {
          navigate('/join');
        }
        
      } else {
        swal("Oh no!", res.message, "error");
      }
    }).catch((error) => {
      setLoading(false);
      console.log("Error: ", error);

      swal("Oh no!", "An error occured!!! while connecting to server. Please try again", "error");
    });
  };

  return (
    <div className='bg-white ' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, height: '100vh', flexDirection: 'column' }} >
      <img src={Logo} className="w-[145px] h-auto 2xl:w-[196px] 2xl:h-[29px] mb-3" alt="" />
      { isLoading ? 
        <p>Loading...</p> 
      : 
        loading ? 
          <p>Authentication...</p> 
        : <p>Redirecting...</p> 
      }
      
    </div>
  )
}
