import React, { useState } from 'react';
import BasicDesignSidebar from './BasicDesignSidebar';

import { Outlet } from "react-router-dom";

export default function Mainsection() {
  const [ basicdesign, setbasicdesign ] = useState(true)
  return (
    <>
      <div className='relative mt-20 lg:mt-0  ' >
        <ul className=' inline-flex w-full ' >
          <li> <BasicDesignSidebar basicdesign={basicdesign} setbasicdesign={setbasicdesign} /> </li>
          <li className='w-full' >
            <div className= {`pt-10 pb-5 ${basicdesign ? " mr-auto pl-[27px] w-[89%] " : " mx-auto w-11/12 "  }   transition-[pl]`}   >
              <Outlet />
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}