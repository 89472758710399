import React, { useState } from "react";
import CryptoJS from "crypto-js";
import { useSelector } from "react-redux";
import { Menu } from "@headlessui/react";
import Dilogboxbasic from "./DialogBoxBasis";
import Deletedilogbox from "./DeleteDialog";
import { Link } from "react-router-dom";

export default function MyDropdown({ design, onDeleteBasisDesign }) {
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setshowModal2] = useState(false);
  const user = useSelector((state) => state.user.profile);

  const [cow, setCow] = useState("sokotogudali");
  let encodedUrl = encodeURIComponent(window.location.origin);

  const encryptToken = (token, secretKey) => {
    return CryptoJS.AES.encrypt(token, secretKey).toString();
  };

  let encryptedToken = encryptToken(user.token, cow);

  const editorUrl = "https://editor.boxpower.cloud";

  return (
    <>
      <Menu>
        <Menu.Button className=" absolute top-[12px] right-0 cursor-pointer dropdown-content ">
          {" "}
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.99286 7.80488C11.1833 7.80488 12.1357 8.78049 12.1357 10C12.1357 11.2195 11.1833 12.1951 9.99286 12.1951C8.80239 12.1951 7.85001 11.2195 7.85001 10C7.85001 8.78049 8.80239 7.80488 9.99286 7.80488ZM7.85001 2.19512C7.85001 3.41463 8.80239 4.39024 9.99286 4.39024C11.1833 4.39024 12.1357 3.41463 12.1357 2.19512C12.1357 0.97561 11.1833 0 9.99286 0C8.80239 0 7.85001 0.97561 7.85001 2.19512ZM7.85001 17.8049C7.85001 19.0244 8.80239 20 9.99286 20C11.1833 20 12.1357 19.0244 12.1357 17.8049C12.1357 16.5854 11.1833 15.6098 9.99286 15.6098C8.80239 15.6098 7.85001 16.5854 7.85001 17.8049Z"
              fill="black"
            />
          </svg>
        </Menu.Button>
        <Menu.Items>
          <div
            id="dropdown"
            className="z-10 px-3 absolute  right-[10px]  w-[160px] bg-white divide-y divide-gray-100 rounded-lg shadow "
          >
            <ul
              className="py-2 text-sm text-gray-700 dark:text-gray-200"
              aria-labelledby="dropdownDefaultButton"
            >
              <a
                href={`${editorUrl}/view?color=${encodeURIComponent(
                  encryptedToken
                )}&site=${design.site_id}&design=${
                  design.id
                }&callback=${encodedUrl}`}
              >
                <li className="profilebrdr2 cursor-pointer">
                  {/* inner ul started */}
                  <ul className="inline-flex">
                    <li className="mt-[4px]">
                      <svg
                        width="22"
                        height="16"
                        viewBox="0 0 22 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 2.5C12.8387 2.49389 14.6419 3.00678 16.2021 3.97973C17.7624 4.95267 19.0164 6.34616 19.82 8C18.17 11.37 14.8 13.5 11 13.5C7.2 13.5 3.83 11.37 2.18 8C2.98362 6.34616 4.23763 4.95267 5.79788 3.97973C7.35813 3.00678 9.16126 2.49389 11 2.5ZM11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 5.5C11.663 5.5 12.2989 5.76339 12.7678 6.23223C13.2366 6.70107 13.5 7.33696 13.5 8C13.5 8.66304 13.2366 9.29893 12.7678 9.76777C12.2989 10.2366 11.663 10.5 11 10.5C10.337 10.5 9.70107 10.2366 9.23223 9.76777C8.76339 9.29893 8.5 8.66304 8.5 8C8.5 7.33696 8.76339 6.70107 9.23223 6.23223C9.70107 5.76339 10.337 5.5 11 5.5ZM11 3.5C8.52 3.5 6.5 5.52 6.5 8C6.5 10.48 8.52 12.5 11 12.5C13.48 12.5 15.5 10.48 15.5 8C15.5 5.52 13.48 3.5 11 3.5Z"
                          fill="black"
                        />
                      </svg>
                    </li>
                    <li className="ml-2">
                      <h2 className="text-lg text-black f-f-r">View</h2>
                    </li>
                  </ul>
                  {/* inner ul ended */}
                </li>
              </a>

              {/*<Link
                to={`/basis/document/overview/${design.site_id}/${design.id}`}
              >
                <li className=" profilebrdr2 mt-1  cursor-pointer ">
                  <ul className=" inline-flex ">
                    <li>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 19H6.4L15.025 10.375L13.625 8.975L5 17.6V19ZM19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.4217 2.75 18.8923 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.655 21.1083 7.11733 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4ZM14.325 9.675L13.625 8.975L15.025 10.375L14.325 9.675Z"
                          fill="black"
                        />
                      </svg>
                    </li>
                    <li className=" ml-2 ">
                      <h2 className=" text-lg text-black f-f-r">Edit</h2>
                    </li>
                  </ul>
                </li>
              </Link> */}

              {/* <Link
                to={`/basis/document/overview/${design.site_id}/${design.id}`}
              >
                <li className=" profilebrdr2 mt-1  cursor-pointer ">
                  <ul className=" inline-flex ">
                    <li>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2004 2.40002C10.0413 2.40002 9.88865 2.46324 9.77613 2.57576C9.6636 2.68828 9.60039 2.84089 9.60039 3.00002C9.60039 3.15915 9.6636 3.31177 9.77613 3.42429C9.88865 3.53681 10.0413 3.60002 10.2004 3.60002H11.4004V20.4H10.2004C10.0413 20.4 9.88865 20.4632 9.77613 20.5758C9.6636 20.6883 9.60039 20.8409 9.60039 21C9.60039 21.1592 9.6636 21.3118 9.77613 21.4243C9.88865 21.5368 10.0413 21.6 10.2004 21.6H13.8004C13.9595 21.6 14.1121 21.5368 14.2247 21.4243C14.3372 21.3118 14.4004 21.1592 14.4004 21C14.4004 20.8409 14.3372 20.6883 14.2247 20.5758C14.1121 20.4632 13.9595 20.4 13.8004 20.4H12.6004V3.60002H13.8004C13.9595 3.60002 14.1121 3.53681 14.2247 3.42429C14.3372 3.31177 14.4004 3.15915 14.4004 3.00002C14.4004 2.84089 14.3372 2.68828 14.2247 2.57576C14.1121 2.46324 13.9595 2.40002 13.8004 2.40002H10.2004ZM5.40039 4.80002H10.2004V6.00002H5.40039C4.923 6.00002 4.46516 6.18967 4.1276 6.52723C3.79003 6.8648 3.60039 7.32263 3.60039 7.80002V16.2C3.60039 16.6774 3.79003 17.1353 4.1276 17.4728C4.46516 17.8104 4.923 18 5.40039 18H10.2004V19.2H5.40039C4.60474 19.2 3.84168 18.884 3.27907 18.3213C2.71646 17.7587 2.40039 16.9957 2.40039 16.2V7.80002C2.40039 7.00437 2.71646 6.24131 3.27907 5.6787C3.84168 5.1161 4.60474 4.80002 5.40039 4.80002ZM18.6004 18H13.8004V19.2H18.6004C19.396 19.2 20.1591 18.884 20.7217 18.3213C21.2843 17.7587 21.6004 16.9957 21.6004 16.2V7.80002C21.6004 7.00437 21.2843 6.24131 20.7217 5.6787C20.1591 5.1161 19.396 4.80002 18.6004 4.80002H13.8004V6.00002H18.6004C19.0778 6.00002 19.5356 6.18967 19.8732 6.52723C20.2107 6.8648 20.4004 7.32263 20.4004 7.80002V16.2C20.4004 16.6774 20.2107 17.1353 19.8732 17.4728C19.5356 17.8104 19.0778 18 18.6004 18Z"
                          fill="black"
                        />
                      </svg>
                    </li>
                    <li className=" ml-2 ">
                      <h2 className=" text-lg text-black f-f-r   ">Rename</h2>
                    </li>
                  </ul>
                </li>
              </Link> */}

              <li
                className=" profilebrdr2 mt-1  cursor-pointer "
                onClick={() => onDeleteBasisDesign(design)}
              >
                {" "}
                {/** setshowModal2(true) */}
                {/* inner ul started */}
                <ul className=" inline-flex ">
                  <li>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
                        fill="black"
                      />
                    </svg>
                  </li>
                  <li className=" ml-2 ">
                    <h2 className=" text-lg text-black f-f-r   ">Delete</h2>
                  </li>
                </ul>
                {/* inner ul ended */}
              </li>
            </ul>
          </div>
        </Menu.Items>
      </Menu>

      <Dilogboxbasic showModal={showModal} setShowModal={setShowModal} />
      <Deletedilogbox showModal2={showModal2} setshowModal2={setshowModal2} />
    </>
  );
}
