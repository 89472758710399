import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Dragicon from '../../../assets/images/icons8_upload-2.svg';



 export default function DragDrop(props) {
  const onDrop = useCallback(acceptedFiles => props.onReceiveFiles(acceptedFiles), []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop});

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
     
      <div className=' drag-drop p-4 my-8 text-center w-11/12 sm:w-[298px] mx-auto cursor-pointer ' >
        <img src={Dragicon} className='w-[62px] h-[62px] mx-auto '  />
                   
        { isDragActive ?
          <p className=' f-f-m text-black text-lg'>Drop the files here ...</p> 
        :
          <h2 className=' f-f-m text-black text-lg'>Drag and Drop Files Here</h2>
        }
        <h3 className=' f-f-r text-tiny text-black'>Or</h3>
        <button className=" mt-4 relative border bg-primary w-[136px] text-center h-[36px] rounded-[8px] f-f-r text-lg text-white  ">Browse Files</button>
      </div>
    </div>
  )
}