import React from "react";

import { useSelector } from "react-redux";

export default function TableAssumption(props) {
  const [isEditable, setIsEditable] = React.useState(false);

  const { design_parameters } = useSelector((state) => state.basisOfDesign);

  const [isUpdatingFromSite, setIsUpdatingFromSite] = React.useState(false);
  const [parameters, setParameters] = React.useState([
    {
      parameter: "Average Daily Usage (kwh/day)",
      value: "18.97",
      source: "CUSTOMER",
    },
    {
      section: "Temperature",
      parameter: "Minimum Temperature",
      value: "-4.0",
      source: "NREL NSRDB",
    },
    {
      section: "Temperature",
      parameter: "Maximum Temperature",
      value: "37.0",
      source: "NREL NSRDB",
    },
    {
      section: "Humidity",
      parameter: "Maximum Humidity",
      value: "62.5",
      source: "NREL NSRDB",
    },
    {
      section: "Humidity",
      parameter: "Minimum Humidity",
      value: "17.3",
      source: "NREL NSRDB",
    },
    {
      section: "Wind Loading",
      parameter: "Wind Speed (MPH)",
      value: "104.0",
      source: "ASCE Hazard Tool",
    },
    {
      section: "Wind Loading",
      parameter: "Exposure Category",
      value: "C",
      source: "ASCE Hazard Tool",
    },
    {
      section: "Wind Loading",
      parameter: "Enclosure Classification",
      value: "Open building",
      source: "ASCE Hazard Tool",
    },
    {
      section: "Snow Loading",
      parameter: "Ground Snow Load (PSF)",
      value: "21.0",
      source: "AHJ",
    },
    {
      section: "Seismic Loading",
      parameter: "Spectral Response [Short, s/s) (g)",
      value: "2.04",
      source: "ASCE Hazard Tool",
    },
    {
      section: "Seismic Loading",
      parameter: "Spectral Response [ Sec, s/1] (g)",
      value: "0.65",
      source: "ASCE Hazard Tool",
    },
    {
      section: "Seismic Loading",
      parameter: "Importance Factor",
      value: "1.5",
      source: "ASCE Hazard Tool",
    },
    {
      section: "Seismic Loading",
      parameter: "Site Class",
      value: "D",
      source: "ASCE Hazard Tool",
    },
    {
      section: "Seismic Loading",
      parameter: "Design Spectral Response [s/DS] (g)",
      value: "1.3",
      source: "ASCE Hazard Tool",
    },
    {
      section: "Seismic Loading",
      parameter: "Design Spectral Response [s/D1] (g)",
      value: "1.03",
      source: "ASCE Hazard Tool",
    },
    {
      section: "Seismic Loading",
      parameter: "Seismic Design Category",
      value: "D",
      source: "ASCE Hazard Tool",
    },
    {
      section: "Risk Category",
      parameter: "All components",
      value: "III",
      source: "PG&E",
    },
    {
      section: "Occupancy Classifications",
      parameter: "Solar Container",
      value: "S-1",
      source: "ASCE Hazard Tool",
    },
    {
      section: "Soil Condition",
      parameter: "Maximum Soil Bearing Pressure (PSI)",
      value: "1250.0",
      source: "Geotech Report",
    },
    {
      section: "Soil Condition",
      parameter: "Soil Type",
      value: "Sandy-Clay Loam",
      source: "Geotech Report",
    },
  ]);

  React.useEffect(() => {
    if (design_parameters?.length) {
      setParameters(design_parameters);
    }
  }, [design_parameters]);

  const _onChangeParametersInput = (e) => {
    let data = [...parameters];

    data[e.target.id][e.target.name] = e.target.value;

    setParameters(data);
  };
  const saveChanges = () => {
    props.onSaveDesignAssumptions(parameters);
    setIsEditable(!isEditable);
    props.onEditTableAssumption(!isEditable);
  };
  const _setIsEditable = () => {
    setIsEditable(!isEditable);
    props.onEditTableAssumption(!isEditable);
  };
  const _updateFromSite = () => {
    setIsUpdatingFromSite(true);
    setTimeout(async () => {
      await props.onExtractSite();

      setIsUpdatingFromSite(false);
    }, 2000);
  };
  return (
    <div>
      <div className="relative overflow-x-auto mt-8 ">
        <button
          onClick={
            isUpdatingFromSite
              ? () => console.log("Loading...")
              : _updateFromSite
          }
          className="absolute top-2 left-2 border bg-primary w-[65px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
        >
          {isUpdatingFromSite ? "Loading..." : "Update"}
        </button>
        <button
          onClick={isEditable ? saveChanges : _setIsEditable}
          className="absolute top-2 right-2 border bg-primary w-[45px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
        >
          {isEditable ? "Save" : "Edit"}
        </button>
        <table className=" table-site w-full  text-left  ">
          <caption className="px-3 py-2 f-f-m text-2xl text-center  bg-primary text-white ">
            DESIGN PARAMETERS
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg">
            <tr className=" f-f-b text-base text-black-dark">
              <th className="px-6 py-3 min-w-[200px]">Parameter</th>
              <th className="px-6 py-3 min-w-[200px]">Value</th>
              <th className="px-6 py-3  min-w-[200px]">Source</th>
            </tr>
          </thead>

          <tbody>
            {parameters.map((parameter, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-base text-black-dark "
                >
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeParametersInput}
                        className="input-equipment-table"
                        id={i}
                        name="parameter"
                        value={parameter.parameter}
                      />
                    ) : (
                      parameter.parameter
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeParametersInput}
                        className="input-equipment-table"
                        id={i}
                        name="value"
                        value={parameter.value}
                      />
                    ) : (
                      parameter.value
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeParametersInput}
                        className="input-equipment-table"
                        id={i}
                        name="source"
                        value={parameter.source}
                      />
                    ) : (
                      parameter.source
                    )}
                  </td>
                </tr>
              );
            })}

            {/* <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Average Daily Usage (kWh/day)
                            </td>
                            <td className="px-6 py-3   ">
                            18.97
                            </td>
                            <td className="px-6 py-3   ">
                            1.06
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-b text-base text-black-dark ">
                            <td colSpan={3}  className="px-6 py-3   ">
                            Temperature
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Minimum
                            </td>
                            <td className="px-6 py-3   ">
                            -4
                            </td>
                            <td className="px-6 py-3   ">
                            NREL NSRDB
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Maximum
                            </td>
                            <td className="px-6 py-3   ">
                            37
                            </td>
                            <td className="px-6 py-3   ">
                            NREL NSRDB
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-b text-base text-black-dark ">
                            <td colSpan={3}  className="px-6 py-3   ">
                            Humidity
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Maximum
                            </td>
                            <td className="px-6 py-3   ">
                            62.5
                            </td>
                            <td className="px-6 py-3   ">
                            NREL NSRDB
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Minimum
                            </td>
                            <td className="px-6 py-3   ">
                            17.3
                            </td>
                            <td className="px-6 py-3   ">
                            NREL NSRDB
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-b text-base text-black-dark ">
                            <td colSpan={3}  className="px-6 py-3   ">
                            Wind Loading
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Wind Speed (MPH)
                            </td>
                            <td className="px-6 py-3   ">
                            104
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Exposure Category
                            </td>
                            <td className="px-6 py-3   ">
                            C
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Enclosure Classification
                            </td>
                            <td className="px-6 py-3   ">
                            Open building
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-b text-base text-black-dark ">
                            <td colSpan={3}  className="px-6 py-3   ">
                            Snow Loading
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Ground Snow Load (PSF)
                            </td>
                            <td className="px-6 py-3   ">
                            21
                            </td>
                            <td className="px-6 py-3   ">
                            AHJ
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-b text-base text-black-dark ">
                            <td colSpan={3}  className="px-6 py-3   ">
                            Seismic Loading
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Spectral Response [Short, S/S] (g)
                            </td>
                            <td className="px-6 py-3   ">
                            2.04
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Spectral Response [1 Sec, S/1] (g)
                            </td>
                            <td className="px-6 py-3   ">
                            0.65
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Importance Factor
                            </td>
                            <td className="px-6 py-3   ">
                            1.5
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Site Class
                            </td>
                            <td className="px-6 py-3   ">
                            D
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Design Spectral Response [S/DS] (g)
                            </td>
                            <td className="px-6 py-3   ">
                            1.3
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Design Spectral Response [S/D1] (g)
                            </td>
                            <td className="px-6 py-3   ">
                            1.03
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Seismic Design Category
                            </td>
                            <td className="px-6 py-3   ">
                            D
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-b text-base text-black-dark ">
                            <td colSpan={3}  className="px-6 py-3   ">
                            Risk Category
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            All components
                            </td>
                            <td className="px-6 py-3   ">
                            3
                            </td>
                            <td className="px-6 py-3   ">
                            PG&E
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-b text-base text-black-dark ">
                            <td colSpan={3}  className="px-6 py-3   ">
                            Occupancy Classifications
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Solar Container
                            </td>
                            <td className="px-6 py-3   ">
                            S-1
                            </td>
                            <td className="px-6 py-3   ">
                            ASCE Hazard Tool
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-b text-base text-black-dark ">
                            <td colSpan={3}  className="px-6 py-3   ">
                            Soil Condition
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Maximum Soil Bearing Pressure (PSI)
                            </td>
                            <td className="px-6 py-3   ">
                            1250
                            </td>
                            <td className="px-6 py-3   ">
                            Geotech Report
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td  className="px-6 py-3   ">
                            Soil Type
                            </td>
                            <td className="px-6 py-3   ">
                            Sandy-Clay Loam
                            </td>
                            <td className="px-6 py-3   ">
                            Geotech Report
                            </td>
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
