import React from "react";

export default function SetTable(props) {
  const [isEditable, setIsEditable] = React.useState(false);

  const [assumptions, setAssumptions] = React.useState([
    {
      equipment: "General Equipment",
      setback: "5'",
      assumption:
        "Minimum of a 5' setback around all equipment for site safety",
    },
    {
      equipment: "Solar Container",
      setback: "5'",
      assumption:
        "Setback around Solar Container end doors for personnel access",
    },
    {
      equipment: "PV Array",
      setback: "5'",
      assumption:
        "Minimum setback around PV array for working clearances. Additionally there will be a 13' setback from existing buildings. 14' minimum clear height.",
    },
    {
      equipment: "PV Array Mounting",
      setback: "14'",
      assumption: "Minimum clear height from the ground",
    },
    {
      equipment: "PCC Switchgear",
      setback: "5'",
      assumption: "Setback in front of PCC/Switchgear for working clearances",
    },
    {
      equipment: "HVAC Unit",
      setback: "5'",
      assumption: "Setback in front of HVAC for working clearances",
    },
    {
      equipment: "Propane Generator",
      setback: "5'",
      assumption: "Setback from all other equipment",
    },
    {
      equipment: "Propane Tank",
      setback: "10'",
      assumption:
        "1,000 gallon propane tank requires a 10' setback from all other equipment per NFPA 58",
    },
    {
      equipment: "Perimeter Security Fence",
      setback: "Varies",
      assumption:
        "Fence must remain the minimum setback distance from any piece of equipment. Some equipment will have a larger setback area.",
    },
    {
      equipment: "30' Defensible Space",
      setback: "30'",
      assumption:
        "Minimum setback from the edges of all equipment for defensible space, actual perimeter was expanded to a more rectangular shape to facilitate easement process",
    },
    {
      equipment: "100' Defensible Space",
      setback: "100'",
      assumption:
        "Minimum setback from the edges of all equipment for defensible space, 70' setback from 30' defensible space perimeter",
    },
    {
      equipment: "Bollards",
      setback: "5'",
      assumption:
        "Bollards will be 42 inches apart, and have a 5' clear zone from any piece of equipment.",
    },
    {
      equipment: "First Responder Panel",
      setback: "3'",
      assumption:
        "Clean zone from the first responder panel for first responder safety and working clearances.",
    },
  ]);
  const _onChangeAssumptionsInput = (e) => {
    let data = [...assumptions];

    data[e.target.id][e.target.name] = e.target.value;

    setAssumptions(data);
  };
  const saveChanges = () => {
    props.onSaveSetbackAssumption(assumptions);
    setIsEditable(!isEditable);
    props.onEditSetTable(!isEditable);
  };
  const _setIsEditable = () => {
    setIsEditable(!isEditable);
    props.onEditSetTable(!isEditable);
  }
  return (
    <div>
      <div className="relative overflow-x-auto mt-3 ">
        <button
          onClick={ isEditable ? saveChanges : _setIsEditable }
          className="absolute top-2 right-2 border bg-primary w-[45px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
        >
          {isEditable ? "Save" : "Edit"}
        </button>
        <table className=" table-site w-full  text-left  ">
          <caption className="px-3 py-2 f-f-m text-2xl text-center  bg-primary text-white ">
            Setback Assumption
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b text-base text-black-dark ">
              <th className="px-6 py-3  min-w-[180px]">Equipment</th>
              <th className="px-6 py-3 min-w-[107px]">Setback</th>
              <th className="px-6 py-3  min-w-[685px]">Assumption </th>
            </tr>
          </thead>
          <tbody>
            {assumptions.map((assumption, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-base text-black-dark "
                >
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeAssumptionsInput}
                        className="input-equipment-table"
                        id={i}
                        name="equipment"
                        value={assumption.equipment}
                      />
                    ) : (
                      assumption.equipment
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeAssumptionsInput}
                        className="input-equipment-table"
                        id={i}
                        name="setback"
                        value={assumption.setback}
                      />
                    ) : (
                      assumption.setback
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeAssumptionsInput}
                        className="input-equipment-table"
                        id={i}
                        name="assumption"
                        value={assumption.assumption}
                      />
                    ) : (
                      assumption.assumption
                    )}
                  </td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">General Equipment </td>
                            <td className="px-6 py-3">5’</td>
                            <td className="px-6 py-3">Minimum of a 5’ setback around all equipment for site safety </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">Solar Container </td>
                            <td className="px-6 py-3">5’</td>
                            <td className="px-6 py-3">Setback around Solar Container end doors for personnel access</td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">PV Array</td>
                            <td className="px-6 py-3">5’</td>
                            <td className="px-6 py-3">Minimum setback around PV array for working clearances. Additionally there will be a 13’ setback from existing buildings. 14’ minimum clear height.</td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">PV Array Mounting</td>
                            <td className="px-6 py-3">14’</td>
                            <td className="px-6 py-3">Minimum clear height from the ground</td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">PCC Switchgear </td>
                            <td className="px-6 py-3"> 5’</td>
                            <td className="px-6 py-3">Setback in front of PCC/Switchgear for working clearances</td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">HVAC Unit</td>
                            <td className="px-6 py-3">5’</td>
                            <td className="px-6 py-3"> Setback in front of HVAC for working clearances</td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">Propane Generator</td>
                            <td className="px-6 py-3">5’</td>
                            <td className="px-6 py-3"> Setback from all other equipment</td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">Propane Tank </td>
                            <td className="px-6 py-3">10’</td>
                            <td className="px-6 py-3">1,000 gallon propane tank requires a 10’ setback from all other equipment per NFPA 58 </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">Perimeter Security Fence</td>
                            <td className="px-6 py-3">Varies</td>
                            <td className="px-6 py-3">Fence must remain the minimum setback distance from any piece of equipment. Some equipment will have a larger setback area. </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">30’ Defensible Space</td>
                            <td className="px-6 py-3">30’</td>
                            <td className="px-6 py-3"> Minimum setback from the edges of all equipment for defensible space, actual perimeter was expanded to a more rectangular shape to facilitate easement process </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">100’ Defensible Space </td>
                            <td className="px-6 py-3">100’</td>
                            <td className="px-6 py-3">Minimum setback from the edges of all equipment for defensible space, 70’ setback from 30’ defensible space perimeter</td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">Bollards</td>
                            <td className="px-6 py-3">5’</td>
                            <td className="px-6 py-3">Bollards will be 42 inches apart, and have a 5’ clear zone from any piece of equipment.</td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3">First Responder Panel</td>
                            <td className="px-6 py-3">3’</td>
                            <td className="px-6 py-3">Clean zone from the first responder panel for first responder safety and working clearances.</td>
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
