import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { store } from "./redux/Store";
import { Provider } from "react-redux";
// import 'react-phone-number-input/style.css';

import { Auth0Provider } from "@auth0/auth0-react";
import Config from "./utils/Config";
import Cookies from "js-cookie";

const onRedirectCallback = (appState, user) => {
  // const navigate = useNavigate();
  // history.push(
  // navigate(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
  Cookies.set("user-info", JSON.stringify(user), {
    // helps auto redirect other apps to the dashboard when user is logged in
    expires: 1,
    secure: true,
    domain: Config.auth0_cookieDomain,
  });
};

const providerConfig = {
  domain: Config.auth0_domain,
  clientId: Config.auth0_clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin + "/auth",
    ...(Config.auth0_audience ? { audience: Config.auth0_audience } : null),
  },
  cookieDomain: Config.auth0_cookieDomain,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider {...providerConfig}>
        <App />
      </Auth0Provider>
    </Provider>
  </React.StrictMode>
);

// git push -f origin main
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
