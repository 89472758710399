import React from 'react';
import FilloutTable from './FilloutTable';
import ElectricTable from './ElectricTable';

import { Link, useParams, useNavigate } from 'react-router-dom';
import Config from '../../../utils/Config';
import { useSelector, useDispatch } from 'react-redux';

import { setElectricalSizingLoadBasisSummaries, setElectricalSizingSiteAreas } from "../../../redux/slices/basisOfDesignSlice";

import swal from 'sweetalert';

const staticLoad_bases = [
  {
    parameter: "Average Daily Usage (kWh/day)",
    primary_loads: 18.97,
    parasitic_loads: 1.06,
    total: 20.03,
  },
  {
    parameter: "Peak Demand (kW)",
    primary_loads: 6.41,
    parasitic_loads: 0.09,
    total: 6.5,
  },
  {
    parameter: "Average (kW)",
    primary_loads: 0.79,
    parasitic_loads: 0.04,
    total: 0.83,
  },
];

const staticAreas = [
  {
    parameter: "Solar PV Capacity (STC-kW)",
    value: 13.2,
  },
  {
    parameter: "Solar PV Tilt (degree)",
    value: 30.0,
  },
  {
    parameter: "Solar PV Azimuth",
    value: 180.0,
  },
  {
    parameter: "Nominal Battery Capacity(kWh)",
    value: 61.44,
  },
  {
    parameter: "Battery Inverter(kW-AC)",
    value: 27.2,
  },
  {
    parameter: "Generator(kVA)",
    value: 22.3,
  },
  {
    parameter: "Generator Runtime (hours/year)",
    value: 526.0,
  },
  {
    parameter: "Fuel Consumption (gal/year)",
    value: 850.0,
  },
  {
    parameter: "Propane Tank Capacity (gallons)",
    value: 1000.0,
  },
  {
    parameter: "Soiling Loss (%)",
    value: 3.0,
  },
  {
    parameter: "PV Derating Factor (%)",
    value: 94.2,
  },
  {
    parameter: "Thermal Loss Factor (w/m2k)",
    value: 29.0,
  },
  {
    parameter: "Battery Minimum State of Charge (%)",
    value: 38.3,
  },
  {
    parameter: "Renewable Fraction (%)",
    value: 71.2,
  },

  
];

export default function Index() {
  const user     = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { site_id, design_id } = useParams();
  
  const { electrical_sizing_load_basis_summaries, electrical_sizing_site_areas } = useSelector((state) => state.basisOfDesign);

  const [ isElectricTableEditable, setIsElectricTableEditable ] = React.useState(false);
  const [ isFilloutTableEditable, setIsFilloutTableEditable ] = React.useState(false);

  const _onSaveElectricalSizingMethodology = (load_bases) => {
    const payload = { design_id, load_bases: load_bases };

    // console.log("payload:", payload);

    fetch(`${Config.API.URL}/design-basis/electrical-sizing/load-basis-summary`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` },
      body: JSON.stringify(payload),
    }).then((res) => {
      // if 401 error (unauthenticated user, then log user out)
      if ((res.status) === 401) {
        console.log("401 Error: Unauthenticated");

        localStorage.removeItem('user');
        navigate('/');
      }
      return res;
    }).then((res) => res.json()).then( async (res) => {
      // console.log('res:', res);

      // if request is succesful, alert user that project has been cloned
      if (res.status) {
       
        dispatch(setElectricalSizingLoadBasisSummaries(res.data));
      } else {
        swal("Oh no!", res.message, "error");
      }
    }).catch((error) => {
      console.log('Error: ', error);
      
      swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
    });
  }

  const _onSaveSiteAreas = (areas) => {
    const payload = { design_id, areas: areas };

    // console.log("payload:", payload);

    fetch(`${Config.API.URL}/design-basis/electrical-sizing/site-areas`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` },
      body: JSON.stringify(payload),
    }).then((res) => {
      // if 401 error (unauthenticated user, then log user out)
      if ((res.status) === 401) {
        console.log("401 Error: Unauthenticated");

        localStorage.removeItem('user');
        navigate('/');
      }
      return res;
    }).then((res) => res.json()).then( async (res) => {
      // console.log('res:', res);

      // if request is succesful, alert user that project has been cloned
      if (res.status) {
        
        dispatch(setElectricalSizingSiteAreas(res.data));
      } else {
        swal("Oh no!", res.message, "error");
      }
    }).catch((error) => {
      console.log('Error: ', error);
      
      swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
    });
  }

  const nextPage = () => {
    if (isElectricTableEditable || isFilloutTableEditable) {
      swal({
        title: "Editable mode detected?",
        text: "When go to the next page, unsave changes will be lost. do you still want go to next page?",
        icon: "warning",
        buttons: ["No", "Next"],
        dangerMode: true,
      }).then((willProcced) => {
        if (willProcced) {
          if (!electrical_sizing_load_basis_summaries.length) {
            _onSaveElectricalSizingMethodology(staticLoad_bases);
          }
          if (!electrical_sizing_site_areas.length) {
            _onSaveSiteAreas(staticAreas);
          }
          navigate(`/basis/design/assupmtion/${site_id}/${design_id}`);
        } 
      });
    } else {
      if (!electrical_sizing_load_basis_summaries.length) {
        _onSaveElectricalSizingMethodology(staticLoad_bases);
      }
      if (!electrical_sizing_site_areas.length) {
        _onSaveSiteAreas(staticAreas);
      }
      navigate(`/basis/design/assupmtion/${site_id}/${design_id}`);
    }
  }

  const _onExtractScenario = () => {
    fetch(`${Config.API.URL}/sites/${site_id}`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` }
    }).then((res) => {
      // if 401 error (unauthenticated user, then log user out)
      if ((res.status) === 401) {
        console.log("401 Error: Unauthenticated");

        localStorage.removeItem('user');
        navigate('/');
      }
      return res;
    }).then((res) => res.json()).then( async (res) => {

      // if request is succesful, alert user that project has been cloned
      if (res.status) {
        const data = res.data;
        if (data.active_scenario_id) {
          const scenario_id = data.active_scenario_id;

          const reqScenario = await fetch(`${Config.API.URL}/scenarios/${scenario_id}`, { headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` }});
          const resScenario = await reqScenario.json();

          let scenario_data = resScenario.data;

          console.log('scenario_data:', scenario_data);

          let areas_data = [ ...staticAreas ];

          areas_data[0].value = scenario_data.solar_pv_capacity;
          areas_data[1].value = scenario_data.pv_tilt;
          areas_data[2].value = scenario_data.pv_azimuth;
          areas_data[3].value = scenario_data.battery_storage_capacity;
          areas_data[4].value = scenario_data.battery_inverter_capacity;
          areas_data[5].value = scenario_data.generator_size;
          areas_data[6].value = scenario_data.generator_runtime;
          areas_data[7].value = scenario_data.fuel_consumption;
          areas_data[8].value = scenario_data.fuel_tank_size;
          areas_data[13].value = scenario_data.model_renewable_fraction;

          console.log('areas_data', areas_data);
          
          let payload = { design_id, areas: areas_data };
          fetch(`${Config.API.URL}/design-basis/electrical-sizing/site-areas`, {
            method: 'POST',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` },
            body: JSON.stringify(payload),
          }).then((res) => {
            // if 401 error (unauthenticated user, then log user out)
            if ((res.status) === 401) {
              console.log("401 Error: Unauthenticated");
      
              localStorage.removeItem('user');
              navigate('/');
            }
            return res;
          }).then((res) => res.json()).then( async (res) => {
            console.log('areas_data-res:', res);
      
            // if request is succesful, alert user that project has been cloned
            if (res.status) {
              
              dispatch(setElectricalSizingSiteAreas(res.data));
            } else {
              swal("Oh no!", res.message, "error");
            }
          }).catch((error) => {
            console.log('Error: ', error);
            
            swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
          });
        } else {
          swal("Oh no!", "This site doesn't have scenario", "error");
        }
          
      } else {
        swal("Oh no!", res.message, "error");
      }
    }).catch((error) => {
      console.log('Error: ', error);
    });
  }

  return (
    <div className='' >
        <h2 className=' f-f-r text-lg text-black ' >PG&E Basis of Design</h2>
        <h4 className=' f-f-sm text-black text-2xl  sm:text-3xl ' >Electrical Sizing Methodology</h4>
        <h2 className=' f-f-m text-black text-lg mt-4 ' >Fill out the load basis summary Table below</h2>

   {/*  table started */}
        <ElectricTable onEditElectricTable={setIsElectricTableEditable} onSaveElectricalSizingMethodology={_onSaveElectricalSizingMethodology} />

   {/* table ended */}
   <h2 className=' f-f-m text-black text-lg mt-20 ' >Fill out the System sizing summary Table below</h2>
        <FilloutTable onEditFilloutTable={setIsFilloutTableEditable} onSaveSiteAreas={_onSaveSiteAreas} onExtractScenario={_onExtractScenario}  />
{/* adjuest in bottom  */}
<div className='  ' >

        

<div className=' border border-gray-300 mt-20  ' ></div>
<div className="grid grid-cols-12  gap-4  ">
<div className="   col-span-3">
    <Link to={`/basis/shading/analysis/${site_id}/${design_id}`} >
<button className="  mt-8 relative border border-primary w-[110px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-primary  ">
       {/* inner ul started */}
        <ul className=' inline-flex text-center justify-center mt-1 mr-3 ' >
            <li>
                        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.25 7.5L11.75 13L17.25 18.5" stroke="#ED583B" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
            </li>
            <li className='ml-1' >
    Back
    </li>
        </ul>
        {/* inner ul ended */}
       </button>
       </Link>
</div>
<div className="   col-span-9">
<div className=' text-end ' >
<ul className=" inline-flex items-center  ">

<li >
<Link to={`/basis/design/assupmtion/${site_id}/${design_id}`}>
<button className=" mt-8  text-center  f-f-r text-lg text-black-darkest  ">
Skip</button>
  </Link>
  </li>
<li className=" ml-6 ">
<button  onClick={nextPage} className=" mt-8 relative border bg-primary w-[127px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-white  ">Next</button></li>

</ul>
</div>
</div>
</div>

</div>
{/* adjuest in bottom ended  */}

    </div>
  )
}
