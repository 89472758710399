import React from "react";

export default function CivilTable(props) {
  const [isEditable, setIsEditable] = React.useState(false);

  const [vehicles, setVehicles] = React.useState([
    {
      vehicle: "F-250 TRUCK",
      quantity: 2,
      dimensions: "23 feet X6 feet 4 inches",
      weight: "6100 lbs",
    },
    {
      vehicle: "SKID STEER",
      quantity: 1,
      dimensions: "8 feet 2 inches X7 feet 8 inches",
      weight: "8615 lbs",
    },
    {
      vehicle: "MINI EXCAVATOR",
      quantity: 1,
      dimensions: "17 feet 3 inches X5 feet 9 inches",
      weight: "7500 lbs",
    },
    {
      vehicle: "CONCRETE TRUCK",
      quantity: 1,
      dimensions: "24 feet X8 feet 6 inches",
      weight: "67000 lbs",
    },
    {
      vehicle: "DUMP TRUCK",
      quantity: 1,
      dimensions: "23 feet 8 inches X9 feet 6 inches",
      weight: "56000 lbs",
    },
    {
      vehicle: "CONTAINER DELIVERY TRUCK",
      quantity: 1,
      dimensions: "12 feet 8 inches X8 inches",
      weight: "10040 lbs",
    },
    {
      vehicle: "CONTAINER DELIVERY TRAILER",
      quantity: 1,
      dimensions: "31 feet X8 feet 6 inches",
      weight: "7730 lbs",
    },
    {
      vehicle: "BOOM TRUCK",
      quantity: 1,
      dimensions: "37 feet X8 feet",
      weight: "33000 lbs",
    },
    {
      vehicle: "CRANE",
      quantity: 1,
      dimensions: "45 feet X8 feet 6 inches",
      weight: "93000 lbs",
    },
    {
      vehicle: "SKIP LOADER",
      quantity: 1,
      dimensions: "23 feet x7 feet 5 inches",
      weight: "10500 lbs",
    },
    {
      vehicle: "WATER TRAILER",
      quantity: 1,
      dimensions: "13 feet 5 inches 6 feet 7 inches",
      weight: "2000 lbs",
    },
  ]);
  const _onChangeVehiclesInput = (e) => {
    let data = [...vehicles];

    data[e.target.id][e.target.name] = e.target.value;

    setVehicles(data);
  };
  const saveChanges = () => {
    props.onSaveCivilDesign(vehicles);
    setIsEditable(!isEditable);
    props.onEditCivilTable(!isEditable);
  };
  const _setIsEditable = () => {
    setIsEditable(!isEditable);
    props.onEditCivilTable(!isEditable);
  };
  return (
    <div>
      <div className="relative overflow-x-auto mt-8 ">
        <button
          onClick={isEditable ? saveChanges : _setIsEditable }
          className="absolute top-2 right-2 border bg-primary w-[45px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
        >
          {isEditable ? "Save" : "Edit"}
        </button>
        <table className=" table-site w-full  text-left  ">
          <caption className="px-3 py-2 f-f-m text-2xl text-center  bg-primary text-white ">
            ONSITE VEHICLES
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b text-base text-black-dark ">
              <th className="px-6 py-3 min-w-[200px]  sm:min-w-auto  ">
                VEHICLE
              </th>
              <th className="px-6 py-3 min-w-[150px]  sm:min-w-auto  ">
                QUANTITY
              </th>
              <th className="px-6 py-3  min-w-[150px]  sm:min-w-auto ">
                DIMENSIONS (LXW)
              </th>
              <th className="px-6 py-3 min-w-[150px]  sm:min-w-auto  ">
                WEIGHT (LBS)
              </th>
            </tr>
          </thead>
          <tbody>
            {vehicles.map((vehicle, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-base text-black-dark "
                >
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeVehiclesInput}
                        className="input-equipment-table"
                        id={i}
                        name="vehicle"
                        value={vehicle.vehicle}
                      />
                    ) : (
                      vehicle.vehicle
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="number"
                        onChange={_onChangeVehiclesInput}
                        className="input-equipment-table"
                        id={i}
                        name="quantity"
                        value={vehicle.quantity}
                      />
                    ) : (
                      vehicle.quantity
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeVehiclesInput}
                        className="input-equipment-table"
                        id={i}
                        name="dimensions"
                        value={vehicle.dimensions}
                      />
                    ) : (
                      vehicle.dimensions
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeVehiclesInput}
                        className="input-equipment-table"
                        id={i}
                        name="weight"
                        value={vehicle.weight}
                      />
                    ) : (
                      vehicle.weight
                    )}
                  </td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            F-250 TRUCK
                            </td>
                            <td className="px-6 py-3   ">
                            23'X6'4"
                            </td>
                            <td className="px-6 py-3   ">
                            6100
                            </td>
                            <td className="px-6 py-3   ">
                            2
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            SKID STEER
                            </td>
                            <td className="px-6 py-3   ">
                            8'2"X7'8"
                            </td>
                            <td className="px-6 py-3   ">
                            8615
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                    
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            MINI EXCAVATOR
                            </td>
                            <td className="px-6 py-3   ">
                            17'3"X5'9"
                            </td>
                            <td className="px-6 py-3   ">
                            7500
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            CONCRETE TRUCK
                            </td>
                            <td className="px-6 py-3   ">
                            24'X8'6"
                            </td>
                            <td className="px-6 py-3   ">
                            67000
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            DUMP TRUCK
                            </td>
                            <td className="px-6 py-3   ">
                            23'8"X9'6’’
                            </td>
                            <td className="px-6 py-3   ">
                            56000
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            CONTAINER DELIVERY TRUCK
                            </td>
                            <td className="px-6 py-3   ">
                            12’8’’X8’
                            </td>
                            <td className="px-6 py-3   ">
                            10040
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            CONTAINER DELIVERY TRAILER
                            </td>
                            <td className="px-6 py-3   ">
                            31’X8’6’’
                            </td>
                            <td className="px-6 py-3   ">
                            7730
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            BOOM TRUCK
                            </td>
                            <td className="px-6 py-3   ">
                            37’X8’
                            </td>
                            <td className="px-6 py-3   ">
                            33000
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            CRANE
                            </td>
                            <td className="px-6 py-3   ">
                            45’X8’6”
                            </td>
                            <td className="px-6 py-3   ">
                            93,000
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            SKIP LOADER
                            </td>
                            <td className="px-6 py-3   ">
                            23’x7’5”
                            </td>
                            <td className="px-6 py-3   ">
                            10500
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            WATER TRAILER
                            </td>
                            <td className="px-6 py-3   ">
                            13’5” x 6’7”
                            </td>
                            <td className="px-6 py-3   ">
                            2000
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
