import React from "react";
// import Esgridman from '../../assets/images/esgridman.svg'
import { useSelector } from "react-redux";
// import { useNavigate } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { setUserMiniProfile } from "../../redux/slices/userSlice";

import { useAuth0 } from "@auth0/auth0-react";

export default function ButtonAppBar() {
  const { logout } = useAuth0();
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.profile);

  const logoutUser = () => {
    localStorage.removeItem("user");
    dispatch(setUserMiniProfile({}));
    logout({ logoutParams: { returnTo: window.location.origin } });
    // navigate('/');
  };

  // React.useEffect(() => {
  //   console.log("user:", user);
  // }, [])
  return (
    <div className="bg-white rounded-b-[8px] p-3 fixed lg:relative w-[85%] md:w-[93%] lg:w-full top-0 z-[999] lg:z-0 shadow-lg ">
      <ul className="inline-flex w-full justify-end">
        <li>
          <h2 className="f-f-sm sm:text-xl text-tiny text-black mt-3 sm:mt-2 ">
            {`${user?.first_name} ${user?.last_name}`}
          </h2>
        </li>
        <li onClick={logoutUser} className=" ml-4 cursor-pointer">
          <img
            alt=""
            style={{ width: 45, height: 45 }}
            src={`https://ui-avatars.com/api/?name=${user?.first_name}+${user?.last_name}&background=random&rounded=true`}
          />
        </li>
        {/* <li className=' sm:ml-4 mr-6 lg:mr-0' >
          <svg className='w-[44px] h-[46px] ' viewBox="0 0 44 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 34.3056C22.42 34.2955 22.823 34.131 23.1377 33.8413C23.4525 33.5515 23.6588 33.1551 23.72 32.7222H20.2133C20.2763 33.1669 20.4922 33.5726 20.8209 33.8638C21.1495 34.1551 21.5685 34.3121 22 34.3056Z" fill="black" />
            <path d="M31.6733 29.8264C31.0305 29.2294 30.4676 28.545 30 27.7917C29.4895 26.7518 29.1835 25.6161 29.1 24.4514V21.0208C29.0973 20.6041 29.0616 20.1884 28.9933 19.7778C28.4686 19.6685 27.9643 19.4715 27.5 19.1944C27.677 19.7881 27.7669 20.4062 27.7667 21.0278V24.4583C27.8484 25.8792 28.2236 27.2649 28.8667 28.5208C29.3269 29.2806 29.8731 29.98 30.4933 30.6042H13.42C14.0403 29.98 14.5864 29.2806 15.0467 28.5208C15.6897 27.2649 16.065 25.8792 16.1467 24.4583V21.0208C16.1432 20.2171 16.2919 19.4206 16.5843 18.6769C16.8767 17.9331 17.3071 17.2568 17.8508 16.6866C18.3945 16.1163 19.0409 15.6634 19.7529 15.3538C20.4648 15.0441 21.2285 14.8838 22 14.8819C23.1292 14.8829 24.233 15.2307 25.1733 15.8819C25.0697 15.4854 25.0115 15.0775 25 14.6667V14.2292C24.3039 13.8725 23.5561 13.6378 22.7867 13.5347V12.6597C22.7867 12.4138 22.6929 12.178 22.526 12.0042C22.3591 11.8303 22.1327 11.7326 21.8967 11.7326C21.6606 11.7326 21.4343 11.8303 21.2673 12.0042C21.1004 12.178 21.0067 12.4138 21.0067 12.6597V13.5694C19.2838 13.8226 17.7071 14.7165 16.5675 16.0861C15.4279 17.4556 14.8025 19.2084 14.8067 21.0208V24.4514C14.7232 25.6161 14.4172 26.7518 13.9067 27.7917C13.4473 28.5433 12.8935 29.2276 12.26 29.8264C12.1889 29.8915 12.1319 29.9716 12.0928 30.0614C12.0537 30.1512 12.0335 30.2486 12.0333 30.3472V31.2917C12.0333 31.4758 12.1036 31.6525 12.2286 31.7827C12.3536 31.9129 12.5232 31.9861 12.7 31.9861H31.2333C31.4102 31.9861 31.5797 31.9129 31.7047 31.7827C31.8298 31.6525 31.9 31.4758 31.9 31.2917V30.3472C31.8999 30.2486 31.8796 30.1512 31.8405 30.0614C31.8014 29.9716 31.7445 29.8915 31.6733 29.8264Z" fill="black" />
            <path d="M30 18.1389C31.841 18.1389 33.3333 16.5843 33.3333 14.6667C33.3333 12.749 31.841 11.1944 30 11.1944C28.1591 11.1944 26.6667 12.749 26.6667 14.6667C26.6667 16.5843 28.1591 18.1389 30 18.1389Z" fill="#ED583B" />
          </svg>
        </li> */}
      </ul>
    </div>
  );
}
