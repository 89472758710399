import React from "react";
import TableAssumption from "./TableAssumption";

import { Link, useParams, useNavigate } from "react-router-dom";
import Config from "../../../utils/Config";
import { useSelector, useDispatch } from "react-redux";

import { setDesignParameters } from "../../../redux/slices/basisOfDesignSlice";

import swal from "sweetalert";

const staticParameters = [
  {
    parameter: "Average Daily Usage (kwh/day)",
    value: "18.97",
    source: "CUSTOMER",
  },
  {
    section: "Temperature",
    parameter: "Minimum Temperature",
    value: "-4.0",
    source: "NREL NSRDB",
  },
  {
    section: "Temperature",
    parameter: "Maximum Temperature",
    value: "37.0",
    source: "NREL NSRDB",
  },
  {
    section: "Humidity",
    parameter: "Maximum Humidity",
    value: "62.5",
    source: "NREL NSRDB",
  },
  {
    section: "Humidity",
    parameter: "Minimum Humidity",
    value: "17.3",
    source: "NREL NSRDB",
  },
  {
    section: "Wind Loading",
    parameter: "Wind Speed (MPH)",
    value: "104.0",
    source: "ASCE Hazard Tool",
  },
  {
    section: "Wind Loading",
    parameter: "Exposure Category",
    value: "C",
    source: "ASCE Hazard Tool",
  },
  {
    section: "Wind Loading",
    parameter: "Enclosure Classification",
    value: "Open building",
    source: "ASCE Hazard Tool",
  },
  {
    section: "Snow Loading",
    parameter: "Ground Snow Load (PSF)",
    value: "21.0",
    source: "AHJ",
  },
  {
    section: "Seismic Loading",
    parameter: "Spectral Response [Short, s/s) (g)",
    value: "2.04",
    source: "ASCE Hazard Tool",
  },
  {
    section: "Seismic Loading",
    parameter: "Spectral Response [ Sec, s/1] (g)",
    value: "0.65",
    source: "ASCE Hazard Tool",
  },
  {
    section: "Seismic Loading",
    parameter: "Importance Factor",
    value: "1.5",
    source: "ASCE Hazard Tool",
  },
  {
    section: "Seismic Loading",
    parameter: "Site Class",
    value: "D",
    source: "ASCE Hazard Tool",
  },
  {
    section: "Seismic Loading",
    parameter: "Design Spectral Response [s/DS] (g)",
    value: "1.3",
    source: "ASCE Hazard Tool",
  },
  {
    section: "Seismic Loading",
    parameter: "Design Spectral Response [s/D1] (g)",
    value: "1.03",
    source: "ASCE Hazard Tool",
  },
  {
    section: "Seismic Loading",
    parameter: "Seismic Design Category",
    value: "D",
    source: "ASCE Hazard Tool",
  },
  {
    section: "Risk Category",
    parameter: "All components",
    value: "III",
    source: "PG&E",
  },
  {
    section: "Occupancy Classifications",
    parameter: "Solar Container",
    value: "S-1",
    source: "ASCE Hazard Tool",
  },
  {
    section: "Soil Condition",
    parameter: "Maximum Soil Bearing Pressure (PSI)",
    value: "1250.0",
    source: "Geotech Report",
  },
  {
    section: "Soil Condition",
    parameter: "Soil Type",
    value: "Sandy-Clay Loam",
    source: "Geotech Report",
  },
];

export default function Index() {
  const user = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { site_id, design_id } = useParams();

  const { design_parameters } = useSelector((state) => state.basisOfDesign);
  const [isTableAssumptionEditable, setIsTableAssumptionEditable] =
    React.useState(false);

  const _onSaveDesignAssumptions = (parameters) => {
    const payload = { design_id, parameters: parameters };

    fetch(`${Config.API.URL}/design-basis/design-parameters`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          localStorage.removeItem("user");
          navigate("/");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        // console.log('res:', res);

        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          dispatch(setDesignParameters(res.data));
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        console.log("Error: ", error);

        swal(
          "Oh no!",
          "An error occured!!! while connecting to server. Please try again",
          "error"
        );
      });
  };

  const nextPage = () => {
    if (isTableAssumptionEditable) {
      swal({
        title: "Editable mode detected?",
        text: "When go to the next page, unsave changes will be lost. do you still want go to next page?",
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willProcced) => {
        if (willProcced) {
          if (!design_parameters.length) {
            _onSaveDesignAssumptions(staticParameters);
          }
          navigate(`/basis/civil/design/${site_id}/${design_id}`);
        }
      });
    } else {
      if (!design_parameters.length) {
        _onSaveDesignAssumptions(staticParameters);
      }
      navigate(`/basis/civil/design/${site_id}/${design_id}`);
    }
  };

  const _onExtractSite = () => {
    fetch(`${Config.API.URL}/sites/${site_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then((res) => {
        // if 401 error (unauthenticated user, then log user out)
        if (res.status === 401) {
          console.log("401 Error: Unauthenticated");

          localStorage.removeItem("user");
          navigate("/");
        }
        return res;
      })
      .then((res) => res.json())
      .then(async (res) => {
        // console.log('site-res:', res);
        // if request is succesful, alert user that project has been cloned
        if (res.status) {
          const data = res.data;

          let parameters_data = [...staticParameters];

          parameters_data[0].value = data.average_daily_usage
            ? data.average_daily_usage?.toString()
            : parameters_data[0].value;
          parameters_data[1].value = data.location_minimum_temperature
            ? data.location_minimum_temperature?.toString()
            : parameters_data[1].value;
          parameters_data[2].value = data.location_maximum_temperature
            ? data.location_maximum_temperature?.toString()
            : parameters_data[2].value;
          parameters_data[3].value = data.location_maximum_humidity
            ? data.location_maximum_humidity?.toString()
            : parameters_data[3].value;
          parameters_data[4].value = data.location_minimum_humidity
            ? data.location_minimum_humidity?.toString()
            : parameters_data[4].value;
          parameters_data[5].value = data.wind_speed
            ? data.wind_speed?.toString()
            : parameters_data[5].value;
          parameters_data[6].value = data.wind_exposure_category
            ? data.wind_exposure_category?.toString()
            : parameters_data[6].value;
          // parameters_data[7].value = data.average_daily_usage;
          parameters_data[8].value = data.snow_load
            ? data.snow_load?.toString()
            : parameters_data[8].value;
          parameters_data[9].value = data.seismic_spectral_response_short
            ? data.seismic_spectral_response_short?.toString()
            : parameters_data[9].value;
          parameters_data[10].value = data.seismic_spectral_response_second
            ? data.seismic_spectral_response_second?.toString()
            : parameters_data[10].value;
          parameters_data[11].value = data.seismic_importance_factor
            ? data.seismic_importance_factor?.toString()
            : parameters_data[11].value;
          parameters_data[12].value = data.seismic_site_class
            ? data.seismic_site_class?.toString()
            : parameters_data[12].value;
          parameters_data[13].value = data.design_spectral_response_short
            ? data.design_spectral_response_short?.toString()
            : parameters_data[13].value;
          parameters_data[14].value = data.design_spectral_response_second
            ? data.design_spectral_response_second?.toString()
            : parameters_data[14].value;
          parameters_data[15].value = data.seismic_design_category
            ? data.seismic_design_category?.toString()
            : parameters_data[15].value;
          // parameters_data[16].value = data.average_daily_usage;
          // parameters_data[17].value = data.average_daily_usage;
          // parameters_data[18].value = data.average_daily_usage;
          parameters_data[19].value = data.soil_type
            ? data.soil_type?.toString()
            : parameters_data[19].value;

          // console.log('parameters_data', parameters_data);

          const payload = { design_id, parameters: parameters_data };

          fetch(`${Config.API.URL}/design-basis/design-parameters`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
            body: JSON.stringify(payload),
          })
            .then((res) => {
              // if 401 error (unauthenticated user, then log user out)
              if (res.status === 401) {
                console.log("401 Error: Unauthenticated");

                localStorage.removeItem("user");
                navigate("/");
              }
              return res;
            })
            .then((res) => res.json())
            .then(async (res) => {
              // console.log('design-parameters-res:', res);

              // if request is succesful, alert user that project has been cloned
              if (res.status) {
                dispatch(setDesignParameters(res.data));
              } else {
                swal("Oh no!", res.message, "error");
              }
            })
            .catch((error) => {
              console.log("Error: ", error);

              swal(
                "Oh no!",
                "An error occured!!! while connecting to server. Please try again",
                "error"
              );
            });
        } else {
          swal("Oh no!", res.message, "error");
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  return (
    <div className="">
      <h2 className=" f-f-r text-lg text-black ">PG&E Basis of Design</h2>
      <h4 className=" f-f-sm text-black text-2xl  sm:text-3xl ">
        Design Assumptions & Parameters
      </h4>
      <h2 className=" f-f-m text-black text-lg mt-4 ">
        Fill out the design parameters Table below
      </h2>

      {/*  table started */}

      <TableAssumption
        onEditTableAssumption={setIsTableAssumptionEditable}
        onSaveDesignAssumptions={_onSaveDesignAssumptions}
        onExtractSite={_onExtractSite}
      />

      <div className=" border border-gray-300 mt-20  "></div>
      <div className="grid grid-cols-12  gap-4  ">
        <div className="   col-span-3">
          <Link to={`/basis/electric/sizing/${site_id}/${design_id}`}>
            <button className="  mt-8 relative border border-primary w-[110px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-primary  ">
              {/* inner ul started */}
              <ul className=" inline-flex text-center justify-center mt-1 mr-3 ">
                <li>
                  <svg
                    width="26"
                    height="25"
                    viewBox="0 0 26 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.25 7.5L11.75 13L17.25 18.5"
                      stroke="#ED583B"
                      strokeWidth="1.16667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </li>
                <li className="ml-1">Back</li>
              </ul>
              {/* inner ul ended */}
            </button>
          </Link>
        </div>
        <div className="   col-span-9">
          <div className=" text-end ">
            <ul className=" inline-flex items-center  ">
              <Link to={`/basis/civil/design/${site_id}/${design_id}`}>
                <li>
                  <button className=" mt-8  text-center  f-f-r text-lg text-black-darkest  ">
                    Skip
                  </button>
                </li>
              </Link>
              <li className=" ml-6 ">
                <button
                  onClick={nextPage}
                  className=" mt-8 relative border bg-primary w-[127px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-white  "
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* adjuest in bottom ended  */}
    </div>
  );
}
