import React from "react";

import { Link } from "react-router-dom";

import Dropdown from "../Contacts/Dropdown";
//import EditColumnsDropdown from "../Contacts/EditColumnsDropdown";
import moment from "moment";

export default function DataTable(props) { 
  const {
    table_label,
    total_items,
    columns_format,
    header_format,
    data_format,
    data,
    setPage,
    lastPage,
    currentPage,
    onCreateItem,
    onViewItem,
    onDeleteItem,
    onEditItem,
    onSearchData,
    utilities
  } = props;

  const [ columns, setColumns ] = React.useState(null)

  React.useEffect(() => {
    // console.log('utilities', utilities);
    setColumns(columns_format);
  }, []);

  const getUtilityNameById = (utility_id) => {
    if (utilities?.length) {
      const utility = utilities.find(x => x.id === utility_id);

      return utility?.utility_name;
    } else {
      return '-';
    }
  }

  const increasePage = () => {
    if (currentPage >= lastPage) {
      setPage(lastPage);
    } else {
      setPage(currentPage + 1);
    }
  };

  const decreasePage = () => {
    if (currentPage <= 1) {
      setPage(1);
    } else {
      setPage(currentPage - 1);
    }
  };
  const _onDeleteItem = onDeleteItem ? (item) => onDeleteItem(item) : null;
  const _onEditItem   = onEditItem   ? (item) => onEditItem(item) : null;
  const _onViewItem   = onViewItem   ? (item) => onViewItem(item) : null;

  const _onSearchData = (e) => onSearchData(e.target.value);
  
  // const _onEditColumns = (e) => {
  //   const data = { ...columns };
  //   data[e.key] = e.value;
  //   setColumns(data);
  // };
  return (
    <>
      {/* <div className=" mt-10 bg-white rounded-[8px] p-4"> */}
        {/* Old Label here */}
        {/* new div started */}
        {/* <div className=" mt-8"> */}
          <div className="grid grid-cols-12 mt-5 gap-4  ">
            <div className="col-span-12 md:col-span-6">
              {
                <ul className=" w-full md:inline-flex">
                  <li>
                    <h3 className=" text-primary text-2xl f-f-r ">{ table_label ? table_label : "" } { total_items ? `(${total_items})` : '' }</h3>
                    
                  </li>
                  <li className=" ml-2 mt-1">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.99988 16.5001C13.142 16.5001 16.4999 13.1422 16.4999 9.00006C16.4999 4.85793 13.142 1.50006 8.99988 1.50006C4.85774 1.50006 1.49988 4.85793 1.49988 9.00006C1.49988 13.1422 4.85774 16.5001 8.99988 16.5001Z"
                        stroke="#D0D1D2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9 6.00006V9.00006"
                        stroke="#D0D1D2"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <circle cx="8.99988" cy="12" r="0.75" fill="#D0D1D2" />
                    </svg>
                  </li>
                  {/* <li className=" mt-3 md:mt-0 md:ml-3">
                    <form action="/action_page.php">
                      <select
                        name="cars"
                        id="cars"
                        className="bg-gray-50 border outline-none relative border-gray-300 text-black-light text-lg rounded-[4px] block w-full  md:w-[152px]  p-2.5  "
                      >
                        <option value="volvo">First Name</option>
                        <option value="saab">Last Name</option>
                        <option value="opel">Email</option>
                        <option value="audi">Company</option>
                      </select>
                    </form>
                  </li> */}
                </ul>
              }
            </div>

            <div className="col-span-12 md:col-span-6">
              <div className=" text-center md:text-end ">
                <ul className=" inline-flex ">
                  {/* list started */}
                  <li className="relative">
                    <form className="flex items-center">
                      <label htmlFor="simple-search" className="sr-only"> Search </label>
                      <div className="relative w-full">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg
                            aria-hidden="true"
                            className="w-5 h-5 text-gray-500 "
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </div>
                        <input
                          type="text"
                          id="simple-search"
                          className="bg-gray-50 border w-full outline-none border-gray-300 text-black-light text-lg rounded-[4px] block md:w-[231px] pl-10 p-2.5  "
                          placeholder="Search By Anything"
                          onChange={_onSearchData}
                          required
                        />
                      </div>
                    </form>
                  </li>
                  { /*columns ?
                    <li className="ml-3 relative">
                      <EditColumnsDropdown
                        columns={columns}
                        onEditColumns={_onEditColumns}
                      />
                    </li>
                  : null*/ }
                  { onCreateItem ?
                    <li className=" ml-3 ">
                      {/* <Link to="/contact/create"> */}
                      <button onClick={onCreateItem} className=" f-f-r text-lg text-white w-[169px] h-[44px] shadow-lg bg-primary rounded-[8px]  ">
                        <ul className=" inline-flex ">
                          <li className=" mt-1 ">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18 13H13V18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18V13H6C5.73478 13 5.48043 12.8946 5.29289 12.7071C5.10536 12.5196 5 12.2652 5 12C5 11.7348 5.10536 11.4804 5.29289 11.2929C5.48043 11.1054 5.73478 11 6 11H11V6C11 5.73478 11.1054 5.48043 11.2929 5.29289C11.4804 5.10536 11.7348 5 12 5C12.2652 5 12.5196 5.10536 12.7071 5.29289C12.8946 5.48043 13 5.73478 13 6V11H18C18.2652 11 18.5196 11.1054 18.7071 11.2929C18.8946 11.4804 19 11.7348 19 12C19 12.2652 18.8946 12.5196 18.7071 12.7071C18.5196 12.8946 18.2652 13 18 13Z"
                                fill="white"
                              />
                            </svg>
                          </li>
                          <li className=" ml-3 mt-1 ">Add New</li>
                        </ul>
                      </button>
                      {/* </Link> */}
                    </li>
                  : null }
                  {/* list ended */}
                </ul>
              </div>
            </div>
          </div>
        {/* </div> */}
        {/* new div ended */}

        {/* table started */}

        <div className="relative py-8  overflow-x-scroll "> {/* overflow-x overflow-x-auto */}
          <table id="export-data-table-to-csv" className="w-full  text-center table-auto overflow-scroll ">
            <thead className=" text-black  bg-grey-light shadow-lg  ">
              <tr className="tabel-home f-f-r text-base text-black-dark ">
                { header_format?.map((format, i) => (
                  columns ?
                    columns[format?.column] ? (
                      <th className="px-6 py-3" key={i}>{format?.label}</th>
                    ) : null
                  : (<th className="px-6 py-3" key={i}>{format?.label}</th>)
                )) }
              </tr>
            </thead>
            <tbody>
              {data?.map((item, i) => (
                <tr
                  key={i}
                  onClick={() => onViewItem ? onViewItem(item) : null }
                  className="bg-white border-b f-f-r text-base text-black-dark cursor-pointer hover:bg-gray-100"
                >
                  { data_format?.map((format, i) => {
                    if (format?.keys[0] === 'project_services' || format?.keys[0] === 'project_contacts') {
                      if (item[format?.keys[0]]) {
                        //console.log(format?.keys[0], ':', JSON.parse(item[format?.keys[0]]));
                      }
                    } else if (format?.keys[0] === 'solar_product' || format?.keys[0] === 'battery_product' || format?.keys[0] === 'inverter_product' || format?.keys[0] === 'mounting_product' || format?.keys[0] === 'generator_product' || format?.keys[0] === 'chargecontroller_product' || format?.keys[0] === 'fence_product') {
                      
                      // format?.keys?.map((key, j) => `${(item[key] !== undefined && item[key] !== null) ? item[key] : '-'} ` )

                      const products = item[format?.keys[0]] ? JSON.parse(item[format?.keys[0]]) : [];

                      // console.log(format?.keys[0], ':', products);
                      return (
                        columns ? (
                          columns[format?.column] ? (
                            format?.keys[1]?.map((key, j) => <td className="px-6 py-3 relative" key={j}>{`${(products[0] && products[0][key] !== undefined && products[0][key] !== null) ? products[0][key] : '-'}`}</td> )
                          ) : null
                        ) : (
                          format?.keys[1]?.map((key, j) => <td className="px-6 py-3 relative" key={j}>{`${(products[0] && products[0][key] !== undefined && products[0][key] !== null) ? products[0][key] : '-'}`}</td> )
                        )
                      )
                    } else if (format?.keys[0] === "utility") {
                      return columns ? (
                        columns[format?.column] ? (
                          <td
                            className="px-6 py-3 relative"
                            //onClick={isSpecialCell ? stopPropagation : undefined}
                            key={i}
                          >
                            { getUtilityNameById(item?.utility_id) }
                          </td>
                        ) : null
                      ) : (
                        <td
                          className="px-6 py-3 relative"
                          //onClick={isSpecialCell ? stopPropagation : undefined}
                          key={i}
                        >
                          { getUtilityNameById(item?.utility_id) }
                        </td>
                      );
                    } else if (format?.keys[0] === 'contacts') {
                      return (
                        columns ? (
                          columns[format?.column] ? (
                            <td className="px-6 py-3 relative" key={i}>
                              {item?.contacts?.length}
                            </td>
                          ) : null
                        ) : (
                          <td className="px-6 py-3 relative" key={i}>
                            {item?.contacts?.length}
                          </td>
                        )
                      )
                    } else if (format?.keys[0] === 'sites') {
                      return (
                        columns ? (
                          columns[format?.column] ? (
                            <td className="px-6 py-3 relative" key={i}>
                              {item?.sites?.length}
                            </td>
                          ) : null
                        ) : (
                          <td className="px-6 py-3 relative" key={i}>
                            {item?.sites?.length}
                          </td>
                        )
                      )
                    } else if (format?.keys[0] === 'modified_by') {
                      return (
                        columns ?
                          columns?.last_modified_by ? (
                            <td className="px-6 py-3" key={i}>
                              {console.log('item?.modified_by:', item?.modified_by)}
                              { item?.modified_by?.first_name && item?.modified_by?.last_name
                                ? `${item?.modified_by?.first_name} ${item?.modified_by?.last_name}`
                                : "Not modified"
                              }
                            </td>
                          ) : null
                        : (
                          <td className="px-6 py-3" key={i}>
                            { item?.modified_by?.first_name && item?.modified_by?.last_name
                              ? `${item?.modified_by?.first_name} ${item?.modified_by?.last_name}`
                              : "Not modified"
                            }
                          </td>
                        )
                      )
                    } else if (format?.keys[0] === 'created_at') {
                      return (
                        columns ? (
                          columns[format?.column] ? (
                            <td className="px-6 py-3 relative" key={i}>
                              { moment(item?.created_at).format("MMMM Do YYYY") }
                            </td>
                          ) : null
                        ) : (
                          <td className="px-6 py-3 relative" key={i}>
                            { moment(item?.created_at).format("MMMM Do YYYY") }
                          </td>
                        )
                      )
                    } else if (format?.keys[0] === 'updated_at') {
                      return (
                        columns ? (
                          columns[format?.column] ? (
                            <td className="px-6 py-3 relative" key={i}>
                              { moment(item?.updated_at).fromNow() }
                              { format?.keys[0] === 'updated_at' ? 
                                <Dropdown
                                  onDeleteItem={_onDeleteItem}
                                  onEditItem={_onEditItem}
                                  onViewItem={_onViewItem}
                                  item={item}
                                />
                              : null }
                            </td>
                          ) : null
                        ) : (
                          <td className="px-6 py-3 relative" key={i}>
                            { moment(item?.updated_at).fromNow() }
                            { format?.keys[0] === 'updated_at' ? 
                              <Dropdown
                                onDeleteItem={_onDeleteItem}
                                onEditItem={_onEditItem}
                                onViewItem={_onViewItem}
                                item={item}
                              />
                            : null }
                          </td>
                        )
                      )
                    } else {
                      return (
                        columns ? (
                          columns[format?.column] ? (
                            <td className="px-6 py-3 relative" key={i}>
                              { format?.keys?.map((key, j) => `${(item[key] !== undefined && item[key] !== null) ? item[key] : '-'} ` ) }
                              { format?.keys[0] === 'title' ? 
                                <Dropdown
                                  onDeleteItem={_onDeleteItem}
                                  onEditItem={_onEditItem}
                                  onViewItem={_onViewItem}
                                  item={item}
                                />
                              : null }
                            </td>
                          ) : null
                        ) : (
                          <td className="px-6 py-3 relative" key={i}>
                            { format?.keys?.map((key, j) => `${(item[key] !== undefined && item[key] !== null) ? item[key] : '-'} ` ) }
                            { format?.keys[0] === 'title' ? 
                              <Dropdown
                                onDeleteItem={_onDeleteItem}
                                onEditItem={_onEditItem}
                                onViewItem={_onViewItem}
                                item={item}
                              />
                            : null }
                          </td>
                        )
                      )
                    }
                  }) }
                  {/* {columns.contact_name ? (
                    <td className="px-6 py-3">{`${item.first_name} ${item.last_name}`}</td>
                  ) : null}
                  {columns.email ? (
                    <td className="px-6 py-3">{item.email}</td>
                  ) : null}
                  {columns.phone_number ? (
                    <td className="px-6 py-3">{item.phone_number}</td>
                  ) : null}
                  {columns.company ? (
                    <td className="px-6 py-3">{item.company}</td>
                  ) : null}
                  {columns.type ? (
                    <td className="px-6 py-3">{item.type}</td>
                  ) : null}
                  {columns.label ? (
                    <td className="px-6 py-3">{item.label}</td>
                  ) : null}
                  {columns.last_modified_by ? (
                    <td className="px-6 py-3">
                      {item?.modified_by?.first_name &&
                      item?.modified_by?.last_name
                        ? `${item?.modified_by?.first_name} ${item?.modified_by?.last_name}`
                        : "Not modified"}
                    </td>
                  ) : null}
                  { columns.title ? (
                    <td className="px-6 py-3 relative ">
                      {columns.title ? item.title : null}
                      <Dropdown
                        onDeleteContact={_onDeleteContact}
                        onEditContact={_onEditContact}
                        contact={item}
                      />
                    </td>
                  ) : null } */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        { lastPage > 1 ?
          <div className=" tabel-home py-5 px-3 w-full  mb-20 ">
            <ul className=" inline-flex  w-full  justify-end ">
              <li>
                <h2 className=" inline-flex f-f-m text-tiny  text-black-vdark ">
                  Rows per page: 15{" "}
                  {/*               <svg
                    width="8"
                    className=" mt-2 ml-2 "
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M3.87636 5L0 0H7.75273L3.87636 5Z" fill="#808080" />
                  </svg> */}
                </h2>
              </li>
              <li className=" ml-5 ">
                <h2 className=" inline-flex f-f-m text-tiny  text-black-vdark ">
                  page {currentPage} of {lastPage}
                </h2>
              </li>{" "}
              <li className=" ml-5 ">
                {/* inner icon list started */}
                <ul className=" inline-flex ">
                  <li>
                    <span onClick={decreasePage} className="cursor-pointer">
                      <svg
                        width="6"
                        height="10"
                        viewBox="0 0 6 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.62044 1.10636C6.22943 0.51287 5.2652 -0.426818 4.65621 0.216126L0.190308 4.51891C-0.0634362 4.7662 -0.0634362 5.21131 0.190308 5.4586L4.65621 9.81084C5.2652 10.4043 6.22943 9.46464 5.62044 8.87115L1.66203 5.01348L5.62044 1.10636Z"
                          fill="#808080"
                        />
                      </svg>
                    </span>
                  </li>
                  <li className=" ml-8 ">
                    <span onClick={increasePage} className="cursor-pointer">
                      <svg
                        width="7"
                        height="10"
                        viewBox="0 0 7 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.65983 1.10636C0.0508423 0.51287 1.01507 -0.426818 1.62406 0.216126L6.08996 4.51891C6.34371 4.7662 6.34371 5.21131 6.08996 5.4586L1.62406 9.81084C1.01507 10.4043 0.0508423 9.46464 0.65983 8.87115L4.61825 5.01348L0.65983 1.10636Z"
                          fill="#808080"
                        />
                      </svg>
                    </span>
                  </li>
                </ul>
                {/* inner icon listended */}
              </li>
            </ul>
          </div>
        : null }

        {/* table ended */}
      {/* </div> */}
    </>
  );
}
