import React from "react";

export default function MoniteringTable(props) {
  const [isEditable, setIsEditable] = React.useState(false);

  const [components, setComponents] = React.useState([
    {
      component: "Stellar Edge",
      unit_qty: 2,
      power_consumption: "5",
    },
    {
      component: "SEL-751 Protection Relay",
      unit_qty: 1,
      power_consumption: "25",
    },
    {
      component: "PCC Meter / Acuvim IIR",
      unit_qty: 1,
      power_consumption: "5",
    },
    {
      component: "Schneider Contactor Latch Block",
      unit_qty: 1,
      power_consumption: "30",
    },
    {
      component: "Combustible Gas Sensor",
      unit_qty: 1,
      power_consumption: "20.4",
    },
    {
      component: "Managed industrial switch, LTE M2M Router",
      unit_qty: 1,
      power_consumption: "3.84",
    },
    {
      component: "Siemens 200A 120/240V Breaker, Shunt Trip",
      unit_qty: 1,
      power_consumption: "5(1 Second)",
    },
  ]);
  const _onChangeComponentsInput = (e) => {
    let data = [...components];

    data[e.target.id][e.target.name] = e.target.value;

    setComponents(data);
  };
  const saveChanges = () => {
    props.onSaveMonitoringOverview(components);
    setIsEditable(!isEditable);
    props.onEditMoniteringTable(!isEditable);
  };
  const _setIsEditable = () => {
    setIsEditable(!isEditable);
    props.onEditMoniteringTable(!isEditable);
  };
  return (
    <div>
      <div className="relative overflow-x-auto mt-8 ">
        <button
          onClick={ isEditable ? saveChanges : _setIsEditable }
          className="absolute top-2 right-2 border bg-primary w-[45px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white  "
        >
          {isEditable ? "Save" : "Edit"}
        </button>
        <table className=" table-site w-full  text-left  ">
          <caption className="px-3 py-2 f-f-m text-2xl text-center  bg-primary text-white ">
            Monitoring & Controls Load Profile
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b text-base text-black-dark ">
              <th className="px-6 py-3 min-w-[350px]">Component</th>
              <th className="px-6 py-3 min-w-[150px]">Power Consumption (W)</th>
              <th className="px-6 py-3 w-[150px]">Unit Qty</th>
            </tr>
          </thead>
          <tbody>
            {components.map((component, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-base text-black-dark "
                >
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeComponentsInput}
                        className="input-equipment-table"
                        id={i}
                        name="component"
                        value={component.component}
                      />
                    ) : (
                      component.component
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="text"
                        onChange={_onChangeComponentsInput}
                        className="input-equipment-table"
                        id={i}
                        name="power_consumption"
                        value={component.power_consumption}
                      />
                    ) : (
                      component.power_consumption
                    )}
                  </td>
                  <td className="px-6 py-3">
                    {isEditable ? (
                      <input
                        type="number"
                        onChange={_onChangeComponentsInput}
                        className="input-equipment-table"
                        id={i}
                        name="unit_qty"
                        value={component.unit_qty}
                      />
                    ) : (
                      component.unit_qty
                    )}
                  </td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Stellar Edge
                            </td>
                            <td className="px-6 py-3   ">
                            5
                            </td>
                            <td className="px-6 py-3   ">
                            2
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            SEL-751 Protection Relay
                            </td>
                            <td className="px-6 py-3   ">
                            25
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            PCC Meter / Acuvim IIR
                            </td>
                            <td className="px-6 py-3   ">
                            5
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Schneider Contactor Latch Block
                            </td>
                            <td className="px-6 py-3   ">
                            30
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Combustible Gas Sensor
                            </td>
                            <td className="px-6 py-3   ">
                            20.4
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Managed industrial switch, LTE M2M Router
                            </td>
                            <td className="px-6 py-3   ">
                            3.84
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r text-base text-black-dark ">
                            <td className="px-6 py-3   ">
                            Siemens 200A 120/240V Breaker, Shunt Trip
                            </td>
                            <td className="px-6 py-3   ">
                            5(1 Second)
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
