import React from "react";
import { useNavigate } from "react-router-dom";

const google = window.google;

export default function Map({ site_gps, site_name, site_address, site_id }) {
  const navigate = useNavigate();

  const [location, setLocation] = React.useState({
    street_address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    latitude: 39.652239,
    longitude: -122.726481,
  });

  React.useEffect(() => {
    initMap();
  }, []);

  const isValidCoordinate = (lat, lng) => {
    return (
      !isNaN(lat) &&
      !isNaN(lng) &&
      lat >= -90 &&
      lat <= 90 &&
      lng >= -180 &&
      lng <= 180
    );
  };

  const initMap = () => {
    let default_gps_lat = 39.2216489;
    let default_gps_lng = -121.0130471;

    if (
      site_gps &&
      site_gps[0] &&
      isValidCoordinate(parseFloat(site_gps[0][0]), parseFloat(site_gps[0][1]))
    ) {
      default_gps_lat = parseFloat(site_gps[0][0]);
      default_gps_lng = parseFloat(site_gps[0][1]);
    }

    const mapView = document.getElementById("map-view");
    const map = new google.maps.Map(mapView, {
      center: { lat: default_gps_lat, lng: default_gps_lng },
      zoom: 2,
    });

    var infowindow = new google.maps.InfoWindow();

    var markers, i;

    for (i = 0; i < site_gps.length; i++) {
      const gps_lat = parseFloat(site_gps[i][0]);
      const gps_lng = parseFloat(site_gps[i][1]);

      // Only proceed if the coordinates are valid
      if (isValidCoordinate(gps_lat, gps_lng)) {
        const name_of_site = site_name[i];
        const address_of_site = site_address[i];
        const id_of_site = site_id[i];

        markers = new google.maps.Marker({
          position: { lat: gps_lat, lng: gps_lng },
          map: map,
          animation: google.maps.Animation.DROP,
        });

        map.panTo({ lat: gps_lat, lng: gps_lng });
        map.setCenter({ lat: gps_lat, lng: gps_lng });
        markers.setPosition({ lat: gps_lat, lng: gps_lng });

        map.setZoom(3);

        google.maps.event.addListener(
          markers,
          "click",
          (function (markers, i) {
            return function () {
              const contentString = `
                <b>${name_of_site} Site:<br/> GPS:</b> ${gps_lat}, ${gps_lng}<br/><b>Address:</b> ${address_of_site}<br/>
                <div class="flex justify-center mt-2">
                  <button id="view-site-${i}" class="border bg-primary w-[50px] text-center h-[30px] rounded-[15px] f-f-r text-sm text-white">View</button>
                </div>
              `;
              infowindow.setContent(contentString);
              infowindow.open(map, markers);

              // Add event listener to the button after the content is set
              google.maps.event.addListenerOnce(
                infowindow,
                "domready",
                function () {
                  document
                    .getElementById(`view-site-${i}`)
                    .addEventListener("click", () => {
                      navigate(`/scenarios/view/${id_of_site}`);
                    });
                }
              );
            };
          })(markers, i)
        );
      }
    }
  };

  return (
    <div>
      <div className="map-view" id="map-view"></div>
    </div>
  );
}
