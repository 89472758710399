import React from "react";

export default function Temparuretable({ temperature_design }) {
  return (
    <div className="pr-10 pl-10">
      <div className="relative overflow-x-auto mt-8 ">
        <table className=" table-site w-full border border-slate-500 text-left  ">
          <caption className="border border-slate-600 px-3 py-2 f-f-m text-lg text-center  bg-primary text-white ">
            TEMPERATURE DESIGN
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b  text-sm text-black-dark ">
              <th className="border border-slate-600 px-6 py-3     ">Month</th>
              <th className="border border-slate-600 px-6 py-3     ">Temperature (°C)</th>
              <th className="border border-slate-600 px-6 py-3     ">Total Heating Demand (kWh)</th>
              <th className="border border-slate-600 px-6 py-3     ">Total Cooling Demand (kWh)</th>
              {/* <th className="px-6 py-3     ">
                            Total Combined Heating and Cooling (kWh)  
                            </th>
                            <th className="px-6 py-3     ">
                            Daily Combined Heating and Cooling (kWh)
                            </th> */}
            </tr>
          </thead>
          <tbody>
            {temperature_design.map((temperature, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-sm text-black-dark "
                >
                  <td className="border border-slate-600 px-6 py-3">{temperature.month}</td>
                  <td className="border border-slate-600 px-6 py-3">{temperature.temperature}</td>
                  <td className="border border-slate-600 px-6 py-3">
                    {temperature.total_heating_demand}
                  </td>
                  <td className="border border-slate-600 px-6 py-3">
                    {temperature.total_cooling_demand}
                  </td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Feb
                            </td>
                            <td className="px-6 py-3   ">
                            7.5
                            </td>
                            <td className="px-6 py-3   ">
                            54.72
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            54.72
                            </td>
                            <td className="px-6 py-3   ">
                            1.77
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Mar
                            </td>
                            <td className="px-6 py-3   ">
                            9.8
                            </td>
                            <td className="px-6 py-3   ">
                            47.33
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            47.33
                            </td>
                            <td className="px-6 py-3   ">
                            1.53
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Apr
                            </td>
                            <td className="px-6 py-3   ">
                            10.3
                            </td>
                            <td className="px-6 py-3   ">
                            43.33
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            43.33
                            </td>
                            <td className="px-6 py-3   ">
                            1.40
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            May
                            </td>
                            <td className="px-6 py-3   ">
                            16.4
                            </td>
                            <td className="px-6 py-3   ">
                            10.94
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            10.94
                            </td>
                            <td className="px-6 py-3   ">
                            0.35
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Jun
                            </td>
                            <td className="px-6 py-3   ">
                            19.4
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            5.46
                            </td>
                            <td className="px-6 py-3   ">
                            5.46
                            </td>
                            <td className="px-6 py-3   ">
                            0.18
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Jul
                            </td>
                            <td className="px-6 py-3   ">
                            21.2
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            16.23
                            </td>
                            <td className="px-6 py-3   ">
                            16.23
                            </td>
                            <td className="px-6 py-3   ">
                            0.52
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Jul
                            </td>
                            <td className="px-6 py-3   ">
                            21.2
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            16.23
                            </td>
                            <td className="px-6 py-3   ">
                            16.23
                            </td>
                            <td className="px-6 py-3   ">
                            0.52
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Aug
                            </td>
                            <td className="px-6 py-3   ">
                            20.2
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            9.80
                            </td>
                            <td className="px-6 py-3   ">
                            9.80
                            </td>
                            <td className="px-6 py-3   ">
                            0.32
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Sep
                            </td>
                            <td className="px-6 py-3   ">
                            20.8
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            13.78
                            </td>
                            <td className="px-6 py-3   ">
                            13.78
                            </td>
                            <td className="px-6 py-3   ">
                            0.44
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Oct
                            </td>
                            <td className="px-6 py-3   ">
                            15.7
                            </td>
                            <td className="px-6 py-3   ">
                            13.94
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            13.94
                            </td>
                            <td className="px-6 py-3   ">
                            0.45
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Nov
                            </td>
                            <td className="px-6 py-3   ">
                            9.4
                            </td>
                            <td className="px-6 py-3   ">
                            47.68
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            47.68
                            </td>
                            <td className="px-6 py-3   ">
                            1.54
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Dec
                            </td>
                            <td className="px-6 py-3   ">
                            7.6
                            </td>
                            <td className="px-6 py-3   ">
                            59.70
                            </td>
                            <td className="px-6 py-3   ">
                            0
                            </td>
                            <td className="px-6 py-3   ">
                            59.70
                            </td>
                            <td className="px-6 py-3   ">
                            1.93
                            </td>
                    
                        
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
