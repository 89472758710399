import React from 'react'

import { Link, useParams, useNavigate } from 'react-router-dom';
import Config from '../../../utils/Config';
import { useSelector, useDispatch } from 'react-redux';

import DeviationTable from './DeviationTable';

import swal from 'sweetalert';

import { setDesignDeviation } from "../../../redux/slices/basisOfDesignSlice";

const staticDeviations = [
  {
    exception: "3.12.1.2 Audible Noise",
    justification:
      "Equipment generating noise for this SPS are the Inverters and the HVAC unit. The HVAC unit is rated by the manufacturer to produce 65.2 dBA at 10ft. This exceeds the noise limits for a residential zone (55 dBa). Since the inverters will be located inside of an insulated shipping container, BoxPower does not anticipate the inverter to contribute to the external nose levels.",
  },
  {
    exception: "3.9.1.6 Future Expansion",
    justification:
      "No additional foundations or future expansion has been requested by PG&E at this time.",
  },
  {
    exception: "3.9.1.14 PV Rapid Shutdown",
    justification:
      "Rapid shutdown for solar is not included in design because it is not installed on an occupied space, it is installed on a detached structure that is specifically built for solar.",
  },
  {
    exception: "3.9.2.2 Pre-approved Energy Storage System",
    justification:
      "BoxPower's proposed battery design is not on the pre-approved ESS list. Please see the provided spec sheet",
  },
  {
    exception: "3.9.4.1 Switchgear breaker for each load circuit exiting PCC",
    justification:
      "BoxPower currently assumes only one load circuit will be connected",
  },
  {
    exception: "3.9.10 CCTV System",
    justification: "CCTV not included in scope at this time",
  },
  {
    exception: "3.9.11.3 Chain-link fence",
    justification:
      "Unless otherwise specified by PG&E, BoxPower assumes the specified chain-link fence with mesh will be used for this site.",
  },
];

export default function Index() {
  const user     = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { site_id, design_id } = useParams();

  const { design_deviation } = useSelector((state) => state.basisOfDesign);
  const [ isDeviationTableEditable, setIsDeviationTableEditable ] = React.useState(false);

  const _onSaveDesignDeviations = (deviations) => {
    const payload = { design_id, deviations: deviations };
    // console.log("payload:", payload);

    fetch(`${Config.API.URL}/design-basis/design-deviation`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` },
      body: JSON.stringify(payload),
    }).then((res) => {
      // if 401 error (unauthenticated user, then log user out)
      if ((res.status) === 401) {
        console.log("401 Error: Unauthenticated");

        localStorage.removeItem('user');
        navigate('/');
      }
      return res;
    }).then((res) => res.json()).then( async (res) => {
      // console.log('res:', res);

      // if request is succesful, alert user that project has been cloned
      if (res.status) {
        dispatch(setDesignDeviation(res.data));
      } else {
        swal("Oh no!", res.message, "error");
      }
    }).catch((error) => {
      console.log('Error: ', error);
      
      swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
    });
  }

  const nextPage = () => {
    if (isDeviationTableEditable) {
      swal({
        title: "Editable mode detected?",
        text: "When go to the next page, unsave changes will be lost. do you still want go to next page?",
        icon: "warning",
        buttons: ["No", "Next"],
        dangerMode: true,
      }).then((willProcced) => {
        if (willProcced) {
          if (!design_deviation?.length) {
            _onSaveDesignDeviations(staticDeviations);
          }
          navigate(`/basis/monitoring/overview/${site_id}/${design_id}`);
        } 
      });
    } else {
      if (!design_deviation?.length) {
        _onSaveDesignDeviations(staticDeviations);
      }
      navigate(`/basis/monitoring/overview/${site_id}/${design_id}`);
    }
  }

  return (
    <div className='' >
        <h2 className=' f-f-r text-lg text-black ' >PG&E Basis of Design</h2>
        <h4 className=' f-f-sm text-black text-2xl  sm:text-3xl ' >Summary of Design Deviations</h4>
        <h2 className=' f-f-m text-black text-lg mt-4 ' >Fill out the Design Deviations Table below</h2>

   {/*  table started */}

<DeviationTable onEditDeviationTable={setIsDeviationTableEditable} onSaveDesignDeviations={_onSaveDesignDeviations} />

<div className='  ' >

        

<div className=' border border-gray-300 mt-20  ' ></div>
<div className="grid grid-cols-12  gap-4  ">
<div className="   col-span-3">
    <Link to={`/basis/temperature/design/${site_id}/${design_id}`} >
    
<button className="  mt-8 relative border border-primary w-[110px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-primary  ">
       {/* inner ul started */}
        <ul className=' inline-flex text-center justify-center mt-1 mr-3 ' >
            <li>
                        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.25 7.5L11.75 13L17.25 18.5" stroke="#ED583B" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
            </li>
            <li className='ml-1' >
    Back
    </li>
        </ul>
        {/* inner ul ended */}
       </button>
       </Link>
</div>
<div className="   col-span-9">
<div className=' text-end ' >
<ul className=" inline-flex items-center  ">

<Link to={`/basis/monitoring/overview/${site_id}/${design_id}`}>
<li >
<button className=" mt-8  text-center  f-f-r text-lg text-black-darkest  ">
Skip</button>
  </li>
  </Link>
<li className=" ml-6 ">
<button onClick={nextPage} className=" mt-8 relative border bg-primary w-[127px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-white  ">Next</button></li>

</ul>
</div>
</div>
</div>

</div>
{/* adjuest in bottom ended  */}

    </div>
  )
}
