import React from 'react';

import { Link, useParams, useNavigate } from 'react-router-dom';
import Config from '../../../utils/Config';
import { useSelector, useDispatch  } from 'react-redux';

import CivilTable from './CivilTable';

import { setCivilDesign } from "../../../redux/slices/basisOfDesignSlice";
import swal from 'sweetalert';

const staticVehicles = [
  {
    vehicle: "F-250 TRUCK",
    quantity: 2,
    dimensions: "23 feet X6 feet 4 inches",
    weight: "6100 lbs",
  },
  {
    vehicle: "SKID STEER",
    quantity: 1,
    dimensions: "8 feet 2 inches X7 feet 8 inches",
    weight: "8615 lbs",
  },
  {
    vehicle: "MINI EXCAVATOR",
    quantity: 1,
    dimensions: "17 feet 3 inches X5 feet 9 inches",
    weight: "7500 lbs",
  },
  {
    vehicle: "CONCRETE TRUCK",
    quantity: 1,
    dimensions: "24 feet X8 feet 6 inches",
    weight: "67000 lbs",
  },
  {
    vehicle: "DUMP TRUCK",
    quantity: 1,
    dimensions: "23 feet 8 inches X9 feet 6 inches",
    weight: "56000 lbs",
  },
  {
    vehicle: "CONTAINER DELIVERY TRUCK",
    quantity: 1,
    dimensions: "12 feet 8 inches X8 inches",
    weight: "10040 lbs",
  },
  {
    vehicle: "CONTAINER DELIVERY TRAILER",
    quantity: 1,
    dimensions: "31 feet X8 feet 6 inches",
    weight: "7730 lbs",
  },
  {
    vehicle: "BOOM TRUCK",
    quantity: 1,
    dimensions: "37 feet X8 feet",
    weight: "33000 lbs",
  },
  {
    vehicle: "CRANE",
    quantity: 1,
    dimensions: "45 feet X8 feet 6 inches",
    weight: "93000 lbs",
  },
  {
    vehicle: "SKIP LOADER",
    quantity: 1,
    dimensions: "23 feet x7 feet 5 inches",
    weight: "10500 lbs",
  },
  {
    vehicle: "WATER TRAILER",
    quantity: 1,
    dimensions: "13 feet 5 inches 6 feet 7 inches",
    weight: "2000 lbs",
  },
];

export default function Index() {
  const user = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { civil_design } = useSelector((state) => state.basisOfDesign);
  const { site_id, design_id } = useParams();
  const [ isCivilTableEditable, setIsCivilTableEditable ] = React.useState(false);

  const _onSaveCivilDesign = (vehicles) => {
    const payload = { design_id, vehicles: vehicles };

    fetch(`${Config.API.URL}/design-basis/civil-design`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` },
      body: JSON.stringify(payload),
    }).then((res) => {
      // if 401 error (unauthenticated user, then log user out)
      if ((res.status) === 401) {
        console.log("401 Error: Unauthenticated");

        localStorage.removeItem('user');
        navigate('/');
      }
      return res;
    }).then((res) => res.json()).then( async (res) => {
      // console.log('res:', res);

      // if request is succesful, alert user that project has been cloned
      if (res.status) {
        dispatch(setCivilDesign(res.data));
      } else {
        swal("Oh no!", res.message, "error");
      }
    }).catch((error) => {
      console.log('Error: ', error);
      
      swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
    });
  }

  const nextPage = () => {
    if (isCivilTableEditable) {
      swal({
        title: "Editable mode detected?",
        text: "When go to the next page, unsave changes will be lost. do you still want go to next page?",
        icon: "warning",
        buttons: ["No", "Next"],
        dangerMode: true,
      }).then((willProcced) => {
        if (willProcced) {
          if (!civil_design.length) {
            _onSaveCivilDesign(staticVehicles);
          }
          navigate(`/basis/temperature/design/${site_id}/${design_id}`);
        } 
      });
    } else {
      if (!civil_design.length) {
        _onSaveCivilDesign(staticVehicles);
      }
      navigate(`/basis/temperature/design/${site_id}/${design_id}`);
    }
  }

  return (
    <div className='' >
        <h2 className=' f-f-r text-lg text-black ' >PG&E Basis of Design</h2>
        <h4 className=' f-f-sm text-black text-2xl  sm:text-3xl ' >Civil & Structural Design</h4>
        <h2 className=' f-f-m text-black text-lg mt-4 ' >Fill out the Onsite Vehicles Table below</h2>

   {/*  table started */}

<CivilTable onEditCivilTable={setIsCivilTableEditable}  onSaveCivilDesign={_onSaveCivilDesign} />

<div className='  ' >

        

<div className=' border border-gray-300 mt-20  ' ></div>
<div className="grid grid-cols-12  gap-4  ">
<div className="   col-span-3">
    <Link to={`/basis/design/assupmtion/${site_id}/${design_id}`} >
   
<button className="  mt-8 relative border border-primary w-[110px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-primary  ">
       {/* inner ul started */}
        <ul className=' inline-flex text-center justify-center mt-1 mr-3 ' >
            <li>
                        <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.25 7.5L11.75 13L17.25 18.5" stroke="#ED583B" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
            </li>
            <li className='ml-1' >
    Back
    </li>
        </ul>
        {/* inner ul ended */}
       </button>
       </Link>
</div>
<div className="   col-span-9">
<div className=' text-end ' >
<ul className=" inline-flex items-center  ">

<Link to={`/basis/temperature/design/${site_id}/${design_id}`}>
<li >
<button className=" mt-8  text-center  f-f-r text-lg text-black-darkest  ">
Skip</button>
  </li>
  </Link>
<li className=" ml-6 ">
<button onClick={nextPage} className=" mt-8 relative border bg-primary w-[127px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-white  ">Next</button></li>

</ul>
</div>
</div>
</div>

</div>
{/* adjuest in bottom ended  */}

    </div>
  )
}
