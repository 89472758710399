import React from "react";

export default function Deviationtable({ design_deviation }) {
  return (
    <div className="pr-10 pl-10">
      <div className="relative overflow-x-auto mt-8 ">
        <table className=" table-site w-full border border-slate-500 text-left  ">
          <caption className="border border-slate-600 px-3 py-2 f-f-m text-lg text-center  bg-primary text-white ">
            DESIGN DEVIATIONS
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b  text-sm text-black-dark ">
              <th className="border border-slate-600 px-6 py-3    ">Exception / Deviation</th>
              <th className="border border-slate-600 px-6 py-3    ">Justification</th>
            </tr>
          </thead>
          <tbody>
            {design_deviation.map((deviation, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-sm text-black-dark "
                >
                  <td className="border border-slate-600 px-6 py-3">{deviation.exception}</td>
                  <td className="border border-slate-600 px-6 py-3">{deviation.justification}</td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            3.12.1.2 Audible Noise 
                            </td>
                            <td className="px-6 py-3   ">
                            Equipment generating noise for this SPS are the Inverters and the HVAC unit. The HVAC unit is rated by the manufacturer to produce 65.2 dBA at 10ft. This exceeds the noise limits for a residential zone (55 dBa). Since the inverters will be located inside of an insulated shipping container, BoxPower does not anticipate the inverter to contribute to the external nose levels.
                          
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            3.9.1.6 Future Expansion
                            </td>
                            <td className="px-6 py-3   ">
                            No additional foundations or future expansion has been requested by PG&E at this time.   
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            3.9.1.14 PV Rapid Shutdown
                            </td>
                            <td className="px-6 py-3   ">
                            Rapid shutdown for solar is not included in design because it is not installed on an occupied space, it is installed on a detached structure that is specifically built for solar.
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            3.9.2.2 Pre-approved Energy Storage System 
                            </td>
                            <td className="px-6 py-3   ">
                            BoxPower’s proposed battery design is not on the pre-approved ESS list. Please see the provided spec sheet. 
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            3.9.4.1 Switchgear breaker for each load circuit exiting PCC
                            </td>
                            <td className="px-6 py-3   ">
                            BoxPower currently assumes only one load circuit will be connected.  
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            3.9.10 CCTV System
                            </td>
                            <td className="px-6 py-3   ">
                            CCTV not included in scope at this time
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            3.9.11.3 Chain-link fence
                            </td>
                            <td className="px-6 py-3   ">
                            Unless otherwise specified by PG&E, BoxPower assumes the specified chain-link fence with mesh will be used for this site.
                            </td>
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
