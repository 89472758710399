import React from "react";

export default function Civiltable({ civil_design }) {
  return (
    <div className="pr-10 pl-10">
      <div className="relative overflow-x-auto mt-8 ">
        <table className=" table-site w-full border border-slate-500 text-left  ">
          <caption className="border border-slate-600 px-3 py-2 f-f-m text-lg text-center  bg-primary text-white ">
            ONSITE VEHICLES
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b  text-sm text-black-dark ">
              <th className="border border-slate-600 px-6 py-3     ">VEHICLE</th>
              <th className="border border-slate-600 px-6 py-3     ">DIMENSIONS (LXW)</th>
              <th className="border border-slate-600 px-6 py-3     ">WEIGHT (LBS)</th>
              <th className="border border-slate-600 px-6 py-3     ">QUANTITY</th>
            </tr>
          </thead>
          <tbody>
            {civil_design.map((vehicle, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-sm text-black-dark "
                >
                  <td className="border border-slate-600 px-6 py-3">{vehicle.vehicle}</td>
                  <td className="border border-slate-600 px-6 py-3">{vehicle.quantity}</td>
                  <td className="border border-slate-600 px-6 py-3">{vehicle.dimensions}</td>
                  <td className="border border-slate-600 px-6 py-3">{vehicle.weight}</td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            F-250 TRUCK
                            </td>
                            <td className="px-6 py-3   ">
                            23'X6'4"
                            </td>
                            <td className="px-6 py-3   ">
                            6100
                            </td>
                            <td className="px-6 py-3   ">
                            2
                            </td>
                    
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            SKID STEER
                            </td>
                            <td className="px-6 py-3   ">
                            8'2"X7'8"
                            </td>
                            <td className="px-6 py-3   ">
                            8615
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                      
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            MINI EXCAVATOR
                            </td>
                            <td className="px-6 py-3   ">
                            17'3"X5'9"
                            </td>
                            <td className="px-6 py-3   ">
                            7500
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            CONCRETE TRUCK
                            </td>
                            <td className="px-6 py-3   ">
                            24'X8'6"
                            </td>
                            <td className="px-6 py-3   ">
                            67000
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            DUMP TRUCK
                            </td>
                            <td className="px-6 py-3   ">
                            23'8"X9'6’’
                            </td>
                            <td className="px-6 py-3   ">
                            56000
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            CONTAINER DELIVERY TRUCK
                            </td>
                            <td className="px-6 py-3   ">
                            12’8’’X8’
                            </td>
                            <td className="px-6 py-3   ">
                            10040
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            CONTAINER DELIVERY TRAILER
                            </td>
                            <td className="px-6 py-3   ">
                            31’X8’6’’
                            </td>
                            <td className="px-6 py-3   ">
                            7730
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            BOOM TRUCK
                            </td>
                            <td className="px-6 py-3   ">
                            37’X8’
                            </td>
                            <td className="px-6 py-3   ">
                            33000
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            CRANE
                            </td>
                            <td className="px-6 py-3   ">
                            45’X8’6”
                            </td>
                            <td className="px-6 py-3   ">
                            93,000
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            SKIP LOADER
                            </td>
                            <td className="px-6 py-3   ">
                            23’x7’5”
                            </td>
                            <td className="px-6 py-3   ">
                            10500
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            WATER TRAILER
                            </td>
                            <td className="px-6 py-3   ">
                            13’5” x 6’7”
                            </td>
                            <td className="px-6 py-3   ">
                            2000
                            </td>
                            <td className="px-6 py-3   ">
                            1
                            </td>
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
