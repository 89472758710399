import React, { useState, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import DialogDropdown from "./DialogDropdown";

import moment from "moment";

export default function DialogBoxBasis({
  showModal,
  setShowModal,
  design_basis,
  onDeleteBasisDesign,
}) {
  // const [ designBasisItems, setDesignBasisItems ] = useState([]);

  // useLayoutEffect(() => {
  //   // console.log('design_basis:', design_basis);
  //   // setDesignBasisItems(design_basis?.design_basis);
  //   const modal = document.querySelector('#view-basis-of-design-modal #view-basis-of-design-modal-content');

  //   if (modal) {
  //     setTimeout(() => {
  //       modal.click();

  //       console.log("Hi..");
  //     }, 500);
  //   }

  //   console.log('modal:', modal);
  // }, [showModal]);

  const _onDeleteBasisDesign = (design) => {
    onDeleteBasisDesign(design);
    setShowModal(false);
  };

  return (
    <div>
      {showModal ? (
        <>
          <div className="dilog-box" onClick={() => setShowModal(false)}></div>

          <div
            id="view-basis-of-design-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed top-[7%]   right-0 z-[10000000]  w-full p-4 overflow-x-hidden overflow-y-auto inset-0 h-[calc(100%-1rem)] max-h-full"
            onClick={(event) => event.stopPropagation()}
          >
            <div className="relative w-full max-w-4xl max-h-full ml-auto mr-auto ">
              <div
                id="view-basis-of-design-modal-content"
                className="relative bg-grey-light  rounded-lg shadow  p-10 "
              >
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className=" absolute top-[8px] right-[19px]  text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  data-modal-hide="view-basis-of-design-modal"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.918759 13.0577C0.360088 13.6164 0.360088 14.5222 0.918759 15.0809C1.47743 15.6395 2.38321 15.6395 2.94189 15.0809L7.99981 10.0229L13.0579 15.0811C13.6166 15.6397 14.5224 15.6397 15.081 15.0811C15.6397 14.5224 15.6397 13.6166 15.081 13.0579L10.0229 7.99982L15.0806 2.94213C15.6393 2.38346 15.6393 1.47767 15.0806 0.919002C14.522 0.360332 13.6162 0.360333 13.0575 0.919003L7.99981 5.9767L2.9423 0.919193C2.38363 0.360523 1.47784 0.360523 0.919172 0.919193C0.360502 1.47786 0.360503 2.38365 0.919174 2.94232L5.97668 7.99982L0.918759 13.0577Z"
                      fill="#FA3030"
                    />
                  </svg>
                </button>

                <h3 className=" f-f-sm text-3xl text-black  text-center ">
                  {design_basis?.site_name}
                </h3>
                <h4 className=" f-f-r text-primary text-2xl text-center my-2 ">
                  Below are all of the Basis of design Documents created for
                  this site{" "}
                </h4>

                {/* grid started */}
                <div className="grid grid-cols-12 mt-5 gap-4">
                  {design_basis?.design_basis?.map((design, index) => {
                    return (
                      <div
                        className="col-span-12 sm:col-span-6 xl:col-span-4"
                        key={index}
                      >
                        <div className="cardcomponent bg-white rounded-[10px] shadow-lg p-[10px] relative">
                          <ul className="inline-flex w-full">
                            <li className="w-full mt-1">
                              <h2 className="f-f-r text-base text-black">
                                {design.title}
                              </h2>
                            </li>
                            <li>
                              <DialogDropdown
                                onDeleteBasisDesign={_onDeleteBasisDesign}
                                design={design}
                              />
                            </li>
                          </ul>
                          <ul className="inline-flex w-full">
                            <li className="w-ful mt-4" style={{ flex: 1 }}>
                              <center>
                                <svg
                                  className="svg-icon-report"
                                  height="60px"
                                  width="60px"
                                  fill="#979797"
                                  xmlns="http://www.w3.org/2000/svg"
                                  version="1.1"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 64 64"
                                >
                                  <path d="M54,5H42.9v0C43,4.5,42.8,4,42.5,3.7C42.2,3.2,41.7,3,41.1,3H39c0,0,0-1.9-1.9-2H36c-0.6,0-1,0.4-1,1s0.4,1,1,1h1v1  c0,0.6,0.4,1,1,1h2.9v6h-11c-0.6,0-1,0.4-1,1s0.4,1,1,1H41c1,0,1.9-0.9,1.9-1.9V7H54c0.6,0,1,0.4,1,1v40c0,0.6,0.4,1,1,1s1-0.4,1-1  V8C57,6.3,55.7,5,54,5z"></path>
                                  <path d="M56,51c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2C57,51.4,56.6,51,56,51z"></path>
                                  <path d="M56,57c-0.6,0-1,0.4-1,1v2c0,0.6-0.4,1-1,1H10c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1h11v4c0,1.1,0.8,2,1.9,2H26  c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3V5h3c0.6,0,1-0.4,1-1V3h5c0.6,0,1-0.4,1-1s-0.4-1-1-1h-5.1c-2,0-1.9,2-1.9,2h-2.1  c-1,0-1.9,0.9-1.9,1.9V5H10C8.3,5,7,6.3,7,8v52c0,1.7,1.3,3,3,3h44c1.7,0,3-1.3,3-3v-2C57,57.4,56.6,57,56,57z"></path>
                                  <path d="M18,9h-3.9c-1.7,0-3.1,1.4-3.1,3.1v4c0,0.6,0.4,1,1,1s1-0.4,1-1v-4c0-0.5,0.4-1.1,1.1-1.1H18c0.6,0,1-0.4,1-1S18.6,9,18,9z"></path>
                                  <path d="M14.1,57.1c-0.5,0-1.1-0.4-1.1-1.1c0-0.6-0.4-1-1-1s-1,0.4-1,1c0,1.7,1.4,3.1,3.1,3.1H22c0.6,0,1-0.4,1-1s-0.4-1-1-1H14.1z"></path>
                                  <path d="M49.9,59.1c1.7,0,3.1-1.4,3.1-3.1V12.1c0-1.7-1.4-3.1-3.1-3.1h-3.6c-0.6,0-1,0.4-1,1s0.4,1,1,1h3.6c0.5,0,1.1,0.4,1.1,1.1  V56c0,0.5-0.4,1.1-1.1,1.1L32.3,57l-15.8-9.8c-0.3-0.2-0.7-0.2-1,0C15.2,47.3,15,47.6,15,48c0,1.3-0.8,2.4-2,2.8V20c0-0.6-0.4-1-1-1  s-1,0.4-1,1v32c0.1,0.8,0.7,1,0.7,1l20,6C31.8,59,49.9,59.1,49.9,59.1z M15.1,51.9c0.7-0.6,1.3-1.4,1.6-2.3l8.4,5.3L15.1,51.9z"></path>
                                  <path d="M33,8c0-0.6-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1s0.4,1,1,1h6C32.6,9,33,8.6,33,8z"></path>
                                  <path d="M38,9c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1H38z"></path>
                                  <path d="M40,18c-0.6,0-1,0.4-1,1v2c0,1.2,0.8,2,2,2h2v2.4l-3.4,1.7c-0.5,0.2-0.7,0.8-0.4,1.3c0.3,0.7,1.2,0.5,1.3,0.4l4-2  c0.3-0.2,0.6-0.5,0.6-0.9v-3h2c1.2,0,2-0.8,2-2v-2c0-1.2-0.8-2-2-2h-3c-0.6,0-1,0.4-1,1s0.4,1,1,1h3v2h-6v-2C41,18.4,40.6,18,40,18z  "></path>
                                  <path d="M41,45c-1.2,0-2,0.8-2,2v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2h6v2h-2.9c-0.6,0-1,0.4-1,1s0.4,1,1,1H47c1.2,0,2-0.8,2-2v-2  c0-1.2-0.8-2-2-2h-2v-7c0-0.6-0.4-1-1-1h-4c-0.6,0-1,0.4-1,1s0.4,1,1,1h3v6H41z"></path>
                                  <path d="M23,23c1.2,0,2-0.8,2-2v-2c0-1.2-0.8-2-2-2h-6c-1.2,0-2,0.8-2,2v2c0,1.2,0.8,2,2,2h2v7c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1  s-0.4-1-1-1h-3v-6H23z M17,21v-2h6v2H17z"></path>
                                  <path d="M37,54c0,0.6,0.4,1,1,1h10c0.6,0,1-0.4,1-1s-0.4-1-1-1H38C37.4,53,37,53.4,37,54z"></path>
                                  <path d="M16,35c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1s1-0.4,1-1v-8C17,35.4,16.6,35,16,35z"></path>
                                  <path d="M17,28c0-0.6-0.4-1-1-1s-1,0.4-1,1v4c0,0.6,0.4,1,1,1s1-0.4,1-1V28z"></path>
                                  <path d="M34,55c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1H34z"></path>
                                  <path d="M29.1,29.2c-0.4-0.3-1.1-0.2-1.4,0.2l-4.5,6c-0.2,0.3-0.3,0.7-0.1,1S23.6,37,24,37h4.8L27,47.8c-0.1,0.5,0.2,0.9,0.6,1.1  c0.5,0.2,1-0.1,1.2-0.3l12-16c0.2-0.3,0.3-0.7,0.1-1C40.7,31.2,40.4,31,40,31h-4.8L37,20.2c0.1-0.5-0.2-0.9-0.6-1.1  c-0.4-0.2-0.9,0-1.2,0.3l-4.5,6c-0.3,0.4-0.2,1.1,0.2,1.4c0.4,0.3,1.1,0.2,1.4-0.2l2-2.7l-1.3,8c-0.1,0.9,0.7,1.2,1,1.2h4l-8.3,11.1  l1.3-8c0-0.3-0.1-1.2-1-1.2h-4l3.3-4.4C29.6,30.2,29.5,29.5,29.1,29.2z"></path>
                                </svg>
                              </center>

                              {/* <h2 className='f-f-r text-sm text-primary ' >Created By:</h2>
                    <h2 className=' f-f-md-it text-tiny text-grey-vdark uppercase ' >Kevin Wagner</h2>

                    <h2 className='f-f-r text-sm text-primary mt-4' >Last Modified By:</h2>
                    <h2 className=' f-f-md-it text-tiny text-grey-vdark uppercase ' >Kevin Wagner</h2> */}
                              {/* 
                      <h2 className=' f-f-r  text-grey-vdark text-base ' >4517 Washington, Manchester,</h2>
                      <h2 className=' f-f-r  text-grey-vdark  text-tiny ' >Kentucky, US 39495</h2> 
                    */}
                            </li>
                            {/* <li className="w-ful pt-2" style={{ flex: 0, textAlign: 'center' }}>
                    <svg
                      className="svg-icon-report"
                      height="60px"
                      width="60px"
                      fill="#979797"
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 64 64"
                    >
                      <path d="M54,5H42.9v0C43,4.5,42.8,4,42.5,3.7C42.2,3.2,41.7,3,41.1,3H39c0,0,0-1.9-1.9-2H36c-0.6,0-1,0.4-1,1s0.4,1,1,1h1v1  c0,0.6,0.4,1,1,1h2.9v6h-11c-0.6,0-1,0.4-1,1s0.4,1,1,1H41c1,0,1.9-0.9,1.9-1.9V7H54c0.6,0,1,0.4,1,1v40c0,0.6,0.4,1,1,1s1-0.4,1-1  V8C57,6.3,55.7,5,54,5z"></path>
                      <path d="M56,51c-0.6,0-1,0.4-1,1v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2C57,51.4,56.6,51,56,51z"></path>
                      <path d="M56,57c-0.6,0-1,0.4-1,1v2c0,0.6-0.4,1-1,1H10c-0.6,0-1-0.4-1-1V8c0-0.6,0.4-1,1-1h11v4c0,1.1,0.8,2,1.9,2H26  c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3V5h3c0.6,0,1-0.4,1-1V3h5c0.6,0,1-0.4,1-1s-0.4-1-1-1h-5.1c-2,0-1.9,2-1.9,2h-2.1  c-1,0-1.9,0.9-1.9,1.9V5H10C8.3,5,7,6.3,7,8v52c0,1.7,1.3,3,3,3h44c1.7,0,3-1.3,3-3v-2C57,57.4,56.6,57,56,57z"></path>
                      <path d="M18,9h-3.9c-1.7,0-3.1,1.4-3.1,3.1v4c0,0.6,0.4,1,1,1s1-0.4,1-1v-4c0-0.5,0.4-1.1,1.1-1.1H18c0.6,0,1-0.4,1-1S18.6,9,18,9z"></path>
                      <path d="M14.1,57.1c-0.5,0-1.1-0.4-1.1-1.1c0-0.6-0.4-1-1-1s-1,0.4-1,1c0,1.7,1.4,3.1,3.1,3.1H22c0.6,0,1-0.4,1-1s-0.4-1-1-1H14.1z"></path>
                      <path d="M49.9,59.1c1.7,0,3.1-1.4,3.1-3.1V12.1c0-1.7-1.4-3.1-3.1-3.1h-3.6c-0.6,0-1,0.4-1,1s0.4,1,1,1h3.6c0.5,0,1.1,0.4,1.1,1.1  V56c0,0.5-0.4,1.1-1.1,1.1L32.3,57l-15.8-9.8c-0.3-0.2-0.7-0.2-1,0C15.2,47.3,15,47.6,15,48c0,1.3-0.8,2.4-2,2.8V20c0-0.6-0.4-1-1-1  s-1,0.4-1,1v32c0.1,0.8,0.7,1,0.7,1l20,6C31.8,59,49.9,59.1,49.9,59.1z M15.1,51.9c0.7-0.6,1.3-1.4,1.6-2.3l8.4,5.3L15.1,51.9z"></path>
                      <path d="M33,8c0-0.6-0.4-1-1-1h-6c-0.6,0-1,0.4-1,1s0.4,1,1,1h6C32.6,9,33,8.6,33,8z"></path>
                      <path d="M38,9c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1H38z"></path>
                      <path d="M40,18c-0.6,0-1,0.4-1,1v2c0,1.2,0.8,2,2,2h2v2.4l-3.4,1.7c-0.5,0.2-0.7,0.8-0.4,1.3c0.3,0.7,1.2,0.5,1.3,0.4l4-2  c0.3-0.2,0.6-0.5,0.6-0.9v-3h2c1.2,0,2-0.8,2-2v-2c0-1.2-0.8-2-2-2h-3c-0.6,0-1,0.4-1,1s0.4,1,1,1h3v2h-6v-2C41,18.4,40.6,18,40,18z  "></path>
                      <path d="M41,45c-1.2,0-2,0.8-2,2v2c0,0.6,0.4,1,1,1s1-0.4,1-1v-2h6v2h-2.9c-0.6,0-1,0.4-1,1s0.4,1,1,1H47c1.2,0,2-0.8,2-2v-2  c0-1.2-0.8-2-2-2h-2v-7c0-0.6-0.4-1-1-1h-4c-0.6,0-1,0.4-1,1s0.4,1,1,1h3v6H41z"></path>
                      <path d="M23,23c1.2,0,2-0.8,2-2v-2c0-1.2-0.8-2-2-2h-6c-1.2,0-2,0.8-2,2v2c0,1.2,0.8,2,2,2h2v7c0,0.6,0.4,1,1,1h4c0.6,0,1-0.4,1-1  s-0.4-1-1-1h-3v-6H23z M17,21v-2h6v2H17z"></path>
                      <path d="M37,54c0,0.6,0.4,1,1,1h10c0.6,0,1-0.4,1-1s-0.4-1-1-1H38C37.4,53,37,53.4,37,54z"></path>
                      <path d="M16,35c-0.6,0-1,0.4-1,1v8c0,0.6,0.4,1,1,1s1-0.4,1-1v-8C17,35.4,16.6,35,16,35z"></path>
                      <path d="M17,28c0-0.6-0.4-1-1-1s-1,0.4-1,1v4c0,0.6,0.4,1,1,1s1-0.4,1-1V28z"></path>
                      <path d="M34,55c0.6,0,1-0.4,1-1s-0.4-1-1-1h-2c-0.6,0-1,0.4-1,1s0.4,1,1,1H34z"></path>
                      <path d="M29.1,29.2c-0.4-0.3-1.1-0.2-1.4,0.2l-4.5,6c-0.2,0.3-0.3,0.7-0.1,1S23.6,37,24,37h4.8L27,47.8c-0.1,0.5,0.2,0.9,0.6,1.1  c0.5,0.2,1-0.1,1.2-0.3l12-16c0.2-0.3,0.3-0.7,0.1-1C40.7,31.2,40.4,31,40,31h-4.8L37,20.2c0.1-0.5-0.2-0.9-0.6-1.1  c-0.4-0.2-0.9,0-1.2,0.3l-4.5,6c-0.3,0.4-0.2,1.1,0.2,1.4c0.4,0.3,1.1,0.2,1.4-0.2l2-2.7l-1.3,8c-0.1,0.9,0.7,1.2,1,1.2h4l-8.3,11.1  l1.3-8c0-0.3-0.1-1.2-1-1.2h-4l3.3-4.4C29.6,30.2,29.5,29.5,29.1,29.2z"></path>
                    </svg>
                    
                  </li> */}
                          </ul>
                          <ul className="inline-flex w-full mt-1 ">
                            <li className="w-full mt-2">
                              <h2 className="f-f-r text-sm text-grey-vdark ">
                                Last Modified{" "}
                                {moment(design?.updated_at).fromNow()}
                              </h2>
                            </li>
                            {/* <li>
                              <Link
                                to={`/preview/doc/${design.site_id}/${design.id}`}
                              >
                                <button className="text-primary f-f-r text-tiny">
                                  Details
                                </button>
                              </Link>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {/* grid ended */}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}
