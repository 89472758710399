import React from 'react'
import FilloutTable from './FilloutTable'
import GenerationTable from './GenerationTable';

import { Link, useParams, useNavigate } from 'react-router-dom'
import Config from '../../../utils/Config';
import { useSelector, useDispatch } from 'react-redux';
import { setSiteDiscussionsGeneralEquips, setSiteDiscussionsSiteAreas } from "../../../redux/slices/basisOfDesignSlice";

import swal from 'sweetalert';

const staticEquipments = [
  {
    component: "Inverter",
    manufacturer_model: "Schneider / XW Pro 6848 NA",
    unit_rating: "6.8",
    units: "kVA",
    unit_qty: 4,
  },
  {
    component: "Charge Controller",
    manufacturer_model: "Schneider / MPPT 100",
    unit_rating: "600",
    units: "Vdc",
    unit_qty: 2,
  },
  {
    component: "Battery ESS",
    manufacturer_model: "SimpliPhi AmpliPhi Battery Modules",
    unit_rating: "3.8",
    units: "kWh-dc",
    unit_qty: 16,
  },
  {
    component: "Monitoring & Controls",
    manufacturer_model: "New Sun Road / Communications",
    unit_rating: "N/A",
    units: "N/A",
    unit_qty: 2,
  },
  {
    component: "HVAC",
    manufacturer_model: "Bard / W12AB Series",
    unit_rating: "1",
    units: "Ton",
    unit_qty: 1,
  },
  {
    component: "Fuel",
    manufacturer_model: "Propane Tank",
    unit_rating: "1000",
    units: "gallons",
    unit_qty: 1,
  },
];

const staticAreas = [
  { description: "Solar Container", area: 160 },
  { description: "Solar Array", area: 260 },
  { description: "Generator Room", area: 60 },
  { description: "Fenced Yard Area", area: 892 },
  { description: "Clear Zone (30 ft)", area: 10980 },
  { description: "Reduced Fuel Zone (100 ft)", area: 55270 },
  { description: "First Responder Panel Clear Zone", area: 70 },
];

export default function Index() {
  const user     = useSelector((state) => state.user.profile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { site_id, design_id } = useParams();

  const { site_discussions_general_equips, site_discussions_site_areas } = useSelector((state) => state.basisOfDesign);
  
  const [ isGenerationTableEditable, setIsGenerationTableEditable ] = React.useState(false);
  const [ isFilloutTableEditable, setIsFilloutTableEditable ] = React.useState(false);

  // React.useEffect(() => {
  //   // setTitleLocal(basis_title);
  // }, [site_discussions_general_equips]);

  const _onSaveGenerationEquipmentSummary = (equipments) => {
    const payload = { site_id: site_id, design_id, equipments: equipments }

    fetch(`${Config.API.URL}/design-basis/site-discussions/general-equipment`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` },
      body: JSON.stringify(payload),
    }).then((res) => {
      // if 401 error (unauthenticated user, then log user out)
      if ((res.status) === 401) {
        console.log("401 Error: Unauthenticated");

        localStorage.removeItem('user');
        navigate('/');
      }
      return res;
    }).then((res) => res.json()).then( async (res) => {
      // console.log('res:', res);

      // if request is succesful, alert user that project has been cloned
      if (res.status) {
        dispatch(setSiteDiscussionsGeneralEquips(res.data));
      } else {
        swal("Oh no!", res.message, "error");
      }
    }).catch((error) => {
      console.log('Error: ', error);
      
      swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
    });
  }

  const _onSaveSiteAreas = (areas) => {
    const payload = { design_id, areas: areas }

    fetch(`${Config.API.URL}/design-basis/site-discussions/site-areas`, {
      method: 'POST',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` },
      body: JSON.stringify(payload),
    }).then((res) => {
      // if 401 error (unauthenticated user, then log user out)
      if ((res.status) === 401) {
        console.log("401 Error: Unauthenticated");

        localStorage.removeItem('user');
        navigate('/');
      }
      return res;
    }).then((res) => res.json()).then( async (res) => {
      // console.log('res:', res);

      // if request is succesful, alert user that project has been cloned
      if (res.status) {
        dispatch(setSiteDiscussionsSiteAreas(res.data));
      } else {
        swal("Oh no!", res.message, "error");
      }
    }).catch((error) => {
      console.log('Error: ', error);
      
      swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
    });
  }

  const nextPage = () => {
    if (isGenerationTableEditable || isFilloutTableEditable) {
      swal({
        title: "Editable mode detected?",
        text: "When go to the next page, unsave changes will be lost. do you still want go to next page?",
        icon: "warning",
        buttons: ["No", "Next"],
        dangerMode: true,
      }).then((willProcced) => {
        if (willProcced) {
          if (!site_discussions_general_equips.length) {
            _onSaveGenerationEquipmentSummary(staticEquipments);
          }
          if (!site_discussions_site_areas.length) {
            _onSaveSiteAreas(staticAreas);
          }
          navigate(`/basis/setback/asumption/${site_id}/${design_id}`);
        } 
      });
    } else {
      if (!site_discussions_general_equips.length) {
        _onSaveGenerationEquipmentSummary(staticEquipments);
      }
      if (!site_discussions_site_areas.length) {
        _onSaveSiteAreas(staticAreas);
      }
      navigate(`/basis/setback/asumption/${site_id}/${design_id}`);
    }
  }
  const _onExtractScenarioProducts = () => {
    fetch(`${Config.API.URL}/sites/${site_id}`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` }
    }).then((res) => {
      // if 401 error (unauthenticated user, then log user out)
      if ((res.status) === 401) {
        console.log("401 Error: Unauthenticated");

        localStorage.removeItem('user');
        navigate('/');
      }
      return res;
    }).then((res) => res.json()).then( async (res) => {
      console.log('res.site:', res.data);

      // if request is succesful, alert user that project has been cloned
      if (res.status) {
        const data = res.data;
          if (data.active_scenario_id) {
          const scenario_id = data.active_scenario_id;

          const reqScenario = await fetch(`${Config.API.URL}/scenarios/${scenario_id}`, { headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` }});
          const resScenario = await reqScenario.json();

          let scenario_data = resScenario.data;

          let battery          = scenario_data?.battery_product[0];
          let chargecontroller = scenario_data?.chargecontroller_product[0];
          let inverter         = scenario_data?.inverter_product[0];

          let battery_manufacturer_model = `${battery.battery_product_manufacturer} / ${battery.battery_product_model}`;
          let battery_unit_rating        = battery.battery_product_unit_cost;
          let battery_unit_quantity      = battery.battery_product_quantity;

          let chargecontroller_manufacturer_model = `${chargecontroller.chargecontroller_product_manufacturer} / ${chargecontroller.chargecontroller_product_model}`;
          let chargecontroller_unit_rating        = chargecontroller.chargecontroller_product_unit_cost;
          let chargecontroller_unit_quantity      = chargecontroller.chargecontroller_product_quantity;

          let inverter_manufacturer_model = `${inverter.inverter_product_manufacturer} / ${inverter.inverter_product_model}`;
          let inverter_unit_rating        = inverter.inverter_product_unit_cost;
          let inverter_unit_quantity      = inverter.inverter_product_quantity;

          let index_battery          = staticEquipments.findIndex(x => x.component === 'Battery ESS');
          let index_chargecontroller = staticEquipments.findIndex(x => x.component === 'Charge Controller');
          let index_inverter         = staticEquipments.findIndex(x => x.component === 'Inverter');
          
          // console.log('manufacturer_model:', battery_manufacturer_model, chargecontroller_manufacturer_model, inverter_manufacturer_model);

          let equipments_data = [ ...staticEquipments ];

          // console.log('equipments_data', equipments_data);

          equipments_data[index_battery].manufacturer_model = battery_manufacturer_model;
          equipments_data[index_battery].unit_rating        = battery_unit_rating?.toString();;
          equipments_data[index_battery].unit_qty           = battery_unit_quantity;

          equipments_data[index_chargecontroller].manufacturer_model = chargecontroller_manufacturer_model;
          equipments_data[index_chargecontroller].unit_rating        = chargecontroller_unit_rating?.toString();;
          equipments_data[index_chargecontroller].unit_qty           = chargecontroller_unit_quantity;

          equipments_data[index_inverter].manufacturer_model = inverter_manufacturer_model;
          equipments_data[index_inverter].unit_rating        = inverter_unit_rating?.toString();;
          equipments_data[index_inverter].unit_qty           = inverter_unit_quantity;
          
          let payload = { site_id: site_id, design_id, equipments: equipments_data };

          // console.log('scenario_data:', battery, chargecontroller, inverter);

          fetch(`${Config.API.URL}/design-basis/site-discussions/general-equipment`, {
            method: 'POST',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${user.token}` },
            body: JSON.stringify(payload),
          }).then((res) => {
            // if 401 error (unauthenticated user, then log user out)
            if ((res.status) === 401) {
              console.log("401 Error: Unauthenticated");
      
              localStorage.removeItem('user');
              navigate('/');
            }
            return res;
          }).then((res) => res.json()).then( async (res) => {
            // console.log('equipment-res:', res);
      
            // if request is succesful, alert user that project has been cloned
            if (res.status) {
              dispatch(setSiteDiscussionsGeneralEquips(res.data));
            } else {
              swal("Oh no!", res.message, "error");
            }
          }).catch((error) => {
            console.log('Error: ', error);
            
            swal("Oh no!", 'An error occured!!! while connecting to server. Please try again', "error");
          });
        } else {
          swal("Oh no!", "This site doesn't have scenario", "error");
        }
      } else {
        swal("Oh no!", res.message, "error");
      }
    }).catch((error) => {
      console.log('Error: ', error);
    });
  }

  return (
    <div className='' >
      <h2 className=' f-f-r text-lg text-black ' >PG&E Basis of Design</h2>
      <h4 className=' f-f-sm text-black text-2xl  sm:text-3xl ' >Site Specific Discussion</h4>
      <h2 className=' f-f-m text-black text-lg mt-4 ' >Fill out the Equipment Summary Table below</h2>

      {/*  table started */}
      <GenerationTable onEditGenerationTable={setIsGenerationTableEditable} onSaveGenerationEquipmentSummary={_onSaveGenerationEquipmentSummary} onExtractScenarioProducts={_onExtractScenarioProducts} />

      {/* table ended */}
      <h2 className=' f-f-m text-black text-lg mt-20 ' >Fill out the Site Areas Table below</h2>
      <FilloutTable onEditFilloutTable={setIsFilloutTableEditable} onSaveSiteAreas={_onSaveSiteAreas} />
      {/* adjuest in bottom  */}
      <div className='  ' >

        <div className=' border border-gray-300 mt-20  ' ></div>
        <div className="grid grid-cols-12  gap-4  ">
          <div className="col-span-3">
          <Link to={`/basis/document/overview/${site_id}/${design_id}`} >
            <button className="  mt-8 relative border border-primary w-[110px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-primary  ">
              <ul className=' inline-flex text-center justify-center mt-1 mr-3 ' >
                <li>
                  <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.25 7.5L11.75 13L17.25 18.5" stroke="#ED583B" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </li>
                <li className='ml-1' >Back</li>
              </ul>
            </button>
          </Link>
          </div>
          <div className="col-span-9">
            <div className='text-end' >
              <ul className="inline-flex items-center">
                <Link to={`/basis/setback/asumption/${site_id}/${design_id}`}>
                  <li><button className="mt-8 text-center f-f-r text-lg text-black-darkest">Skip</button></li>
                </Link>
                <li className=" ml-6 ">
                  <button onClick={nextPage} className="mt-8 relative border bg-primary w-[127px] text-center h-[48px] rounded-[8px] f-f-r text-lg text-white">Next</button>
                </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      {/* adjuest in bottom ended  */}

    </div>
  )
}
