import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    title: "Untitled Design",
    shading_analysis: null,
    site_discussions_general_equips: [],
    site_discussions_site_areas: [],
    setback_notes_setback_assumptions: [],
    electrical_sizing_load_basis_summaries: [],
    electrical_sizing_site_areas: [],
    design_parameters: [],
    civil_design: [],
    temperature_design: [],
    design_deviation: [],
    monitoring_overview: [],
    design_life: [],
    others: []
}

export const basisOfDesignSlice = createSlice({
    name: 'basisOfDesign',
    initialState,
    reducers: {
        setTitle: (state, action) => {
            state.title = action.payload;
        },
        setShadingAnalysis: (state, action) => {
            state.shading_analysis = action.payload;
        },
        setSiteDiscussionsGeneralEquips: (state, action) => {
            state.site_discussions_general_equips = action.payload;
        },
        setSiteDiscussionsSiteAreas: (state, action) => {
            state.site_discussions_site_areas = action.payload;
        },
        setSetbackNotesSetbackAssumptions: (state, action) => {
            state.setback_notes_setback_assumptions = action.payload;
        },
        setElectricalSizingLoadBasisSummaries: (state, action) => {
            state.electrical_sizing_load_basis_summaries = action.payload;
        },
        setElectricalSizingSiteAreas: (state, action) => {
            state.electrical_sizing_site_areas = action.payload;
        },
        setDesignParameters: (state, action) => {
            state.design_parameters = action.payload;
        },
        setCivilDesign: (state, action) => {
            state.civil_design = action.payload;
        },
        setTemperatureDesign: (state, action) => {
            state.temperature_design = action.payload;
        },
        setDesignDeviation: (state, action) => {
            state.design_deviation = action.payload;
        },
        setMonitoringOverview: (state, action) => {
            state.monitoring_overview = action.payload;
        },
        setDesignLife: (state, action) => {
            state.design_life = action.payload;
        },
        setOthers: (state, action) => {
            state.others = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setTitle, setShadingAnalysis, setSiteDiscussionsGeneralEquips, setSiteDiscussionsSiteAreas, setSetbackNotesSetbackAssumptions, setElectricalSizingLoadBasisSummaries, setElectricalSizingSiteAreas, setDesignParameters, setCivilDesign, setTemperatureDesign, setDesignDeviation, setMonitoringOverview, setDesignLife, setOthers } = basisOfDesignSlice.actions

export default basisOfDesignSlice.reducer;