import React from "react";

export default function Designtable({ design_life }) {
  return (
    <div className="pr-10 pl-10">
      <div className="relative overflow-x-auto mt-8 ">
        <table className=" table-site w-full border border-slate-500 text-left  ">
          <caption className="border border-slate-600 px-3 py-2 f-f-m  text-lg text-center  bg-primary text-white ">
            DESIGN LIFE
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b  text-sm text-black-dark ">
              <th className="border border-slate-600 px-6 py-3    ">Component </th>
              <th className="border border-slate-600 px-6 py-3    ">Supplier </th>
              <th className="border border-slate-600 px-6 py-3    ">System </th>
              <th className="border border-slate-600 px-6 py-3    ">Warranty </th>
            </tr>
          </thead>
          <tbody>
            {design_life.map((component, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-sm text-black-dark "
                >
                  <td className="border border-slate-600 px-6 py-3">{component.component}</td>
                  <td className="border border-slate-600 px-6 py-3">{component.supplier}</td>
                  <td className="border border-slate-600 px-6 py-3">{component.system}</td>
                  <td className="border border-slate-600 px-6 py-3">{component.warranty}</td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Ampliphi 3.8 kWh
                            </td>
                            <td className="px-6 py-3   ">
                            Simpliphi
                            </td>
                            <td className="px-6 py-3   ">
                            Battery Bank 
                            </td>
                            <td className="px-6 py-3   ">
                            10 Year
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            CS3W-440MB-AG
                            </td>
                            <td className="px-6 py-3   ">
                            Canadian Solar
                            </td>
                            <td className="px-6 py-3   ">
                            Solar Array 
                            </td>
                            <td className="px-6 py-3   ">
                            20 Years
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Conext MPPT
                            </td>
                            <td className="px-6 py-3   ">
                            Schneider
                            </td>
                            <td className="px-6 py-3   ">
                            Charge Controller 
                            </td>
                            <td className="px-6 py-3   ">
                            5 Year
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            XW Pro 6848 NA
                            </td>
                            <td className="px-6 py-3   ">
                            Schneider
                            </td>
                            <td className="px-6 py-3   ">
                            Inverter 
                            </td>
                            <td className="px-6 py-3   ">
                            5 Years
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Ampliphi 3.8 kWh
                            </td>
                            <td className="px-6 py-3   ">
                            Simpliphi
                            </td>
                            <td className="px-6 py-3   ">
                            Battery Bank 
                            </td>
                            <td className="px-6 py-3   ">
                            10 Year
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            Stellar Edge
                            </td>
                            <td className="px-6 py-3   ">
                            New Sun Road
                            </td>
                            <td className="px-6 py-3   ">
                            Monitoring & Control 
                            </td>
                            <td className="px-6 py-3   ">
                            2 Years
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            SolarContainer
                            </td>
                            <td className="px-6 py-3   ">
                            BoxPower
                            </td>
                            <td className="px-6 py-3   ">
                            Container   
                            </td>
                            <td className="px-6 py-3   ">
                            10 Years
                            </td>
                        
                        </tr>
                        <tr className="bg-white border-b  f-f-r  text-sm text-black-dark ">
                            <td className="px-6 py-3   ">
                            AIE-G 30
                            </td>
                            <td className="px-6 py-3   ">
                            AIE
                            </td>
                            <td className="px-6 py-3   ">
                            Generator 
                            </td>
                            <td className="px-6 py-3   ">
                            2 Year
                            </td>
                        
                        </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
