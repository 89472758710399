import React from "react";

export default function FilloutTable({ site_discussions_site_areas }) {
  return (
    <div className="pr-10 pl-10">
      {/*  table started */}

      <div className="relative overflow-x-auto mt-3  ">
        <table className=" table-site w-full border border-slate-500 text-left  ">
          <caption className="border border-slate-600 px-3 py-2 f-f-m  text-lg text-center  bg-primary text-white ">
            Site Areas
          </caption>
          <thead className=" text-black  bg-grey-light shadow-lg  ">
            <tr className=" f-f-b text-sm text-black-dark ">
              <th className="border border-slate-600 px-6 py-3    ">Description</th>
              <th className="border border-slate-600 px-6 py-3 min-w-[250px] lg:min-w-full  ">
                Area (FT ²)
              </th>
            </tr>
          </thead>
          <tbody>
            {site_discussions_site_areas.map((item, i) => {
              return (
                <tr
                  key={i}
                  className="bg-white border-b  f-f-r text-sm text-black-dark "
                >
                  <td className="border border-slate-600 px-6 py-3">{item.description}</td>
                  <td className="border border-slate-600 px-6 py-3" style={{ width: 150 }}>
                    {item.area}
                  </td>
                </tr>
              );
            })}
            {/* <tr className="bg-white border-b  f-f-r text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Solar Container
                         </td>
                         <td className="px-6 py-3   ">
                         160
                         </td>
                         
                     
                     </tr>
                     <tr className="bg-white border-b  f-f-r text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Solar Array
                         </td>
                         <td className="px-6 py-3   ">
                         260
                         </td>
                         
                     
                     </tr>
                     <tr className="bg-white border-b  f-f-r text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Generator Room
                         </td>
                         <td className="px-6 py-3   ">
                         60
                         </td>
                     </tr>
                     <tr className="bg-white border-b  f-f-r text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Fenced Yard Area
                         </td>
                         <td className="px-6 py-3   ">
                         892
                         </td>
                     </tr>
                     <tr className="bg-white border-b  f-f-r text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Clear Zone (30 ft)
                         </td>
                         <td className="px-6 py-3   ">
                         10,980
                         </td>
                     </tr>
                     <tr className="bg-white border-b  f-f-r text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         Reduced Fuel Zone (100 ft)
                         </td>
                         <td className="px-6 py-3   ">
                         55,270
                         </td>
                     </tr>
                     <tr className="bg-white border-b  f-f-r text-sm text-black-dark ">
                         <td className="px-6 py-3   ">
                         First Responder Panel Clear Zone
                         </td>
                         <td className="px-6 py-3   ">
                         70
                         </td>
                     </tr> */}
          </tbody>
        </table>
      </div>

      {/* table ended */}
    </div>
  );
}
